import React, { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import Modal from "react-modal";
import ReactCustomModal from "components/common/ReactCustomModal/ReactCustomModal";

Modal.setAppElement("#root");

export default function FirstTimePopUp() {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    let pop_status = sessionStorage.getItem("popUpStatus");
    if (!pop_status) {
      setVisible(true);
      sessionStorage.setItem("popUpStatus", 1);
    }
  }, []);

  if (!visible) return null;

  const server = process.env.REACT_APP_ENV == "staging-test" ? "Test" : "UAT";

  return (
    <ReactCustomModal isOpen={visible} contentStyles={{ minHeight: "100px" }}>
      <span className="m-3">You are currently using MEC {server} Server</span>
      <div className="d-flex justify-content-end m-2">
        <Button
          className="mt-3"
          variant="success"
          onClick={() => {
            setVisible(false);
          }}>
          Continue
        </Button>
      </div>
    </ReactCustomModal>
  );
}
