import {
  downloadFileFromS3,
  getPillsArrayFromObject,
  redirectToViewExport,
} from "common/utils/utils";

import DownloadIconImg from "images/ic_file_download.svg";
import { EXPORT_REPORT_STATUS } from "common/constants";
import EyeIconImg from "images/Eye.svg";
import HyperLink from "../HyperLink/HyperLink";
import Pills from "../Pills/Pills";
import { convertToCompactFormat } from "common/utils/dateAndTimeUtils";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";

export const ExportTableBody = ({ exports, showCreatedBy }) => {
  const navigate = useNavigate();

  const handleOnClick = (id) => {
    navigate(`/exports/${id}`);
  };

  return (
    <tbody className="Tbody">
      {exports.map((export_report, index) => {
        // * Prepare additional filter pills array
        const { obj: additionalFilters, array: additionalFilterPills } =
          getPillsArrayFromObject(
            export_report?.filter_names?.additional_filters
          );
        const disabled =
          export_report?.report_status !== EXPORT_REPORT_STATUS.COMPLETED;

        // * Render the row
        return (
          <tr
            className="Tr"
            key={index}
            onClick={
              !disabled ? () => handleOnClick(export_report?.id) : undefined
            }>
            {showCreatedBy ? (
              <td className="Td">{export_report?.user_name}</td>
            ) : null}
            <td className="Td">
              {export_report?.filter_names?.common_filters?.partner}
            </td>
            <td className="Td">
              {export_report?.filter_names?.common_filters?.data_phase}
            </td>
            <td className="Td">{export_report?.report_type}</td>
            <td className="Td">
              {convertToCompactFormat(export_report?.updated_at) || ""}
            </td>
            <td className="Td Td_Pills">
              {additionalFilters && (
                <Pills
                  pills={additionalFilterPills}
                  showAllPills
                  renderWithLabels
                />
              )}
            </td>
            <td className="Td">
              <span>{export_report?.total_count} Records</span>

              {Object.entries(export_report?.pivot_table_summary || {}).map(
                ([column, count]) => (
                  <span key={column}>
                    <br />
                    {pluralize(column, count, true)}
                  </span>
                )
              )}
            </td>
            <td className="Td">{export_report?.report_status}</td>
            <td className="Td d-flex gap-4">
              <HyperLink
                iconSrc={EyeIconImg}
                altText="eye"
                text="View Details"
                onClick={() => redirectToViewExport(export_report?.id)}
                disabled={disabled}
              />
              <DownloadReportLink
                {...{ reportPath: export_report?.report_path, disabled }}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

const DownloadReportLink = ({ reportPath, disabled }) => {
  return (
    <HyperLink
      onClick={(event) => {
        event.stopPropagation();
        downloadFileFromS3(reportPath);
      }}
      iconSrc={DownloadIconImg}
      altText="Download"
      text="Download Report"
      disabled={disabled}
    />
  );
};
