import * as styles from "../PADetail.module.scss";

import { CapacityBodyTable } from "./CapacityBodyTable";
import { CapacityListHeader } from "./CapacityListHeader";
import PAProductTypeAndCapacityPieChart from "./PAProductTypeAndCapacityPieChart";
import { PRODUCT_TYPES } from "common/constants";

const CapacityResponse = ({
  capacity,
  isPieChartDataLoading,
  PAProductTypeStatistics,
  PACapacityStatistics,
}) => {
  const productTypeCapacitites = [
    PRODUCT_TYPES.pt3,
    PRODUCT_TYPES.pt1,
    PRODUCT_TYPES.pt2,
  ].map((prodType) => ({ [prodType]: capacity && capacity[prodType] }));
  return (
    <>
      <div className="d-flex col-12 justify-content-between">
        <div className="col-5">
          <span className={styles.PA_Detail_Heading}>Capacities</span>
          <section className="Table_Wrapper">
            <table className="Table">
              <CapacityListHeader />
              <CapacityBodyTable {...{ productTypeCapacitites }} />
            </table>
          </section>
        </div>
        <div className="col-6 my-auto">
          <PAProductTypeAndCapacityPieChart
            {...{
              isPieChartDataLoading,
              PAProductTypeStatistics,
              PACapacityStatistics,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CapacityResponse;
