import ArchiveExcludedRawRecord from "components/ArchiveExcludedRawRecord/ArchiveExcludedRawRecord";
import CleanDataRecord from "components/CleanDataRecord/CleanDataRecord";
import CleanDataUpdateImport from "components/CleanDataUpdateImport/CleanDataUpdateImport";
import DlightKenyaDataDetail from "components/DlightKenyaDataDetail/DlightKenyaDataDetail";
import DlightKenyaDataList from "components/DlightKenyaDataList/DlightKenyaDataList";
import DlightUgandaDataDetail from "components/DlightUgandaDataDetail/DlightUgandaDataDetail";
import DlightUgandaDataList from "components/DlightUgandaDataList/DlightUgandaDataList";
import DownloadFile from "components/DownloadFile/DownloadFile";
import ExportDetail from "components/ExportDetail/ExportDetail";
import Exports from "components/Exports/Exports";
import ForgotPassword from "components/ForgotPassword/ForgotPassword";
import ImportDetail from "components/ImportDetail/ImportDetail";
import Imports from "components/Imports/Imports";
import Login from "components/Login/Login";
import MongoliaDataDetail from "components/MongoliaDataDetail/MongoliaDataDetail";
import MongoliaDataList from "components/MongoliaDataList/MongoliaDataList";
import NewImport from "components/NewImport/NewImport";
import PADetail from "components/ProjectActivityDetail/PADetail";
import PartnerDashboardV2 from "components/PartnerDashboardV2/PartnerDashboard";
import PartnersList from "components/PartnerDashboardV2/PartnersList/PartnersList";
import ProjectActivityList from "components/ProjectActivityList/ProjectActivityList";
import RawDataRecord from "components/RawDataRecord/RawDataRecord";
import ResetPassword from "components/ResetPassword/ResetPassword";
import Search from "components/Search/Search";
import SearchDetail from "components/SearchDetail/SearchDetail";
import Searches from "components/Searches/Searches";
import UserDashboardV2 from "components/UserDashboardV2/UserDashboard";
import UserProfile from "components/UserProfile/UserProfile";

export default Object.freeze({
  Login: {
    component: <Login />,
    path: "/login",
  },
  ForgotPassword: {
    component: <ForgotPassword />,
    path: "/forgot-password",
  },
  ResetPassword: {
    component: <ResetPassword />,
    path: "/reset-password/:user_id/:token",
  },
  Imports: {
    component: <Imports />,
    path: "/imports",
  },
  Import: {
    component: <NewImport />,
    path: "/import",
  },
  Search: {
    component: <Search />,
    path: "/searches/search",
  },
  RecentFilters: {
    component: <Searches />,
    path: "/searches",
  },
  UserDashboardV2: {
    component: <UserDashboardV2 />,
    path: "/user-dashboardV2",
  },
  PartnersList: {
    component: <PartnersList />,
    path: "/partners",
  },
  PartnerDashboardV2: {
    component: <PartnerDashboardV2 />,
    path: "/partners/:partnerId",
  },
  Exports: {
    component: <Exports />,
    path: "/exports",
  },
  SearchDetail: {
    component: <SearchDetail />,
    path: "/searches/:filterId",
  },
  ImportDetail: {
    component: <ImportDetail />,
    path: "/imports/:rawDataImportId",
  },
  ExportDetail: {
    component: <ExportDetail />,
    path: "/exports/:exportId",
  },
  CleanDataUpdateImport: {
    component: <CleanDataUpdateImport />,
    path: "/clean-data-update-import",
  },
  DownloadFile: {
    component: <DownloadFile />,
    path: "/download-file/:filePath",
  },
  ProjectActivityList: {
    component: <ProjectActivityList />,
    path: "/project-activities",
  },
  PADetail: {
    component: <PADetail />,
    path: "/project-activities/:projectActivityId",
  },
  CleanDataRecord: {
    component: <CleanDataRecord />,
    path: "/searches/:filterId/clean-data/:cleanDataId",
  },
  CleanDataByLoanId: {
    component: <CleanDataRecord />,
    path: "/partners/:partnerId/clean-data/:cleanDataId",
  },
  MongoliaDataList: {
    component: <MongoliaDataList />,
    path: "/mongolian-program/",
  },
  MongoliaDataDetail: {
    component: <MongoliaDataDetail />,
    path: "/mongolian-program/:recordId",
  },
  DlightKenyaDataList: {
    component: <DlightKenyaDataList />,
    path: "/dlight-kenya/",
  },
  DlightKenyaDataDetail: {
    component: <DlightKenyaDataDetail />,
    path: "/dlight-kenya/:recordId",
  },
  DlightUgandaDataList: {
    component: <DlightUgandaDataList />,
    path: "/dlight-uganda/",
  },
  DlightUgandaDataDetail: {
    component: <DlightUgandaDataDetail />,
    path: "/dlight-uganda/:recordId",
  },
  UserProfile: {
    component: <UserProfile />,
    path: "/profile",
  },
  RawDataRecord: {
    component: <RawDataRecord />,
    path: "/searches/:filterId/raw-data/:rawDataId",
  },
  ArchiveExcludedRawRecord: {
    component: <ArchiveExcludedRawRecord />,
    path: "/searches/:filterId/archive-excluded-raw-data/:archiveRawDataId",
  },
});
