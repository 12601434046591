import { getBranchList, getStateList } from "common/API/filtersAndGroupByApi";
import {
  setBranch,
  setBranchList,
  setState,
  setStateList,
} from "slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

import FilterItem from "components/Search/FilterItem/FilterItem";
import { conditionallyCreateOptionObject } from "../../utils";
import styles from "./StateAndBranchFilters.module.scss";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "common/utils/errorUtils";

const StateAndBranchFilters = ({ selectedDataFilter }) => {
  // * #####################################
  // * Redux States
  // * #####################################
  const {
    state,
    branch,
    stateList,
    branchList,
    partner,
    showNonMandatoryFilters,
    isGettingPrefilled,
  } = useSelector((state) => state.search);

  const dispatch = useDispatch();

  const additionalFilterIds =
    selectedDataFilter?.filter_ids?.additional_filters;
  const additionalFilterNames =
    selectedDataFilter?.filter_names?.additional_filters;

  // * #####################################
  // * Use effects
  // * #####################################

  // * Use effect to fetch branch based on partner and state
  useEffect(() => {
    (async () => {
      try {
        const branches = await getBranchList(partner?.value, state?.value, "");
        dispatch(setBranchList(branches));
      } catch (error) {
        toast.error(getErrorMessage(error, "Failed to fetch branch list"));
      }
    })();

    if (isGettingPrefilled) return;

    // * If filter is reapplied, set branch based on template
    dispatch(
      setBranch(
        state?.value === additionalFilterIds?.state
          ? conditionallyCreateOptionObject(
              additionalFilterNames,
              additionalFilterIds,
              "branch"
            )
          : ""
      )
    );
  }, [partner, state, additionalFilterIds]);

  // * Effect to handle showing non mandatory filters
  useEffect(() => {
    (async () => {
      if (!showNonMandatoryFilters) return;

      try {
        const states = await getStateList();
        dispatch(setStateList(states));
      } catch (error) {
        toast.error(getErrorMessage(error, "Failed to fetch state list"));
      }
    })();
  }, [showNonMandatoryFilters]);

  // * #####################################
  // * Dynamic options and Handlers
  // * #####################################

  const getDynamicBranchOptions = (query) => {
    const res = getBranchList(partner?.value, state?.value, query);
    return res;
  };

  const handleState = (state) => {
    dispatch(setState(state));
  };

  const handleBranch = (branch) => {
    dispatch(setBranch(branch));
  };

  return (
    <>
      <div className={styles.State_Dd}>
        <FilterItem
          {...{
            label: "State",
            type: "dropdown",
            selectedOption: state,
            setSelectedOption: handleState,
            options: stateList,
          }}
        />
      </div>
      {state && (
        <div className={styles.Branch_Dd}>
          <FilterItem
            {...{
              label: "Branch",
              type: "dropdown",
              selectedOption: branch,
              setSelectedOption: handleBranch,
              options: branchList,
              dynamicOptions: getDynamicBranchOptions,
              fetchOptions: true,
              dropdownProps: {
                isMulti: true,
                closeMenuOnSelect: false,
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default StateAndBranchFilters;
