import Pill from "../Pill/Pill";
import React from "react";
import RichText from "../RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import Tooltip from "../Tooltip/Tooltip";

const Pills = ({ pills, renderWithLabels, showAllPills }) => {
  if (pills.length === 0) {
    return null;
  }

  const [firstPill, ...remainingPills] = pills;

  const remainingPillComponents = remainingPills.map((pill, idx) => (
    <Pill
      pill={pill}
      key={idx}
      renderWithLabels={renderWithLabels}
      showAllPills={showAllPills}
    />
  ));

  return (
    <>
      <Pill
        pill={firstPill}
        renderWithLabels={renderWithLabels}
        showAllPills={showAllPills}
      />
      {remainingPills.length > 0 &&
        (showAllPills ? (
          remainingPillComponents
        ) : (
          <>
            <div className="Pill" data-tip data-for="pill">
              <RichText textType={TextTypes.BODY4}>
                +{remainingPills.length} more
              </RichText>
            </div>
            <Tooltip id="pill">{remainingPillComponents}</Tooltip>
          </>
        ))}
    </>
  );
};

export default Pills;
