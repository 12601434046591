import * as styles from "../../AssignPAModalContent.module.scss";

import { useEffect, useState } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import every from "lodash/every";
import { getErrorMessage } from "common/utils/errorUtils";
import { getPACodesList } from "common/API/filtersAndGroupByApi";
import { toast } from "react-toastify";

const ProjectActivityDropdown = ({
  selectedPA,
  setSelectedPA,
  productTypeCounts,
}) => {
  const [projectActivityList, setProjectActivityList] = useState([]);

  useEffect(() => {
    fetchProjectActivityList(setProjectActivityList);
  }, []);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: true,
        customWidth: "inherit",
        selectedOption: selectedPA,
        setSelectedOption: setSelectedPA,
        options: projectActivityList,
        dynamicOptions: getDynamicPACodesOptions,
        placeholder: "Select project activity for assignment",
        className: styles.ProjectActivity_Dropdown,
        isOptionDisabled: (option) => disableOption(option, productTypeCounts),
      }}
    />
  );
};

export default ProjectActivityDropdown;

// * #####################################
// * Utilities
// * #####################################

const fetchProjectActivityList = async (setProjectActivityList) => {
  try {
    const data = await getPACodesList({
      include_capacities: true,
      send_all: true,
    });
    const paCodes = data.map((paOption) => {
      return {
        ...paOption,
        //* Do not wrap multiple consecutive whitespace like 'tab' in title
        name: <span style={{ whiteSpace: "pre-wrap" }}>{paOption.name}</span>,
      };
    });
    setProjectActivityList(paCodes);
  } catch (error) {
    toast.error(
      getErrorMessage(error, "Failed to fetch project activity list")
    );
  }
};

const getDynamicPACodesOptions = (query) => {
  const res = getPACodesList({
    code: query,
    include_capacities: true,
    send_all: true,
  });
  return res;
};

const disableOption = (option, productTypeCounts) => {
  const isAllCapacityAvailable = every(
    Object.keys(productTypeCounts || []),
    (productType) => {
      const countInResults = productTypeCounts[productType] || 0;
      const availableCapacity =
        (option?.capacities[productType] &&
          option?.capacities[productType]["remaining_capacity"]) ||
        0;

      return countInResults <= availableCapacity;
    }
  );

  return !isAllCapacityAvailable;
};
