import * as commonStyles from "styles/Common.module.scss";
import * as styles from "../SearchDetailRecordSection.module.scss";

import {
  CURRENT_ORDER_FIELD,
  ORDERING_FIELD,
  SORTING_ORDER,
} from "common/constants";
import {
  storeScrollPosition,
  fetchStoredScrollPosition,
} from "common/utils/utils";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import RecordListHeader from "./RecordListHeader";
import RecordListTableBody from "./RecordListTableBody";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import UpdateCountMessage from "./UpdateCountMessage";
import classNames from "classnames";
import { createRef } from "react";
import { useEffect } from "react";

const RecordTable = ({
  records,
  filterId,
  recordStatus,
  isLoading,
  noDataFound,
  sortOrder,
  currentOrderField,
  orderingFields,
  handleSorting,
  hasNextPage,
  fetchNextPage,
  lastPage,
  handleUpdateCount,
  isCountStale,
}) => {
  const recordTableRef = createRef();

  const tableclasses = classNames("Table_Wrapper", styles.Records_Table);
  const containerClasses = { overflow: "default" };

  useEffect(() => {
    !isLoading &&
      fetchStoredScrollPosition("search-records-scrollable", "RECORDS");
    sessionStorage.setItem(SORTING_ORDER, sortOrder);
    sessionStorage.setItem(CURRENT_ORDER_FIELD, currentOrderField);
    sessionStorage.setItem(ORDERING_FIELD, orderingFields);
  }, [isLoading, records, sortOrder, currentOrderField, orderingFields]);
  return (
    <>
      <section
        id="search-records-scrollable"
        ref={recordTableRef}
        className={tableclasses}>
        <InfiniteScroll
          style={containerClasses}
          loader={<h4 className="Font_H8">Loading...</h4>}
          hasMore={hasNextPage}
          next={fetchNextPage}
          dataLength={lastPage * records?.length || 0}
          onScroll={(event) => storeScrollPosition(event, "RECORDS")}
          scrollableTarget="search-records-scrollable">
          <table className="Table overflow-auto">
            <RecordListHeader
              {...{
                records,
                sortOrder,
                currentOrderField,
                handleSorting,
                isLoading,
                isCountStale,
              }}
            />
            {!isCountStale && (
              <RecordListTableBody {...{ records, filterId, recordStatus }} />
            )}
          </table>
          <ScrollToTop
            element={recordTableRef}
            className={styles.Scroll_To_Top_Btn_Search_detail}
          />
        </InfiniteScroll>
        {isCountStale && !noDataFound && (
          <UpdateCountMessage {...{ handleUpdateCount }} />
        )}
        {noDataFound && !isLoading && !isCountStale && (
          <NoDataFound
            {...{
              title: "No records found for requested search",
              wrapperClasses: commonStyles.Table_Body_Container,
            }}
          />
        )}
        {isLoading && (
          <CustomLoader
            {...{ containerClasses: commonStyles.Table_Body_Container }}
          />
        )}
      </section>
    </>
  );
};

export default RecordTable;
