import ErrorIcon from "images/ic_error.svg";

const ErrorMessage = ({ message }) => {
  return (
    <>
      <img src={ErrorIcon} alt="error" className="col-2 mb-3" />
      <p className="Font_H4 mb-3">{message}</p>
    </>
  );
};

export default ErrorMessage;
