import { getFieldURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getPartnersList = () => {
  const url = getFieldURLs("getPartners");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getDataPhaseList = () => {
  const url = getFieldURLs("getDataPhases");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getProductTypeList = async (queryParams) => {
  const url = getFieldURLs("getProductTypes");

  return axiosInstance.get(url, {
    params: queryParams,
    headers: getAuthorizationHeaders(),
  });
};
