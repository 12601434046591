import * as styles from "./OverviewTab.module.scss";

import HyperLink from "components/common/HyperLink/HyperLink";
import LoanIdModal from "../LoanIdModal/LoanIdModal";
import PartnerSalesChart from "components/common/PartnerSalesChart/PartnerSalesChart";
import TopPerformers from "./TopPerformers/TopPerformers";
import { useState } from "react";

const OverviewTab = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <section className={styles.Overview_Container}>
        <PartnerSalesChart />
        <TopPerformers />
        <div className={styles.Find_Record}>
          <span className="Font_H9 Font_Title_Color">
            To search an individual clean data record using loan id &nbsp;
          </span>
          <HyperLink
            {...{
              text: "click here",
              onClick: () => setIsModalOpen(true),
            }}
          />
        </div>
      </section>
      {isModalOpen && <LoanIdModal {...{ isModalOpen, setIsModalOpen }} />}
    </>
  );
};

export default OverviewTab;
