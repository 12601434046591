import { Navigate } from "react-router-dom";
import routeConstants from "../routeConstants";

const home_page_route = {
  component: <Navigate to={routeConstants["PartnersList"].path} />,
  path: "/",
};

export default Object.freeze([
  routeConstants["Imports"],
  routeConstants["Search"],
  routeConstants["RecentFilters"],
  routeConstants["PartnersList"],
  routeConstants["PartnerDashboardV2"],
  routeConstants["Exports"],
  routeConstants["SearchDetail"],
  routeConstants["ImportDetail"],
  routeConstants["ExportDetail"],
  routeConstants["DownloadFile"],
  routeConstants["ProjectActivityList"],
  routeConstants["PADetail"],
  routeConstants["CleanDataRecord"],
  routeConstants["CleanDataByLoanId"],
  ...(process.env.REACT_APP_ENV == "production"
    ? []
    : [
        routeConstants["MongoliaDataList"],
        routeConstants["MongoliaDataDetail"],
        routeConstants["DlightKenyaDataList"],
        routeConstants["DlightKenyaDataDetail"],
        routeConstants["DlightUgandaDataList"],
        routeConstants["DlightUgandaDataDetail"],
      ]),
  routeConstants["UserProfile"],
  routeConstants["RawDataRecord"],
  routeConstants["ArchiveExcludedRawRecord"],
  home_page_route,
]);
