import { useEffect } from "react";

/**
 * A custom hook that sets a timeout to clear filters after 20min
 * if user stays on a specific page
 * @param pageIdentifier - This is a unique identifier for a current page.
 * @param clearFilters -  A function used to clear any filters of a page identifies by pageIdentifier
 */
export const useAutoClearFilter = (pageIdentifier, clearFilters) => {
  /**
   * The code is retrieving the previous origin name.
   * It is also getting the current pathname and splitting it by "/" to get an array of segments.
   * The filter(Boolean) is used to remove any empty segments from the array.
   */
  const previousOriginName = sessionStorage.getItem("previousOriginName");
  const pathName = window.location.pathname;
  const currentOriginPathname = pathName.split("/").filter(Boolean)?.[0];

  useEffect(() => {
    const currentURL = window.location;
    const storedTimeID = sessionStorage.getItem(`${pageIdentifier}-timeID`);

    /* To check if there is a stored timeout ID in the
    sessionStorage for the given `pageIdentifier`. */
    if (storedTimeID) {
      clearTimeout(storedTimeID);
      sessionStorage.removeItem(`${pageIdentifier}-timeID`);
    }

    /* A cleanup function, called when component unmounts */
    return () => {
      /* Get the current pathname when component unmounts */
      const pathName1 = window.location.pathname;

      /* Extract the first part of the pathname, ignoring empty segments. */
      const currentOriginPathname1 = pathName1.split("/").filter(Boolean)?.[0];

      /* Check if the current URL includes the specified page identifier */
      /* Or if the origin has changed */
      if (
        !currentURL.href.includes(pageIdentifier) ||
        (previousOriginName && currentOriginPathname1 !== previousOriginName)
      ) {
        const timeID = setTimeout(() => {
          clearFilters(pageIdentifier);
        }, 1200000);
        sessionStorage.setItem(`${pageIdentifier}-timeID`, timeID);
      }
    };
  }, [pageIdentifier]);

  useEffect(() => {
    return () => {
      // Update the stored origin name for next execution.
      sessionStorage.setItem("previousOriginName", currentOriginPathname);
    };
  }, []);
};
