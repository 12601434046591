import * as styles from "./MPAndDateFilters.module.scss";

import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "common/constants";
import {
  getISOFormatedDateString,
  isValidDate,
} from "common/utils/dateAndTimeUtils";
import {
  setEarliestDate,
  setLatestDate,
  setMonitoringPeriod,
  setMonitoringPeriodList,
} from "slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

import DateRangeFilterItem from "components/Search/DateRangeFilterItem/DateRangeFilterItem";
import FilterItem from "components/Search/FilterItem/FilterItem";
import InfoIcon from "components/common/InfoIcon/InfoIcon";
import { conditionallyCreateOptionObject } from "../../utils";
import { getErrorMessage } from "common/utils/errorUtils";
import { getMonitoringPeriodList } from "common/API/filtersAndGroupByApi";
import { toast } from "react-toastify";
import { useEffect } from "react";

const MPAndDateFilters = ({ selectedDataFilter }) => {
  // * #####################################
  // * Redux States
  // * #####################################

  const {
    earliestDate,
    latestDate,
    monitoringPeriod,
    monitoringPeriodList,
    showNonMandatoryFilters,
    isGettingPrefilled,
  } = useSelector((state) => state.search);

  const rangeKey = "selection";
  const selectedDateRange = [
    {
      startDate: earliestDate ? new Date(earliestDate) : DEFAULT_START_DATE,
      endDate: latestDate ? new Date(latestDate) : DEFAULT_END_DATE,
      key: rangeKey,
    },
  ];

  const additionalFilterIds =
    selectedDataFilter?.filter_ids?.additional_filters;
  const additionalFilterNames =
    selectedDataFilter?.filter_names?.additional_filters;

  const dispatch = useDispatch();

  // * #####################################
  // * Use effects
  // * #####################################

  //* Use effect to fetch monitoring period list
  useEffect(() => {
    if (!showNonMandatoryFilters) return;

    if (isGettingPrefilled) return;

    getMPList(dispatch);

    // * If filter is reapplied, set monitoring period based on template
    dispatch(
      setMonitoringPeriod(
        conditionallyCreateOptionObject(
          additionalFilterNames,
          additionalFilterIds,
          "monitoring_period"
        )
      )
    );
  }, [showNonMandatoryFilters, getMPList]);

  // * #####################################
  // * Handlers
  // * #####################################

  const handleDateRange = (dateRangeArr) => {
    const { startDate, endDate } = dateRangeArr[0];

    dispatch(
      setEarliestDate(
        isValidDate(startDate) ? getISOFormatedDateString(startDate) : ""
      )
    );
    dispatch(
      setLatestDate(
        isValidDate(endDate) ? getISOFormatedDateString(endDate) : ""
      )
    );
    dispatch(setMonitoringPeriod(""));
  };

  const handleMonitoringPeriod = (monitoringPeriod) => {
    dispatch(setMonitoringPeriod(monitoringPeriod));
    dispatch(setEarliestDate(monitoringPeriod?.start_date));
    dispatch(setLatestDate(monitoringPeriod?.end_date));
  };

  return (
    <>
      <div className={styles.Monitoring_Period_Dd}>
        <FilterItem
          {...{
            label: "Monitoring Period",
            type: "dropdown",
            selectedOption: monitoringPeriod,
            setSelectedOption: handleMonitoringPeriod,
            options: monitoringPeriodList,
          }}
        />
      </div>
      <div className={styles.Earliest_Date}>
        <div className="d-flex gap-4 ">
          <DateRangeFilterItem
            {...{
              label: "Date Range",
              rangeKey,
              selectedDateRange,
              setSelectedDateRange: handleDateRange,
            }}
          />
          <InfoIcon msg="Either select a monitoring period or a date range" />
        </div>
      </div>
    </>
  );
};

export default MPAndDateFilters;

const getMPList = async (dispatch) => {
  try {
    const mpList = await getMonitoringPeriodList();
    dispatch(setMonitoringPeriodList(mpList));
  } catch (error) {
    toast.error(
      getErrorMessage(error, "Failed to fetch monitoring period list")
    );
  }
};
