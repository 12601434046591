import * as styles from "./PASalesChart.module.scss";

import PartnerFilter from "../SalesChart/SalesChartFilters/PartnerFilter";
import ProductTypeFilter from "../SalesChart/SalesChartFilters/ProductTypeFilter";
import SalesChartFilters from "../SalesChart/SalesChartFilters/SalesChartFilters";
import SalesChartTitle from "./SalesChartTitle";
import SalesComposedChartWrapper from "./SalesComposedChartWrapper";
import StateFilter from "../SalesChart/SalesChartFilters/StateFilter";
import TimelineFilter from "../SalesChart/SalesChartFilters/TimelineFilter";
import { getErrorMessage } from "common/utils/errorUtils";
import { getPASalesStatistics } from "common/API/projectActivityApi";
import moment from "moment";
import { useQuery } from "react-query";
import { useState } from "react";

const PASalesChart = ({ projectActivityId, PADetail }) => {
  const [queryParams, setQueryParams] = useState({});
  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useQuery(["pa-sales-statistics", projectActivityId, queryParams], () =>
    getPASalesStatistics(projectActivityId, queryParams)
  );

  const chartData = response?.data?.payload?.sales_data;
  let firstSaleDate = response?.data?.payload?.first_sale_date;
  let lastSaleDate = response?.data?.payload?.last_sale_date;
  const isEmptyData = chartData?.length === 0;

  firstSaleDate =
    moment(firstSaleDate, "YYYY-MM-DD").isValid() &&
    moment(firstSaleDate, "YYYY-MM-DD").format("MMM Y");
  lastSaleDate =
    moment(lastSaleDate, "YYYY-MM-DD").isValid() &&
    moment(lastSaleDate, "YYYY-MM-DD").format("MMM Y");

  // * Get unique years from the data
  const uniqueYears =
    (chartData && [...new Set(chartData.map((item) => item.year))]) || [];

  return (
    <>
      <section className={styles.Chart_Container}>
        <div className={styles.Chart_Card}>
          <span className="Font_H7 Font_Title_Color d-flex flex-start pb-4 ">
            Sales Overview
          </span>
          <div className="d-flex flex-wrap-reverse">
            <div className={styles.Chart_Filter_Container}>
              {!isError && (
                <SalesChartFilters>
                  <div className="col-6">
                    <ProductTypeFilter {...{ setQueryParams }} />
                    <TimelineFilter {...{ setQueryParams }} />
                  </div>
                  <div className="col-6">
                    <PartnerFilter {...{ setQueryParams, PADetail }} />
                    <StateFilter {...{ setQueryParams, PADetail }} />
                  </div>
                </SalesChartFilters>
              )}
            </div>

            <div className="d-flex flex-column centered-div">
              {!isError && (
                <SalesChartTitle
                  {...{
                    PADetail,
                    productTypeName: queryParams?.product_type_name,
                    partner: queryParams?.partner_name,
                    state: queryParams?.state_name,
                    startYear:
                      (chartData && chartData[0]?.year) ||
                      queryParams.start_year,
                    endYear:
                      (chartData && chartData[chartData.length - 1]?.year) ||
                      queryParams.end_year,
                  }}
                />
              )}
              <SalesComposedChartWrapper
                {...{
                  uniqueYearsLength: uniqueYears?.length,
                  chartData,
                  isLoading,
                  isError,
                  isEmptyData,
                  firstSaleDate,
                  lastSaleDate,
                  queryParams,
                  errorMessage: getErrorMessage(
                    error,
                    "Cannot fetch Chart Data. Something went wrong"
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
};

export default PASalesChart;
