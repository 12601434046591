import * as styles from "./SearchDetailLeftSection.module.scss";

import SearchActions from "./SearchActions/SearchActions";
import SearchPillSection from "components/common/SearchPillSection/SearchPillSection";
import SearchRecentExports from "./SearchRecentExports/SearchRecentExports";

const SearchDetailLeftSection = ({
  filterId,
  isBookmarked,
  setIsBookmarked,
  setIsSaved,
  filterNames,
  groupByNames,
  isGenerateReportModalOpen,
}) => {
  return (
    <section className={styles.Search_Left_Section}>
      <SearchPillSection {...{ filterNames, groupByNames }}>
        <SearchActions
          {...{
            isBookmarked,
            setIsBookmarked,
            filterId,
            setIsSaved,
            filterNames,
            groupByNames,
          }}
        />
      </SearchPillSection>
      <SearchRecentExports key={isGenerateReportModalOpen} {...{ filterId }} />
      <footer className={styles.Footer}></footer>
    </section>
  );
};

export default SearchDetailLeftSection;
