import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import { useSelector } from "react-redux";

const SearchButton = ({ handleFilterSubmit, areDataFiltersSame }) => {
  const { partner, dataPhase } = useSelector((state) => state.search);
  // Enable only when Partner and DataPhase is selected
  const disableSearch = !(partner && dataPhase);

  return (
    <Button
      buttonType={ButtonTypes.PRIMARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={() => {
        handleFilterSubmit();
      }}
      isDisabled={disableSearch}
      className="me-3">
      <span>{areDataFiltersSame ? "Update Count" : "Search"}</span>
    </Button>
  );
};

export default SearchButton;
