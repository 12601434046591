import EraseImportModalContent from "./EraseImportModalContent/EraseImportModalContent";
import ReactCustomModal from "components/common/ReactCustomModal/ReactCustomModal";

const EraseImportModal = ({
  isEraseImportModalOpen,
  setIsEraseImportModalOpen,
  importDetail,
  refetch,
}) => {
  return (
    <ReactCustomModal
      {...{
        isOpen: isEraseImportModalOpen,
        onRequestClose: () => setIsEraseImportModalOpen(false),
        contentStyles: {
          minHeight: "150px",
        },
      }}>
      <EraseImportModalContent
        {...{ setIsEraseImportModalOpen, importDetail, refetch }}
      />
    </ReactCustomModal>
  );
};

export default EraseImportModal;
