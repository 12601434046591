import { axiosInstance, getAuthorizationHeaders } from "./api";

import { getRawDataURLs } from "common/utils/apiUrls";

export const getRawDataRecord = ({ rawDataId, dataFilterId }) => {
  const url = getRawDataURLs("getRawDataRecord", { rawDataId });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      data_filter_id: dataFilterId,
    },
  });
};
