import ImportSummary from "../ImportSummary/ImportSummary";
import Overview from "../Overview/Overview";
import Performance from "../Performance/Performance";

const ImportDetailLeftSection = ({ importDetail }) => {
  return (
    <section className="d-flex flex-row flex-xxl-column overflow-auto">
      <Overview
        {...{
          details: importDetail,
          title: "Overview",
          type: "main",
        }}
      />
      {importDetail?.is_correction_import &&
        importDetail?.parent_uploaded_file && (
          <Overview
            {...{
              details: importDetail?.parent_uploaded_file,
              title: "Correction of Import",
              className: "cursor-pointer",
            }}
          />
        )}
      {importDetail?.child_uploaded_files.map((childImport, index) => {
        return (
          <Overview
            key={childImport.id}
            {...{
              details: childImport,
              title:
                importDetail?.child_uploaded_files.length > 1
                  ? `Sub Import ${index + 1}`
                  : "Sub Import",
              className: "cursor-pointer",
            }}
          />
        );
      })}
      <ImportSummary
        {...{
          importSummary: importDetail?.import_summary,
        }}
      />
      <Performance
        {...{
          importDetail,
        }}
      />
    </section>
  );
};

export default ImportDetailLeftSection;
