import SearchList from "components/common/SearchList/SearchList";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { getUserSearches } from "common/API/searchAPIs";

const SearchTab = () => {
  return (
    <>
      <SearchList
        {...{ currentDashboard: "user", fetchSearches: getUserSearches }}
      />
      <ScrollToTop />
    </>
  );
};
export default SearchTab;
