import * as styles from "./ModalContent.module.scss";

import {
  ButtonContentTypes,
  ButtonTypes,
  TextTypes,
} from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import ModalCloseButton from "components/common/ModalCloseButton/ModalCloseButton";
import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import RichText from "components/common/RichText/RichText";
import { generateFilterDetailReport } from "common/API/filtersAndGroupByApi";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";

const ExportReportActions = ({
  refetchSearchRecords,
  exportFormat,
  setExportFormat,
  exportFormatOptions,
  setIsGenerateReportModalOpen,
  filterId,
  isLoading,
}) => {
  return (
    <div className={styles.Export_Report_Actions}>
      <div className={styles.Export_Format}>
        <RichText textType={TextTypes.SUBHEAD3} className="text-nowrap">
          Export Format:
        </RichText>
      </div>

      <div className={styles.Export_Format_Dropdown}>
        <ReactSelectDropdown
          isAsync={false}
          selectedOption={exportFormat}
          setSelectedOption={setExportFormat}
          options={exportFormatOptions}
          customWidth={"130px"}
          isClearable={false}
          isSearchable={false}
        />
      </div>
      <ExportReportActionButtons
        {...{
          refetchSearchRecords,
          setIsGenerateReportModalOpen,
          filterId,
          isLoading,
          exportFormat,
        }}
      />
    </div>
  );
};

export default ExportReportActions;

//***************************/
//*****Local Components******/
//***************************/

const ExportReportActionButtons = ({
  refetchSearchRecords,
  setIsGenerateReportModalOpen,
  exportFormat,
  filterId,
  isLoading,
}) => {
  return (
    <div className={styles.Modal_Buttons}>
      <ModalCloseButton {...{ setIsModalOpen: setIsGenerateReportModalOpen }} />
      <ExportButton
        {...{
          refetchSearchRecords,
          filterId,
          isLoading,
          exportFormat,
          setIsGenerateReportModalOpen,
        }}
      />
    </div>
  );
};

const ExportButton = ({
  refetchSearchRecords: refetch,
  filterId,
  isLoading,
  exportFormat,
  setIsGenerateReportModalOpen,
}) => {
  const handleExportReport = async (filterId, exportFormat) => {
    try {
      const response = await generateFilterDetailReport(filterId, exportFormat);
      toast.success(response?.data?.message || "Started Generating the report");
      refetch();
    } catch (error) {
      toast.error(getErrorMessage(error, "Error while generating the report"));
    }
    setIsGenerateReportModalOpen(false);
  };
  return (
    <Button
      buttonType={ButtonTypes.PRIMARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={() => {
        handleExportReport(filterId, exportFormat.value);
      }}
      className="me-3"
      isDisabled={isLoading}>
      <span>Export</span>
    </Button>
  );
};
