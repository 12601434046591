import * as styles from "./UserProfile.module.scss";

import UserProfileLeftSection from "./UserProfileLeftSection/UserProfileLeftSection";
import UserProfileRightSection from "./UserProfileRightSection/UserProfileRightSection";

const UserProfile = () => {
  return (
    <main className={styles.User_Profile_Container}>
      <UserProfileLeftSection />
      <UserProfileRightSection />
    </main>
  );
};

export default UserProfile;
