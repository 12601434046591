import Button from "components/common/Button/Button";
import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

const ClearAllButton = ({ setClearForm }) => {
  return (
    <Button
      buttonType={ButtonTypes.SECONDARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={() => {
        setClearForm(true);
      }}
      className="me-3">
      <span>Clear all</span>
    </Button>
  );
};

export default ClearAllButton;
