import { useEffect, useState } from "react";
import TextField from "../TextField/TextField";

const EmailTextField = ({ className, setEmail }) => {
  const [emailInputRef, setEmailInputRef] = useState();

  useEffect(() => {
    if (!emailInputRef || !emailInputRef.current) return;
    emailInputRef.current.oninvalid = (event) => {
      event.target.setCustomValidity("Please enter a valid email");
    };
  }, [emailInputRef]);

  return (
    <TextField
      {...{
        id: "email",
        type: "email",
        setElementRef: setEmailInputRef,
        onChange: (e) => {
          setEmail(e.target.value);
          e.target.setCustomValidity("");
        },
        placeholder: "Email",
        className,
      }}
    />
  );
};

export default EmailTextField;
