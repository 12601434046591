import ExportList from "components/common/ExportList/ExportList";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { getPartnerExports } from "common/API/exportAPIs";
import { useSelector } from "react-redux";

const ExportTab = ({ dashboardRef }) => {
  const { id: partnerId } = useSelector((state) => state.partner.partner);
  return (
    <>
      <ExportList
        {...{
          showCreatedBy: true,
          fetchExports: getPartnerExports,
          partnerId,
        }}
      />
      <ScrollToTop element={dashboardRef} />
    </>
  );
};

export default ExportTab;
