import * as styles from "./CustomDateRangePicker.module.scss";

const DateRangePickerInput = ({
  setIsDatePickerOpen,
  isDatePickerOpen,
  isDateSelected,
  dateRangePickerInputRef,
  placeholder = "Select a date range",
}) => {
  return (
    <input
      className={styles.Date_Range_Picker_Input}
      onClick={() => {
        setIsDatePickerOpen(!isDatePickerOpen);
      }}
      readOnly={true}
      placeholder={isDateSelected ? "" : placeholder}
      ref={dateRangePickerInputRef}
    />
  );
};

export default DateRangePickerInput;
