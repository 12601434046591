import "bootstrap/dist/css/bootstrap.min.css";
import "styles/_index.scss";
import "styles/_utilities.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "styles/_daterange_picker.scss"; // custom theme css file
import "react-tabs/style/react-tabs.css"; // React tabs css file
import "react-toastify/dist/ReactToastify.css"; // React toastify css file
import "react-datepicker/dist/react-datepicker.css"; // react-datepicker css file

import App from "./app/App";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";
import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { persistStore } from "redux-persist";
import { queryClient } from "app/queryClient";
import reportWebVitals from "./reportWebVitals";
import store from "app/store";

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
