import * as styles from "./PADetail.module.scss";

import BoxKeyValue from "../common/BoxKeyValue/BoxKeyValue";
import { formatDate } from "common/utils/dateAndTimeUtils";

export const PAOverview = ({ PADetail }) => {
  return (
    <>
      <span className={styles.PA_Detail_Heading}>Overview</span>
      <div className="d-flex justify-content-start">
        <BoxKeyValue
          {...{
            itemKey: "Earliest Installation Date",
            itemValue: formatDate(PADetail?.min_installation_date),
          }}
        />
        <BoxKeyValue
          {...{
            itemKey: "Latest Installation Date",
            itemValue: formatDate(PADetail?.max_installation_date),
          }}
        />
      </div>
      <hr className="mt-4" />
    </>
  );
};
