import * as styles from "./ForgotPassword.module.scss";

import SentIcon from "images/ic_send.svg";

export const Success = () => {
  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <div className={`col-5 ${styles.ForgotPassword}`}>
          <p className="Font_H4 mb-3">Recovery link sent!</p>
          <img src={SentIcon} alt="sent" className="col-2 mb-3" />
          <p className="Font_H4">You can check your email.</p>
        </div>
      </div>
    </div>
  );
};
