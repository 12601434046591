import { useState } from "react";

import PartnerDropdown from "components/common/PartnerDropdown/PartnerDropdown";
import { convertArrayToDropdownOptions } from "common/utils/utils";

const PartnerFilter = ({ setQueryParams, PADetail }) => {
  const [partner, setPartner] = useState();
  const partnersList = PADetail?.partners_list;
  const partners = convertArrayToDropdownOptions(partnersList, "partner");

  const handlePartner = (partner) => {
    setQueryParams((value) => ({
      ...value,
      partner_id: partner?.value || "",
      partner_name: partner?.name || "",
    }));
    setPartner(partner);
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <span className="Font_H10 Font_Subtitle_Color pe-3 py-2">
        Partner Name
      </span>
      <PartnerDropdown
        {...{
          selectedOption: partner,
          setSelectedOption: handlePartner,
          options: partners,
          customWidth: "330px",
          placeholder: "Select a partner",
        }}
      />
    </div>
  );
};

export default PartnerFilter;
