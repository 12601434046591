import {
  Navigate,
  Routes as ReactRoutes,
  Route,
  useLocation,
} from "react-router-dom";
import { getNext, getRoutesBasedOnUserRole } from "./utils";
import { shallowEqual, useSelector } from "react-redux";

import { isAuthenticated as isUserAuthenticated } from "common/utils/authenticationUtils";
import routeConstants from "./routeConstants";

const Routes = () => {
  const user = useSelector((state) => state.user.user, shallowEqual);
  const isAuthenticated = isUserAuthenticated(user);

  const routes = isAuthenticated
    ? getRoutesBasedOnUserRole(user.user_role)
    : Object.values(routeConstants);

  const location = useLocation();

  return (
    <ReactRoutes>
      <Route
        path={routeConstants.Login.path}
        element={routeConstants.Login.component}
      />
      {!isAuthenticated && (
        // On first time opening the app redirect to login page if user is not authenticated
        <>
          <Route
            path={"/"}
            element={
              <Navigate
                to={`${routeConstants.Login.path}?next=${getNext(location)}`}
              />
            }
          />
          <Route
            path={routeConstants.ForgotPassword.path}
            element={routeConstants.ForgotPassword.component}
          />
          <Route
            path={routeConstants.ResetPassword.path}
            element={routeConstants.ResetPassword.component}
          />
        </>
      )}

      {routes.map((route, index) => (
        <Route
          path={route?.path}
          element={
            <RequireAuth {...{ isAuthenticated }}>
              {route?.component}
            </RequireAuth>
          }
          key={index}
        />
      ))}
      <Route path="*" element={<Navigate to={"/"} />} />
    </ReactRoutes>
  );
};

export default Routes;

const RequireAuth = ({ isAuthenticated, children }) => {
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={`${routeConstants.Login.path}?next=${getNext(location)}`} />
  );
};
