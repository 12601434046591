import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import TextFieldRow from "components/common/FormRows/TextFieldRow/TextFieldRow";

const UpdateProfileFormRows = ({
  setFormDetails,
  formDetails,
  handleSubmit,
}) => {
  return (
    <div className="d-flex flex-column align-items-end">
      <TextFieldRow
        {...{
          value: formDetails.email,
          setValue: () => {},
          labelText: "Email",
          isDisabled: true,
          inputId: "email",
          title: "Contact administrator to update Email",
        }}
      />
      <TextFieldRow
        {...{
          value: formDetails.userRole,
          setValue: () => {},
          labelText: "User Role",
          isDisabled: true,
          inputId: "user_role",
          title: "Contact administrator to update Role",
        }}
      />
      <TextFieldRow
        {...{
          value: formDetails.firstName,
          setValue: (value) => {
            setFormDetails((prev) => ({ ...prev, firstName: value }));
          },
          labelText: "First Name",
          isRequired: true,
          inputId: "first_name",
        }}
      />
      <TextFieldRow
        {...{
          value: formDetails.lastName,
          setValue: (value) => {
            setFormDetails((prev) => ({ ...prev, lastName: value }));
          },
          labelText: "Last Name",
          isRequired: false,
          inputId: "last_name",
        }}
      />
      <Button
        {...{
          onClick: handleSubmit,
          type: "submit",
          isDisabled: !formDetails?.firstName?.trim(),
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
        }}>
        Update Profile
      </Button>
    </div>
  );
};

export default UpdateProfileFormRows;
