import ImportList from "components/common/ImportList/ImportList";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { getUserImports } from "common/API/importAPIs";

const ImportTab = () => {
  return (
    <>
      <ImportList
        {...{ fetchImports: getUserImports, currentDashboard: "user" }}
      />
      <ScrollToTop />
    </>
  );
};

export default ImportTab;
