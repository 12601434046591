import React from "react";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const DlightUgandaDataHeader = () => {
  return (
    <thead className={`Thead ${styles.Table_Header_Sticky}`}>
      <tr>
        <th className="Th">Customer_ID</th>
        <th className="Th">Loan_ID</th>
        <th className="Th">Customer_Name</th>
        <th className="Th">Address</th>
        <th className="Th">State</th>
        <th className="Th">Branch</th>
        <th className="Th">Technology_Type</th>
        <th className="Th">Product_Model</th>
        <th className="Th">Installation_Date</th>
        <th className="Th">CPA_Number</th>
      </tr>
    </thead>
  );
};

export default React.memo(DlightUgandaDataHeader);
