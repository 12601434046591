import React from "react";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const DlightKenyaDataHeader = () => {
  return (
    <thead className={`Thead ${styles.Table_Header_Sticky}`}>
      <tr>
        <th className="Th">Name</th>
        <th className="Th">Address</th>
        <th className="Th">Branch</th>
        <th className="Th">State_X</th>
        <th className="Th">Prod_Type_X</th>
        <th className="Th">Prod_Model_X</th>
        <th className="Th">Loan_ID</th>
        <th className="Th">User_ID</th>
        <th className="Th">Install_Date</th>
        <th className="Th">CPA_X</th>
      </tr>
    </thead>
  );
};

export default React.memo(DlightKenyaDataHeader);
