import styled from "styled-components";
import styles from "./SearchPivotTable.module.scss";
import arrow from "images/ic_arrow_top.svg";

export const TableStyles = styled.div`
  .table {
    width: 100%;
    margin-bottom: 0;
    height: calc(100vh - 13.5rem - ${styles.breadcrumbsBarHeight});
    font-family: ${styles.fontFamilyKarla};
    font-size: ${styles.fontSizeBase};

    --bs-table-bg: none;
    --bs-table-accent-bg: none;
    --bs-table-striped-color: none;
    --bs-table-striped-bg: none;
    --bs-table-active-color: none;
    --bs-table-active-bg: none;
    --bs-table-hover-color: none;
    --bs-table-hover-bg: none;
    color: none;

    > * {
      box-shadow: none;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 14px 16px;
      border-bottom: 1px solid ${styles.darkColor};
      border-right: 1px solid ${styles.darkColor};
      background-color: #001628;
      overflow: hidden;
      text-align: left;
      word-break: break-all;

      color: ${styles.fontTitleColor};
      font-size: ${styles.fontSizeSmall};
      font-weight: ${styles.fontWeightRegular};

      :last-child {
        border-right: 0;
      }
    }

    .th-column-names {
      .th-column-name-vertical {
        display: block;
        position: relative;
        width: 90%;
        word-break: break-word;
        margin-bottom: 8px;

        &::after {
          content: url(${arrow});
          display: inline-block;
          transform: rotate(90deg);

          position: absolute;
          top: -5px;
          right: -20px;
        }
      }

      .th-column-name-horizontal {
        display: block;
        width: 90%;
        word-break: break-word;

        &::before {
          content: url(${arrow});
          display: inline-block;
          transform: rotate(180deg);
          position: absolute;
          left: 10px;
          bottom: 5px;
        }
      }
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        ${(props) =>
          typeof props.isTableOverflown !== "undefined" &&
          !props?.isTableOverflown &&
          "width: 100%"}
      }

      .header {
        top: 0;

        .tr > .th {
          padding: 6px 16px;
          text-align: left;
          background-color: ${styles.bgCardColor};
          font-weight: ${styles.fontWeightBold};
          display: inline-flex !important;
          align-items: center;

          :first-child {
            color: ${styles.fontDisabledColor};
            font-size: ${styles.fontSizeSmall};
          }

          :first-child,
          :last-child {
            text-align: left;
            padding: 6px 42px;
          }
        }
      }

      .footer {
        bottom: 0;
        border-top: 1px solid ${styles.bgCardColor};

        .tr > .td {
          background-color: ${styles.bgCardColor};
        }

        .tr > .td:first-child {
          font-weight: ${styles.fontWeightBold};
        }
      }

      .body {
        position: relative;
        z-index: 0;
        display: flex;
        flex-direction: column;
        min-height: calc(100% - 5.4rem);
        border-top: none;

        .tr:last-of-type {
          flex: auto;
        }

        .tr > .td {
          :first-child {
            font-weight: ${styles.fontWeightBold};
          }
        }
      }

      .tr {
        ${(props) =>
          typeof props.isTableOverflown !== "undefined" &&
          !props?.isTableOverflown &&
          "width: 100% !important"};

        .th:nth-last-child(2),
        .td:nth-last-child(2) {
          flex: auto;
        }

        .th:last-child,
        .td:last-child {
          margin-left: auto;
          border-left: ${(props) => (props.isSingleColumn ? "1px" : "2px")}
            solid ${styles.lightColor};
        }

        .th:first-child,
        .td:first-child {
          border-right: ${(props) => (props.isSingleColumn ? "1px" : "2px")}
            solid ${styles.lightColor};
        }

        .td {
          :first-child,
          :last-child {
            text-align: left;
            padding: 14px 32px;
          }
        }
      }

      [data-sticky-td] {
        position: sticky;
      }
    }
  }
`;
