import DashboardTabFilters from "components/common/DashboardTabFilters/DashboardTabFilters";
import ExportTable from "components/common/ExportTable/ExportTable";
import ListTopSection from "components/common/ListTopSection/ListTopSection";
import NewSearchForExportButton from "../NewSearchForExportButton/NewSearchForExportButton";
import { options } from "./ExportTab.constant";
import styles from "./ExportList.module.scss";
import { useState } from "react";

const ExportList = ({ showCreatedBy, partnerId, fetchExports }) => {
  const [selectedOption, setSelectedOption] = useState("All");
  const [searchOptions, setSearchOptions] = useState({});
  const [areOptionsDisabled, setAreOptionsDisabled] = useState(false);

  const handleOptionChange = (e) => {
    const targetValue = e.target.value;
    setSelectedOption(targetValue);
    setSearchOptions({ report_type: targetValue });
  };

  return (
    <>
      <ListTopSection
        actionChildren={<NewSearchForExportButton />}
        filterChildren={
          <DashboardTabFilters
            {...{
              options,
              selectedOption,
              handleOptionChange,
              areOptionsDisabled,
              className: styles.Export_List_Radio_Button_Container,
            }}
          />
        }
      />
      <ExportTable
        {...{
          searchOptions,
          setAreOptionsDisabled,
          partnerId,
          showCreatedBy,
          fetchExports,
          extraClass: "Table_Height2",
        }}
      />
    </>
  );
};
export default ExportList;
