import RadioGroup from "components/common/RadioGroup/RadioGroup";

const DashboardTabFilters = ({
  options,
  selectedOption,
  handleOptionChange,
  areOptionsDisabled,
  className,
}) => {
  return (
    <div className="d-flex gap-1">
      <span className="Font_H10 Font_Title_Color mx-3">Showing:</span>
      <RadioGroup
        {...{
          selectedOption,
          disabled: areOptionsDisabled,
          onChange: handleOptionChange,
          radioButtons: options,
          className,
        }}
      />
    </div>
  );
};

export default DashboardTabFilters;
