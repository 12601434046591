import Collapsible from "react-collapsible";
import CustomCollapsibleMenuHeader from "./CustomCollapsibleMenuHeader";
import { useState } from "react";

// TODO: Add more abstraction for styles to make it useful everywhere
const CustomCollapsibleMenu = ({
  isDisabled,
  headerLabel,
  disableHeaderLabel,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <Collapsible
        {...{
          trigger: (
            <CustomCollapsibleMenuHeader
              {...{ isOpen, isDisabled, headerLabel, disableHeaderLabel }}
            />
          ),
          onOpening: () => setIsOpen(true),
          onClosing: () => setIsOpen(false),
          triggerDisabled: isDisabled,
        }}>
        {!isDisabled && children}
      </Collapsible>
    </div>
  );
};

export default CustomCollapsibleMenu;
