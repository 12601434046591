import * as commonStyles from "styles/Common.module.scss";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import MemoizedPartnerListTableHeader from "./PartnerListTableHeader";
import PartnerListTableBody from "./PartnerListTableBody/PartnerListTableBody";
import { getErrorMessage } from "common/utils/errorUtils";
import { getPartnersListWithDetails } from "common/API/partnerDashboardAPI";
import { toast } from "react-toastify";
import { useSortableTable } from "common/hooks/useSortableTable";
import { useEffect, createRef } from "react";
import * as styles from "./PartnersList.module.scss";
import {
  storeScrollPosition,
  fetchStoredScrollPosition,
} from "common/utils/utils";

import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";

const PartnersList = () => {
  const tableRef = createRef();

  const tableclasses = classNames("Table_Wrapper", styles.List_Scroll_Table);
  const containerClasses = { overflow: "default" };

  const {
    isLoading,
    data: response,
    isError,
    error,
    sortOrder,
    currentOrderField,
    handleSorting,
  } = useSortableTable(
    "partners-list-with-details",
    getPartnersListWithDetails,
    "desc",
    "Total Sales of last 12 months",
    ["total_sales"]
  );

  if (isError) {
    toast.error(getErrorMessage(error, "Cannot fetch partners list"));
  }

  const partnersList = response?.data?.payload;
  const lastPage = response?.pageParams?.at(-1) || 1;

  useEffect(() => {
    fetchStoredScrollPosition("scrollable-partners", "PARTNERS");
  }, []);

  return (
    <section id="scrollable-partners" className={tableclasses} ref={tableRef}>
      <InfiniteScroll
        style={containerClasses}
        loader={<h4 className="Font_H8">Loading...</h4>}
        dataLength={lastPage * partnersList?.length || 0}
        onScroll={(event) => storeScrollPosition(event, "PARTNERS")}
        scrollableTarget="scrollable-partners">
        <table className="Table overflow-auto">
          <MemoizedPartnerListTableHeader
            {...{ handleSorting, sortOrder, currentOrderField, isLoading }}
          />
          {!isLoading && <PartnerListTableBody {...{ partnersList }} />}
        </table>
      </InfiniteScroll>
      {isLoading && (
        <CustomLoader
          containerClasses={commonStyles.Table_Body_Loader_Container}
        />
      )}
    </section>
  );
};

export default PartnersList;
