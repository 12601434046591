import * as styles from "../AssignPAModalContent.module.scss";

import AssignPAWizardButtons from "./AssignPAWizardButtons";
import ModalCloseButton from "components/common/ModalCloseButton/ModalCloseButton";
import classNames from "classnames";

const AssignPAModalActions = ({
  refetchSearchRecords,
  setIsModalOpen,
  wizardInstance,
  currentStep,
  selectedPA,
  dataFilterId,
  lastTotalCount,
  errorPayload,
  setErrorPayload,
  productTypeCounts,
  isLoading,
}) => {
  const actionsWrapper = classNames(
    "d-flex justify-content-end",
    styles.Assign_PA_Modal_Actions
  );

  return (
    <section className={actionsWrapper}>
      <ModalCloseButton {...{ setIsModalOpen }} />
      <AssignPAWizardButtons
        {...{
          refetchSearchRecords,
          wizardInstance,
          currentStep,
          selectedPA,
          dataFilterId,
          lastTotalCount,
          setIsModalOpen,
          errorPayload,
          setErrorPayload,
          isLoading,
          productTypeCounts,
        }}
      />
    </section>
  );
};

export default AssignPAModalActions;
