export const switchItems = [
  {
    name: "All time",
    value: "all_time",
    checked: true,
  },
  {
    name: "Last 12 months",
    value: "last_12_months",
    checked: false,
  },
];
