import * as styles from "./ModalContent.module.scss";

import ExportReportActions from "./ExportReportActions";
import ModalSearchDetails from "components/common/ModalSearchDetails/ModalSearchDetails";
import RichText from "components/common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";

const ModalContent = ({
  refetchSearchRecords,
  filterNames,
  isLoading,
  exportFormat,
  setExportFormat,
  groupByNames,
  totalCount,
  pivotTableSummary,
  exportFormatOptions,
  setIsGenerateReportModalOpen,
  filterId,
}) => {
  return (
    <div className={styles.Generate_Detail_Report_Modal}>
      <RichText textType={TextTypes.SUBHEAD1}>Create New Export</RichText>
      <ModalSearchDetails
        {...{
          isLoading,
          filterNames,
          groupByNames,
          pivotTableSummary,
          totalCount,
        }}
      />

      <ExportReportActions
        {...{
          refetchSearchRecords,
          exportFormat,
          setExportFormat,
          exportFormatOptions,
          setIsGenerateReportModalOpen,
          filterId,
          isLoading,
        }}
      />
    </div>
  );
};

export default ModalContent;
