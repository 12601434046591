import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { useEffect } from "react";
import { importStatuses } from "./ImportStatusDropdown.constant";

const ImportStatusDropdown = ({
  importStatus,
  setImportStatus,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  // Stores import status filter for import page.
  useEffect(() => {
    const storedImportStatus = sessionStorage.getItem(
      `${pageIdentifier}importStatus`
    );
    if (storedImportStatus) {
      setImportStatus(JSON.parse(storedImportStatus));
    }
  }, [pageIdentifier]);

  // Fetches and applies a stored import type filter for import page.
  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      status: importStatus?.value,
    }));
    if (importStatus !== undefined || importStatus !== null) {
      sessionStorage.setItem(
        `${pageIdentifier}importStatus`,
        JSON.stringify(importStatus)
      );
    }
  }, [importStatus, setSearchOptions, pageIdentifier]);
  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: importStatus,
        setSelectedOption: setImportStatus,
        options: importStatuses,
        customWidth: "200px",
        className,
        placeholder: "Import Status",
      }}
    />
  );
};

export default ImportStatusDropdown;
