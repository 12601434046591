import * as styles from "./ListTopSection.module.scss";

const ListTopSection = ({
  filterChildren,
  actionChildren,
  extraChildren,
  collapsibleFilterChildren = null,
}) => {
  return (
    <>
      <div className={styles.List_Top_Section_Container}>
        {filterChildren}
        {extraChildren && <div className="w-75 ms-5">{extraChildren}</div>}
        {actionChildren && (
          <div className="ms-auto order-2">{actionChildren}</div>
        )}
      </div>
      {collapsibleFilterChildren}
    </>
  );
};

export default ListTopSection;
