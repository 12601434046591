export const CapacityListHeader = () => {
  return (
    <thead className="Thead">
      <tr className="Tr">
        <th className="Th">Type Of Product</th>
        <th className="Th">Total Capacity</th>
        <th className="Th">Used Capacity</th>
        <th className="Th">Free Capacity</th>
      </tr>
    </thead>
  );
};
