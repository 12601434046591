import * as styles from "./PartnerListTableBody.module.scss";

import { PRODUCT_TYPES } from "common/constants";
import routeConstants from "routes/routeConstants";
import { useNavigate } from "react-router-dom";

const PartnerListTableBody = ({ partnersList }) => {
  const navigate = useNavigate();

  const handleOnClick = (id) => {
    navigate(`${routeConstants.PartnersList.path}/${id}`);
  };

  return (
    <tbody className="Tbody">
      {partnersList.map((partner, index) => (
        <tr
          className="Tr"
          key={index}
          onClick={() => handleOnClick(partner?.id)}>
          <td className="Td">
            <span className={styles.PartnerName}>{partner?.partner}</span>
          </td>
          <td className="Td">{partner?.total_sales}</td>
          <td className="Td">{partner?.preliminary_records_count}</td>
          <td className="Td">{partner?.proposed_records_count}</td>
          <td className="Td">{partner?.confirmed_records_count}</td>
          <td className="Td">{partner[PRODUCT_TYPES.pt4] || 0}</td>
          <td className="Td">{partner[PRODUCT_TYPES.pt5] || 0}</td>
          <td className="Td">{partner[PRODUCT_TYPES.pt1] || 0}</td>
          <td className="Td">{partner[PRODUCT_TYPES.pt2] || 0}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default PartnerListTableBody;
