import { useState } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { convertArrayToDropdownOptions } from "common/utils/utils";

const StateFilter = ({ setQueryParams, PADetail }) => {
  const [state, setState] = useState();
  const statesList = PADetail?.states_list;
  const states = convertArrayToDropdownOptions(statesList, "state");

  const handleState = (state) => {
    setQueryParams((value) => ({
      ...value,
      governing_district_id: state?.value || "",
      state_name: state?.name || "",
    }));
    setState(state);
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <span className="Font_H10 Font_Subtitle_Color pe-3 py-2">State</span>
      <ReactSelectDropdown
        {...{
          selectedOption: state,
          setSelectedOption: handleState,
          options: states,
          placeholder: "Select a state",
        }}
      />
    </div>
  );
};

export default StateFilter;
