import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // * #####################################
  // * Filters
  // * #####################################
  partnerList: [],
  dataPhaseList: [],
  stateList: [],
  branchList: [],
  productTypeList: [],
  productModelList: [],
  projectActivityAssignedList: [],
  monitoringPeriodList: [],
  partner: "",
  dataPhase: "",
  state: "",
  branch: "",
  productType: "",
  productModel: "",
  projectActivityAssigned: "",
  earliestDate: "",
  latestDate: "",
  monitoringPeriod: "",
  showNonMandatoryFilters: false,

  // * #####################################
  // * Group By
  // * #####################################
  commonColumnsList: [],
  column1List: [],
  column2List: [],
  column1: "",
  column2: "",

  areDataFiltersSame: false,
  isGettingPrefilled: false,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    // * #####################################
    // * Filters
    // * #####################################
    setPartnerList: (state, action) => {
      state.partnerList = action.payload;
    },
    setDataPhaseList: (state, action) => {
      state.dataPhaseList = action.payload;
    },
    setStateList: (state, action) => {
      state.stateList = action.payload;
    },
    setBranchList: (state, action) => {
      state.branchList = action.payload;
    },
    setProductTypeList: (state, action) => {
      state.productTypeList = action.payload;
    },
    setProductModelList: (state, action) => {
      state.productModelList = action.payload;
    },
    setProjectActivityAssignedList: (state, action) => {
      state.projectActivityAssignedList = action.payload;
    },
    setMonitoringPeriodList: (state, action) => {
      state.monitoringPeriodList = action.payload;
    },
    setPartner: (state, action) => {
      state.partner = action.payload;
    },
    setDataPhase: (state, action) => {
      state.dataPhase = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setProductType: (state, action) => {
      state.productType = action.payload;
    },
    setProductModel: (state, action) => {
      state.productModel = action.payload;
    },
    setProjectActivityAssigned: (state, action) => {
      state.projectActivityAssigned = action.payload;
    },
    setShowNonMandatoryFilters: (state, action) => {
      state.showNonMandatoryFilters = action.payload;
    },
    setEarliestDate: (state, action) => {
      state.earliestDate = action.payload;
    },
    setLatestDate: (state, action) => {
      state.latestDate = action.payload;
    },
    setMonitoringPeriod: (state, action) => {
      state.monitoringPeriod = action.payload;
    },

    // * #####################################
    // * Group By
    // * #####################################
    setCommonColumnsList: (state, action) => {
      state.commonColumnsList = action.payload;
    },
    setColumn1List: (state, action) => {
      state.column1List = action.payload;
    },
    setColumn2List: (state, action) => {
      state.column2List = action.payload;
    },
    setColumn1: (state, action) => {
      state.column1 = action.payload;
    },
    setColumn2: (state, action) => {
      state.column2 = action.payload;
    },

    setAreDataFiltersSame: (state, action) => {
      state.areDataFiltersSame = action.payload;
    },

    setIsGettingPrefilled: (state, action) => {
      state.isGettingPrefilled = action.payload;
    },
  },
});

export const {
  setPartnerList,
  setDataPhaseList,
  setStateList,
  setBranchList,
  setProductTypeList,
  setProductModelList,
  setProjectActivityAssignedList,
  setMonitoringPeriodList,
  setPartner,
  setDataPhase,
  setState,
  setBranch,
  setProductType,
  setProductModel,
  setProjectActivityAssigned,
  setEarliestDate,
  setLatestDate,
  setMonitoringPeriod,
  setShowNonMandatoryFilters,

  setCommonColumnsList,
  setColumn1List,
  setColumn2List,
  setColumn1,
  setColumn2,

  setAreDataFiltersSame,
  setIsGettingPrefilled,
} = searchSlice.actions;

export default searchSlice.reducer;
