const SearchPivotTableHeaderText = ({ header, index }) => {
  const columnHeaders = header.split("/");

  if (index !== 0 || columnHeaders.length !== 2) return header;

  return (
    <div className="th-column-names">
      <span className="th-column-name-vertical w-100">{columnHeaders[1]}</span>
      <span className="th-column-name-horizontal w-100">
        {columnHeaders[0]}
      </span>
    </div>
  );
};

export default SearchPivotTableHeaderText;
