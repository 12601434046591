import * as commonStyles from "styles/Common.module.scss";
import * as styles from "./DownloadFile.module.scss";

import { useEffect, useState } from "react";

import HyperLink from "components/common/HyperLink/HyperLink";
import { getPresignedURL } from "common/API/api";
import { useParams } from "react-router-dom";

const DownloadFile = () => {
  const { filePath } = useParams();

  const [isError, setIsError] = useState(false);
  const [errorStatusCode, setErrorStatusCode] = useState(null);

  const downloadFile = async () => {
    try {
      const response = await getPresignedURL(filePath);
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      setIsError(true);
      setErrorStatusCode(error.response?.status);
    }
  };

  useEffect(() => downloadFile(), []);

  return (
    <div className={commonStyles.Full_Height_Flex_Container}>
      {isError ? (
        <ErrorMessage {...{ errorStatusCode }} />
      ) : (
        <LoadingOrSuccessMessage {...{ downloadFile }} />
      )}
    </div>
  );
};

export default DownloadFile;

//***************************/
//*****Local Components******/
//***************************/

const ErrorMessage = ({ errorStatusCode }) => {
  if (errorStatusCode === 404) {
    return <span>Requested file does not exist</span>;
  } else if (errorStatusCode === 400) {
    return <span>Invalid file path given.</span>;
  }
  return <span>Error while downloading the file, Something went wrong.</span>;
};

const LoadingOrSuccessMessage = ({ downloadFile }) => {
  return (
    <span>
      The file will be downloaded shortly. If it doesn&apos;t please{" "}
      <HyperLink
        onClick={downloadFile}
        wrapperClassName={styles.HyperLink_Wrapper}
        text={"click here."}
      />
    </span>
  );
};
