import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as importDetailStyles from "../ImportDetail.module.scss";

import classNames from "classnames";

const ImportTableItem = ({ itemKey, itemValue }) => {
  const valueClasses = classNames(
    "Font_H9 Font_Title_Color",
    importDetailStyles.text_ellipsis
  );
  return (
    <div className={countTableStyles.Count_Table_Item_Wrapper}>
      <span className="Font_H9 Font_Subtitle_Color text-start">{itemKey}</span>
      <span className={valueClasses}>{itemValue || "-"}</span>
    </div>
  );
};

export default ImportTableItem;
