import React from "react";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const MongoliaDataHeader = () => {
  return (
    <thead className={`Thead ${styles.Table_Header_Sticky}`}>
      <tr>
        <th className="Th">Sysnum</th>
        <th className="Th">Customer Name</th>
        <th className="Th">Address 1</th>
        <th className="Th">Address 2</th>
        <th className="Th">MFI Branch Code</th>
        <th className="Th">District</th>
        <th className="Th">MFI Tracking Code</th>
        <th className="Th">MFI Account ID</th>
        <th className="Th">Crediting Category</th>
        <th className="Th">BID Device Code</th>
        <th className="Th">Install Date</th>
        <th className="Th">Project Activity</th>
      </tr>
    </thead>
  );
};

export default React.memo(MongoliaDataHeader);
