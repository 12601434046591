import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loanId: null,
  searchId: null,
  dataPhase: null,
  partnerName: null,
};

export const searchDetailRecordSlice = createSlice({
  name: "searchDetailRecord",
  initialState,
  reducers: {
    setSearchDetailRecord: (state, action) => {
      state.searchDetailRecord = action.payload;
    },
  },
});

export const { setSearchDetailRecord } = searchDetailRecordSlice.actions;

export default searchDetailRecordSlice.reducer;
