import { getApiUrls } from "../utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getDataPhaseList = async () => {
  const url = getApiUrls("getDataPhases");

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const getProductTypeList = async (queryParams) => {
  const url = getApiUrls("getProductTypes");

  const response = await axiosInstance.get(url, {
    params: queryParams,
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const getStateList = async () => {
  const url = getApiUrls("getStates", { countryId: 1 });

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const getProductModelList = async (productTypeId, name) => {
  if (!productTypeId) return [];

  const url = getApiUrls("getProductModels", {
    productTypeId: productTypeId,
  });

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { name: name },
  });

  return response?.data?.payload;
};

export const getBranchList = async (partnerId, stateId, name) => {
  if (!stateId || !partnerId) return [];

  const url = getApiUrls("getBranches", {
    partnerId: partnerId,
    stateId: stateId,
  });

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { name: name },
  });

  return response?.data?.payload;
};

export const getPACodesList = async (queryParams = {}) => {
  const url = getApiUrls("getPACodesList");

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: queryParams,
  });

  return response?.data?.payload;
};

export const getPartnersList = async () => {
  const url = getApiUrls("getPartners");

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const getMonitoringPeriodList = async () => {
  const url = getApiUrls("getMonitoringPeriodList");

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const getGroupByColumnList = async (dataPhaseId) => {
  const url = getApiUrls("getGroupByColumns");

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      ...(dataPhaseId ? { data_phase_id: dataPhaseId } : {}),
    },
  });

  return response?.data?.payload;
};

export const applyFilters = async (data) => {
  const url = getApiUrls("applyFilter");

  const response = await axiosInstance.post(url, data, {
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const downloadFilterSummary = (dataFilterId) => {
  const url = getApiUrls("downloadFilterSummary", {
    dataFilterId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const generateFilterDetailReport = async (
  data_filter_id,
  export_type
) => {
  const url = getApiUrls("generateFilterDetailReport", {
    data_filter_id: data_filter_id,
  });

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { export_type: export_type },
  });

  return response;
};

export const updateFilterBookmarkStatus = async (
  dataFilterId,
  isBookmarked
) => {
  const url = getApiUrls("updateFilterBookmarkStatus", { dataFilterId });

  const response = await axiosInstance.put(
    url,
    { is_bookmarked: isBookmarked },
    { headers: getAuthorizationHeaders() }
  );

  return response?.data?.payload;
};
