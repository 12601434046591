import * as styles from "./OverviewTab.module.scss";

import React, { useState } from "react";

import { ReactComponent as ChevronIconDownImg } from "images/chevron-down.svg";
import { ReactComponent as ChevronIconUpImg } from "images/chevron-up.svg";
import Collapsible from "react-collapsible";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import classNames from "classnames";
import { formatDateTime } from "common/utils/dateAndTimeUtils";
import { redirectToViewImport } from "common/utils/utils";

const UploadedFileStatistics = ({ uploadedDataFileStatistics }) => {
  return (
    <div className={styles.Uploaded_File_Statistic_Container}>
      <div className="p-3">
        <h4 className="Font_H4">Your Total Preliminary Data Counts</h4>
      </div>
      <>
        {uploadedDataFileStatistics?.length === 0 ? (
          <NoDataFound
            {...{
              title: "No preliminary data found",
              wrapperClasses: styles.Empty_Content_Container,
            }}
          />
        ) : (
          <>
            <MemoizedHeader />
            <UploadedFileStatisticCard
              {...{
                uploadedDataFileStatistics,
              }}
            />
          </>
        )}
      </>
    </div>
  );
};

export default UploadedFileStatistics;

//***************************/
//*****Local Components******/
//***************************/

const Header = () => {
  const headerClasses = classNames(
    "Bg_Card_Color",
    styles.Uploaded_File_Statistic_Header
  );
  return (
    <div className={headerClasses}>
      <div>Partner</div>
      <div>Total Uploaded Files</div>
      <div>Total Records</div>
      <div>Total Errorneous Record</div>
      <div></div>
    </div>
  );
};

const MemoizedHeader = React.memo(Header);

const UploadedFileStatisticCard = ({ uploadedDataFileStatistics }) => {
  return (
    <>
      {uploadedDataFileStatistics?.map((statistic) => {
        return (
          <Card
            key={statistic?.id}
            {...{
              statistic,
            }}
          />
        );
      })}
    </>
  );
};

const SubTable = ({ statistic }) => {
  return (
    <div className={styles.Statistic_Content}>
      <section className="Table_Wrapper">
        <table className="Table">
          <SubTableHeader />
          <SubTableBody {...{ statistic }} />
        </table>
      </section>
    </div>
  );
};

const SubTableHeader = () => {
  return (
    <thead className="Thead">
      <tr>
        <th className="Th">File Label</th>
        <th className="Th">Import Type</th>
        <th className="Th">Total Records</th>
        <th className="Th">Total Errorneous Record</th>
        <th className="Th">Uploaded On</th>
      </tr>
    </thead>
  );
};

const SubTableBody = ({ statistic }) => {
  return (
    <tbody className="Tbody">
      {statistic?.uploaded_files.map((file, index) => {
        return <SubTableRow key={index} {...{ index, file }} />;
      })}
    </tbody>
  );
};

const SubTableRow = ({ index, file }) => {
  return (
    <tr
      key={index}
      className="Tr"
      onClick={() => redirectToViewImport(file?.id)}>
      <td className="Td">{file.label}</td>
      <td className="Td">{file.import_type}</td>
      <td className="Td">{file.total_records}</td>
      <td className="Td">{file.total_error_records}</td>
      <td>{formatDateTime(file.created_at) || "-"}</td>
    </tr>
  );
};

const Card = ({ statistic }) => {
  const [isOpen, setIsOpen] = useState(false);

  const classes = classNames(
    styles.Uploaded_File_Statistic_Card,
    "Hover_Effect_2"
  );

  return (
    <Collapsible
      trigger={<CardDetail {...{ isOpen, statistic }} />}
      overflowWhenOpen="hidden"
      transitionTime={350}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      className={classes}
      openedClassName={classes}>
      <SubTable {...{ statistic }} />
    </Collapsible>
  );
};

const CardDetail = ({ isOpen, statistic }) => {
  const cardDetailclasses = classNames(
    "cursor-pointer",
    styles.Uploaded_File_Statistic_Row
  );
  return (
    <div key={statistic?.id} className={cardDetailclasses}>
      <div>{statistic?.name}</div>
      <div>{statistic?.total_uploaded_files}</div>
      <div>{statistic?.total_preliminary_records}</div>
      <div>{statistic?.total_records_with_errors}</div>
      <div>{isOpen ? <ChevronIconUpImg /> : <ChevronIconDownImg />}</div>
    </div>
  );
};
