import * as styles from "./SearchPivotTable.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import { isOverflown, prepareHeadersAndData } from "./utils";
import { useBlockLayout, useTable } from "react-table";

import NoPivotTable from "./NoPivotTable";
import SearchPivotTableBody from "./SearchPivotTableBody";
import SearchPivotTableFooter from "./SearchPivotTableFooter";
import SearchPivotTableHeader from "./SearchPivotTableHeader";
import { TableStyles } from "./SearchPivotTableStyles";
import classNames from "classnames";
import { useSticky } from "react-table-sticky";

const SearchPivotTable = ({ pivotTable, className, isSearchDetail }) => {
  // * States and Refs
  const pivotTableRef = React.createRef(null);
  const [isTableOverflown, setIsTableOverflown] = useState();
  const [isSingleColumn, setIsSingleColumn] = useState();

  // * Effects
  useEffect(() => {
    // * Find if table is overflown is x-direction
    setIsTableOverflown(isOverflown(pivotTableRef.current));
    // * Find if pivot table has single column group by
    pivotTable.length > 0 && setIsSingleColumn(pivotTable[0].length === 2);
  }, []);

  // * If pivot table is not found, return illustration
  if (pivotTable?.length === 0 || pivotTable[0].length === 0) {
    return <NoPivotTable {...{ className }} />;
  }

  // * Split pivot table into headers, rows and footer
  const [pivotTableHeaders, ...pivotTableRows] = pivotTable;
  const pivotTableFooter = pivotTableRows.pop();

  // * Modify header and data according to react table sticky requirements
  const [headers, data] = useMemo(
    () =>
      prepareHeadersAndData(
        pivotTableHeaders,
        pivotTableRows,
        pivotTableFooter
      ),
    [pivotTableHeaders, pivotTableRows, pivotTableFooter]
  );

  // * Default column width specification
  const defaultColumn = useMemo(
    () => ({
      minWidth: 100,
      width: 200,
      maxWidth: 600,
    }),
    []
  );

  // * Initialize props and groups using react table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: headers,
        data: data,
        defaultColumn,
        autoResetHiddenColumns: false,
      },
      useBlockLayout,
      useSticky
    );

  const footerGroups = headerGroups.slice().reverse();
  const tableClasses = classNames("table sticky", {
    [styles.Summary_Table]: isSearchDetail,
  });
  return (
    <TableStyles {...{ isTableOverflown, isSingleColumn }}>
      <section
        {...getTableProps()}
        className={tableClasses}
        ref={pivotTableRef}>
        <SearchPivotTableHeader {...{ headerGroups }} />
        <SearchPivotTableBody {...{ getTableBodyProps, rows, prepareRow }} />
        <SearchPivotTableFooter {...{ footerGroups }} />
      </section>
    </TableStyles>
  );
};

export default SearchPivotTable;
