import React from "react";
import Filled_Bookmark from "images/ic_filled_bookmark.svg";
import Bookmark from "images/ic_bookmark.svg";

const BookmarkIcon = ({ isBookmarked, showEmptyIcon, ...attributeProps }) => {
  return isBookmarked ? (
    <div className="d-flex place-items-center" {...attributeProps}>
      <img src={Filled_Bookmark} alt="Bookmarked" />
    </div>
  ) : showEmptyIcon ? (
    <div className="d-flex place-items-center" {...attributeProps}>
      <img src={Bookmark} alt="Not Saved" />
    </div>
  ) : null;
};

export default BookmarkIcon;
