import {
  setProjectActivityAssigned,
  setProjectActivityAssignedList,
} from "slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

import { DATA_PHASE } from "common/constants";
import FilterItem from "components/Search/FilterItem/FilterItem";
import { conditionallyCreateOptionObject } from "components/Search/utils";
import { getErrorMessage } from "common/utils/errorUtils";
import { getPACodesList } from "common/API/filtersAndGroupByApi";
import styles from "./ProjectActivityAssignedFilter.module.scss";
import { toast } from "react-toastify";
import { useEffect } from "react";

const ProjectActivityAssignedFilter = ({ selectedDataFilter }) => {
  // * #####################################
  // * Redux States
  // * #####################################
  const {
    projectActivityAssigned,
    projectActivityAssignedList,
    dataPhase,
    showNonMandatoryFilters,
    isGettingPrefilled,
  } = useSelector((state) => state.search);

  const additionalFilterIds =
    selectedDataFilter?.filter_ids?.additional_filters;
  const additionalFilterNames =
    selectedDataFilter?.filter_names?.additional_filters;

  const dispatch = useDispatch();

  // * Effect to handle showing non mandatory filters
  useEffect(() => {
    if (!showNonMandatoryFilters) return;

    if (dataPhase?.name === DATA_PHASE.p3) {
      (async () => {
        try {
          const projectActivityList = await getPACodesList();
          dispatch(setProjectActivityAssignedList(projectActivityList));
        } catch (error) {
          toast.error(
            getErrorMessage(
              error,
              "Failed to fetch project activity assigned list"
            )
          );
        }
      })();

      if (isGettingPrefilled) return;

      // * If filter is reapplied, set PA based on template
      dispatch(
        setProjectActivityAssigned(
          conditionallyCreateOptionObject(
            additionalFilterNames,
            additionalFilterIds,
            "project_activity_id"
          )
        )
      );
    }
  }, [showNonMandatoryFilters]);

  // * #####################################
  // * Dynamic options and Handlers
  // * #####################################

  const getDynamicPACodesOptions = (query) => {
    const res = getPACodesList({ code: query });
    return res;
  };

  const handleProjectActivityAssigned = (projectActivityAssigned) => {
    dispatch(setProjectActivityAssigned(projectActivityAssigned));
  };

  return (
    <div className={styles.ProjectActivity_Assigned_Dd}>
      <FilterItem
        {...{
          label: "Project Activity Assigned",
          type: "dropdown",
          selectedOption: projectActivityAssigned,
          setSelectedOption: handleProjectActivityAssigned,
          options: projectActivityAssignedList,
          dynamicOptions: getDynamicPACodesOptions,
          fetchOptions: true,
          dropdownProps: {
            isMulti: true,
            closeMenuOnSelect: false,
          },
        }}
      />
    </div>
  );
};

export default ProjectActivityAssignedFilter;
