import * as styles from "./ResetPassword.module.scss";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import ResetPasswordContent from "./ResetPasswordContent";
import { passwordResetTokenVerification } from "common/API/authenticationAPIs";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

const ResetPassword = () => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const { user_id, token } = useParams();

  const {
    error: verificationError,
    isError: isTokenInvalid,
    isLoading,
  } = useQuery(["token-verification", user_id, token], () =>
    passwordResetTokenVerification(user_id, token)
  );

  if (isLoading) return <CustomLoader />;

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <div className={`col-5 ${styles.Reset_Password_Container}`}>
          <ResetPasswordContent
            {...{
              passwordUpdated,
              setPasswordUpdated,
              isTokenInvalid,
              verificationError,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
