import { setUser } from "slices/userSlice";
import store from "app/store";

export const isAuthenticated = (user) => {
  if (!user) return false;
  return !!user?.token;
};

export const getAbbreviatedUserName = () => {
  const user = store.getState()?.user?.user;
  const lastName = user?.last_name?.charAt(0)
    ? user.last_name.charAt(0) + "."
    : "";

  return `${user?.first_name} ${lastName}`;
};

export const getUserNameInitials = () => {
  const user = store.getState()?.user?.user;
  const firstName = user?.first_name?.charAt(0)?.toUpperCase() || "";
  const lastName = user?.last_name?.charAt(0)?.toUpperCase() || "";
  return `${firstName}${lastName}`.trim();
};

export const logout = (dispatch) => {
  dispatch(setUser(null));
  window.location.href = "/login";
};

/**
 * It returns the value of a property of the user object in the Redux store
 * @param prop - The property of the user object you want to get.
 * @returns The value of the property of the user object.
 */
export const getUserProperty = (prop) => {
  const user = store.getState()?.user?.user;
  if (!user || !prop) return "";

  return user[prop];
};

/**
 * Updates the user info in the Redux store
 * @param dispatch - The dispatch function from the redux store.
 * @param updatedObj - This is the object that contains the updated user
 * information.
 */
export const updateUserInfo = (dispatch, updatedObj) => {
  const user = store.getState()?.user?.user || {};
  const updatedUser = { ...user, ...updatedObj };
  dispatch(setUser(updatedUser));
};
