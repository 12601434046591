import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

/* 
Sample User object
{
  email: "user@test.com",
  first_name: "User",
  last_name: "Test",
  id: "1",
  user_role: "ADMIN",
  token: "66cb3f7c5f69d1af30e566619953fad3d32",
}
*/

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
