import * as styles from "./DetailResponse.module.scss";

import { DetailBodyTable } from "./DetailBodyTable";
import { DetailListHeader } from "./DetailListHeader";
import classNames from "classnames";

const DetailResponse = ({ itemName, itemCount, listItems, isState }) => {
  const classes = classNames("ms-3 Table_Wrapper", styles.Detail_Response);

  return (
    <section className={classes}>
      <table className="Table">
        <DetailListHeader {...{ itemName, itemCount }} />
        <DetailBodyTable {...{ listItems, isState }} />
      </table>
    </section>
  );
};

export default DetailResponse;
