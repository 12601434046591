import * as styles from "./PartnerSalesChart.module.scss";

import DownloadPartnerSalesExport from "./DownloadPartnerSalesExport";
import ProductTypeFilter from "../SalesChart/SalesChartFilters/ProductTypeFilter";
import SalesChartFilters from "../SalesChart/SalesChartFilters/SalesChartFilters";
import SalesChartTitle from "./SalesChartTitle";
import SalesComposedChartWrapper from "./SalesComposedChartWrapper";
import TimelineFilter from "../SalesChart/SalesChartFilters/TimelineFilter";
import { getErrorMessage } from "common/utils/errorUtils";
import { getPartnerSalesStatistics } from "common/API/partnerDashboardAPI";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useState } from "react";

const PartnerSalesChart = () => {
  const { partnerId } = useParams();
  const { name: partnerName } = useSelector((state) => state.partner.partner);
  const [queryParams, setQueryParams] = useState({});
  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useQuery(["partner-sales-statistics", partnerId, queryParams], () =>
    getPartnerSalesStatistics(partnerId, queryParams)
  );

  const chartData = response?.data?.payload?.sales_data;
  let firstSaleDate = response?.data?.payload?.first_sale_date;
  let lastSaleDate = response?.data?.payload?.last_sale_date;
  const isEmptyData = chartData?.length === 0;

  firstSaleDate =
    moment(firstSaleDate, "YYYY-MM-DD").isValid() &&
    moment(firstSaleDate, "YYYY-MM-DD").format("MMM Y");
  lastSaleDate =
    moment(lastSaleDate, "YYYY-MM-DD").isValid() &&
    moment(lastSaleDate, "YYYY-MM-DD").format("MMM Y");

  // * Get unique years from the data
  const uniqueYears =
    (chartData && [...new Set(chartData.map((item) => item.year))]) || [];

  return (
    <section className={styles.Chart_Container}>
      <div className={styles.Chart_Card}>
        <span className="Font_H7 Font_Title_Color d-flex flex-start pb-4 ">
          Sales Overview
        </span>

        <div className="d-flex flex-wrap-reverse">
          <div className={styles.Chart_Filter_Container}>
            {!isError && (
              <SalesChartFilters>
                <ProductTypeFilter {...{ setQueryParams }} />
                <TimelineFilter {...{ setQueryParams }} />
              </SalesChartFilters>
            )}
            <div className={styles.Sales_All_Export}>
              <DownloadPartnerSalesExport {...{ partnerId, partnerName }} />
            </div>
          </div>
          <div className="d-flex flex-column centered-div">
            {!isError && (
              <SalesChartTitle
                {...{
                  partnerName,
                  productTypeName: queryParams?.product_type_name,
                  startYear:
                    (chartData && chartData[0]?.year) || queryParams.start_year,
                  endYear:
                    (chartData && chartData[chartData.length - 1]?.year) ||
                    queryParams.end_year,
                }}
              />
            )}
            <SalesComposedChartWrapper
              {...{
                uniqueYearsLength: uniqueYears?.length,
                chartData,
                isLoading,
                isError,
                isEmptyData,
                firstSaleDate,
                lastSaleDate,
                queryParams,
                errorMessage: getErrorMessage(
                  error,
                  "Cannot fetch Chart Data. Something went wrong"
                ),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerSalesChart;
