import { abbreviateNumber } from "common/utils/utils";

export const renderCustomizedLabel = ({
  cx,
  value,
  percentage,
  x,
  y,
  fill,
  name,
  hideLabel = true,
}) => {
  // * Do not render Label if percentage is < 1%
  const percentInt = parseInt(percentage.slice(0, percentage.length - 1));
  if (hideLabel && percentInt < 1) return null;

  return (
    <text
      className="p-1"
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {`${name} - ${abbreviateNumber(value)} (${percentage})`}
    </text>
  );
};

export const renderCustomizedLabelLine = ({
  cx,
  cy,
  fill,
  percentage,
  stroke,
  points,
  name,
  hideLabel = true,
}) => {
  // * Do not render LabelLine if percentage is < 1%
  const percentInt = parseInt(percentage.slice(0, percentage.length - 1));
  if (hideLabel && percentInt < 1) return null;

  return (
    <path
      {...{ cx, cy, name, stroke, fill }}
      d={`M${points[0].x},${points[0].y}L${points[1].x},${points[1].y}`}
      className="customized-label-line"
    />
  );
};

export const getPACapacityData = (PAStatistics) => {
  const usedAndAvailableCapacityData = PAStatistics.flatMap((PAStat) => {
    const totalCapacity = PAStat.available_capacity + PAStat.used_capacity;
    return [
      {
        name: `${PAStat.name} - Used`,
        value: PAStat.used_capacity,
        percentage: PAStat.used_capacity_percentage,
        ...{ totalCapacity },
      },
      {
        name: `${PAStat.name} - Remaining`,
        value: PAStat.available_capacity,
        percentage: PAStat.available_capacity_percentage,
        ...{ totalCapacity },
      },
    ];
  });

  return usedAndAvailableCapacityData;
};
