import * as styles from "./CollapsibleTopSection.module.scss";
import { ReactComponent as FilterIconImg } from "images/ic_filter.svg";

const FilterIcon = ({
  setToggleFilters,
  searchOptions,
  emptySearchOptionsToConsider = [],
}) => {
  // * Calculate number of searches to show as bubble
  let numberOfSearches =
    Object.values(searchOptions).reduce(
      (count, searchValue) => (count += !!searchValue),
      0
      // ! Since start_date and end_date should be considered as a single filter
    ) - (searchOptions?.start_date ? 1 : 0);

  numberOfSearches += emptySearchOptionsToConsider
    ? emptySearchOptionsToConsider.reduce(
        (count, searchOption) => (count += searchOptions[searchOption] === ""),
        0
      )
    : 0;

  const handleOnClick = () => setToggleFilters((value) => !value);

  return (
    <div className={styles.Filter_Icon_Wrapper}>
      {numberOfSearches > 0 && (
        <span className={styles.Filter_Icon_Bubble} onClick={handleOnClick}>
          {numberOfSearches}
        </span>
      )}
      <FilterIconImg onClick={handleOnClick} />
    </div>
  );
};

export default FilterIcon;
