import LoanIdModalContent from "./LoanIdModalContent/LoanIdModalContent";
import ReactCustomModal from "components/common/ReactCustomModal/ReactCustomModal";

const LoanIdModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <ReactCustomModal
      {...{
        isOpen: isModalOpen,
        onRequestClose: () => setIsModalOpen(false),
        contentStyles: {
          minHeight: "200px",
        },
      }}>
      <LoanIdModalContent {...{ setIsModalOpen }} />
    </ReactCustomModal>
  );
};

export default LoanIdModal;
