import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import partnerSlice from "slices/partnerSlice";
import partnersByIdSlice from "slices/partnersByIdSlice";
import searchDetailRecordSlice from "slices/searchDetailRecordSlice";
import searchSlice from "slices/searchSlice";
import storage from "redux-persist/lib/storage";
import userSlice from "slices/userSlice";

const reducers = combineReducers({
  searchDetailRecord: searchDetailRecordSlice,
  partner: partnerSlice,
  partnersById: partnersByIdSlice,
  search: searchSlice,
  user: userSlice,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["search"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
