import * as importDetailStyles from "../ImportDetail.module.scss";

import RightArrow from "images/ic_tail_arrow_right.svg";
import Tooltip from "components/common/Tooltip/Tooltip";
import classNames from "classnames";

const OverviewHeader = ({ title, type }) => {
  const headerClasses = classNames(
    "d-flex justify-content-between pb-4",
    importDetailStyles.Import_Card_Header
  );
  return (
    <header className={headerClasses}>
      <span className="Font_H7 Font_Title_Color">{title}</span>
      {type !== "main" && <RedirectArrow />}
    </header>
  );
};

export default OverviewHeader;

//***************************/
//*****Local Components******/
//***************************/

const RedirectArrow = () => {
  return (
    <>
      <img
        src={RightArrow}
        alt="Info"
        data-tip="Click to go to Details of this import"
      />
      <Tooltip />
    </>
  );
};
