import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "common/constants";
import { useEffect, useRef, useState } from "react";

import CancelablePill from "../CancelablePill/CancelablePill";
import DateRangePickerInput from "./DateRangePickerInput";
import DateRangePickerWrapper from "./DateRangePickerWrapper";
import PropTypes from "prop-types";
import classNames from "classnames";
import { convertDateRangeToCompactFormat } from "common/utils/dateAndTimeUtils";
import styles from "./CustomDateRangePicker.module.scss";
import useOutsideClick from "common/hooks/useOutsideClick";

const CustomDateRangePicker = ({
  selectedDateRange,
  setSelectedDateRange,
  rangeKey,
  maxDate = "",
  minDate = "",
  wrapperClassName = "",
  placeholder,
}) => {
  // * #####################################
  // * States and Refs
  // * #####################################

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const dateRangePickerRef = useRef(null);
  const dateRangePickerInputRef = useRef(null);
  const cancelablePillRef = useRef(null);

  // * #####################################
  // * Hooks
  // * #####################################

  useOutsideClick(dateRangePickerRef, () => setIsDatePickerOpen(false), [
    dateRangePickerInputRef,
    cancelablePillRef,
  ]);

  useEffect(() => {
    setIsDateSelected(
      selectedDateRange.length > 0 && selectedDateRange[0]?.startDate
    );
  }, [selectedDateRange]);

  // * #####################################
  // * Handlers
  // * #####################################
  const handlePillClose = () => {
    setIsDateSelected(false);
    setSelectedDateRange([
      {
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE,
        key: rangeKey,
      },
    ]);
  };

  const wrapperClasses = classNames(
    styles.Date_Range_Picker_Input_Wrapper,
    wrapperClassName
  );

  return (
    <section className={wrapperClasses}>
      <DateRangePickerInput
        {...{
          setIsDatePickerOpen,
          isDatePickerOpen,
          isDateSelected,
          selectedDateRange,
          dateRangePickerInputRef,
          placeholder,
        }}
      />
      {isDateSelected && (
        <CancelablePill
          {...{
            text: convertDateRangeToCompactFormat(selectedDateRange[0]),
            isVisible: isDateSelected,
            className: styles.Date_Range_Cancelable_Pill_Wrapper,
            handleClose: handlePillClose,
            textOnClick: () => {
              setIsDatePickerOpen(!isDatePickerOpen);
            },
            textRef: cancelablePillRef,
          }}
        />
      )}
      {isDatePickerOpen && (
        <DateRangePickerWrapper
          {...{
            dateRangePickerRef,
            selectedDateRange,
            setSelectedDateRange,
            minDate,
            maxDate,
            rangeKey,
          }}
        />
      )}
    </section>
  );
};

CustomDateRangePicker.propTypes = {
  selectedDateRange: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string,
    }).isRequired
  ).isRequired,
  setSelectedDateRange: PropTypes.func.isRequired,
  rangeKey: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default CustomDateRangePicker;
