import { Cell, Pie, PieChart, Tooltip } from "recharts";
import {
  getPACapacityData,
  renderCustomizedLabel,
  renderCustomizedLabelLine,
} from "./utils";

import CustomPieChartTooltip from "../SalesChart/CustomToolTips/CustomPieChartTooltip";
import React from "react";

const COLORS = ["#ffb428", "#fcc151", "#00C49F", "#6bcfbc"];

const PACapacityPieChart = ({ PAStatistics }) => {
  const capacityData = getPACapacityData(PAStatistics);

  return (
    <section>
      <PieChart width={850} height={350}>
        <Pie
          {...{
            data: capacityData,
            LabelList: true,
            cx: "50%",
            cy: "50%",
            labelLine: (props) =>
              renderCustomizedLabelLine({ ...props, hideLabel: false }),
            label: (props) =>
              renderCustomizedLabel({ ...props, hideLabel: false }),
            outerRadius: 120,
            minAngle: 10,
            animationDuration: 1200,
          }}>
          {capacityData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>

        <Tooltip
          content={
            <CustomPieChartTooltip {...{ extraFields: ["totalCapacity"] }} />
          }
        />
      </PieChart>
    </section>
  );
};

export default React.memo(PACapacityPieChart);
