import {
  axiosInstance,
  getAuthorizationHeaders,
  uploadOnS3,
} from "common/API/api";
import { getApiUrls, getPartnerDashboardURLs } from "common/utils/apiUrls";

import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getFilePathFromPresignedPost } from "../utils/utils";
import qs from "qs";

/**
 * It gets the list of partners with some details
 */
export const getPartnersListWithDetails = (queryParams) => {
  const url = getPartnerDashboardURLs("getPartnersList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: queryParams,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getPartnerStatistics = (partnerId) => {
  const url = getApiUrls("getPartnerStatistics", { partnerId });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

/**
 * It gets the headers for the clean data
 * @returns A list of headers for the clean data.
 */
export const getCleanDataHeaders = async () => {
  const url = getApiUrls("getCleanDataHeaders");

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
  return response.data.payload;
};

/**
 * It uploads the clean data report to S3 and saves the clean data report to the
 * database.
 */
export const uploadCleanDataReport = async (payload, file, partnerId) => {
  const url = getApiUrls("generateCleanDataFilePreSignedPost", {
    partnerId,
  });

  // * Prepare presigned post for the clean data report
  const response = await axiosInstance.post(url, payload, {
    headers: getAuthorizationHeaders(),
  });

  const presignedPost = response.data.payload;
  const filePath = getFilePathFromPresignedPost(presignedPost, file.name);

  // * Upload the file to S3
  await uploadOnS3(presignedPost, file);

  // * Save clean data report to database
  return saveCleanDataFile(payload, filePath, partnerId);
};

/**
 * It saves the clean data file to the database.
 */
export const saveCleanDataFile = async (payload, filePath, partnerId) => {
  const url = getApiUrls("saveCleanDataFile", { partnerId });

  const response = await axiosInstance.post(
    url,
    {
      file_path: filePath,
      ...payload,
    },
    { headers: getAuthorizationHeaders() }
  );

  return response;
};

/**
 * It gets the  list of partner searches
 * @returns A list of searches
 */
export const getPartnerSearches = async (
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  searchOptions = {},
  extraParams = {}
) => {
  const url = getApiUrls("getPartnerSearches", {
    partnerId: extraParams?.partnerId,
  });
  try {
    const response = await axiosInstance.get(url, {
      headers: getAuthorizationHeaders(),
      params: {
        page: page,
        page_size: pageSize,
        ...searchOptions,
      },
    });

    return response.data;
  } catch (error) {
    if (error?.response?.data?.detail === "Invalid page.") {
      return undefined;
    } else {
      throw new Error("Cannot fetch partner searches");
    }
  }
};

/**
 * It gets the  list of partner imports
 * @returns A list of imports
 */
export const getPartnerImports = async (
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  searchOptions = {},
  extraParams = {}
) => {
  const url = getApiUrls("getPartnerImports", {
    partnerId: extraParams?.partnerId,
  });

  try {
    const response = await axiosInstance.get(url, {
      headers: getAuthorizationHeaders(),
      params: {
        page: page,
        page_size: pageSize,
        ...searchOptions,
      },
    });

    return response.data;
  } catch (error) {
    if (error?.response?.data?.detail === "Invalid page.") {
      return undefined;
    } else {
      throw new Error("Cannot fetch partner's imports");
    }
  }
};

export const getPartnerSalesStatistics = (partnerId, queryParams = {}) => {
  const url = getPartnerDashboardURLs("getPartnerSalesStatistics", {
    partnerId,
  });

  // ? To remove unnecessary query params
  const { product_type_id, start_year, end_year } = queryParams;

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { product_type_id, start_year, end_year },
  });
};

export const getPartnerSalesExport = (partnerId) => {
  const url = getPartnerDashboardURLs("getPartnerSalesExport", {
    partnerId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    responseType: "arraybuffer",
  });
};
