import React from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";
import { ButtonTypes, ButtonContentTypes } from "constants/enumTypes.constants";
import LoadingIcon from "images/ic_loader.svg";

const Button = ({
  buttonType,
  buttonContentType,
  children,
  isLoading,
  className,
  isDisabled,
  ...attributeProps
}) => {
  const btnClasses = classNames(
    [styles.Btn],
    {
      [styles.Primary_Btn]: [
        ButtonTypes.PRIMARY_LARGE,
        ButtonTypes.PRIMARY_SMALL,
      ].includes(buttonType),
      [styles.Secondary_Btn]: [
        ButtonTypes.SECONDARY_LARGE,
        ButtonTypes.SECONDARY_SMALL,
      ].includes(buttonType),
      [styles.Danger_Btn]: [
        ButtonTypes.DANGER_LARGE,
        ButtonTypes.DANGER_SMALL,
      ].includes(buttonType),
      [styles.Ghost_Btn]: [
        ButtonTypes.GHOST_LARGE,
        ButtonTypes.GHOST_SMALL,
      ].includes(buttonType),
      [styles.Ghost_Danger_Btn]: [
        ButtonTypes.GHOST_DANGER_LARGE,
        ButtonTypes.GHOST_DANGER_SMALL,
      ].includes(buttonType),
    },
    {
      [styles.Large_Btn]: [
        ButtonTypes.PRIMARY_LARGE,
        ButtonTypes.SECONDARY_LARGE,
        ButtonTypes.DANGER_LARGE,
        ButtonTypes.GHOST_LARGE,
        ButtonTypes.GHOST_DANGER_LARGE,
      ].includes(buttonType),
      [styles.Small_Btn]: [
        ButtonTypes.PRIMARY_SMALL,
        ButtonTypes.SECONDARY_SMALL,
        ButtonTypes.DANGER_SMALL,
        ButtonTypes.GHOST_SMALL,
        ButtonTypes.GHOST_DANGER_SMALL,
      ].includes(buttonType),
    },
    {
      [styles.Text_Btn]: buttonContentType === ButtonContentTypes.TEXT,
      [styles.Icon_Btn]: buttonContentType === ButtonContentTypes.ICON,
      [styles.Icon_Text_Btn]:
        buttonContentType === ButtonContentTypes.ICON_TEXT,
    },
    [className]
  );

  return (
    <button className={btnClasses} disabled={isDisabled} {...attributeProps}>
      {isLoading ? <img src={LoadingIcon} alt="Loading" /> : children}
    </button>
  );
};

export default Button;
