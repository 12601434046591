import AssignPAButton from "./AssignPAButton";
import CreateNewExportButton from "./CreateNewExportButton";
import { DATA_PHASE } from "common/constants";

const FooterSearchDetailRightSectionButtons = ({
  handleGenerateReport,
  setIsAssignPAModalOpen,
  recordStatus,
}) => {
  return (
    <>
      {recordStatus === DATA_PHASE["p2"] && (
        <AssignPAButton {...{ setIsModalOpen: setIsAssignPAModalOpen }} />
      )}
      <CreateNewExportButton {...{ handleGenerateReport }} />
    </>
  );
};

export default FooterSearchDetailRightSectionButtons;
