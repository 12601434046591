import AssignPAModalContent from "./AssignPAModalContent/AssignPAModalContent";
import ReactCustomModal from "components/common/ReactCustomModal/ReactCustomModal";

const AssignPAModalV2 = ({
  refetchSearchRecords,
  isModalOpen,
  setIsModalOpen,
  lastTotalCount,
  partner,
  dataFilterId,
  filterNames,
  groupByNames,
  pivotTableSummary,
  productTypeCounts,
  isLoading,
  onAfterClose = () => {},
  onAfterOpen = () => {},
}) => {
  return (
    <ReactCustomModal
      {...{
        isOpen: isModalOpen,
        onRequestClose: () => setIsModalOpen(false),
        contentStyles: {
          minHeight: "250px",
          minWidth: "600px",
        },
        onAfterClose,
        onAfterOpen,
      }}>
      <AssignPAModalContent
        {...{
          refetchSearchRecords,
          setIsModalOpen,
          lastTotalCount,
          partner,
          dataFilterId,
          filterNames,
          groupByNames,
          pivotTableSummary,
          productTypeCounts,
          isLoading,
        }}
      />
    </ReactCustomModal>
  );
};

export default AssignPAModalV2;
