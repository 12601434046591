import { ReactComponent as UpArrow } from "images/ic_gray_tail_arrow_up.svg";

export const CustomXAxisLabel = ({ viewBox, firstSaleDate }) => {
  const { y } = viewBox;

  return (
    <>
      <text {...viewBox} y={y + 55} className="Font_H10" textAnchor="start">
        First Sale: {firstSaleDate}
      </text>
    </>
  );
};

export const CustomYAxis1Label = ({ viewBox }) => {
  const { x, y } = viewBox;
  return (
    <>
      <text
        {...viewBox}
        x={0}
        y={y + 200}
        transform="rotate(-90)"
        className="Font_H11 transform-origin-center transform-box-fill-box"
        textAnchor="start">
        Total Sales
      </text>
      <UpArrow x={x + 6} y={y + 140} />
    </>
  );
};

export const CustomYAxis2Label = ({ viewBox }) => {
  const { x, y } = viewBox;
  return (
    <>
      <text
        {...viewBox}
        x={x}
        y={y + 200}
        transform="rotate(90)"
        className="Font_H11 transform-origin-center transform-box-fill-box"
        textAnchor="start">
        Cumulative Sales
      </text>
      <UpArrow x={x + 45} y={y + 120} />
    </>
  );
};
