import * as styles from "./TopPerformers.module.scss";

import TopPerformersCardContent from "./TopPerformersCardContent";
import { getPartnerStatistics } from "common/API/partnerDashboardAPI";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

const TopPerformers = () => {
  const { partnerId } = useParams();
  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useQuery(["partner-statistics", partnerId], () =>
    getPartnerStatistics(partnerId)
  );

  return (
    <section className={styles.Top_Performers_Container}>
      <section className={styles.Top_Performers_Card}>
        <TopPerformersCardContent
          {...{ data: response?.data?.payload, isLoading, isError, error }}
        />
      </section>
    </section>
  );
};

export default TopPerformers;
