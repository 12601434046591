import routeConstants from "routes/routeConstants";

export const quickLinkMenuItems = [
  "Partners",
  "Imports",
  "Searches",
  "Exports",
  "ProjectActivities",
  ...(process.env.REACT_APP_ENV == "production"
    ? []
    : ["MongolianProgram", "DlightKenya", "DlightUganda"]),
];

export const menuRoutes = {
  Partners: routeConstants["PartnersList"].path,
  Imports: routeConstants["Imports"].path,
  Searches: routeConstants["RecentFilters"].path,
  Exports: routeConstants["Exports"].path,
  ProjectActivities: routeConstants["ProjectActivityList"].path,
  MongolianProgram: routeConstants["MongoliaDataList"].path,
  DlightKenya: routeConstants["DlightKenyaDataList"].path,
  DlightUganda: routeConstants["DlightUgandaDataList"].path,
};
