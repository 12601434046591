import * as styles from "./CustomToolTip.module.scss";

import { capitalCase } from "change-case";

const CustomPieChartTooltip = ({ active, payload, extraFields = [] }) => {
  if (!(active && payload && payload.length)) return null;

  const { payload: firstPayload } = payload[0];
  const { name, value, percentage, ...extraFieldsData } = firstPayload;

  return (
    <div className={styles.Tooltip}>
      <div className={styles.Tooltip_Title}>{name}</div>
      <p className={styles.Tooltip_Desc}>
        <span className="Font_H13 Font_Title_Color pe-3">Sales:</span>
        <span className="Font_H12 Font_Title_Color">
          {Intl.NumberFormat("en-US").format(value)}
        </span>
      </p>
      <p className={styles.Tooltip_Desc}>
        <span className="Font_H13 Font_Title_Color pe-3">Percentage:</span>
        <span className="Font_H12 Font_Title_Color">{percentage}</span>
      </p>
      {extraFields.map((field, index) => (
        <p key={index} className={styles.Tooltip_Desc}>
          <span className="Font_H13 Font_Title_Color pe-3">
            {capitalCase(field)}
          </span>
          <span className="Font_H12 Font_Title_Color">
            {extraFieldsData[field]}
          </span>
        </p>
      ))}
    </div>
  );
};

export default CustomPieChartTooltip;
