export const excludedFields = [
  "id",
  "created_at",
  "updated_at",
  "created_at_in_data",
  "updated_at_in_data",
];
export const dateFields = [
  "created_at_in_data",
  "delivery_date",
  "install_date",
  "last_contact_date",
  "last_visit_date",
  "order_date",
  "updated_at_in_data",
  "signed_date",
  "end_date",
  "subsidy_paid_date",
];
