import {
  setDataPhase,
  setPartner,
  setProjectActivityAssigned,
} from "slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

import { DATA_PHASE } from "common/constants";
import FilterItem from "components/Search/FilterItem/FilterItem";
import styles from "./PartnerAndDataPhaseFilters.module.scss";

const PartnerAndDataPhaseFilters = () => {
  // * #####################################
  // * Redux States
  // * #####################################

  const { partner, dataPhase, partnerList, dataPhaseList } = useSelector(
    (state) => state.search
  );

  const dispatch = useDispatch();

  const handlePartner = (partner) => {
    dispatch(setPartner(partner));
  };

  const handleDataPhase = (dataPhase) => {
    dataPhase?.name !== DATA_PHASE.p3 &&
      dispatch(setProjectActivityAssigned(""));
    dispatch(setDataPhase(dataPhase));
  };

  return (
    <>
      <div className={styles.Partner_Dd}>
        <FilterItem
          label="Partner"
          type="dropdown"
          selectedOption={partner}
          setSelectedOption={handlePartner}
          options={partnerList}
        />
      </div>
      <div className={styles.DataPhase_Dd}>
        <FilterItem
          label="Data Phase"
          type="dropdown"
          selectedOption={dataPhase}
          setSelectedOption={handleDataPhase}
          options={dataPhaseList}
        />
      </div>
    </>
  );
};

export default PartnerAndDataPhaseFilters;
