import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";

import FileNameLink from "./FileNameLink";
import { formatDate } from "common/utils/dateAndTimeUtils";
import { sentenceCase } from "change-case";

const FileInfoCardBody = ({ filePath, fileName, ...restFileInfo }) => {
  return (
    <>
      <FileNameLink
        {...{
          filePath,
          fileName,
        }}
      />
      {Object.entries(restFileInfo).map(([key, value]) => {
        return (
          <div className={countTableStyles.Count_Table_Item_Wrapper} key={key}>
            <span className="Font_H9 Font_Subtitle_Color text-start">
              {sentenceCase(key)}
            </span>
            <span className="Font_H9 Font_Title_Color text_ellipsis">
              {key.includes("created_at") ? formatDate(value) : value || "-"}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default FileInfoCardBody;
