import {
  DynamicDataPhaseBreadcrumb,
  DynamicPartnerNameBreadcrumb,
  DynamicRecordLoanIdBreadcrumb,
  DynamicSearchDetailBreadcrumb,
} from "./Breadcrumb.utils";

import routeConstants from "routes/routeConstants";

export const routes = [
  {
    path: routeConstants.UserDashboardV2.path,
    breadcrumb: null,
  },
  {
    path: routeConstants.RecentFilters.path,
    breadcrumb: "Searches",
  },
  {
    path: routeConstants.Search.path,
    breadcrumb: "New",
  },
  {
    path: routeConstants.SearchDetail.path,
    breadcrumb: DynamicSearchDetailBreadcrumb,
    children: [
      {
        path: "raw-data",
        breadcrumb: DynamicDataPhaseBreadcrumb,
      },
      {
        path: "raw-data/:rawDataId",
        breadcrumb: DynamicRecordLoanIdBreadcrumb,
      },
      {
        path: "clean-data",
        breadcrumb: DynamicDataPhaseBreadcrumb,
      },
      {
        path: "clean-data/:cleanDataId",
        breadcrumb: DynamicRecordLoanIdBreadcrumb,
      },
      {
        path: "archive-excluded-raw-data",
        breadcrumb: DynamicDataPhaseBreadcrumb,
      },
      {
        path: "archive-excluded-raw-data/:archiveRawDataId",
        breadcrumb: DynamicRecordLoanIdBreadcrumb,
      },
    ],
  },
  {
    path: routeConstants.PartnerDashboardV2.path,
    breadcrumb: DynamicPartnerNameBreadcrumb,
    children: [
      {
        path: "clean-data/:cleanDataId",
        breadcrumb: DynamicRecordLoanIdBreadcrumb,
      },
    ],
  },
  {
    path: routeConstants.Exports.path,
    breadcrumb: "Exports",
  },
  {
    path: routeConstants.Import.path,
    breadcrumb: "Import",
  },
  {
    path: routeConstants.ImportDetail.path,
    breadcrumb: "Details",
  },
  {
    path: routeConstants.Imports.path,
    breadcrumb: "Imports",
  },
  {
    path: routeConstants.ExportDetail.path,
    breadcrumb: "Details",
  },
  {
    path: routeConstants.DownloadFile.path,
    breadcrumb: null,
  },
  {
    path: routeConstants.ForgotPassword.path,
    breadcrumb: null,
  },
  {
    path: routeConstants.ResetPassword.path,
    breadcrumb: null,
  },
  {
    path: routeConstants.ProjectActivityList.path,
    breadcrumb: "Project Activities",
  },
  {
    path: routeConstants.PADetail.path,
    breadcrumb: "Details",
  },
  {
    path: routeConstants.MongoliaDataDetail.path,
    breadcrumb: "Record",
  },
  {
    path: routeConstants.DlightKenyaDataList.path,
    breadcrumb: "Dlight Kenya",
  },
  {
    path: routeConstants.DlightUgandaDataList.path,
    breadcrumb: "Dlight Uganda",
  },
];
