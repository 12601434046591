import * as commonStyles from "styles/Common.module.scss";
import * as styles from "./PADetail.module.scss";

import {
  getPAPieChartData,
  getProjectActivityDetails,
} from "common/API/projectActivityApi";

import CapacityResponse from "./CapacityResponse/CapacityResponse";
import CustomLoader from "components/common/CustomLoader/CustomLoader";
import { PAOverview } from "./PAOverview";
import { PAPartnerStats } from "./PAPartnerStats";
import PASalesChart from "components/common/PASalesChart/PASalesChart";
import { PAStateStats } from "./PAStateStats";
import RichText from "../common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

const PADetail = () => {
  const { projectActivityId } = useParams();
  const { data: PADetailResponse, isLoading: isPADetailLoading } = useQuery(
    ["project-activtiy-details", projectActivityId],
    () => getProjectActivityDetails(projectActivityId)
  );

  const { data: pieChartDataResponse, isLoading: isPieChartDataLoading } =
    useQuery(["project-activtiy-pie-chart-data", projectActivityId], () =>
      getPAPieChartData(projectActivityId)
    );

  const PADetail = PADetailResponse?.data?.payload;
  const PAPieChartData = pieChartDataResponse?.data?.payload;

  if (isPADetailLoading) {
    return (
      <CustomLoader
        containerClasses={commonStyles.Full_Height_Flex_Container}
      />
    );
  }

  return (
    <section>
      <RichText
        className={styles.Detail_Container}
        textType={TextTypes.PAGE_TITLE}>
        {`Project Activity Code : ${PADetail?.code}`}
      </RichText>
      <PASalesChart {...{ projectActivityId, PADetail }} />
      <div className={styles.PA_Detail}>
        <PAOverview PADetail={PADetail} />
        <PAPartnerStats
          {...{
            PADetail,
            isPieChartDataLoading,
            PAPartnerStatistics: PAPieChartData?.pa_partner_statistics,
          }}
        />
        <PAStateStats
          {...{
            PADetail,
            isPieChartDataLoading,
            PAStateStatistics: PAPieChartData?.pa_state_statistics,
          }}
        />
        <CapacityResponse
          {...{
            capacity: PADetail,
            isPieChartDataLoading,
            PAProductTypeStatistics: PAPieChartData?.pa_product_type_statistics,
            PACapacityStatistics: PAPieChartData?.pa_capacity_statistics,
          }}
        />
      </div>
    </section>
  );
};

export default PADetail;
