import { DATA_PHASE } from "common/constants";

const exportFormatPreliminaryOptions = [
  {
    name: "Raw",
    value: "Raw",
  },
];

const exportFormatCleanOptions = [
  {
    name: "Clean",
    value: "Clean",
  },
  {
    name: "Direct",
    value: "Direct",
  },
];

export const exportFormats = {
  [DATA_PHASE.p1]: exportFormatCleanOptions,
  [DATA_PHASE.p2]: exportFormatCleanOptions,
  [DATA_PHASE.p3]: exportFormatCleanOptions,
  [DATA_PHASE.p4]: exportFormatPreliminaryOptions,
  [DATA_PHASE.p5]: exportFormatPreliminaryOptions,
};
