import InfoIcon from "components/common/InfoIcon/InfoIcon";
import CleanDataHeadersDropdown from "./CleanDataHeadersDropdown/CleanDataHeadersDropdown";

const CleanDataHeadersRow = ({
  updatedColumns,
  setUpdatedColumns,
  ...attributeProps
}) => {
  return (
    <div className="Form_Row">
      <label htmlFor="updated-columns">Updated Columns :</label>
      <div className="d-flex gap-4 align-items-center">
        <CleanDataHeadersDropdown
          {...{
            updatedColumns,
            setUpdatedColumns,
            id: "updated-columns",
            ...attributeProps,
          }}
        />
        <InfoIcon msg="You can select up to 4 columns" />
      </div>
    </div>
  );
};

export default CleanDataHeadersRow;
