import SearchTableRow from "./SearchTableRow/SearchTableRow";
import { getPillsArrayFromObject } from "common/utils/utils";

const SearchTableBody = ({ searches, isPartnerDashboard, showLastRunBy }) => {
  return (
    <tbody className="Tbody">
      {searches.map((search, index) => {
        // * Get user data filter if exists
        const userSearch =
          (search?.user_data_filters &&
            search?.user_data_filters.length > 0 &&
            search?.user_data_filters[0]) ||
          {};

        // * Prepare additional filter pills array
        const { obj: additionalFilters, array: additionalFilterPills } =
          getPillsArrayFromObject(search?.filter_names?.additional_filters);

        // * Prepare group by pills array
        const { obj: groupBys, array: groupByPills } = getPillsArrayFromObject(
          search?.group_by_names
        );

        // * Render the row
        return (
          <SearchTableRow
            key={index}
            {...{
              key: index,
              id: search?.id,
              isBookmarked: userSearch?.is_bookmarked,
              partner: search?.filter_names?.common_filters?.partner,
              dataPhase: search?.filter_names?.common_filters?.data_phase,
              doesAdditionalFiltersExist: !!additionalFilters,
              doesGroupByExist: !!groupBys,
              additionalFilterPills,
              groupByPills,
              lastRunBy: search?.last_run_by || "",
              totalCount: search?.total_count || 0,
              lastUpdatedCountAt: search?.last_updated_count_at || "",
              lastExportType: search?.last_export_type || "",
              lastExportedAt: search?.last_export_datetime || "",
              isPartnerDashboard,
              showLastRunBy,
            }}
          />
        );
      })}
    </tbody>
  );
};

export default SearchTableBody;
