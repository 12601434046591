import {
  getDataPhaseList,
  getPartnersList,
} from "common/API/filtersAndGroupByApi";
import {
  setAreDataFiltersSame,
  setBranch,
  setColumn1,
  setColumn1List,
  setColumn2,
  setColumn2List,
  setCommonColumnsList,
  setDataPhase,
  setDataPhaseList,
  setEarliestDate,
  setIsGettingPrefilled,
  setLatestDate,
  setMonitoringPeriod,
  setPartner,
  setPartnerList,
  setProductModel,
  setProductType,
  setProjectActivityAssigned,
  setState,
} from "slices/searchSlice";

import _ from "lodash";
import { getGroupByColumnList } from "common/API/filtersAndGroupByApi";

/**
 * Utility function to create an object or list of objects with name and id/value
 * conditionally based on field
 */
export const conditionallyCreateOptionObject = (
  nameObject,
  idObject,
  field,
  valueFieldName = "value"
) => {
  if (!idObject || !nameObject) return "";

  const idField = idObject[field];
  const nameField = nameObject[field];

  // * Return zipped array if both ID and Name values are of type Array
  if (Array.isArray(idField) && Array.isArray(nameField))
    return _.zipWith(idField, nameField, (id, name) => ({
      name,
      value: id,
    }));

  // * Else return object
  return idField
    ? {
        name: nameField,
        [valueFieldName]: idField,
      }
    : "";
};

/**
 * It dispatches an action to reset the value of each of the redux state properties
 */
export const cleanUp = ({ dispatch }) => {
  dispatch(setPartner(""));
  dispatch(setDataPhase(""));
  dispatch(setProductType(""));
  dispatch(setProductModel(""));
  dispatch(setState(""));
  dispatch(setBranch(""));
  dispatch(setProjectActivityAssigned(""));
  dispatch(setEarliestDate(""));
  dispatch(setLatestDate(""));
  dispatch(setMonitoringPeriod(""));
  dispatch(setColumn1(""));
  dispatch(setColumn2(""));
  dispatch(setAreDataFiltersSame(false));
  dispatch(setIsGettingPrefilled(false));
};

/**
 * It takes in a dataFilter object and uses it to prefill the filter form
 */
export const prefillFilterForm = ({ dispatch, dataFilter }) => {
  const commonFilterIds = dataFilter?.filter_ids?.common_filters;
  const commonFilterNames = dataFilter?.filter_names?.common_filters;
  const additionalFilterIds = dataFilter?.filter_ids?.additional_filters;
  const additionalFilterNames = dataFilter?.filter_names?.additional_filters;

  dispatch(setIsGettingPrefilled(true));

  dispatch(
    setPartner(
      conditionallyCreateOptionObject(
        commonFilterNames,
        commonFilterIds,
        "partner"
      )
    )
  );
  dispatch(
    setDataPhase(
      conditionallyCreateOptionObject(
        commonFilterNames,
        commonFilterIds,
        "data_phase"
      )
    )
  );

  dispatch(
    setState(
      conditionallyCreateOptionObject(
        additionalFilterNames,
        additionalFilterIds,
        "state"
      )
    )
  );
  dispatch(
    setBranch(
      conditionallyCreateOptionObject(
        additionalFilterNames,
        additionalFilterIds,
        "branch"
      )
    )
  );

  dispatch(
    setProductType(
      conditionallyCreateOptionObject(
        additionalFilterNames,
        additionalFilterIds,
        "product_type",
        "id"
      )
    )
  );
  dispatch(
    setProductModel(
      conditionallyCreateOptionObject(
        additionalFilterNames,
        additionalFilterIds,
        "product_model"
      )
    )
  );

  dispatch(
    setEarliestDate(
      additionalFilterIds?.earliest_date
        ? additionalFilterIds?.earliest_date
        : ""
    )
  );
  dispatch(
    setLatestDate(
      additionalFilterIds?.latest_date ? additionalFilterIds?.latest_date : ""
    )
  );

  dispatch(
    setMonitoringPeriod(
      conditionallyCreateOptionObject(
        additionalFilterNames,
        additionalFilterIds,
        "monitoring_period"
      )
    )
  );

  dispatch(
    setProjectActivityAssigned(
      conditionallyCreateOptionObject(
        additionalFilterNames,
        additionalFilterIds,
        "project_activity_assigned"
      )
    )
  );

  dispatch(
    setColumn1(
      dataFilter?.group_by_ids?.column1
        ? {
            name: dataFilter?.group_by_names?.column1,
            value: dataFilter?.group_by_ids?.column1,
          }
        : ""
    )
  );
  dispatch(
    setColumn2(
      dataFilter?.group_by_ids?.column2
        ? {
            name: dataFilter?.group_by_names?.column2,
            value: dataFilter?.group_by_ids?.column2,
          }
        : ""
    )
  );

  dispatch(setAreDataFiltersSame(true));
  dispatch(setIsGettingPrefilled(false));
};

/**
 * It gets the list of dropdown options which doest not have any dependency
 */
export const fetchDefaultDropdownOptions = async ({ dispatch }) => {
  const groupByColumns = await getGroupByColumnList();
  dispatch(setColumn1List(groupByColumns));
  dispatch(setColumn2List(groupByColumns));
  dispatch(setCommonColumnsList(groupByColumns));

  const partnersList = await getPartnersList();
  dispatch(setPartnerList(partnersList));

  const dataPhaseList = await getDataPhaseList();
  dispatch(setDataPhaseList(dataPhaseList));
};

export const customizeComparison = (item1, item2) => {
  if (Array.isArray(item1)) return _(item1).xorWith(item2, _.isEqual).isEmpty();
};
