import {
  FILE_STATUS_ALL_VALID,
  FILE_STATUS_PROBLEMS_FOUND,
  FILE_STATUS_PROMOTION_FAILED,
} from "common/constants";

export const promotionFileStages = [
  FILE_STATUS_PROBLEMS_FOUND,
  FILE_STATUS_ALL_VALID,
  FILE_STATUS_PROMOTION_FAILED,
];
