import { useEffect } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { getErrorMessage } from "common/utils/errorUtils";
import { getCPAList } from "common/API/dlightUgandaAPIs";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const CPADropdown = ({
  cpa,
  setCPA,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  const {
    data: response,
    isError,
    error,
  } = useQuery("africa-uganda-cpa", getCPAList);

  if (isError) toast.error(getErrorMessage(error), "Cannot fetch CPA list");

  const CPAList = response?.data?.payload;

  useEffect(() => {
    const storedCPA = sessionStorage.getItem(`${pageIdentifier}CPAFilter`);
    if (storedCPA) {
      setCPA(JSON.parse(storedCPA));
    }
  }, [pageIdentifier]);

  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      cpa_number: cpa?.value,
    }));
    if (cpa !== undefined || cpa !== null) {
      sessionStorage.setItem(`${pageIdentifier}CPAFilter`, JSON.stringify(cpa));
    }
  }, [cpa, setSearchOptions, pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: cpa,
        setSelectedOption: setCPA,
        options: CPAList,
        customWidth: "300px",
        className,
        placeholder: "CPA_Number",
      }}
    />
  );
};

export default CPADropdown;
