import { useEffect } from "react";
import ReactModal from "react-modal";
import styles from "./ReactCustomModal.module.scss";
import { produce } from "immer";

const customStyles = {
  overlay: {
    background: styles.bgModalColor,
    zIndex: "5",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",

    minWidth: "300px",
    minHeight: "300px",

    overflow: "visible",
    background: "#001F38",
    padding: "10px",
  },
};
const ReactCustomModal = ({
  children,
  isOpen,
  contentStyles,
  onRequestClose = (e) => e?.stopPropagation(),
  ...otherProps
}) => {
  // * Use effect to Hide scrollbar if modal  is open
  useEffect(() => {
    isOpen && (document.body.style.overflow = "hidden");

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Default height and width will be auto if not provided
  const styles = produce(customStyles, (draft) => {
    draft.content = { ...draft.content, ...contentStyles };
  });

  return (
    <ReactModal
      style={styles}
      isOpen={isOpen}
      onRequestClose={(e) => {
        e?.stopPropagation();
        onRequestClose();
      }}
      {...otherProps}>
      {children}
    </ReactModal>
  );
};

export default ReactCustomModal;
