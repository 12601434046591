import * as styles from "./SearchPivotTable.module.scss";

import classNames from "classnames";

const NoPivotTable = ({ className }) => {
  const wrapperClasses = classNames(
    styles.Pivot_Container,
    "position-relative",
    className
  );
  return (
    <section className={wrapperClasses}>
      <span className="Font_H3 Font_Body_Color">
        No pivot table is defined for this search
      </span>
    </section>
  );
};

export default NoPivotTable;
