import * as styles from "../RawDataRecord.module.scss";

import FileInfoCard from "components/common/FileInfoCard/FileInfoCard";
import RecordDetailKeyValue from "components/common/RecordDetailKeyValue/RecordDetailKeyValue";

const RawDataKeyValues = ({
  rawDataFileColumns,
  rawDataRecord,
  rawDataFileInfo,
}) => {
  return (
    <>
      <RecordDetailKeyValue
        {...{
          recordData: rawDataRecord,
          recordDataFileColumns: rawDataFileColumns,
        }}
      />
      <div className={styles.File_Info_Container}>
        <FileInfoCard
          {...{
            fileIdKey: "uploaded_file_id",
            filePathKey: "uploaded_file_path",
            fileNameKey: "uploaded_file_name",
            fileInfo: rawDataFileInfo,
          }}
        />
      </div>
    </>
  );
};

export default RawDataKeyValues;
