import { QueryClient } from "react-query";
import { getNextPageParam } from "common/utils/reactQueryUtils";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      getNextPageParam,
      onError: (error) => {
        console.error("Query Client: " + error);
      },
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
