import {
  ADMIN_USER_ROLE,
  DATA_TEAM_USER_ROLE,
  VIEWER_USER_ROLE,
} from "common/constants";

import adminRoleRoutes from "./userRoleRoutes/adminRoleRoutes";
import otherRoleRoutes from "./userRoleRoutes/otherRoleRoutes";
import routeConstants from "./routeConstants";
import viewerRoleRoutes from "./userRoleRoutes/viewerRoleRoutes";

export const getRoutesBasedOnUserRole = (userRole) => {
  if (!userRole) {
    return [];
  }

  // * Return routes based on user role
  const routes =
    userRole === VIEWER_USER_ROLE
      ? viewerRoleRoutes
      : [DATA_TEAM_USER_ROLE, ADMIN_USER_ROLE].includes(userRole)
      ? adminRoleRoutes
      : otherRoleRoutes;

  return routes;
};

export const getNext = (location) => {
  const next =
    location?.pathname === routeConstants.Login.path ? "/" : location?.pathname;
  // encode url to avoid error in case of special characters
  const encodedNext = encodeURIComponent(next);

  return encodedNext;
};
