import * as styles from "./DashboardTabs.module.scss";

import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { removeStoredScrollPosition } from "common/utils/utils";

import PropTypes from "prop-types";
import classNames from "classnames";

const DashboardTabs = ({
  tabs,
  dashboardRef,
  contentProps = {},
  hasStickyDashboardTabs,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const DEFAULT_ACTIVE_TAB_ID = 1;
  const searchQuery = new URLSearchParams(location.search);
  const activeTab = parseInt(searchQuery.get("tab"));

  const dashboardTabs = classNames(styles.Dashboard_Tabs, {
    [styles.Sticky_Dashboard_Tab]: hasStickyDashboardTabs,
  });
  const dashboardTabLists = classNames(styles.Dashboard_Tab_List, {
    [styles.Sticky_Tab_List]: hasStickyDashboardTabs,
  });
  useEffect(() => {
    let index = 0;
    if (!activeTab) {
      navigate(`?tab=${DEFAULT_ACTIVE_TAB_ID}`, { replace: true });
    } else {
      index = tabs.findIndex((x) => x.id === activeTab);
    }
    setTabIndex(index);
  }, [location]);

  const handleTabChange = (index) => {
    const clickedTab = tabs[index];

    if (activeTab !== clickedTab.id) {
      navigate(`?tab=${clickedTab.id}`);
    }

    if (clickedTab.id === 2 && activeTab === 1) {
      removeStoredScrollPosition("SEARCHES");
    }
    setTabIndex(index);
  };

  return (
    <Tabs
      className={dashboardTabs}
      selectedTabClassName={styles.Dashboard_Selected_Tab}
      selectedIndex={tabIndex}
      onSelect={(index) => handleTabChange(index)}>
      <TabList className={dashboardTabLists}>
        {tabs.map((tab) => (
          <Tab key={tab.id}>{tab.name}</Tab>
        ))}
      </TabList>

      {tabs.map((tab) => (
        <TabPanel key={tab.id}>
          {
            <tab.content
              {...(tab?.showScrollToTop
                ? { dashboardRef, ...contentProps, hasStickyDashboardTabs }
                : {})}
            />
          }
        </TabPanel>
      ))}
    </Tabs>
  );
};

DashboardTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  dashboardRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  contentProps: PropTypes.object,
  hasStickyDashboardTabs: PropTypes.bool,
};

export default DashboardTabs;
