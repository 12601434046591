import Loader from "react-loader-spinner";
import * as styles from "styles/Common.module.scss";

const CustomLoader = ({
  containerClasses = styles.Full_Height_Flex_Container,
  visible,
  height = 100,
  width = 100,
}) => {
  return (
    <main className={containerClasses}>
      <Loader
        color="#009aff"
        type="TailSpin"
        {...{
          visible,
          height,
          width,
        }}
      />
    </main>
  );
};

export default CustomLoader;
