import * as styles from "./ExportDetailRightSection.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { downloadFileFromS3, formatBytes } from "common/utils/utils";

import Button from "components/common/Button/Button";
import { FooterBelowPivotTable } from "components/common/SearchPivotSection/FooterBelowPivotTable/FooterBelowPivotTable";
import React from "react";
import SearchPivotTable from "components/common/SearchPivotSection/SearchPivotTable/SearchPivotTable";
import { SearchResultTopSection } from "components/common/SearchPivotSection/SearchResultTopSection/SearchResultTopSection";
import { useNavigate } from "react-router-dom";

const ExportDetailRightSection = ({
  filterId,
  filterResult,
  reportPath,
  fileSize,
}) => {
  const { totalCount, pivotTableSummary, lastUpdatedCountAt, pivotTable } =
    filterResult;

  return (
    <section className={styles.Export_Detail_Right_Section}>
      <SearchResultTopSection
        {...{
          totalCount,
          pivotTableSummary,
          lastUpdatedCountAt,
        }}>
        <RepeatThisSearchButton {...{ filterId }} />
      </SearchResultTopSection>

      <SearchPivotTable {...{ pivotTable }} />

      <FooterBelowPivotTable>
        <DownloadExportReportButton {...{ reportPath, fileSize }} />
      </FooterBelowPivotTable>
    </section>
  );
};

export default ExportDetailRightSection;

//***************************/
//*****Local Components******/
//***************************/

const DownloadExportReportButton = ({ reportPath, fileSize }) => {
  const readeableFileSize = fileSize ? ` (${formatBytes(fileSize)})` : "";

  return (
    <Button
      isDisabled={!reportPath}
      buttonType={ButtonTypes.SECONDARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={() => downloadFileFromS3(reportPath)}
      className="me-3">
      <span>Download Export{readeableFileSize}</span>
    </Button>
  );
};

const RepeatThisSearchButton = ({ filterId }) => {
  const navigate = useNavigate();

  const handleRepeatSearch = () =>
    navigate(`/searches/${filterId}?updateCount=true`);

  return (
    <div className="d-flex justify-content-center align-items-center ms-4">
      <Button
        buttonContentType={ButtonContentTypes.TEXT}
        onClick={handleRepeatSearch}
        buttonType={ButtonTypes.SECONDARY_SMALL}>
        Repeat this Search
      </Button>
    </div>
  );
};
