import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import ModalCloseButton from "components/common/ModalCloseButton/ModalCloseButton";
import RichText from "components/common/RichText/RichText";
import SearchSummary from "components/common/SearchSummary/SearchSummary";
import { TextTypes } from "constants/enumTypes.constants";
import styles from "./BookmarkModalContent.module.scss";
import { toast } from "react-toastify";
import { updateFilterBookmarkStatus } from "common/API/filtersAndGroupByApi";

const BookmarkModalContent = ({
  isBookmarked,
  filterId,
  setIsModalOpen,
  setIsBookmarked,
  filterNames,
  groupByNames,
}) => {
  return (
    <section className={styles.Bookmark_Details_Container}>
      <div className="d-flex flex-column">
        <RichText textType={TextTypes.SUBHEAD1} className="mb-3">
          {isBookmarked ? "Remove this bookmark?" : "Bookmark this search"}
        </RichText>
        <div className="d-flex p-2">
          <SearchSummary {...{ filterNames, groupByNames }} />
        </div>
      </div>
      <BookmarkModalActions
        {...{
          setIsModalOpen,
          filterId,
          isBookmarked,
          setIsBookmarked,
        }}
      />
    </section>
  );
};

export default BookmarkModalContent;

//***************************/
//*****Local Components******/
//***************************/

const BookmarkModalActions = ({
  setIsModalOpen,
  filterId,
  isBookmarked,
  setIsBookmarked,
}) => {
  return (
    <div className="d-flex justify-content-end">
      <ModalCloseButton {...{ setIsModalOpen }} />
      <UpdateBookmarkActionButton
        {...{ filterId, isBookmarked, setIsBookmarked, setIsModalOpen }}
      />
    </div>
  );
};

const UpdateBookmarkActionButton = ({
  filterId,
  isBookmarked,
  setIsBookmarked,
  setIsModalOpen,
}) => {
  const handleBookmark = async () => {
    try {
      const dataFilter = await updateFilterBookmarkStatus(
        filterId,
        !isBookmarked
      );
      toast.success(
        isBookmarked
          ? "Removed bookmark successfully"
          : "Search bookmarked successfully"
      );
      setIsBookmarked(dataFilter?.is_bookmarked);
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Failed to update bookmark status of Search");
    }
  };

  return (
    <Button
      buttonType={ButtonTypes.PRIMARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={handleBookmark}>
      <span>{isBookmarked ? "Delete" : "Save"}</span>
    </Button>
  );
};
