import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as importDetailStyles from "../../../ImportDetail.module.scss";
import * as styles from "./ExpandableSummaryItem.module.scss";

import { ReactComponent as ChevronIconDownImg } from "images/chevron-down.svg";
import { ReactComponent as ChevronIconUpImg } from "images/chevron-up.svg";
import Collapsible from "react-collapsible";
import classNames from "classnames";
import { convertSnakeCaseToTitleCase } from "common/utils/utils";
import { useState } from "react";

const ExpandableSummaryItem = ({ summary, objectListKeyName }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!summary) {
    return null;
  }

  const { count, unique_count: uniqueCount } = summary;
  const itemObjects = summary[objectListKeyName];

  const summaryOf = convertSnakeCaseToTitleCase(objectListKeyName);

  return (
    <Collapsible
      trigger={
        <ExpandableSummaryItemHeader
          {...{ isOpen, count, uniqueCount, summaryOf }}
        />
      }
      transitionTime={200}
      overflowWhenOpen="hidden"
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}>
      <ExpandedContent {...{ itemObjects, uniqueCount, summaryOf }} />
    </Collapsible>
  );
};

export default ExpandableSummaryItem;

//***************************/
//*****Local Components******/
//***************************/

const ExpandableSummaryItemHeader = ({
  isOpen,
  count,
  uniqueCount,
  summaryOf,
}) => {
  const valueClasses = classNames(
    "Font_H9 Font_Title_Color",
    importDetailStyles.text_ellipsis,
    styles.Equal_Widht_Flex
  );

  const keyClasses = classNames(
    "Font_H9 Font_Subtitle_Color text-start",
    styles.Equal_Widht_Flex
  );

  const svgClasses = classNames({ [styles.Invisible_SVG]: uniqueCount === 0 });

  return (
    <div className={countTableStyles.Count_Table_Item_Wrapper}>
      <span className={keyClasses}>{summaryOf}</span>
      <span className={valueClasses}>{uniqueCount || 0}</span>
      <span className={valueClasses}>{count || 0}</span>
      {isOpen ? (
        <ChevronIconUpImg className={svgClasses} />
      ) : (
        <ChevronIconDownImg className={svgClasses} />
      )}
    </div>
  );
};

const ExpandedContent = ({ itemObjects, uniqueCount, summaryOf }) => {
  const valueClasses = classNames(
    "Font_H9 Font_Title_Color",
    importDetailStyles.text_ellipsis,
    styles.Expanded_Content_Item_Values
  );

  const keyClasses = classNames(
    "Font_H9 Font_Subtitle_Color text-start",
    styles.Expanded_Content_Item_key
  );

  return (
    <section className={styles.Expanded_Content}>
      {itemObjects && (
        <>
          {uniqueCount > 5 && <span>Showing top 5 {summaryOf}</span>}
          {itemObjects.map((item) => (
            <div
              key={item?.id}
              className={countTableStyles.Count_Table_Item_Wrapper}>
              <span className={keyClasses}>{item?.name}</span>
              <span className={valueClasses}>{item?.count || 0}</span>
            </div>
          ))}
        </>
      )}
    </section>
  );
};
