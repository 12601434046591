import PropTypes from "prop-types";

import NestedDropdown from "../../common/NestedDropdown/NestedDropdown";
import React from "react";
import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";

const FilterItem = ({
  label,
  type,
  selectedOption,
  setSelectedOption,
  options,
  dynamicOptions,
  fetchOptions,
  dropdownProps = {},
}) => {
  // Date Filters

  const filterLabel = <span className="Filter_Label">{label}</span>;

  switch (type) {
    case "dropdown":
      return (
        <DropdownFilter
          {...{
            filterLabel,
            fetchOptions,
            selectedOption,
            setSelectedOption,
            options,
            dynamicOptions,
            ...dropdownProps,
          }}
        />
      );
    case "nested-dropdown":
      return (
        <NestedDropdownFilter
          {...{
            filterLabel,
            options,
            selectedOption,
            setSelectedOption,
          }}
        />
      );
    default:
      return <></>;
  }
};

FilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  selectedOption: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  ]),
  setSelectedOption: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  dynamicOptions: PropTypes.func,
  fetchOptions: PropTypes.bool,
  dropdownProps: PropTypes.shape(Object),
};

export default FilterItem;

//************************/
//****Local Components****/
//************************/

const NestedDropdownFilter = ({
  filterLabel,
  options,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <div className="Filter">
      {filterLabel}

      <NestedDropdown
        options={options}
        selectedOption={selectedOption.id}
        optionName={selectedOption.name}
        setSelectedOption={setSelectedOption}
        key={selectedOption}
      />
    </div>
  );
};

const DropdownFilter = ({
  filterLabel,
  fetchOptions,
  selectedOption,
  setSelectedOption,
  options,
  dynamicOptions,
  ...otherProps
}) => {
  return (
    <div className="Filter">
      {filterLabel}

      <ReactSelectDropdown
        isAsync={fetchOptions}
        {...{
          selectedOption,
          setSelectedOption,
          options,
          dynamicOptions,
          ...otherProps,
        }}
        customWidth={false}
      />
    </div>
  );
};
