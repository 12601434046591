import Actions from "./Actions";
import CollapsibleFilterChildren from "./CollapsibleFilterChildren/CollapsibleFilterChildren";
import CollapsibleTopSection from "components/common/CollapsibleTopSection/CollapsibleTopSection";
import ImportTable from "components/common/ImportTable/ImportTable";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { getAllImports } from "common/API/importAPIs";
import { useState } from "react";

const Imports = () => {
  const [searchOptions, setSearchOptions] = useState({});
  const [toggleFilters, setToggleFilters] = useState(false);

  const { partner, ...remainingSearchOptions } = searchOptions;
  return (
    <>
      <CollapsibleTopSection
        {...{
          searchOptions,
          actionChildren: (
            <Actions
              {...{ selectedPartnerId: searchOptions?.partner?.value }}
            />
          ),
          collapsibleFilterChildren: (
            <CollapsibleFilterChildren
              {...{ searchOptions, setSearchOptions }}
            />
          ),
          toggleFilters,
          setToggleFilters,
        }}
      />
      <ImportTable
        {...{
          searchOptions: {
            ...remainingSearchOptions,
            partner_id: partner?.value,
          },
          setAreOptionsDisabled: () => {},
          fetchImports: getAllImports,
          hidePromoteLink: true,
          extraClass: toggleFilters ? "Table_Height3" : "Table_Height1",
        }}
      />
      <ScrollToTop />
    </>
  );
};

export default Imports;
