import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { useNavigate } from "react-router-dom";
import routeConstants from "routes/routeConstants";
import Button from "../Button/Button";
import PlusIcon from "images/circle_plus.svg";

const NewSearchForExportButton = () => {
  const navigate = useNavigate();

  const handleNewSearch = () => {
    navigate(routeConstants.Search.path);
  };

  return (
    <Button
      buttonType={ButtonTypes.PRIMARY_SMALL}
      buttonContentType={ButtonContentTypes.ICON_TEXT}
      onClick={handleNewSearch}
      className="me-3">
      <img src={PlusIcon} alt="plus" />
      <span>New Search for export</span>
    </Button>
  );
};

export default NewSearchForExportButton;
