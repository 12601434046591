import CollapsibleListFilters from "components/common/CollapsibleListFilters/CollapsibleListFilters";
import FilterIcon from "./FilterIcon";
import ListTopSection from "components/common/ListTopSection/ListTopSection";

const CollapsibleTopSection = ({
  searchOptions,
  actionChildren,
  extraChildren,
  collapsibleFilterChildren,
  emptySearchOptionsToConsider,
  toggleFilters,
  setToggleFilters,
}) => {
  return (
    <ListTopSection
      {...{
        extraChildren,
        actionChildren,
        filterChildren: (
          <FilterIcon
            {...{
              setToggleFilters,
              searchOptions,
              emptySearchOptionsToConsider,
            }}
          />
        ),
        collapsibleFilterChildren: (
          <CollapsibleListFilters
            {...{ toggleFilters, collapsibleFilterChildren }}
          />
        ),
      }}
    />
  );
};

export default CollapsibleTopSection;
