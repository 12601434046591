import ExportList from "components/common/ExportList/ExportList";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { getUserExports } from "common/API/exportAPIs";

const ExportTab = () => {
  return (
    <>
      <ExportList {...{ showCreatedBy: false, fetchExports: getUserExports }} />
      <ScrollToTop />
    </>
  );
};

export default ExportTab;
