import UpdateProfileFormRows from "./UpdateProfileFormRows";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";
import { updateUserInfo } from "common/utils/authenticationUtils";
import { updateUserProfileDetails } from "common/API/userProfileAPIs";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";

const UpdateProfileForm = ({ userId, formDetails, setFormDetails }) => {
  const mutation = useMutation(({ userId, data }) =>
    updateUserProfileDetails(userId, data)
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(
      {
        userId,
        data: {
          first_name: formDetails.firstName,
          last_name: formDetails.lastName,
        },
      },
      {
        onSuccess: (response) => {
          toast.success(
            response?.data?.message ||
              "Updated profile information successfully"
          );
          updateUserInfo(dispatch, response?.data);
        },
        onError: (error) => {
          toast.error(
            getErrorMessage(error, "Error while updating profile information")
          );
        },
      }
    );
  };

  return (
    <form className="Form d-flex flex-column align-items-baseline">
      <span className="Font_H1 Font_Title_Color d-block mb-4 border-bottom-1 text-start w-30">
        Account Details
      </span>
      <span className="Font_H10 Font_Subtitle_Color mb-3">
        Note: Contact administrator to update your Email and User Role
      </span>
      <UpdateProfileFormRows
        {...{ formDetails, setFormDetails, handleSubmit }}
      />
    </form>
  );
};

export default UpdateProfileForm;
