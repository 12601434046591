import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as styles from "./FileInfoCard.module.scss";

import FileInfoCardBody from "./FileInfoCardBody";
import FileInfoCardHeader from "./FileInfoCardHeader";
import classNames from "classnames";
import { redirectToViewImport } from "common/utils/utils";

// TODO: Make this common compound component
const FileInfoCard = ({ fileInfo, filePathKey, fileIdKey, fileNameKey }) => {
  const {
    [filePathKey]: filePath,
    [fileIdKey]: fileId,
    [fileNameKey]: fileName,
    ...restFileInfo
  } = fileInfo;

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    styles.Card_Container,
    "cursor-pointer"
  );

  return (
    <section
      {...{
        className: sectionClasses,
        onClick: () => redirectToViewImport(fileId),
      }}>
      <FileInfoCardHeader />
      <FileInfoCardBody
        {...{
          filePath,
          fileName,
          ...restFileInfo,
        }}
      />
    </section>
  );
};

export default FileInfoCard;
