import Pills from "components/common/Pills/Pills";
import classNames from "classnames";
import { getPillsArrayFromObject } from "common/utils/utils";
import styles from "./DataFilterPills.module.scss";

const DataFilterPills = ({ obj, renderWithLabels, className = "" }) => {
  const { obj: updatedObj, array } = getPillsArrayFromObject(obj);
  const classes = classNames(styles.Filter_Pills_Wrapper, className);

  return (
    updatedObj &&
    array.length > 0 && (
      <section className={classes}>
        <Pills {...{ pills: array, showAllPills: true, renderWithLabels }} />
      </section>
    )
  );
};

export default DataFilterPills;
