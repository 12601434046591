import { DATA_PHASE_BREADCRUMB } from "common/constants";
import { convertSnakeCaseToTitleCase } from "common/utils/utils";
import store from "app/store";

export const DynamicPartnerNameBreadcrumb = ({ match }) => {
  // Get redux state from store
  const state = store.getState();

  const partnerId = match?.params?.partnerId;
  const partnersById = state?.partnersById?.partnersById;

  return (
    !!partnersById[partnerId] &&
    convertSnakeCaseToTitleCase(partnersById[partnerId])
  );
};

export const DynamicSearchDetailBreadcrumb = () => {
  const state = store.getState();
  const searchId = state?.searchDetailRecord?.searchDetailRecord?.searchId;
  const partnerName = state.searchDetailRecord?.searchDetailRecord?.partnerName;

  return `#${searchId}, ${partnerName}`;
};

export const DynamicDataPhaseBreadcrumb = () => {
  const state = store.getState();
  let dataPhase = state?.searchDetailRecord?.searchDetailRecord?.dataPhase;

  return DATA_PHASE_BREADCRUMB[dataPhase] || "-";
};

export const DynamicRecordLoanIdBreadcrumb = () => {
  const state = store.getState();
  const loanId = state?.searchDetailRecord?.searchDetailRecord?.loanId;

  return loanId || "No transaction id";
};
