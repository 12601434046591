import { ReactComponent as InfoIconImg } from "images/ic_gray_info.svg";
import Tooltip from "components/common/Tooltip/Tooltip";

const InfoIcon = ({ msg }) => {
  return (
    <>
      <InfoIconImg data-tip={msg} />
      <Tooltip />
    </>
  );
};

export default InfoIcon;
