import * as styles from "./DlightKenyaDataDetail.module.scss";

import { ADMIN_USER_ROLE, DATA_TEAM_USER_ROLE } from "common/constants";

import FormFooter from "components/common/FormFooter/FormFooter";
import DlightKenyaDataFormField from "./DlightKenyaDataFormField";
import DlightKenyaDataActions from "./DlightKenyaDataActions";
import classNames from "classnames";
import { getUserProperty } from "common/utils/authenticationUtils";
import { useState } from "react";

const DlightKenyaDataForm = ({ record, refetchRecord }) => {
  const userRole = getUserProperty("user_role");
  const isAdminUser = [DATA_TEAM_USER_ROLE, ADMIN_USER_ROLE].includes(userRole);

  const [formDetails, setFormDetails] = useState(record);

  const wrapperClasses = classNames(
    styles.Dlight_Kenya_Data_Form_Field_Wrapper,
    "d-flex align-items-center gap-4 py-2"
  );

  return (
    <form className="pb-5">
      {Object.keys(record).map((recordKey, index) => {
        return (
          <DlightKenyaDataFormField
            key={index}
            {...{
              recordKey,
              formDetails,
              setFormDetails,
              isAdminUser,
              wrapperClasses,
            }}
          />
        );
      })}
      {isAdminUser && (
        <FormFooter
          {...{
            showClearFormButton: false,
          }}>
          <DlightKenyaDataActions
            {...{ formDetails, record, refetchRecord, setFormDetails }}
          />
        </FormFooter>
      )}
    </form>
  );
};

export default DlightKenyaDataForm;
