import ExpandableItems from "./ExpandableItems";
import classNames from "classnames";
import { convertSnakeCaseToTitleCase } from "common/utils/utils";
import { useState } from "react";

const ExpandablePill = ({ pill = {} }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { label, value } = pill;

  if (!Array.isArray(value)) return null;

  const firstTwoItems = value.slice(0, 2);
  const remainingItems = value.slice(2);

  const containerClasses = classNames("Expandable_Pill_Container", {
    Active: isExpanded,
  });

  return (
    <div
      className={containerClasses}
      onClick={(e) => {
        e.stopPropagation();
        // * Expandable pill only if array of values has more than two elements
        !!remainingItems.length && setIsExpanded((value) => !value);
      }}>
      <div className="Pill">
        <span className="Font_H13 Font_Title_Color">
          {convertSnakeCaseToTitleCase(label)}:&nbsp;
        </span>

        <ExpandableItems
          {...{
            items: firstTwoItems,
            isExpanded,
            hasMoreItems: !!remainingItems.length,
          }}
        />
      </div>
      {isExpanded && (
        <div className="Font_H12 Font_Title_Color Expanded_Items">
          <span>{remainingItems.join(", ")}</span>
        </div>
      )}
    </div>
  );
};

export default ExpandablePill;
