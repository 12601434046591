import { formatDate } from "common/utils/dateAndTimeUtils";
import { showAvailableCapacity } from "common/utils/utils";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";
import { PRODUCT_TYPES } from "common/constants";
import { useNavigate } from "react-router-dom";
import routeConstants from "routes/routeConstants";

const ProjectActivityListTableBody = ({
  projectActivityList,
  error,
  isError,
}) => {
  const navigate = useNavigate();

  const handleOnClick = (id) => {
    navigate(`${routeConstants.ProjectActivityList.path}/${id}`);
  };

  if (isError) {
    toast.error(getErrorMessage(error, "Can't fetch project activities list"));
  }

  return (
    <tbody className="Tbody">
      {projectActivityList.map((projectActivity, index) => (
        <tr
          className="Tr user-select-none"
          key={index}
          onClick={() => handleOnClick(projectActivity?.id)}>
          <td className="Td">
            <span>{projectActivity?.code}</span>
          </td>

          <td className="Td">
            {showAvailableCapacity(projectActivity[PRODUCT_TYPES.pt3])}
          </td>
          <td className="Td">
            {showAvailableCapacity(projectActivity[PRODUCT_TYPES.pt1])}
          </td>
          <td className="Td">
            {showAvailableCapacity(projectActivity[PRODUCT_TYPES.pt2])}
          </td>
          <td className="Td">
            {formatDate(projectActivity?.min_installation_date) || "-"}
          </td>
          <td className="Td">
            {formatDate(projectActivity?.max_installation_date) || "-"}
          </td>
          <td className="Td">{projectActivity?.total_states}</td>
          <td className="Td">{projectActivity?.total_partners}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default ProjectActivityListTableBody;
