import * as commonStyles from "styles/Common.module.scss";

import { useEffect, useRef, useState } from "react";

import CorrectionImportRow from "./CorrectionImportRow";
import FileUploadRow from "components/common/FormRows/FileUploadRow/FileUploadRow";
import LabelRow from "components/common/FormRows/LabelRow/LabelRow";
import NewImportFooter from "./NewImportFooter";
import PartnerRow from "components/common/FormRows/PartnerRow/PartnerRow";
import { redirectToViewImport } from "common/utils/utils";
import { handleFileUploadToastMessages } from "common/utils/errorUtils";
import { uploadRawData } from "common/API/api";

const NewImport = () => {
  const [partner, setPartner] = useState("");
  const [file, setFile] = useState(null);
  const [isFileValid, setIsFileValid] = useState(false);
  const [label, setLabel] = useState("");
  const [isCorrectionImport, setIsCorrectionImport] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const fileUploadRef = useRef();

  const resetForm = () => {
    setPartner("");
    setFile(null);
    setIsFileValid(false);
    setLabel("");
    setIsCorrectionImport(false);
    fileUploadRef.current.value = "";
  };

  useEffect(() => {
    setIsDisabled(!(partner?.partner?.value && file && isFileValid && label));
  }, [partner, file, isFileValid, label]);

  // TODO: Use react query to call upload file APIs
  const handleSubmit = async () => {
    setIsDisabled(true);
    if (partner?.partner?.value) {
      try {
        const response = await uploadRawData(
          {
            partner_id: partner?.partner?.value,
            fileLabel: label,
            isReimport: isCorrectionImport,
          },
          file
        );

        handleFileUploadToastMessages(response);
        const importId = response?.data?.payload?.id;
        importId && redirectToViewImport(importId);
      } catch (error) {
        handleFileUploadToastMessages(error?.response);
        return;
      }
      resetForm();
    }
  };

  return (
    <section className={commonStyles.Full_Height_Container}>
      <section className="Form">
        <PartnerRow {...{ partner, setPartner }} />
        <FileUploadRow
          {...{
            file,
            setFile,
            isFileValid,
            setIsFileValid,
            inputRef: fileUploadRef,
          }}
        />
        <LabelRow {...{ label, setLabel }} />
        <CorrectionImportRow
          {...{ isCorrectionImport, setIsCorrectionImport }}
        />
      </section>
      <NewImportFooter
        {...{ handleSubmit, setClearForm: resetForm, isDisabled }}
      />
    </section>
  );
};

export default NewImport;
