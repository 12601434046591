import BookmarkIcon from "components/common/BookmarkIcon/BookmarkIcon";
import EyeIconImg from "images/Eye.svg";
import HyperLink from "components/common/HyperLink/HyperLink";
import Pills from "components/common/Pills/Pills";
import { convertToCompactFormat } from "common/utils/dateAndTimeUtils";
import { useNavigate } from "react-router-dom";

const SearchTableRow = ({
  id,
  partner,
  dataPhase,
  additionalFilterPills,
  groupByPills,
  isBookmarked = false,
  lastRunBy = "",
  totalCount = 0,
  lastUpdatedCountAt = "",
  lastExportType = "",
  lastExportedAt = "",
  doesAdditionalFiltersExist = false,
  doesGroupByExist = false,
  isPartnerDashboard = false,
  showLastRunBy,
}) => {
  const navigate = useNavigate();

  const handleOnClick = (id) => {
    navigate(`/searches/${id}`);
  };

  return (
    <tr className="Tr cursor-pointer" onClick={() => handleOnClick(id)}>
      {!isPartnerDashboard && (
        <td>
          <BookmarkIcon isBookmarked={isBookmarked} className="ps-4 pe-2" />
        </td>
      )}
      <td className="Td">{partner}</td>
      <td className="Td">{dataPhase}</td>
      <td className="Td Td_Pills">
        {doesAdditionalFiltersExist && (
          <Pills pills={additionalFilterPills} showAllPills renderWithLabels />
        )}
      </td>
      <td>{doesGroupByExist && <Pills pills={groupByPills} showAllPills />}</td>
      {showLastRunBy && <td className="Td">{lastRunBy}</td>}
      <td className="Td">
        {totalCount}, {convertToCompactFormat(lastUpdatedCountAt) || ""}
      </td>
      <td>
        {lastExportType && `${lastExportType}, `}
        {convertToCompactFormat(lastExportedAt) || ""}
      </td>
      <td className="Td">
        <HyperLink
          iconSrc={EyeIconImg}
          altText="eye"
          text="View Results"
          onClick={() => handleOnClick(id)}
        />
      </td>
    </tr>
  );
};

export default SearchTableRow;
