const FileUploadInput = ({ setFile, inputRef }) => {
  return (
    <input
      id="file"
      name="upload-file"
      type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      onChange={(event) => setFile(event.target.files[0])}
      ref={inputRef}
      required
    />
  );
};

export default FileUploadInput;
