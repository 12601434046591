import {
  getProductModelList,
  getProductTypeList,
} from "common/API/filtersAndGroupByApi";
import {
  setProductModel,
  setProductModelList,
  setProductType,
  setProductTypeList,
} from "slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

import FilterItem from "components/Search/FilterItem/FilterItem";
import { conditionallyCreateOptionObject } from "../../utils";
import styles from "./ProductTypeAndProductFilters.module.scss";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "common/utils/errorUtils";

const ProductTypeAndProductFilters = ({ selectedDataFilter }) => {
  // * #####################################
  // * Redux States
  // * #####################################

  const {
    productType,
    productModel,
    productModelList,
    productTypeList,
    dataPhase,
    showNonMandatoryFilters,
    isGettingPrefilled,
  } = useSelector((state) => state.search);

  const dispatch = useDispatch();

  const commonFilterIds = selectedDataFilter?.filter_ids?.common_filters;
  const additionalFilterIds =
    selectedDataFilter?.filter_ids?.additional_filters;
  const additionalFilterNames =
    selectedDataFilter?.filter_names?.additional_filters;

  // * #####################################
  // * Use Effects
  // * #####################################

  // * Use effect to fetch product type based on data phase
  useEffect(() => {
    (async () => {
      try {
        const productTypeList = await getProductTypeList({
          data_phase: dataPhase?.name,
        });
        dispatch(setProductTypeList(productTypeList));
      } catch (error) {
        toast.error(
          getErrorMessage(error, "Failed to fetch product type list")
        );
      }
    })();

    if (isGettingPrefilled) return;

    // * Set product type if it is coming from template else reset it
    const productType =
      dataPhase?.value === commonFilterIds?.data_phase
        ? conditionallyCreateOptionObject(
            additionalFilterNames,
            additionalFilterIds,
            "product_type",
            "id"
          )
        : "";
    dispatch(setProductType(productType));
  }, [dataPhase, commonFilterIds, additionalFilterIds]);

  // * Use effect to fetch model based on prod type
  useEffect(() => {
    (async () => {
      try {
        const productModelList = await getProductModelList(productType?.id, "");
        dispatch(setProductModelList(productModelList));
      } catch (error) {
        toast.error(
          getErrorMessage(error, "Failed to fetch product model list")
        );
      }
    })();

    if (isGettingPrefilled) return;

    // * If filter is reapplied, set product model based on template
    dispatch(
      setProductModel(
        productType?.id === additionalFilterIds?.product_type
          ? conditionallyCreateOptionObject(
              additionalFilterNames,
              additionalFilterIds,
              "product_model"
            )
          : ""
      )
    );
  }, [productType, additionalFilterIds]);

  // * Effect to handle showing non mandatory filters
  useEffect(() => {
    (async () => {
      if (!showNonMandatoryFilters) return;

      try {
        const productTypeList = await getProductTypeList({
          data_phase: dataPhase?.name,
        });
        dispatch(setProductTypeList(productTypeList));
      } catch (error) {
        toast.error(
          getErrorMessage(error, "Failed to fetch product type list")
        );
      }
    })();
  }, [showNonMandatoryFilters]);

  // * #####################################
  // * Dynamic options and Handlers
  // * #####################################

  const getDynamicProductModelsOptions = (query) => {
    const res = getProductModelList(productType?.id, query);
    return res;
  };

  const handleProductType = (productType) => {
    dispatch(setProductType(productType));
  };

  const handleProductModel = (productModel) => {
    dispatch(setProductModel(productModel));
  };

  return (
    <>
      <div className={styles.Product_Type_Dd}>
        <FilterItem
          {...{
            label: "Product Type",
            type: "nested-dropdown",
            selectedOption: productType,
            setSelectedOption: handleProductType,
            options: productTypeList,
          }}
        />
      </div>
      {productType && (
        <div className={styles.Product_Dd}>
          <FilterItem
            {...{
              label: "Product Model",
              type: "dropdown",
              selectedOption: productModel,
              setSelectedOption: handleProductModel,
              options: productModelList,
              dynamicOptions: getDynamicProductModelsOptions,
              fetchOptions: true,
              dropdownProps: {
                isMulti: true,
                closeMenuOnSelect: false,
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ProductTypeAndProductFilters;
