import { useState } from "react";
import { useQuery } from "react-query";

const sortStates = {
  asc: "desc",
  desc: "asc",
};

/**
 * It takes a query key, a fetchData function, and a defaultOrderField, a defaultOrderingFields, and returns an object with
 * isLoading, data, isError, error, handleSorting, sortOrder, and currentOrderField
 * @param queryKey - This is the key that will be used to identify the query.
 * @param fetchData - a function that returns a promise. It should accept an object with the following
 * keys:
 * @params sortOrder - This will give the order type
 * @params orderingFields - This will give the field by which sorting will be done
 * @param defaultOrderField - The default field to sort by.
 * @param defaultOrderingFields - The default key path from response to sort by.
 * @returns an object with the following properties:
 * isLoading,
 * data,
 * isError,
 * error,
 * handleSorting,
 * sortOrder,
 * currentOrderField,
 */

export const useSortableTable = (
  queryKey,
  fetchData,
  defaultSortOrder = "",
  defaultOrderField = "",
  defaultOrderingFields = ""
) => {
  /* To maintain the state of sortOrder */
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  /* To maintain the state of field by which sorting is done*/
  const [currentOrderField, setcurrentOrderField] = useState(defaultOrderField);

  /* To maintain the state of array of key path from response for sorting*/
  const [orderingFields, setOrderingFields] = useState([defaultOrderingFields]);

  const { isLoading, data, isError, error } = useQuery(
    [queryKey, sortOrder, orderingFields],
    () =>
      fetchData({
        ordering: sortOrder,
        ordering_fields: orderingFields,
      })
  );

  const handleSorting = (currentOrderField, orderingFields) => {
    setSortOrder((value) => sortStates[value]);
    setcurrentOrderField(currentOrderField);
    setOrderingFields(orderingFields);
  };

  return {
    isLoading,
    data,
    isError,
    error,
    handleSorting,
    sortOrder,
    currentOrderField,
  };
};
