import * as styles from "../UserProfile.module.scss";

import { useEffect, useState } from "react";

import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";
import CustomLoader from "components/common/CustomLoader/CustomLoader";
import UpdateProfileForm from "./UpdateProfileForm/UpdateProfileForm";
import { getErrorMessage } from "common/utils/errorUtils";
import { getUserProfileDetails } from "common/API/userProfileAPIs";
import { getUserProperty } from "common/utils/authenticationUtils";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const UserProfileRightSection = () => {
  const userId = getUserProperty("id");

  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useQuery(["getUserProfileDetails", userId], () =>
    getUserProfileDetails(userId)
  );

  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userRole: "",
  });

  useEffect(() => {
    setFormDetails({
      firstName: response?.data?.first_name || "",
      lastName: response?.data?.last_name || "",
      email: response?.data?.email || "",
      userRole: response?.data?.user_role || "",
    });
  }, [response]);

  if (isLoading) {
    return (
      <section className={styles.User_Profile_Right_Section}>
        <CustomLoader />
      </section>
    );
  }

  if (isError) {
    toast.error(getErrorMessage(error, "Cannot fetch user profile info"));
  }

  return (
    <section className={styles.User_Profile_Right_Section}>
      <UpdateProfileForm {...{ userId, formDetails, setFormDetails }} />
      <ChangePasswordForm {...{ userId }} />
    </section>
  );
};

export default UserProfileRightSection;
