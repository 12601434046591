import PropTypes from "prop-types";
import RadioButton from "../RadioButton/RadioButton";
import React from "react";

const RadioGroup = ({
  radioButtons,
  selectedOption,
  onChange,
  disabled,
  className,
}) => {
  return (
    <section className="d-flex gap-1">
      {radioButtons.map((radioButton, index) => (
        <RadioButton
          key={index}
          checked={selectedOption === radioButton.value}
          {...{ onChange, disabled, ...radioButton, className }}
        />
      ))}
    </section>
  );
};

RadioGroup.propTypes = {
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default RadioGroup;
