import ExportTab from "./ExportTab/ExportTab";
import ImportTab from "./ImportTab/ImportTab";
import OverviewTab from "./OverviewTab/OverviewTab";
import SearchTab from "./SearchTab/SearchTab";
import AdminTab from "./AdminTab/AdminTab";

// * Add your components in the content attribute
export const tabs = [
  { id: 1, name: "Overview", content: OverviewTab, showScrollToTop: false },
  { id: 2, name: "Imports", content: ImportTab, showScrollToTop: true },
  { id: 3, name: "Searches", content: SearchTab, showScrollToTop: true },
  { id: 4, name: "Exports", content: ExportTab, showScrollToTop: true },
];

export const adminTabs = [
  ...tabs,
  { id: 5, name: "Admin", content: AdminTab, showScrollToTop: false },
];
