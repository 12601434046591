import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getImportURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getUserImports = async ({
  pageParam,
  pageSize = DEFAULT_PAGE_SIZE,
  searchOptions,
}) => {
  const url = getImportURLs("getUserImports");
  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};

export const getPartnerImports = async ({
  pageParam,
  pageSize = DEFAULT_PAGE_SIZE,
  searchOptions,
  urlParams,
}) => {
  const url = getImportURLs("getPartnerImports", {
    partnerId: urlParams?.partnerId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};

export const getImportDetails = async (rawDataFileId) => {
  const url = getImportURLs("getImportDetails", { rawDataFileId });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getAllImports = async ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getImportURLs("getAllImports");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};

export const downloadExcludedRecords = (partnerId) => {
  const url = getImportURLs("downloadExcludedRecords", {
    partnerId: partnerId,
  });

  return axiosInstance.get(url, { headers: getAuthorizationHeaders() });
};

export const eraseImport = (rawDataFileId) => {
  const url = getImportURLs("eraseImport", {
    rawDataFileId,
  });

  return axiosInstance.patch(
    url,
    {},
    {
      headers: getAuthorizationHeaders(),
    }
  );
};
