import SearchPivotTableHeaderText from "./SearchPivotTableHeaderText";

const SearchPivotTableHeader = ({ headerGroups }) => {
  return (
    <section className="header">
      {headerGroups.map((headerGroup, index) => (
        <div {...headerGroup.getHeaderGroupProps()} className="tr" key={index}>
          {headerGroup.headers.map((column, index) => {
            return (
              <div {...column.getHeaderProps()} className="th" key={index}>
                <SearchPivotTableHeaderText
                  key={index}
                  {...{ header: column.render("Header"), index }}
                />
              </div>
            );
          })}
        </div>
      ))}
    </section>
  );
};

export default SearchPivotTableHeader;
