import * as styles from "./ModalSearchDetails.module.scss";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import { PRODUCT_TYPES } from "common/constants";
import RichText from "components/common/RichText/RichText";
import { SearchResultSummary } from "components/common/SearchResultSummary/SearchResultSummary";
import SearchSummary from "components/common/SearchSummary/SearchSummary";
import { TextTypes } from "constants/enumTypes.constants";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";

const ModalSearchDetails = ({
  isLoading,
  filterNames,
  groupByNames,
  pivotTableSummary,
  totalCount,
  productTypeCounts,
  className = "",
}) => {
  const containerClasses = classNames(styles.Container, className);
  return (
    <div className={containerClasses}>
      <SearchSummary {...{ filterNames, groupByNames }} />
      <ResultSummary
        {...{
          isLoading,
          totalCount,
          pivotTableSummary,
          productTypeCounts,
        }}
      />
    </div>
  );
};

export default ModalSearchDetails;

//***************************/
//*****Local Components******/
//***************************/

const ResultSummary = ({
  isLoading,
  totalCount,
  pivotTableSummary,
  productTypeCounts,
}) => {
  return (
    <>
      <div className={styles.Results}>
        <RichText textType={TextTypes.SUBHEAD2} className="text-nowrap">
          Results:
        </RichText>
      </div>
      <div className={styles.Result_Brief_Stats}>
        {isLoading ? (
          <CustomLoader containerClasses="ms-3" height={28} width={28} />
        ) : (
          <SearchResultSummary
            {...{
              recordsSummary: totalCount,
              pivotTableSummary,
              textType: TextTypes.SUBHEAD2,
              className: "ps-2",
            }}
          />
        )}
      </div>
      {productTypeCounts && !isEmpty(productTypeCounts) && (
        <ProductTypeCounts {...{ productTypeCounts }} />
      )}
    </>
  );
};

const ProductTypeCounts = ({ productTypeCounts }) => {
  return (
    <div className={styles.Product_Type_Counts}>
      <div className="Table_Wrapper">
        <table className="Table">
          <thead className="Thead">
            <tr className="Tr">
              <th className="Th">Product Type</th>
              <th className="Th">Count</th>
            </tr>
          </thead>
          <ProductTypeCountsTableBody {...{ productTypeCounts }} />
        </table>
      </div>
    </div>
  );
};

const ProductTypeCountsTableBody = ({ productTypeCounts }) => {
  const WPS = productTypeCounts[PRODUCT_TYPES.pt1];
  const ICS = productTypeCounts[PRODUCT_TYPES.pt2];
  const SLS = productTypeCounts[PRODUCT_TYPES.pt3];

  return (
    <tbody className="Tbody">
      {WPS && (
        <ProductTypeAndItsCount
          {...{ productType: PRODUCT_TYPES.pt1, count: WPS }}
        />
      )}
      {ICS && (
        <ProductTypeAndItsCount
          {...{ productType: PRODUCT_TYPES.pt2, count: ICS }}
        />
      )}
      {SLS && (
        <ProductTypeAndItsCount
          {...{ productType: PRODUCT_TYPES.pt3, count: SLS }}
        />
      )}
    </tbody>
  );
};

const ProductTypeAndItsCount = ({ productType, count }) => {
  return (
    <tr className="Tr">
      <td className="Td">{productType}</td>
      <td className="Td">{count}</td>
    </tr>
  );
};
