import * as commonStyles from "styles/Common.module.scss";
import * as styles from "./CleanDataUpdateImport.module.scss";

import { useEffect, useRef, useState } from "react";

import CleanDataHeadersRow from "./CleanDataHeadersRow";
import CleanDataUpdateImportFooter from "./CleanDataUpdateImportFooter";
import FileUploadRow from "components/common/FormRows/FileUploadRow/FileUploadRow";
import LabelRow from "components/common/FormRows/LabelRow/LabelRow";
import PartnerRow from "components/common/FormRows/PartnerRow/PartnerRow";
import { uploadCleanDataReport } from "common/API/partnerDashboardAPI";
import { handleFileUploadToastMessages } from "common/utils/errorUtils";

const CleanDataUpdateImport = () => {
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [partner, setPartner] = useState("");
  const [label, setLabel] = useState("");
  const [file, setFile] = useState(null);
  const [isFileValid, setIsFileValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const fileUploadRef = useRef();

  useEffect(() => {
    setIsDisabled(
      !(
        partner?.partner?.value &&
        file &&
        isFileValid &&
        label &&
        updatedColumns.length > 0
      )
    );
  }, [partner, file, isFileValid, label]);

  const resetForm = () => {
    setUpdatedColumns([]);
    setPartner("");
    setFile(null);
    setIsFileValid(false);
    setLabel("");
    fileUploadRef.current.value = "";
  };

  // TODO: Use react query to call upload file APIs
  const handleSubmit = async () => {
    setIsDisabled(true);

    const columnsList = updatedColumns.map((e) => e.name);

    try {
      const response = await uploadCleanDataReport(
        { updated_columns: columnsList, label },
        file,
        partner?.partner?.value
      );
      handleFileUploadToastMessages(response);
    } catch (error) {
      console.error(error);
    }
    resetForm();
  };

  return (
    <section className={commonStyles.Full_Height_Container}>
      <section className="Form">
        <CleanDataHeadersRow
          {...{
            updatedColumns,
            setUpdatedColumns,
            customWidth: "450px",
            isOptionDisabled: () => updatedColumns.length >= 4,
          }}
        />
        <PartnerRow {...{ partner, setPartner, customWidth: "450px" }} />
        <FileUploadRow
          {...{
            file,
            setFile,
            isFileValid,
            setIsFileValid,
            inputRef: fileUploadRef,
            className: styles.Form_Widget,
            labelText: "Updated Clean Data File :",
          }}
        />
        <LabelRow {...{ label, setLabel, className: styles.Form_Widget }} />
      </section>
      <CleanDataUpdateImportFooter
        {...{ setClearForm: resetForm, handleSubmit, isDisabled }}
      />
    </section>
  );
};

export default CleanDataUpdateImport;
