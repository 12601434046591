import { useEffect } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { getDataPhaseList } from "common/API/fieldAPIs";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const DataPhaseDropdown = ({
  dataPhase,
  setDataPhase,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  const {
    data: response,
    isError,
    error,
  } = useQuery("data-phases", getDataPhaseList);

  if (isError)
    toast.error(getErrorMessage(error), "Cannot fetch Data Phases for filters");

  const dataPhasesList = response?.data?.payload;

  useEffect(() => {
    const storedDataPhase = sessionStorage.getItem(
      `${pageIdentifier}DataPhase`
    );
    if (storedDataPhase) {
      setDataPhase(JSON.parse(storedDataPhase));
    }
  }, [pageIdentifier]);

  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      data_phase: dataPhase?.value,
    }));
    if (dataPhase !== undefined) {
      sessionStorage.setItem(
        `${pageIdentifier}DataPhase`,
        JSON.stringify(dataPhase)
      );
    }
  }, [dataPhase, setSearchOptions, pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: dataPhase,
        setSelectedOption: setDataPhase,
        options: dataPhasesList,
        customWidth: "300px",
        className,
        placeholder: "Data Phase",
      }}
    />
  );
};

export default DataPhaseDropdown;
