import { convertToCompactFormat } from "common/utils/dateAndTimeUtils";
import { useNavigate } from "react-router-dom";

const MongoliaDataBody = ({ mongoliaData }) => {
  const tdClasses = "Td text-wrap";
  const navigate = useNavigate();

  const handleOnClick = (id) => {
    navigate(`/mongolian-program/${id}`);
  };

  return (
    <tbody className="Tbody">
      {mongoliaData.map((mongoliaDataRecord, index) => {
        return (
          <tr
            className="Tr cursor-pointer"
            key={index}
            onClick={() => {
              handleOnClick(mongoliaDataRecord?.id);
            }}>
            <td className={tdClasses}>{mongoliaDataRecord?.sysnum}</td>
            <td className={tdClasses}>{mongoliaDataRecord?.customer_name}</td>
            <td className={tdClasses}>{mongoliaDataRecord?.address1}</td>
            <td className={tdClasses}>{mongoliaDataRecord?.address2}</td>
            <td className={tdClasses}>{mongoliaDataRecord?.mfi_branch_code}</td>
            <td className={tdClasses}>{mongoliaDataRecord?.district}</td>
            <td className={tdClasses}>
              {mongoliaDataRecord?.mfi_tracking_code}
            </td>
            <td className={tdClasses}>{mongoliaDataRecord?.mfi_account_id}</td>
            <td className={tdClasses}>
              {mongoliaDataRecord?.crediting_category}
            </td>
            <td className={tdClasses}>{mongoliaDataRecord?.bid_device_code}</td>
            <td className={tdClasses}>
              {convertToCompactFormat(mongoliaDataRecord?.install_date)}
            </td>
            <td className={tdClasses}>
              {mongoliaDataRecord?.project_activity}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default MongoliaDataBody;
