import * as styles from "./SearchDetailSummarySection.module.scss";

import SearchPivotTable from "components/common/SearchPivotSection/SearchPivotTable/SearchPivotTable";
import { SearchResultTopSection } from "components/common/SearchPivotSection/SearchResultTopSection/SearchResultTopSection";
import UpdateCountButton from "./../UpdateCountButton/UpdateCountButton";
import pluralize from "pluralize";
import classNames from "classnames";
import CustomLoader from "components/common/CustomLoader/CustomLoader";

const SearchDetailSummarySection = ({ filter, handleUpdateCount }) => {
  if (!filter) {
    const loaderContainerClasses = classNames(
      styles.Search_Result_Container,
      "align-items-center",
      "justify-content-center",
      "m-auto"
    );
    return (
      <section className={styles.Search_Detail_Custom_loader}>
        <CustomLoader containerClasses={loaderContainerClasses} />
      </section>
    );
  }

  const {
    total_count: totalCount,
    pivot_table_summary: pivotTableSummary,
    last_updated_count_at: lastUpdatedCountAt,
    pivot_table: pivotTable,
  } = filter;

  return (
    <>
      <SearchResultTopSection
        {...{
          totalCount,
          pivotTableSummary,
          recordsSummary: pluralize(
            "Record",
            Intl.NumberFormat("en-US").format(totalCount),
            true
          ),
          lastUpdatedCountAt,
        }}>
        <UpdateCountButton {...{ handleUpdateCount }} />
      </SearchResultTopSection>
      <SearchPivotTable
        {...{
          pivotTable,
          className: styles.Pivot_Container_Search_Detail,
          isSearchDetail: true,
        }}
      />
    </>
  );
};

export default SearchDetailSummarySection;
