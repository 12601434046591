import FormFooter from "components/common/FormFooter/FormFooter";
import SearchButton from "./SearchButton";

const SearchFooter = ({
  setClearForm,
  handleFilterSubmit,
  areDataFiltersSame,
}) => {
  return (
    <FormFooter {...{ setClearForm }}>
      <SearchButton
        {...{
          handleFilterSubmit,
          areDataFiltersSame,
        }}
      />
    </FormFooter>
  );
};

export default SearchFooter;
