import React from "react";

const SearchPivotTableFooter = ({ footerGroups }) => {
  return (
    <section className="footer">
      {footerGroups.map((footerGroup, index) => (
        <div {...footerGroup.getHeaderGroupProps()} className="tr" key={index}>
          {footerGroup.headers.map((column, index) => (
            <div {...column.getHeaderProps()} className="td" key={index}>
              {column.render("Footer")}
            </div>
          ))}
        </div>
      ))}
    </section>
  );
};

export default SearchPivotTableFooter;
