import * as styles from "./FilterWrapper.module.scss";

import {
  setDataPhase,
  setEarliestDate,
  setLatestDate,
  setPartner,
  setProductType,
  setProjectActivityAssigned,
  setShowNonMandatoryFilters,
  setState,
} from "slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

import { DATA_PHASE } from "common/constants";
import MPAndDateFilters from "./MPAndDateFilters/MPAndDateFilters";
import PartnerAndDataPhaseFilters from "./PartnerAndDataPhaseFilters/PartnerAndDataPhaseFilters";
import ProductTypeAndProductFilters from "./ProductTypeAndProductFilters/ProductTypeAndProductFilters";
import ProjectActivityAssignedFilter from "./ProjectActivityAssignedFilter/ProjectActivityAssignedFilter";
import StateAndBranchFilters from "./StateAndBranchFilters/StateAndBranchFilters";
import { useEffect } from "react";

const FilterWrapper = ({
  selectedDataFilter,
  setSelectedDataFilter,
  clearFilters,
  setClearFilters,
}) => {
  const searchQuery = new URLSearchParams(location.search);
  const fromPage = searchQuery.get("fromPage");

  const { partner, dataPhase, showNonMandatoryFilters } = useSelector(
    (state) => state.search
  );

  const dispatch = useDispatch();

  // * ##############################################
  // * USE EFFECTS
  // * ##############################################

  // * Use effect to clear out non-mandatory fields if mandatory fields are not selected
  useEffect(() => {
    if (!partner || !dataPhase) {
      dispatch(setShowNonMandatoryFilters(false));
      dispatch(setState(""));
      dispatch(setProductType(""));
      dispatch(setProjectActivityAssigned(""));
      dispatch(setEarliestDate(""));
      dispatch(setLatestDate(""));
    } else {
      dispatch(setShowNonMandatoryFilters(true));
    }
  }, [partner, dataPhase]);

  // * Use effect to clear filters on Change
  useEffect(() => {
    if (clearFilters) {
      // Clear all the filters when clear button is clicked
      dispatch(setPartner(""));
      dispatch(setDataPhase(""));
      setSelectedDataFilter(null);
      setClearFilters(false);
    }
  }, [clearFilters]);

  // * ################################
  // * Dynamic options for react-select
  // * ################################

  // * ##############################################
  // * Utilities
  // * ##############################################

  return (
    <section className={styles.Filter_Wrapper}>
      <span className={styles.Filter_Title}>Filters</span>
      <div className={styles.Filter_Container}>
        <PartnerAndDataPhaseFilters {...{ selectedDataFilter, fromPage }} />
        {showNonMandatoryFilters && (
          <>
            {dataPhase?.name === DATA_PHASE.p3 && (
              <ProjectActivityAssignedFilter {...{ selectedDataFilter }} />
            )}
            <StateAndBranchFilters {...{ selectedDataFilter }} />
            <ProductTypeAndProductFilters {...{ selectedDataFilter }} />
            <MPAndDateFilters {...{ selectedDataFilter }} />
          </>
        )}
      </div>
    </section>
  );
};

export default FilterWrapper;
