import * as styles from "../AssignPAModalContent.module.scss";

import AssignPAConfirmationMessage from "./AssignPAConfirmationMessage";
import ModalSearchDetails from "components/common/ModalSearchDetails/ModalSearchDetails";
import ProjectActivityDropdown from "./ProjectActivityDropdown/ProjectActivityDropdown";
import StepWizard from "react-step-wizard";
import classNames from "classnames";

const AssignPAModalWizard = ({
  setWizardInstance,
  onStepChange,
  selectedPA,
  setSelectedPA,
  lastTotalCount,
  partner,
  filterNames,
  groupByNames,
  pivotTableSummary,
  productTypeCounts,
  isLoading,
  errorPayload,
}) => {
  const animationClasses = classNames(styles.Animated, styles.Intro);

  const settings = {
    isLazyMount: true,
    onStepChange,
    instance: setWizardInstance,
    transitions: {
      enterRight: animationClasses,
      enterLeft: animationClasses,
      exitRight: animationClasses,
      exitLeft: animationClasses,
    },
  };

  return (
    <section className={styles.Assign_PA_Modal_Wizard_Wrapper}>
      <span className="Font_H3 Font_Subtitle_Color mb-3">
        Project Activity Assignment
      </span>
      <StepWizard {...settings}>
        <ModalSearchDetails
          {...{
            isLoading,
            filterNames,
            groupByNames,
            pivotTableSummary,
            totalCount: lastTotalCount,
            productTypeCounts,
            className: "ps-0",
          }}
        />
        <ProjectActivityDropdown
          {...{ selectedPA, setSelectedPA, productTypeCounts }}
        />
        {!errorPayload ? (
          <AssignPAConfirmationMessage
            {...{ selectedPA, lastTotalCount, partner, productTypeCounts }}
          />
        ) : (
          <ErrorMessage {...{ errorPayload }} />
        )}
      </StepWizard>
    </section>
  );
};

export default AssignPAModalWizard;

//***************************/
//*****Local Components******/
//***************************/

const ErrorMessage = ({ errorPayload }) => {
  return (
    <div className="Font_H8 Font_Body_Color">
      {errorPayload.map((error) => {
        const {
          product_type: productType,
          error_message: errorMessage,
          total_capacity: totalCapacity,
          used_capacity: usedCapacity,
          capacity_to_be_added: capacityToBeAdded,
        } = error;

        const keyClasses = "Font_H7 Font_Title_Color pe-1";

        return (
          <>
            <div className="py-2">
              <div>
                <span className={keyClasses}>Product Type:</span> {productType}
              </div>
              <div className="d-flex">
                <span className={keyClasses}>Error:</span>
                <span className="Font_H7 Error_Font_Color">{errorMessage}</span>
              </div>
              {totalCapacity && (
                <div>
                  <span className={keyClasses}>Total Capacity:</span>
                  {totalCapacity}
                </div>
              )}
              {totalCapacity && usedCapacity && (
                <div>
                  <span className={keyClasses}>Free Capacity:</span>
                  {totalCapacity - usedCapacity}
                </div>
              )}
              {capacityToBeAdded && (
                <div>
                  <span className={keyClasses}>
                    Capacity that you are trying to add:
                  </span>
                  {capacityToBeAdded}
                </div>
              )}
            </div>
            <hr className="Bg_Dropdown_Border_Color" />
          </>
        );
      })}
    </div>
  );
};
