import {
  getFilePathFromPresignedPost,
  getUploadFileFormData,
} from "../utils/utils";

import axios from "axios";
import { getApiUrls } from "../utils/apiUrls";
import { getErrorMessage } from "../utils/errorUtils";
import qs from "qs";
import store from "app/store";
import { toast } from "react-toastify";

export const apiURL = process.env.REACT_APP_SERVER_URL;
export let partnerList = [];
global.uploadErrorMsg = "";

export const axiosInstance = axios.create({
  baseURL: apiURL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

// Headers
export const getAuthorizationHeaders = () => {
  const state = store.getState();
  const token = state?.user?.user?.token;

  const headers = {
    Authorization: `Token ${token}`,
  };
  return headers;
};

// API to get the list of partners
export const getPartnerList = async () => {
  const response = await axiosInstance.get("/partners/", {
    headers: getAuthorizationHeaders(),
  });
  return response.data.payload;
};

export const promoteFileV2 = (partnerId, uploadedFileId) => {
  const url = getApiUrls("promoteFile", {
    partnerId: partnerId,
    uploadedFileId: uploadedFileId,
  });

  return axiosInstance.post(url, {}, { headers: getAuthorizationHeaders() });
};

// API to Generate pre-signed URL
export const uploadRawData = async (uploadData, file) => {
  const url = getApiUrls(
    uploadData.isReimport
      ? "generatePreSignedPostReImport"
      : "generatePresignedPost",
    uploadData
  );

  // * prepare presigned post for the required file
  const response = await axiosInstance.post(
    url,
    { label: uploadData?.fileLabel },
    {
      headers: getAuthorizationHeaders(),
    }
  );
  const presignedPost = response.data.payload;
  const filePath = getFilePathFromPresignedPost(presignedPost, file.name);

  // * upload the file to S3
  await uploadOnS3(presignedPost, file);
  // * Save the uploaded file to database
  return saveUploadedFile(uploadData, filePath, file.size);
};

// API to upload file on S3
export const uploadOnS3 = (presigned_post, file) => {
  const s3_url = presigned_post.url;

  const axiosS3Instance = axios.create({
    baseURL: s3_url,
  });

  const formData = getUploadFileFormData(file, presigned_post);

  return axiosS3Instance.post(s3_url, formData);
};

// API to Save Uploaded file in the Database and
// Check (in the background) for Submission Problems
export const saveUploadedFile = async (upload_data, filePath, fileSize) => {
  const url = getApiUrls(
    upload_data.isReimport ? "saveUploadedFileReImport" : "saveUploadedFile",
    upload_data
  );

  const response = await axiosInstance.post(
    url,
    {
      file_path: filePath,
      file_size: fileSize,
      partner_id: upload_data.partner_id,
      country_id: 1,
      label: upload_data.fileLabel,
    },
    { headers: getAuthorizationHeaders() }
  );
  return response;
};

// Common action to perform if user is unauthorized
export const unauthorizedCommonAction = async (error) => {
  console.log("API Error:: ", error?.response?.statusText);
  console.log(error.response);
  if (error?.response?.data) {
    switch (error?.response?.status) {
      case 401:
        toast.error("Something went wrong: " + error.response.data.detail);
        global.uploadErrorMsg = "Something went wrong!";
        if (
          error.response?.statusText === "Unauthorized" ||
          error.response.data?.detail === "Invalid token."
        ) {
          toast.error("Please login again");
          localStorage.clear();
          window.location.href = "/login";
        }
        break;
      case 403:
        toast.error(
          getErrorMessage(
            error,
            "You are not authorized to perform this action."
          )
        );
        break;
      case 404:
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
          return;
        }

        toast.error("Requested resource not found on server.");
        break;
      case 504:
        toast.error("Server is Down!!!!");
        global.uploadErrorMsg = "Something went wrong with the server";
        break;
      case 400: {
        // TODO: Need to improve handling of bad request errors
        const errors =
          getErrorMessage(error, "Bad request sent!", true, true) || [];
        Array.isArray(errors)
          ? errors.forEach((error) => toast.error(error))
          : toast.error(errors);
        break;
      }
      default:
        toast.error("Something went wrong");
        global.uploadErrorMsg = "Something went wrong";
    }
  }
};

// Interceptor for axiosInstance
axiosInstance.interceptors.response.use(
  (response) => {
    // TODO: Something if needed
    return Promise.resolve(response);
  },
  async (error) => {
    await unauthorizedCommonAction(error);
    return Promise.reject(error);
  }
);

export const assignProjectActivity = async (payload) => {
  const url = getApiUrls("assignProjectActivity");

  const response = await axios
    .create({
      baseURL: apiURL,
    })
    .patch(url, payload, {
      headers: getAuthorizationHeaders(),
    });

  return {
    message:
      response?.data?.message || "Project Activity Assigned successfully",
    status: response?.status,
  };
};

export const getPresignedURL = async (objectKey) => {
  const url = getApiUrls("getPresignedUrl");

  return axiosInstance.get(url, {
    params: {
      object_key: objectKey,
    },
    headers: getAuthorizationHeaders(),
  });
};
