import React from "react";
import Button from "../Button/Button";
import { ButtonTypes, ButtonContentTypes } from "constants/enumTypes.constants";

const ModalCloseButton = ({ setIsModalOpen }) => {
  return (
    <Button
      buttonType={ButtonTypes.SECONDARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={(event) => {
        event.stopPropagation();
        setIsModalOpen(false);
      }}
      className="me-3">
      <span>Cancel</span>
    </Button>
  );
};

export default ModalCloseButton;
