import { axiosInstance, getAuthorizationHeaders } from "./api";

import { getApiUrls } from "common/utils/apiUrls";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";

export const getUserDashboardStatistics = () => {
  let get_user_dashboard_statistics_url = getApiUrls(
    "getUserDashboardStatistics"
  );
  return axiosInstance
    .get(get_user_dashboard_statistics_url, {
      headers: getAuthorizationHeaders(),
    })
    .then((response) => {
      return response?.data?.payload;
    })
    .catch((err) => {
      console.error(err);
      toast.error(
        getErrorMessage(
          err?.message || "Some error occured, please try again later."
        )
      );
    });
};
