import * as styles from "./BoxKeyValue.module.scss";

const BoxKeyValue = ({ itemKey, itemValue, width = "400px" }) => {
  return (
    <div style={{ width }} className={styles.Box}>
      <span className="Font_H9 Font_Subtitle_Color text-start">{itemKey}</span>
      <span className="Font_H9 Font_Title_Color">
        {itemValue !== undefined ? itemValue : "-"}
      </span>
    </div>
  );
};

export default BoxKeyValue;
