import * as styles from "./DetailSectionsTopActionBar.module.scss";

const DetailSectionsTopActionBar = ({ disabled, children }) => {
  return (
    <div disabled={disabled} className={styles.Detail_Sections_Top_Action_Bar}>
      {children}
    </div>
  );
};

export default DetailSectionsTopActionBar;
