import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";

const EraseButton = ({ handleErase, isBeingErased }) => {
  return (
    <Button
      {...{
        buttonType: ButtonTypes.DANGER_SMALL,
        buttonContentType: ButtonContentTypes.TEXT,
        onClick: handleErase,
        isDisabled: isBeingErased,
      }}>
      {!isBeingErased ? "Erase" : "Erasing"}
    </Button>
  );
};

export default EraseButton;
