import CloseIcon from "../CloseIcon/CloseIcon";
import classNames from "classnames";
import styles from "./CancelablePill.module.scss";

const CancelablePill = ({
  text,
  isVisible,
  handleClose,
  className,
  textOnClick = () => {},
  textRef = null,
}) => {
  if (!isVisible) return null;

  const classes = classNames("Pill", styles.Cancelable_Pill_Wrapper, className);

  return (
    <div className={classes}>
      <span
        className="Font_H11 Font_Title_Color"
        onClick={textOnClick}
        ref={textRef}>
        {text}
      </span>
      <div onClick={handleClose} className={styles.Cancelable_Pill_Close_Icon}>
        <CloseIcon />
      </div>
    </div>
  );
};

export default CancelablePill;
