import * as styles from "./FileInfoCard.module.scss";

import { ReactComponent as RightArrow } from "images/ic_tail_arrow_right.svg";
import Tooltip from "components/common/Tooltip/Tooltip";
import classNames from "classnames";

const FileInfoCardHeader = () => {
  const headerClasses = classNames(
    "d-flex justify-content-between pb-4",
    styles.Card_Header
  );
  return (
    <header className={headerClasses}>
      <span className="Font_H7 Font_Title_Color">Original Import</span>
      <RightArrow data-tip="Click to go to details of this import" />
      <Tooltip />
    </header>
  );
};

export default FileInfoCardHeader;
