import { getApiUrls } from "../utils/apiUrls";

import { axiosInstance, getAuthorizationHeaders } from "./api";

/**
 * Get the details for a data filter
 * @returns The payload of the response.
 */
export const getFilterDetails = async (dataFilterId) => {
  const url = getApiUrls("getFilterDetails", { dataFilterId });

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const getSearchExportReports = async (
  dataFilterId,
  queryParams = {}
) => {
  const url = getApiUrls("getSearchExportReports", {
    dataFilterId,
  });

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: queryParams,
  });

  return response;
};

export const getExportReportDetails = async (exportReportId) => {
  const url = getApiUrls("getExportReportDetails", { exportReportId });

  const response = await axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });

  return response?.data?.payload;
};

export const updateFilterStatistics = (dataFilterId, queryParams = {}) => {
  const url = getApiUrls("updateFilterStatistics", { dataFilterId });

  return axiosInstance.post(
    url,
    {},
    {
      headers: getAuthorizationHeaders(),
      params: queryParams,
    }
  );
};
