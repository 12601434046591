import { useState } from "react";

import * as commonStyles from "styles/CommonDropdownStyles.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { PAGE_IDENTIFIERS } from "common/constants";
import { clearSearchesFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

import DataPhaseDropdown from "./DataPhaseDropdown/DataPhaseDropdown";
import DateRangeFilter from "components/common/DateRangeFilter/DateRangeFilter";
import PartnerDropdown from "components/common/PartnerDropdown/PartnerDropdown";
import Button from "components/common/Button/Button";

const CollapsibleFilterChildren = ({ searchOptions, setSearchOptions }) => {
  const [dataPhase, setDataPhase] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const pageIdentifier = PAGE_IDENTIFIERS.SEARCHES;
  const buttonTitle = "Clear Filter";

  /**
   * The function `clearFilters` clears all filters and search options for a given page identifier.
   *
   * @param pageIdentifier - The `pageIdentifier` parameter is used to identify the page for which the
   * searches filters are being cleared.
   */
  const clearFilters = (pageIdentifier) => {
    clearSearchesFilters(pageIdentifier);

    setSearchOptions({});
    setDataPhase(null);
    setStartDate("");
    setEndDate("");
  };

  /* To automatically clear searches filters for a given page identifier. */
  useAutoClearFilter(pageIdentifier, clearSearchesFilters);

  return (
    <div className="d-flex gap-4">
      <PartnerDropdown
        {...{
          selectedPartner: searchOptions?.partner,
          setSearchOptions,
          className: commonStyles.Dropdown,
          pageIdentifier,
        }}
      />
      <DataPhaseDropdown
        {...{
          dataPhase,
          setDataPhase,
          setSearchOptions,
          className: commonStyles.Dropdown,
          pageIdentifier,
        }}
      />
      <DateRangeFilter
        {...{
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          setSearchOptions,
          className: commonStyles.Date_Picker,
          placeholder: "Last run between",
          pageIdentifier,
        }}
      />
      <Button
        {...{
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          onClick: () => clearFilters(pageIdentifier),
          title: buttonTitle,
        }}>
        <span>{buttonTitle}</span>
      </Button>
    </div>
  );
};

export default CollapsibleFilterChildren;
