import ExpandableSummaryItem from "./ExpandableSummaryItem/ExpandableSummaryItem";
import React from "react";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";

const ExpandableItemsInSummary = ({
  productSummary,
  branchSummary,
  productTypeSummary,
  stateSummary,
}) => {
  const isAllEmpty = every(
    [productSummary, branchSummary, productTypeSummary, stateSummary],
    (summary) => isEmpty(summary)
  );
  if (isAllEmpty) {
    return null;
  }

  return (
    <div>
      <ExpandableSectionHeaderMemoized />
      <ExpandableItems
        {...{ productSummary, branchSummary, productTypeSummary, stateSummary }}
      />
    </div>
  );
};

export default ExpandableItemsInSummary;

//***************************/
//*****Local Components******/
//***************************/

const ExpandableSectionHeader = () => {
  return (
    <div className="d-flex justify-content-around my-2">
      <span>Item</span>
      <span>Unique Counts</span>
      <span>Total Counts</span>
    </div>
  );
};

const ExpandableSectionHeaderMemoized = React.memo(ExpandableSectionHeader);

const ExpandableItems = ({
  productSummary,
  branchSummary,
  productTypeSummary,
  stateSummary,
}) => {
  return (
    <section>
      <ExpandableSummaryItem
        {...{ summary: productSummary, objectListKeyName: "products" }}
      />
      <ExpandableSummaryItem
        {...{ summary: branchSummary, objectListKeyName: "branches" }}
      />
      <ExpandableSummaryItem
        {...{
          summary: productTypeSummary,
          objectListKeyName: "product_types",
        }}
      />
      <ExpandableSummaryItem
        {...{ summary: stateSummary, objectListKeyName: "states" }}
      />
    </section>
  );
};
