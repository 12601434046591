import { dateFields, excludedFields } from "./DlightKenyaDataDetail.constant";

import CustomDatePicker from "components/common/CustomDatePicker/CustomDatePicker";
import TextField from "components/common/TextField/TextField";
import { capitalCase } from "change-case";
import {
  getDateObjectFromString,
  getISOFormatedDateString,
} from "common/utils/dateAndTimeUtils";

const DlightKenyaDataFormField = ({
  recordKey,
  formDetails,
  setFormDetails,
  isAdminUser,
  wrapperClasses,
}) => {
  if (excludedFields.includes(recordKey)) return null;

  const isDateField = dateFields.includes(recordKey);
  const placeholder = isAdminUser ? `Enter ${capitalCase(recordKey)} here` : "";

  return (
    <div className={wrapperClasses}>
      <label htmlFor={recordKey} className="w-20 text-start ps-5 ms-3">
        {capitalCase(recordKey)} :
      </label>
      {!isDateField ? (
        <TextField
          {...{
            type: "text",
            value:
              formDetails[recordKey] === null
                ? undefined
                : formDetails[recordKey],
            onChange: (e) =>
              setFormDetails((values) => ({
                ...values,
                [recordKey]: e.target.value,
              })),
            disabled: !isAdminUser,
            className: "w-20",
            placeholder,
            required: false,
          }}
        />
      ) : (
        <CustomDatePicker
          {...{
            selectedDate:
              formDetails[recordKey] === null
                ? undefined
                : getDateObjectFromString(formDetails[recordKey]),
            setSelectedDate: (e) => {
              setFormDetails((values) => ({
                ...values,
                [recordKey]: getISOFormatedDateString(e),
              }));
            },
            placeholder,
            disabled: !isAdminUser,
          }}
        />
      )}
    </div>
  );
};

export default DlightKenyaDataFormField;
