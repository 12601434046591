import * as styles from "./ForgotPassword.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import EmailTextField from "components/common/EmailTextField/EmailTextField";
import HyperLink from "components/common/HyperLink/HyperLink";
import LockIcon from "images/ic_lock.svg";
import { Success } from "./Success";
import routeConstants from "routes/routeConstants";
import { sendRecoveryEmail } from "common/API/authenticationAPIs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [link, setLink] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleOnClick = async (event) => {
    setLink(true);
    event.preventDefault();
    try {
      const response = await sendRecoveryEmail({ email });
      setMessage(response?.data?.message);
      setEmailSent(true);
      setLink(false);
    } catch (error) {
      setEmailSent(false);
      setLink(false);
    }
  };

  if (emailSent) return <Success />;

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <form
          className={`col-5 ${styles.ForgotPassword}`}
          onSubmit={handleOnClick}>
          <img src={LockIcon} alt="lock-icon" className="col-2" />
          <h3 className="h3 text-center my-3">Trouble Logging In?</h3>
          <EmailTextField
            {...{
              email,
              setEmail,
            }}
          />
          {message && <p className="text-danger mt-2">{message}</p>}
          <div className={styles.Buttons}>
            <Button
              type="submit"
              buttonType={ButtonTypes.PRIMARY_SMALL}
              buttonContentType={ButtonContentTypes.ICON_TEXT}
              className="me-3"
              isDisabled={!email || link}>
              <span>{link ? "Sending" : "Get the link"}</span>
            </Button>
            <HyperLink
              className={styles.Forgot_Password}
              text="Login?"
              onClick={() => navigate(routeConstants.Login.path)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
