import * as styles from "./PartnerSalesChart.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "../Button/Button";
import { getErrorMessage } from "common/utils/errorUtils";
import { getPartnerSalesExport } from "common/API/partnerDashboardAPI";
import { toast } from "react-toastify";
import { useState } from "react";

const DownloadPartnerSalesExport = ({ partnerId, partnerName }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      {...{
        buttonType: ButtonTypes.PRIMARY_SMALL,
        buttonContentType: ButtonContentTypes.TEXT,
        onClick: () =>
          downloadPartnerSalesExport(partnerId, partnerName, setIsLoading),
        className: styles.Sales_Export_Button,
        isDisabled: false,
        isLoading: isLoading,
      }}>
      <span>Download All Sales Export</span>
    </Button>
  );
};

export default DownloadPartnerSalesExport;

//***************************/
//*****Local Utilities*******/
//***************************/

const downloadPartnerSalesExport = async (
  partnerId,
  partnerName,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const response = await getPartnerSalesExport(partnerId);
    const date = new Date();

    const filename = `${partnerName}_Sales_export_${date.toISOString()}.xlsx`;

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    toast.error(
      getErrorMessage(error, "Failed to generate Partner Sales Export")
    );
  }
  setIsLoading(false);
};
