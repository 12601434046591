export const options = [
  {
    label: "All",
    name: "searches-group",
    value: "All",
  },
  {
    label: "Clean",
    name: "searches-group",
    value: "Clean",
  },
  {
    label: "Direct",
    name: "searches-group",
    value: "Direct",
  },
  {
    label: "Raw",
    name: "searches-group",
    value: "Raw",
  },
];
