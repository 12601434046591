import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";

const SearchCleanRecordButton = ({ handleSearch, loanId, isSearchRecord }) => {
  return (
    <Button
      {...{
        buttonType: ButtonTypes.PRIMARY_SMALL,
        buttonContentType: ButtonContentTypes.TEXT,
        onClick: handleSearch,
        isDisabled: !loanId || isSearchRecord,
      }}>
      {!isSearchRecord ? "Search" : "Searching"}
    </Button>
  );
};

export default SearchCleanRecordButton;
