import { useInfiniteQuery } from "react-query";
import { useState } from "react";

const sortStates = {
  asc: "desc",
  desc: "asc",
};

/**
 * A custom hook that is used to fetch data in paginated response from the API and sort it
 * @param queryKey - This is the key that is used to fetch data from the API.
 * @param fetchData - This is the function that fetches the data from the API.
 * @param [defaultSortOrder] - The default sort order for the table.
 * @param [defaultOrderField] - The default field by which the data is sorted.
 * @param [defaultOrderingFields] - This is the default key path from response by which the data is sorted.
 * @returns an object with the following properties:
 *  isLoading,
    data,
    isError,
    error,
    handleSorting,
    sortOrder,
    currentOrderField,
    hasNextPage,
    fetchNextPage,
 */
export const usePaginatedSortableTable = (
  /* This is a custom hook that is used to fetch data from the API and sort it. */
  queryKey,
  fetchData,
  defaultSortOrder = "",
  defaultOrderField = "",
  defaultOrderingFields = ""
) => {
  /* To maintain the state of sortOrder */
  const [sortOrder, setSortOrder] = useState(
    sessionStorage.getItem("sortOrder")
      ? sessionStorage.getItem("sortOrder")
      : defaultSortOrder
  );
  /* To maintain the state of field by which sorting is done*/
  const [currentOrderField, setcurrentOrderField] = useState(
    sessionStorage.getItem("currentOrderField")
      ? sessionStorage.getItem("currentOrderField")
      : defaultOrderField
  );

  /* To maintain the state of array of key path from response for sorting*/
  const [orderingFields, setOrderingFields] = useState(
    sessionStorage.getItem("orderField")
      ? [sessionStorage.getItem("orderField")]
      : [defaultOrderingFields]
  );

  /* This is destructuring the object returned by the useInfiniteQuery hook. */
  const queryData = useInfiniteQuery(
    [queryKey, sortOrder, orderingFields],
    ({ pageParam = 1 }) =>
      fetchData({
        ordering: sortOrder,
        ordering_field: orderingFields,
        page: pageParam,
      }),
    {
      refetchInterval: 300000,
    }
  );

  const handleSorting = (currentOrderField, orderingFields) => {
    setSortOrder((value) => sortStates[value]);
    setcurrentOrderField(currentOrderField);
    setOrderingFields(orderingFields);
  };

  return {
    ...queryData,
    handleSorting,
    sortOrder,
    currentOrderField,
    orderingFields,
  };
};
