import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

const CustomDatePicker = ({
  selectedDate,
  setSelectedDate,
  placeholder,
  showTimeSelect = false,
  disabled,
  minDate,
  maxDate = "",
}) => {
  return (
    <DatePicker
      {...{
        selected: selectedDate,
        onChange: (date) => setSelectedDate(date),
        placeholderText: placeholder,
        formatWeekDay: (day) => day.slice(0, 3),
        dateFormat: "dd/MM/yyyy",
        timeIntervals: 10,
        timeCaption: "Time",
        disabled,
        isClearable: !disabled,
        minDate,
        maxDate,
      }}
      {...(showTimeSelect
        ? {
            dateFormat: "dd/MM/yyyy hh:mm:ss",
            timeIntervals: 10,
            timeCaption: "Time",
            showTimeSelect,
          }
        : {})}
      showMonthDropdown
      useShortMonthInDropdown
      showYearDropdown
    />
  );
};

CustomDatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default CustomDatePicker;
