export const CustomDot = ({ cx, cy }) => {
  return (
    <svg x={cx - 5} y={cy - 5} width={10} height={10}>
      <circle
        cx="5"
        cy="5"
        r="3"
        fill="#001F38"
        stroke="#F06595"
        strokeWidth="2"
      />
    </svg>
  );
};

export const CustomActiveDot = ({ cx, cy }) => {
  return (
    <svg x={cx - 7} y={cy - 7} width={14} height={14}>
      <circle
        cx="7"
        cy="7"
        r="5"
        fill="#F06595"
        stroke="#F06595"
        strokeWidth="2"
      />
    </svg>
  );
};
