import { UPLOADED_FILE_STATUS } from "common/constants";

export const options = [
  {
    label: "In Progress",
    name: "status",
    value: UPLOADED_FILE_STATUS.s1,
  },
  {
    label: "Promoted",
    name: "status",
    value: UPLOADED_FILE_STATUS.s2,
  },
];
