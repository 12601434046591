import TextField from "components/common/TextField/TextField";

const LabelRow = ({ label, setLabel, className = "" }) => {
  return (
    <div className="Form_Row">
      <label>File Label: </label>
      <TextField
        {...{
          type: "text",
          value: label,
          onChange: (e) => setLabel(e.target.value),
          placeholder: "Enter a unique label",
          className,
        }}
      />
    </div>
  );
};

export default LabelRow;
