import * as styles from "./SiteAdminLinks.module.scss";

import SiteAdminLink from "./SiteAdminLink";
import { siteAdminLinks } from "../SiteAdminlinks.constant";

const SiteAdminLinks = ({ title }) => {
  return (
    <section className={styles.Links_Wrapper}>
      <span className="Font_H5">{title}</span>
      <div className={styles.Links_Container}>
        {siteAdminLinks.map((siteAdminLink, index) => (
          <SiteAdminLink key={index} {...siteAdminLink} />
        ))}
      </div>
    </section>
  );
};
export default SiteAdminLinks;
