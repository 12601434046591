import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DashboardTabs from "../common/DashboardTabs/DashboardTabs";
import RichText from "../common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import { createRef, useEffect } from "react";
import { setPartner } from "slices/partnerSlice";
import styles from "./PartnerDashboard.module.scss";
import { tabs } from "./PartnerDashboard.constant";
import { useParams } from "react-router-dom";

const PartnerDashboard = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const partnerId = parseInt(params.partnerId);
  const partnersById = useSelector(
    (state) => state.partnersById.partnersById,
    shallowEqual
  );

  const partnerName = partnersById[partnerId];

  useEffect(() => {
    dispatch(
      setPartner({
        id: partnerId,
        name: partnerName,
      })
    );
  }, []);

  return (
    <section>
      <DashboardContents {...{ partnerId, partnerName }} />
    </section>
  );
};

export default PartnerDashboard;

const DashboardContents = ({ partnerId, partnerName }) => {
  if (!partnerId && !partnerName) {
    return (
      <span className={styles.No_Partner_Selected_Message}>
        Please Select a Partner
      </span>
    );
  }
  const dashboardRef = createRef();

  return (
    <section ref={dashboardRef}>
      <RichText textType={TextTypes.PAGE_TITLE}>
        {partnerName} | Partner Dashboard
      </RichText>

      <DashboardTabs {...{ tabs, dashboard: "partner", dashboardRef }} />
    </section>
  );
};
