import * as styles from "./CollapsibleListFilters.module.scss";

import classNames from "classnames";

const CollapsibleListFilters = ({
  toggleFilters,
  collapsibleFilterChildren,
}) => {
  const wrapperClasses = classNames(styles.Filter_Collapsible_Content, {
    [styles.Filter_Collapsible_Content_Open]: toggleFilters,
  });
  return <div className={wrapperClasses}>{collapsibleFilterChildren}</div>;
};

export default CollapsibleListFilters;
