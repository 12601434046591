import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as importDetailStyles from "../ImportDetail.module.scss";

import ExpandableItemsInSummary from "./ExpandableItemsInSummary/ExpandableItemsInSummary";
import ImportTableItem from "../ImportTableItem/ImportTableItem";
import classNames from "classnames";
import { formatDate } from "common/utils/dateAndTimeUtils";
import isEmpty from "lodash/isEmpty";

const ImportSummary = ({ importSummary }) => {
  if (isEmpty(importSummary)) {
    return null;
  }

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    importDetailStyles.Import_Card_Container
  );
  return (
    <section className={sectionClasses}>
      <ImportSummaryHeader />
      <ImportSummaryDetail {...{ importSummary }} />
    </section>
  );
};

export default ImportSummary;

//***************************/
//*****Local Components******/
//***************************/

const ImportSummaryHeader = () => {
  const headerClasses = classNames(
    "d-flex justify-content-between pb-4",
    importDetailStyles.Import_Card_Header
  );
  return (
    <header className={headerClasses}>
      <span className="Font_H7 Font_Title_Color">Import Summary</span>
    </header>
  );
};

const ImportSummaryDetail = ({ importSummary }) => {
  const {
    min_install_date: minInstallDate,
    max_install_date: maxInstallDate,
    product_summary: productSummary,
    branch_summary: branchSummary,
    product_type_summary: productTypeSummary,
    state_summary: stateSummary,
  } = importSummary;

  return (
    <>
      <MinMaxInstallDateItems {...{ minInstallDate, maxInstallDate }} />
      <ExpandableItemsInSummary
        {...{ productSummary, branchSummary, productTypeSummary, stateSummary }}
      />
    </>
  );
};

const MinMaxInstallDateItems = ({ minInstallDate, maxInstallDate }) => {
  return (
    <>
      <ImportTableItem
        itemKey="Min Install Date"
        itemValue={formatDate(minInstallDate)}
      />
      <ImportTableItem
        itemKey="Max Install Date"
        itemValue={formatDate(maxInstallDate)}
      />
    </>
  );
};
