import * as styles from "./RecordDetailTitle.module.scss";

import { formatDate } from "common/utils/dateAndTimeUtils";

const RecordDetailTitle = ({
  recordsLoanId,
  recordsFileInfo,
  recordsCustomerName,
  isExcludedRecord = false,
}) => {
  return (
    <div className={styles.Record_Detail_Title}>
      {isExcludedRecord && (
        <>
          <span className={styles.Title_Container}>
            <span className="Font_H3 Font_Title_Color">Excluded Records</span>
          </span>
          <span className={styles.Title_Container}>
            <span className="Font_H3 Font_Title_Color">
              Customer Name : &nbsp;
            </span>
            <span className="Font_H3 Font_Subtitle_Color">
              {recordsCustomerName}
            </span>
          </span>
        </>
      )}
      <span className={styles.Title_Container}>
        {recordsLoanId ? (
          <>
            <span className="Font_H3 Font_Title_Color">Loan Id : &nbsp;</span>
            <span className="Font_H3 Font_Subtitle_Color">{recordsLoanId}</span>
          </>
        ) : (
          <span className="Font_H3 Font_Title_Color">No Transaction ID</span>
        )}
      </span>

      {recordsFileInfo && (
        <span className={styles.Title_Container}>
          <span className="Font_H3 Font_Title_Color">Imported On : &nbsp;</span>
          <span className="Font_H3 Font_Subtitle_Color">
            {<>{formatDate(recordsFileInfo?.created_at)}</>}
          </span>
        </span>
      )}
    </div>
  );
};

export default RecordDetailTitle;
