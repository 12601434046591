import * as qs from "qs";

import { axiosInstance, getAuthorizationHeaders } from "./api";

import { getProjectActivityURLs } from "common/utils/apiUrls";

export const getProjectActivityListWithDetails = (queryParams) => {
  const url = getProjectActivityURLs("getProjectActivityList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: queryParams,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getProjectActivityDetails = (projectActivityId) => {
  const url = getProjectActivityURLs("getProjectActivityDetail", {
    projectActivityId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getPASalesStatistics = (projectActivityId, queryParams = {}) => {
  const url = getProjectActivityURLs("getPASalesStatistics", {
    projectActivityId,
  });

  const {
    product_type_id,
    start_year,
    end_year,
    partner_id,
    governing_district_id,
  } = queryParams;
  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      product_type_id,
      start_year,
      end_year,
      partner_id,
      governing_district_id,
    },
  });
};

export const getPAPieChartData = (projectActivityId) => {
  const url = getProjectActivityURLs("getPAPieChartData", {
    projectActivityId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};
