import { getAdminURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getCleanDataHeaders = async () => {
  const url = getAdminURLs("getCleanDataHeaders");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};
