import { UPLOADED_FILE_STATUS, VIEWER_USER_ROLE } from "common/constants";

import DashboardTabFilters from "components/common/DashboardTabFilters/DashboardTabFilters";
import ImportTable from "components/common/ImportTable/ImportTable";
import ListTopSection from "components/common/ListTopSection/ListTopSection";
import NavigateButton from "components/common/NavigateButton/NavigateButton";
import { getUserProperty } from "common/utils/authenticationUtils";
import { options } from "./ImportList.constant";
import { useState } from "react";

const ImportList = ({ currentDashboard, partnerId, fetchImports }) => {
  const [selectedOption, setSelectedOption] = useState(UPLOADED_FILE_STATUS.s1);
  const [searchOptions, setSearchOptions] = useState({
    status: UPLOADED_FILE_STATUS.s1,
  });
  const [areOptionsDisabled, setAreOptionsDisabled] = useState(false);
  const isViewerRoleUser = getUserProperty("user_role") === VIEWER_USER_ROLE;

  const handleOptionChange = (e) => {
    const targetValue = e.target.value;
    setSelectedOption(targetValue);
    setSearchOptions({ status: targetValue });
  };
  return (
    <>
      <ListTopSection
        actionChildren={
          !isViewerRoleUser && (
            <NavigateButton {...{ title: "Import", route: "Import" }} />
          )
        }
        filterChildren={
          <DashboardTabFilters
            {...{
              options,
              selectedOption,
              handleOptionChange,
              areOptionsDisabled,
            }}
          />
        }
      />
      <ImportTable
        {...{
          searchOptions,
          setAreOptionsDisabled,
          currentDashboard,
          partnerId,
          fetchImports,
          extraClass: "Table_Height2",
        }}
      />
    </>
  );
};

export default ImportList;
