import * as commonStyles from "styles/Common.module.scss";
import * as styles from "./RawDataRecord.module.scss";

import { useLocation, useParams } from "react-router-dom";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import RawDataKeyValues from "./RawDataKeyValues/RawDataKeyValues";
import RecordDetailTitle from "components/common/RecordDetailTitle/RecordDetailTitle";
import { getErrorMessage } from "common/utils/errorUtils";
import { getRawDataRecord } from "common/API/rawDataAPIs";
import { setSearchDetailRecord } from "slices/searchDetailRecordSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { usePreservedRecordTab } from "common/hooks/usePreservedRecordTab";
import { useQuery } from "react-query";

const RawDataRecord = () => {
  const { rawDataId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    scrollPosition,
    searchId,
    sortOrder,
    currentOrderField,
    orderFields,
  } = location?.state || {};

  // Fetch data filter id from current URL
  const dataFilterId = location?.pathname?.split("/")[2];

  const {
    data: response,
    isLoading,
    error,
    isError,
  } = useQuery(["detailed-raw-data-record", rawDataId, dataFilterId], () =>
    getRawDataRecord({ rawDataId, dataFilterId })
  );

  const rawData = response?.data?.payload;

  // Constants for breadcrumbs of clean record detail page
  const dataPhase = rawData?.data_phase;
  const partnerName = rawData?.partner_name;

  // * #####################################
  // * Hooks
  // * #####################################

  /* Setting the loan id, search id, data phase, and partner name in the redux store. */
  useEffect(() => {
    dispatch(
      setSearchDetailRecord({
        loanId: rawData?.loan_id || "",
        searchId: dataFilterId || "",
        dataPhase: dataPhase || "",
        partnerName: partnerName || "",
      })
    );
  }, [response]);

  // Save scroll position, record list's search id, sorting order, and sorting field in session storage to preserve
  usePreservedRecordTab(
    scrollPosition,
    searchId,
    sortOrder,
    currentOrderField,
    orderFields
  );

  if (isLoading) {
    return (
      <CustomLoader
        containerClasses={commonStyles.Tab_Content_Flex_Container}
      />
    );
  }

  if (!rawData) {
    return (
      <NoDataFound
        {...{
          title: "No raw data record found",
          wrapperClasses: commonStyles.Tab_Content_Flex_Container,
        }}
      />
    );
  }

  if (isError)
    return toast.error(getErrorMessage(error, "Failed to fetch raw data"));

  const {
    file_columns: rawDataFileColumns,
    data: rawDataRecord,
    raw_file: rawDataFileInfo,
  } = rawData;

  return (
    <>
      <div className={styles.Raw_Data}>
        <RecordDetailTitle
          {...{
            recordsLoanId: rawData?.loan_id,
            recordsFileInfo: rawDataFileInfo,
          }}
        />
      </div>
      <div className="pb-5">
        <RawDataKeyValues
          {...{ rawDataFileColumns, rawDataRecord, rawDataFileInfo }}
        />
      </div>
    </>
  );
};
export default RawDataRecord;
