import Actions from "./Actions";
import CollapsibleFilterChildren from "./CollapsibleFilterChildren/CollapsibleFilterChildren";
import CollapsibleTopSection from "components/common/CollapsibleTopSection/CollapsibleTopSection";
import ExportTable from "components/common/ExportTable/ExportTable";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { getAllExports } from "common/API/exportAPIs";
import { useState } from "react";

const Exports = () => {
  const [searchOptions, setSearchOptions] = useState({});
  const [toggleFilters, setToggleFilters] = useState(false);

  const { partner, ...remainingSearchOptions } = searchOptions;
  return (
    <>
      <CollapsibleTopSection
        {...{
          searchOptions,
          actionChildren: <Actions />,
          collapsibleFilterChildren: (
            <CollapsibleFilterChildren
              {...{ searchOptions, setSearchOptions }}
            />
          ),
          toggleFilters,
          setToggleFilters,
        }}
      />
      <ExportTable
        {...{
          searchOptions: {
            ...remainingSearchOptions,
            partner_id: partner?.value,
          },
          showCreatedBy: true,
          setAreOptionsDisabled: () => {},
          fetchExports: getAllExports,
          extraClass: toggleFilters ? "Table_Height3" : "Table_Height1",
        }}
      />
      <ScrollToTop />
    </>
  );
};

export default Exports;
