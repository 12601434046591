import styles from "./GroupByWrapper.module.scss";

import React, { useEffect } from "react";

import Column1AndColumn2Filters from "./Column1AndColumn2Filters/Column1AndColumn2Filters";
import { useDispatch } from "react-redux";
import { setColumn1, setColumn2 } from "slices/searchSlice";

const GroupByWrapper = ({
  setSelectedDataFilter,
  clearFilters,
  setClearFilters,
}) => {
  const dispatch = useDispatch();

  // * Use effect to clear filters
  useEffect(() => {
    if (clearFilters) {
      dispatch(setColumn1(""));
      dispatch(setColumn2(""));
      setSelectedDataFilter(null);
      setClearFilters(false);
    }
  }, [clearFilters]);

  return (
    <section className={styles.Group_By_Wrapper}>
      <span className={styles.Group_By_Title}>Group Bys</span>
      <div className={styles.Group_By_Container}>
        <Column1AndColumn2Filters />
      </div>
    </section>
  );
};

export default GroupByWrapper;
