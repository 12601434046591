import BigTick from "./BigTick";

export const YearTick = ({ x, y, payload, data, lastSaleDate }) => {
  const ticks = data.filter((tick) => tick.year === payload.value);
  const lastTickIdx = data.length - 1;
  const firstGroupIdx = data.findIndex((tick) => tick.year === payload.value);
  const lastGroupIdx = data.findLastIndex(
    (tick) => tick.year === payload.value
  );

  // * Render big tick and text if payload is the first payload in "Year"
  if (firstGroupIdx === payload.index) {
    // * Some mathematical calculation to find offset to render year text
    const xOffset =
      ((lastGroupIdx - firstGroupIdx) * 5) / 2 +
      payload.offset * Math.floor(ticks.length / 2);
    return (
      <>
        <BigTick {...{ x: x - payload.offset, y }} />
        <text
          x={x + xOffset}
          y={y}
          width={75}
          textAnchor="middle"
          className="Font_H10">
          {payload.value}
        </text>
      </>
    );
  }

  // * Render big tick if its the last tick
  if (lastTickIdx === payload.index) {
    return (
      <>
        <BigTick {...{ x: x + payload.offset, y }} />
        <text
          x={x + payload.offset}
          y={y + 15}
          className="Font_H10"
          textAnchor="end">
          Last Sale: {lastSaleDate}
        </text>
      </>
    );
  }
  return null;
};

export default YearTick;
