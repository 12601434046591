import Switch from "components/common/Switch/Switch";
import { abbreviateNumber } from "common/utils/utils";
import cloneDeep from "lodash/cloneDeep";

const TopPerformersCardHeader = ({
  data = {},
  switchItems,
  selectedSwitchItem,
  setSelectedSwitchItem,
  isLoading,
  isError,
}) => {
  const {
    last_12_months_total_sales: last12MonthsTotalSales,
    all_time_total_sales: allTimeTotalSales,
  } = data;

  const newSwitchItems = cloneDeep(switchItems).map((switchItem) => {
    if (switchItem.value === "all_time")
      switchItem.name += ` (${abbreviateNumber(allTimeTotalSales)} sales)`;

    if (switchItem.value === "last_12_months")
      switchItem.name += ` (${abbreviateNumber(last12MonthsTotalSales)} sales)`;

    return switchItem;
  });

  return (
    <div className="d-flex justify-content-between align-items-center pe-5">
      <span className="Font_H7 Font_Title_Color d-flex flex-start pb-4 ">
        Top Performers
      </span>
      {!isError && (
        <Switch
          {...{
            switchItems: newSwitchItems,
            groupName: "top-performers",
            selectedSwitchItem,
            setSelectedSwitchItem,
            isDisabled: isLoading,
          }}
        />
      )}
    </div>
  );
};

export default TopPerformersCardHeader;
