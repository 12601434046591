import { getErrorMessage } from "./errorUtils";
import { getPartnersList } from "common/API/fieldAPIs";

export const getPartnersById = async () => {
  try {
    const res = await getPartnersList();
    const partnersList = res?.data?.payload;

    const partnersById = {};
    partnersList.forEach((partner) => {
      partnersById[partner.value] = partner.name;
    });
    return partnersById;
  } catch (error) {
    getErrorMessage(error, "Error occured while fetching Partners");
    return {};
  }
};
