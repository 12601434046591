import TextField from "../TextField/TextField";

// TODO: Remove this unnecessary component
const PasswordTextField = ({ onChange, placeholder, className, value }) => {
  return (
    <TextField
      {...{
        type: "password",
        value,
        onChange,
        placeholder,
        className,
      }}
    />
  );
};

export default PasswordTextField;
