import * as styles from "./FormFooter.module.scss";

import ClearAllButton from "./ClearAllButton";

const FormFooter = ({ children, setClearForm, showClearFormButton = true }) => {
  return (
    <footer className={styles.Form_Footer}>
      <div className={styles.Footer_Buttons}>
        {showClearFormButton && <ClearAllButton {...{ setClearForm }} />}
        {children}
      </div>
    </footer>
  );
};

export default FormFooter;
