export function getApiUrls(apiName, data) {
  const apiUrlsMapping = {
    getPresignedUrl: "partners/presigned-url/",

    // * Uploaded Files API URLS

    generatePresignedPost: `/partners/${data?.partner_id}/pre-signed-post/`,
    generatePreSignedPostReImport: `/partners/${data?.partner_id}/re-import/pre-signed-post/`,

    saveUploadedFile: `/partners/${data?.partner_id}/uploaded-file/`,
    saveUploadedFileReImport: `/partners/${data?.partner_id}/re-import/`,

    downloadExcludedRecords: `/partners/${data?.partnerId}/excluded-records/`,

    promoteFile: `/partners/${data?.partnerId}/uploaded-file/${data?.uploadedFileId}/proposed-state/`,

    getRawDataFileDetails: `/raw-data-files/${data?.rawDataFileId}/`,

    // * FIlters and Group By API URLS

    getDataPhases: "/data-phases/",

    getProductTypes: "/product-types/",
    getProductModels: `/product-types/${data?.productTypeId}/products/`,

    getStates: `/countries/${data?.countryId}/states/`,
    getBranches: `/partners/${data?.partnerId}/states/${data?.stateId}/branches/`,

    getPACodesList: "/project-activity/codes",

    getPartners: "/partners/",

    getMonitoringPeriodList: "/monitoring-periods/",

    getGroupByColumns: "/group-by-columns/",

    getFilteredData: "/apply-filters/",

    applyFilter: "/data-filters/",

    downloadFilterSummary: `/data-filters/${data?.dataFilterId}/download-filter-summary/`,

    generateFilterDetailReport: `/data-filters/${data?.data_filter_id}/generate-filter-detail/`,

    updateFilterBookmarkStatus: `/data-filters/${data?.dataFilterId}/bookmark/`,

    // * Assign Project Activity

    assignProjectActivity: "/assign/project-activity",

    // * Filter and Reports List API URLS

    getUserFilters: "/users/data-filters/",

    getPartnerFilters: `/partners/${data?.partnerId}/data-filters/`,

    getFilters: "/data-filters/",

    getFilterDetails: `/data-filters/${data?.dataFilterId}`,

    updateFilterStatistics: `/data-filters/${data?.dataFilterId}/statistics/`,

    getSearchExportReports: `/data-filters/${data?.dataFilterId}/reports/`,

    getExportReportDetails: `/data-filters/reports/${data?.exportReportId}`,

    // * Dashboard statistics

    getUserDashboardStatistics: "/users/statistics/",
    getUserImports: "/users/raw-data-files",

    // * Dashboard
    getPartnerImports: `/partners/${data?.partnerId}/raw-data-files/`,

    getPartnerStatistics: `/partners/${data?.partnerId}/statistics/`,

    // * Clean data report

    getCleanDataHeaders: "/clean-data-headers/",
    generateCleanDataFilePreSignedPost: `partners/${data?.partnerId}/clean-data-file/pre-signed-post/`,
    saveCleanDataFile: `/partners/${data?.partnerId}/clean-data-file/`,

    // Partner Dashboard New
    getPartnerSearches: `/partners/${data?.partnerId}/data-filters/`,
  };

  return apiUrlsMapping[apiName];
}

export const getImportURLs = (apiName, data) => {
  const mapping = {
    getUserImports: "/users/raw-data-files",
    getPartnerImports: `/partners/${data?.partnerId}/raw-data-files/`,
    getAllImports: "/raw-data-files/",

    getImportDetails: `/raw-data-files/${data?.rawDataFileId}/`,

    downloadExcludedRecords: `/partners/${data?.partnerId}/excluded-records/`,

    eraseImport: `/erase-import/${data?.rawDataFileId}/`,
  };
  return mapping[apiName];
};

export const getFieldURLs = (apiName) => {
  const mapping = {
    getPartners: "/partners/",
    getDataPhases: "/data-phases/",
    getProductTypes: "/product-types/",
  };
  return mapping[apiName];
};

export const getSearchURLs = (apiName, data) => {
  const mapping = {
    getUserSearches: "/users/data-filters/",

    getPartnerSearches: `/partners/${data?.partnerId}/data-filters/`,

    getAllSearches: "/data-filters/",

    getSearchResultRecords: `/data-filters/${data?.dataFilterId}/records/`,
  };
  return mapping[apiName];
};

export const getPartnerDashboardURLs = (apiName, data) => {
  const mapping = {
    getPartnersList: "/partners-detail/",
    getPartnerSalesStatistics: `/partners/${data?.partnerId}/sales-statistics/`,
    getPartnerSalesExport: `/partners/${data?.partnerId}/sales-statistics-export/`,
  };
  return mapping[apiName];
};

export const getAdminURLs = (apiName, data) => {
  const mapping = {
    getCleanDataHeaders: "/clean-data-headers/",
    generateCleanDataFilePreSignedPost: `partners/${data?.partnerId}/clean-data-file/pre-signed-post/`,
    saveCleanDataFile: `/partners/${data?.partnerId}/clean-data-file/`,
  };
  return mapping[apiName];
};

export const getExportURLs = (apiName, data) => {
  const mapping = {
    getUserExports: "/users/export-reports",
    getPartnerExports: `/partners/${data?.partnerId}/export-reports`,
    getAllExports: "/export-reports",
  };
  return mapping[apiName];
};

export const getProjectActivityURLs = (apiName, data) => {
  const mapping = {
    getProjectActivityList: "/project-activities/",
    getProjectActivityDetail: `/project-activities/${data?.projectActivityId}`,
    getPASalesStatistics: `/project-activities/${data?.projectActivityId}/sales-statistics`,
    getPAPieChartData: `/project-activities/${data?.projectActivityId}/pie-chart-data`,
  };
  return mapping[apiName];
};

export const getAuthenticationURLs = (apiName) => {
  const mapping = {
    login: "/login/",
    passwordResetEmail: "/password-reset-email/",
    resetPassword: "/reset-password/",
    passwordResetTokenVerification: "/password-reset-token-verification/",
  };
  return mapping[apiName];
};

export const getMongolianProgramURLs = (apiName, data) => {
  const mapping = {
    getMongoliaDataList: "/mongolia-data/",
    getMongoliaDataDetail: `/mongolia-data/${data?.recordId}/`,

    getProductList: "/mongolia-data/products",
    getProjectActivityList: "/mongolia-data/project-activities",
  };

  return mapping[apiName];
};

export const getUserProfileURLs = (apiName, data) => {
  const mapping = {
    getUserProfileDetails: `/user-profile/${data.userId}/`,
    updateUserProfileDetails: `/user-profile/${data.userId}/`,
    changePassword: `/change-password/${data.userId}/`,
  };

  return mapping[apiName];
};

export const getRawDataURLs = (apiName, data) => {
  const mapping = {
    // * Raw Data Records
    getRawDataRecord: `/raw-data/${data?.rawDataId}/`,
  };

  return mapping[apiName];
};

export const getCleanDataURLs = (apiName, data) => {
  const mapping = {
    // * Clean Data Id
    getCleanDataIdURLs: `/partners/${data?.partnerId}/clean-data-id/`,

    //* Clean Data Records
    getCleanDataRecordURLs: `/clean-data/${data?.cleanDataId}/`,
  };

  return mapping[apiName];
};

export const getArchiveExcludedRawDataURLs = (apiName, data) => {
  const mapping = {
    getArchiveExcludedRawRecord: `archive-excluded-raw-data/${data?.archiveRawDataId}`,
  };

  return mapping[apiName];
};

export const getDlightKenyaURLs = (apiName, data) => {
  const mapping = {
    getDlightKenyaDataList: "/africa-data/kenya/",

    getProductModelList: "/africa-data/kenya/product-models/",
    getCPAList: "/africa-data/kenya/cpa/",

    getDlightKenyaDataDetail: `/africa-data/kenya/${data?.recordId}/`,
  };

  return mapping[apiName];
};

export const getDlightUgandaURLs = (apiName, data) => {
  const mapping = {
    getDlightUgandaDataList: "/africa-data/uganda",

    getProductModelList: "/africa-data/uganda/product-models/",
    getCPAList: "/africa-data/uganda/cpa/",

    getDlightUgandaDataDetail: `/africa-data/uganda/${data?.recordId}/`,
  };

  return mapping[apiName];
};
