import { axiosInstance, getAuthorizationHeaders } from "common/API/api";

import { getCleanDataURLs } from "common/utils/apiUrls";

export const getCleanDataId = ({ partnerId, loanId }) => {
  const url = getCleanDataURLs("getCleanDataIdURLs", {
    partnerId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      loan_id: loanId,
    },
  });
};

export const getCleanDataRecord = ({ cleanDataId, dataFilterId }) => {
  const url = getCleanDataURLs("getCleanDataRecordURLs", {
    cleanDataId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      data_filter_id: dataFilterId,
    },
  });
};
