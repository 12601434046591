import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { useEffect } from "react";
import { importTypes } from "./ImportTypeDropdown.constant";

const ImportTypeDropdown = ({
  importType,
  setImportType,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  // Stores import type filter for import page.
  useEffect(() => {
    const storedImportType = sessionStorage.getItem(
      `${pageIdentifier}selectedImportType`
    );
    if (storedImportType) {
      setImportType(JSON.parse(storedImportType));
    }
  }, [pageIdentifier]);

  // Fetches and applies a stored import type filter for import page.
  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      is_correction_import: importType?.value,
    }));
    if (importType !== undefined || importType !== null) {
      sessionStorage.setItem(
        `${pageIdentifier}selectedImportType`,
        JSON.stringify(importType)
      );
    }
  }, [importType, setSearchOptions, pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: importType,
        setSelectedOption: setImportType,
        options: importTypes,
        customWidth: "200px",
        className,
        placeholder: "Type of Import",
      }}
    />
  );
};

export default ImportTypeDropdown;
