import * as styles from "./SearchDetail.module.scss";

import {
  CURRENT_ORDER_FIELD,
  ORDERING_FIELD,
  SEARCH_ID_OF_RECORD,
  SORTING_ORDER,
} from "common/constants";
import {
  getFilterDetails,
  updateFilterStatistics,
} from "common/API/filterAndReportListAPI";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import AssignPAModalV2 from "components/AssignPAModalV2/AssignPAModalV2";
import DashboardTabs from "components/common/DashboardTabs/DashboardTabs";
import { FooterBelowPivotTable } from "components/common/SearchPivotSection/FooterBelowPivotTable/FooterBelowPivotTable";
import FooterSearchDetailRightSectionButtons from "./FooterSearchDetailRightSectionButtons/FooterSearchDetailRightSectionButtons";
import GenerateDetailReportModal from "./SearchDetailSummarySection/GenerateDetailReportModal/GenerateDetailReportModal";
import SearchDetailLeftSection from "./SearchDetailLeftSection/SearchDetailLeftSection";
import { getSearchResultRecords } from "common/API/searchAPIs";
import { setSearchDetailRecord } from "slices/searchDetailRecordSlice";
import { tabs } from "./SearchDetail.constant";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { usePaginatedSortableTable } from "common/hooks/usePaginatedSortableTable";

import { PAGE_IDENTIFIERS } from "common/constants";
import { clearSearchesFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

const SearchDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { filterId } = useParams();
  const [dataFilter, setDataFilter] = useState();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] =
    useState(false);
  const [isAssignPAModalOpen, setIsAssignPAModalOpen] = useState(false);

  const [searchOptions, setSearchOptions] = useState(
    sessionStorage.getItem("searchId")
      ? {
          search_id: sessionStorage.getItem("searchId"),
        }
      : {}
  );

  const partnerName = dataFilter?.filter_names?.common_filters?.partner;

  /* To automatically clear searches filters for a given page identifier. */
  const pageIdentifier = PAGE_IDENTIFIERS.SEARCHES;
  useAutoClearFilter(pageIdentifier, clearSearchesFilters);

  const {
    data,
    isLoading: isSearchDetailRecordsLoading,
    fetchNextPage,
    hasNextPage,
    sortOrder,
    currentOrderField,
    orderingFields,
    handleSorting,
    refetch,
  } = usePaginatedSortableTable(
    ["search-detail-records", filterId, searchOptions],
    (args) =>
      getSearchResultRecords({
        ...args,
        filterId,
        ...searchOptions,
      }),
    "asc",
    "Customer Name",
    ["customer_name"]
  );

  // * #####################################
  // * Effects
  // * #####################################
  useEffect(() => {
    // Check in url paramas if updateCount is there, if so, first update the count
    const searchQuery = new URLSearchParams(location.search);
    const updateCount = searchQuery.get("updateCount");
    if (updateCount === "true") {
      handleUpdateCount();
      return;
    }
    (async () => {
      try {
        const dataFilter = await getFilterDetails(filterId);
        setDataFilter(dataFilter);
        setIsBookmarked(dataFilter?.user_data_filters[0]?.is_bookmarked);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(SEARCH_ID_OF_RECORD);
      sessionStorage.removeItem(SORTING_ORDER);
      sessionStorage.removeItem(CURRENT_ORDER_FIELD);
      sessionStorage.removeItem(ORDERING_FIELD);
    };
  }, []);

  /* Setting the searchId & partner name in the redux store. */
  useEffect(() => {
    dispatch(
      setSearchDetailRecord({
        searchId: filterId,
        partnerName: partnerName || "",
      })
    );
  }, [data, dataFilter]);

  // * #####################################
  // * Handlers
  // * #####################################
  const handleGenerateReport = () => {
    setIsGenerateReportModalOpen(true);
  };

  const handleUpdateCount = async (event, queryParams = {}) => {
    setIsLoading(true);
    try {
      const response = await updateFilterStatistics(filterId, queryParams);
      const data = response?.data?.payload;
      setDataFilter(data);
      setIsBookmarked(data?.user_data_filters[0]?.is_bookmarked);
      setIsLoading(false);
      refetch();
    } catch (error) {
      toast.error(error?.message || "Something went wrong.");
    }
  };

  return (
    <>
      <main className={styles.Search_Result_Container}>
        <SearchDetailLeftSection
          {...{
            filterNames: dataFilter?.filter_names,
            groupByNames: dataFilter?.group_by_names,
            filterId,
            isBookmarked,
            setIsBookmarked,
            isGenerateReportModalOpen,
          }}
        />
        <section className={styles.Right_Section_Container}>
          <DashboardTabs
            {...{
              tabs,
              dashboord: "search",
              hasStickyDashboardTabs: true,
              contentProps: {
                ...{
                  filter: dataFilter,
                  handleUpdateCount,
                  setIsAssignPAModalOpen,
                  handleGenerateReport,
                  recordStatus:
                    dataFilter?.filter_names?.common_filters?.data_phase,
                  data,
                  isSearchDetailRecordsLoading,
                  fetchNextPage,
                  hasNextPage,
                  sortOrder,
                  currentOrderField,
                  orderingFields,
                  handleSorting,
                  searchOptions,
                  setSearchOptions,
                },
              },
            }}
          />

          <FooterBelowPivotTable>
            <FooterSearchDetailRightSectionButtons
              {...{
                handleGenerateReport,
                recordStatus:
                  dataFilter?.filter_names?.common_filters?.data_phase,
                setIsAssignPAModalOpen,
              }}
            />
          </FooterBelowPivotTable>
        </section>
      </main>

      {isGenerateReportModalOpen && (
        <GenerateDetailReportModal
          {...{
            refetchSearchRecords: refetch,
            isLoading,
            isGenerateReportModalOpen,
            setIsGenerateReportModalOpen,
            filterId,
            onAfterOpen: handleUpdateCount,
            filterNames: dataFilter?.filter_names,
            groupByNames: dataFilter?.group_by_names,
            totalCount: dataFilter?.total_count,
            pivotTableSummary: dataFilter?.pivot_table_summary,
          }}
        />
      )}

      {isAssignPAModalOpen && (
        <AssignPAModalV2
          {...{
            refetchSearchRecords: refetch,
            isModalOpen: isAssignPAModalOpen,
            setIsModalOpen: setIsAssignPAModalOpen,
            lastTotalCount: dataFilter?.total_count,
            partner: dataFilter?.filter_names?.common_filters?.partner,
            dataFilterId: filterId,
            filterNames: dataFilter?.filter_names,
            groupByNames: dataFilter?.group_by_names,
            pivotTableSummary: dataFilter?.pivot_table_summary,
            productTypeCounts: dataFilter?.product_type_counts,
            onAfterClose: handleUpdateCount,
            onAfterOpen: (event) =>
              handleUpdateCount(event, { include_product_type_count: true }),
            isLoading,
          }}
        />
      )}
    </>
  );
};

export default SearchDetail;
