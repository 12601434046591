import { PRODUCT_TYPES } from "common/constants";
import React from "react";
import SortableTableHeader from "components/common/SortableTableHeader/SortableTableHeader";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const ProjectActivityListTableHeader = ({
  records,
  sortOrder,
  handleSorting,
  currentOrderField,
  isLoading,
  isCountStale,
}) => {
  const isHeaderNonClickable =
    records?.length === 0 || isLoading || isCountStale;
  const isHeaderSticky = true;
  return (
    <thead
      className={`Thead ${isHeaderSticky ? styles.Table_Header_Sticky : ""}`}>
      <tr className="Tr">
        <th className="Th"></th>
        <th className="Th" scope="colgroup" colSpan="3">
          Capacity Remaining
        </th>
        <th className="Th" scope="colgroup" colSpan="4">
          Date Range
        </th>
      </tr>
      <tr className="Tr">
        <SortableTableHeader
          {...{
            title: "VPA Code",
            onClick: () => handleSorting("VPA Code", ["code"]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: PRODUCT_TYPES.pt3,
            onClick: () =>
              handleSorting(PRODUCT_TYPES.pt3, [
                PRODUCT_TYPES.pt3,
                "available_capacity",
              ]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: PRODUCT_TYPES.pt1,
            onClick: () =>
              handleSorting(PRODUCT_TYPES.pt1, [
                PRODUCT_TYPES.pt1,
                "available_capacity",
              ]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: PRODUCT_TYPES.pt2,
            onClick: () =>
              handleSorting(PRODUCT_TYPES.pt2, [
                PRODUCT_TYPES.pt2,
                "available_capacity",
              ]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Earliest Installation Date",
            onClick: () =>
              handleSorting("Earliest Installation Date", [
                "min_installation_date",
              ]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Latest Installation Date",
            onClick: () =>
              handleSorting("Latest Installation Date", [
                "max_installation_date",
              ]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "States",
            onClick: () => handleSorting("States", ["total_states"]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Partners",
            onClick: () => handleSorting("Partners", ["total_partners"]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
      </tr>
    </thead>
  );
};
const MemoizedProjectActivityListTableHeader = React.memo(
  ProjectActivityListTableHeader
);

export default MemoizedProjectActivityListTableHeader;
