import React from "react";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const SearchTableHeader = ({ isPartnerDashboard, showLastRunBy }) => {
  const isHeaderSticky = true;
  return (
    <thead
      className={`Thead ${isHeaderSticky ? styles.Table_Header_Sticky : ""}`}>
      <tr>
        {!isPartnerDashboard && <th className="Th"></th>}
        <th className="Th">Partner</th>
        <th className="Th">Data Phase</th>
        <th className="Th">Additional Filters</th>
        <th className="Th">Group By</th>
        {showLastRunBy && <th className="Th">Last Run By</th>}
        <th className="Th">Last Count</th>
        <th className="Th">Recent Export</th>
        <th className="Th">Action</th>
      </tr>
    </thead>
  );
};

export default React.memo(SearchTableHeader);
