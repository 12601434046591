import CustomLoader from "components/common/CustomLoader/CustomLoader";
import TopPerformersCardHeader from "./TopPerformersCardHeader";
import TopPerformersCardTables from "./TopPerformersCardTables";
import { getErrorMessage } from "common/utils/errorUtils";
import { switchItems } from "./TopPerformers.constants";
import { useState } from "react";
import NoDataFound from "components/common/NoDataFound/NoDataFound";

const TopPerformersCardContent = ({ data, isLoading, isError, error }) => {
  const [selectedSwitchItem, setSelectedSwitchItem] = useState(
    () => switchItems.find((switchItem) => switchItem?.checked)?.value
  );

  return (
    <>
      <TopPerformersCardHeader
        {...{
          data,
          switchItems,
          selectedSwitchItem,
          setSelectedSwitchItem,
          isLoading,
          isError,
        }}
      />
      {!isLoading && !isError && (
        <TopPerformersCardTables
          {...{
            data,
            selectedSwitchItem,
          }}
        />
      )}
      {isLoading && <CustomLoader {...{ containerClasses: "my-5 py-5" }} />}
      {isError && (
        <NoDataFound
          {...{
            title: getErrorMessage(error),
            wrapperClasses: "my-5 py-5",
            spanClasses: "Font_H9",
          }}
        />
      )}
    </>
  );
};

export default TopPerformersCardContent;
