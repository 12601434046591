import { ReactComponent as CheckIcon } from "images/ic_success.svg";

const Success = ({ name }) => {
  return (
    <>
      <CheckIcon />
      {name}
    </>
  );
};

export default Success;
