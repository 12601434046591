import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import { difference } from "common/utils/utils";
import { getErrorMessage } from "common/utils/errorUtils";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useState } from "react";
import { updateDlightKenyaDataDetail } from "common/API/dlightKenyaAPIs";

const DlightKenyaDataActions = ({
  record,
  formDetails,
  refetchRecord,
  setFormDetails,
}) => {
  // * #####################################
  // * Mutations and States
  // * #####################################

  const recordId = record?.id;
  const mutation = useMutation(({ recordId, recordDiff }) =>
    updateDlightKenyaDataDetail(recordId, recordDiff)
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [recordDiff, setRecordDiff] = useState({});

  const isDisabled = !recordId || isUpdating || isEmpty(recordDiff);

  // * #####################################
  // * Effects
  // * #####################################

  useEffect(() => {
    setRecordDiff(difference(record, formDetails));
  }, [record, formDetails]);

  // * #####################################
  // * Handlers
  // * #####################################

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsUpdating(true);

    mutation.mutate(
      { recordId, recordDiff },
      {
        onSuccess: () => {
          toast.success("Updated Dlight Kenya data record successfully");
          refetchRecord();
        },
        onError: (error) => {
          if (error?.response?.status !== 400)
            toast.error(
              getErrorMessage(error, "Error while updating Kenya data record")
            );
        },
        onSettled: () => {
          setIsUpdating(false);
        },
      }
    );
  };

  return (
    <>
      <Button
        {...{
          buttonType: ButtonTypes.SECONDARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          className: "me-3",
          onClick: (e) => {
            e.preventDefault();
            setFormDetails(record);
          },
          isDisabled,
        }}>
        Cancel
      </Button>
      <Button
        {...{
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          className: "me-3",
          onClick: handleSubmit,
          isDisabled,
        }}>
        {isUpdating ? "Updating record" : "Update Record"}
      </Button>
    </>
  );
};

export default DlightKenyaDataActions;
