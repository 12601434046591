import Button from "components/common/Button/Button";
import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { useState } from "react";

const NewImportButton = ({ handleSubmit, isDisabled }) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleOnClick = async () => {
    setIsButtonClicked(true);
    await handleSubmit();
    setIsButtonClicked(false);
  };

  return (
    <Button
      {...{
        buttonType: ButtonTypes.PRIMARY_SMALL,
        buttonContentType: ButtonContentTypes.TEXT,
        onClick: handleOnClick,
        isDisabled: isDisabled || isButtonClicked,
        className: "me-3",
      }}>
      {!isButtonClicked ? "Import" : "Importing"}
    </Button>
  );
};

export default NewImportButton;
