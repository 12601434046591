import * as styles from "./PADetail.module.scss";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import DetailResponse from "./DetailResponse/DetailResponse";
import PAPieChart from "components/common/PAPieCharts/PAPieChart";

export const PAStateStats = ({
  PADetail,
  PAStateStatistics,
  isPieChartDataLoading,
}) => {
  const statesList = PADetail?.states_list;

  return (
    <>
      <div className="d-flex col-12 justify-content-between">
        <div className="col-5">
          <span className={styles.PA_Detail_Heading}>
            States : {PADetail?.total_states}
          </span>
          <DetailResponse
            {...{
              itemName: "Name",
              itemCount: "Counts",
              listItems: statesList,
            }}
            isState={true}
          />
        </div>
        <div className="col-6 my-auto">
          {isPieChartDataLoading ? (
            <CustomLoader containerClasses="col-6 my-auto" />
          ) : (
            <PAPieChart {...{ PAStatistics: PAStateStatistics }} />
          )}
        </div>
      </div>
      <hr className="mt-4" />
    </>
  );
};
