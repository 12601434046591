import React from "react";
import CloseIcon from "../CloseIcon/CloseIcon";
import { ToastContainer } from "react-toastify";
import ToastIcon from "../ToastIcon/ToastIcon";

const contextClass = {
  success: "Success_Stroke_Color Success_Bg_Color",
  error: "Error_Stroke_Color Error_Bg_Color",
  info: "Info_Stroke_Color Info_Bg_Color",
  warning: "Alert_Stroke_Color Alert_Bg_Color",
};

const CustomToastContainer = () => {
  return (
    <ToastContainer
      toastClassName={({ type }) =>
        contextClass[type] + " d-flex cursor-pointer Toast_Wrapper"
      }
      bodyClassName={() => "d-flex align-items-start Font_H10 Toast_Body"}
      closeButton={CloseIcon}
      position="top-right"
      autoClose={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      icon={ToastIcon}
    />
  );
};

export default CustomToastContainer;
