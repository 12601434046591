import * as styles from "./RecordDetailKeyValue.module.scss";

import classNames from "classnames";
import { sentenceCase } from "change-case";

const RecordDetailKeyValue = ({
  dataCardHeading,
  recordData,
  recordDataFileColumns,
}) => {
  // * Check whether the record has file columns or not to maintain the order of data
  const hasRecordFileColumns = recordDataFileColumns?.length > 0;

  const containerClasses = classNames(
    "mx-auto my-4",
    styles.Record_Data_Container
  );

  const headerClasses = classNames(
    "Font_H7 Font_Title_Color",
    styles.Record_Data_Header
  );

  return (
    Object.keys(recordData).length !== 0 && (
      <div className={containerClasses}>
        {dataCardHeading && <p className={headerClasses}>{dataCardHeading}</p>}
        {Object.entries(
          !hasRecordFileColumns ? recordData : recordDataFileColumns
        ).map(([key, value]) => {
          return (
            <div key={key} className={styles.Key_Value_Wrapper}>
              <span className="Font_H9 Font_Subtitle_Color text-start">
                {!hasRecordFileColumns
                  ? sentenceCase(key)
                  : sentenceCase(value)}
              </span>
              <span className="Font_H9 Font_Title_Color col-8 text-break text-end">
                {!hasRecordFileColumns ? value : recordData[value] || "-"}
              </span>
            </div>
          );
        })}
      </div>
    )
  );
};

export default RecordDetailKeyValue;
