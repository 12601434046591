import React from "react";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const ImportTableHeader = ({ searchOptions, currentDashboard }) => {
  const isHeaderSticky = true;

  return (
    <thead
      className={`Thead ${isHeaderSticky ? styles.Table_Header_Sticky : ""}`}>
      <tr>
        {currentDashboard === "partner" ? (
          <th className="Th">User</th>
        ) : (
          <th className="Th">Partner</th>
        )}

        <th className="Th">Type</th>
        <th className="Th">label</th>
        <th className="Th">Status</th>
        <th className="Th">Imported At</th>
        {searchOptions?.status === "promoted" && (
          <th className="Th">Promoted On</th>
        )}
        <th className="Th">Rows</th>
        <th className="Th">Clean</th>
        <th className="Th">Errors</th>
        <th className="Th">Excluded</th>
        <th className="Th">Action</th>
      </tr>
    </thead>
  );
};

export default React.memo(ImportTableHeader);
