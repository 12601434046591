/**
 * Given an element, return true if the element is overflown in x direction
 * @returns A boolean value.
 */
export const isOverflown = (element) => {
  return element?.scrollWidth > element?.clientWidth;
};

/**
 * It takes in the headers and rows and returns the updated headers and rows
 * needed for React table/
 * @returns The updated headers and rows are being returned.
 */
export const prepareHeadersAndData = (headers, rows, footers) => {
  const updatedHeaders = headers.map((header, index) => {
    return {
      Header: header,
      Footer: footers[index],
      accessor: index.toString(),
      sticky:
        index === 0 ? "left" : index === footers.length - 1 ? "right" : "",
    };
  });

  const updatedRows = rows.map((row) => {
    return JSON.parse(JSON.stringify(Object.assign({}, row)));
  });

  return [updatedHeaders, updatedRows];
};
