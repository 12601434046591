import * as styles from "./ExportDetailPillSection.module.scss";

import { downloadFileFromS3, formatBytes } from "common/utils/utils";

import DetailSectionsTopActionBar from "components/common/DetailSectionsTopActionBar/DetailSectionsTopActionBar";
import DownloadIcon from "images/ic_download.svg";
import Pills from "components/common/Pills/Pills";
import React from "react";
import RichText from "components/common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import { formatDateTime } from "common/utils/dateAndTimeUtils";

const ExportDetailPillSection = ({ exportDetails }) => {
  return (
    <section className={styles.Export_Pill_Section}>
      <section className="d-flex justify-content-between">
        <RichText textType={TextTypes.SUBHEAD2}>Export Details</RichText>
        <ExportDetailActions
          {...{
            reportPath: exportDetails?.reportPath,
            fileSize: exportDetails?.fileSize,
          }}
        />
      </section>
      <ExportDetailPills {...{ exportDetails }} />
    </section>
  );
};

export default ExportDetailPillSection;

//***************************/
//*****Local Components******/
//***************************/

const ExportDetailPills = ({ exportDetails }) => {
  const exportDetailsArray = prepareExportDetailsArrayForPills(exportDetails);
  return (
    <>
      {exportDetailsArray.length > 0 && (
        <section className={styles.Export_Details_Pills_Wrapper}>
          <Pills
            {...{
              pills: exportDetailsArray,
              showAllPills: true,
              renderWithLabels: true,
            }}
          />
        </section>
      )}
    </>
  );
};

const ExportDetailActions = ({ reportPath, fileSize }) => {
  return (
    <DetailSectionsTopActionBar disabled={!reportPath}>
      <img
        role="button"
        className="ms-2"
        src={DownloadIcon}
        height="30px"
        width="30px"
        alt="Download Export"
        title={reportPath && `Download Export (${formatBytes(fileSize)})`}
        onClick={reportPath ? () => downloadFileFromS3(reportPath) : undefined}
      />
    </DetailSectionsTopActionBar>
  );
};

//***************************/
//*****Local Utils******/
//***************************/

const prepareExportDetailsArrayForPills = (exportDetails) => {
  if (!exportDetails?.reportType) return [];

  const exportDetailsArray = [
    { label: "export_type", value: exportDetails?.reportType },
    { label: "created_on", value: formatDateTime(exportDetails?.updatedAt) },
    { label: "created_by", value: exportDetails?.userFirstName },
    { label: "filename", value: exportDetails?.filename },
  ];
  return exportDetailsArray;
};
