import { NavDropdown } from "react-bootstrap";
import styles from "./NavigationDropdown.module.scss";
import startCase from "lodash/startCase";
import classNames from "classnames";

const NavigationDropdown = ({
  title,
  selectedMenuItem,
  handleMenuItemChange,
  menuItems,
}) => {
  return (
    <NavDropdown title={title} className={styles.Nav_Menu_Title}>
      {menuItems.map((menuItem, index) => {
        // * Make cursor default for Role item
        const classes = classNames(styles.Nav_Menu_Item, {
          [styles.Nav_Menu_Role]: menuItem.includes("Role"),
        });

        return (
          <NavDropdown.Item
            key={index}
            onClick={() => handleMenuItemChange(menuItem)}
            active={selectedMenuItem === menuItem}
            className={classes}>
            {startCase(menuItem)}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

export default NavigationDropdown;
