import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import { downloadExcludedRecords } from "common/API/importAPIs";
import { toast } from "react-toastify";

const DownloadExcludedRecords = ({ selectedPartnerId }) => {
  const handleDownloadExcludedRecords = async () => {
    if (!selectedPartnerId) return;

    const response = await downloadExcludedRecords(selectedPartnerId);
    toast.success(response?.data?.message);
  };

  return (
    <Button
      buttonType={ButtonTypes.SECONDARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={handleDownloadExcludedRecords}
      isDisabled={!selectedPartnerId}
      className="me-3"
      {...(!selectedPartnerId
        ? {
            title:
              "Please choose a partner using filters to download excluded records",
          }
        : {})}>
      <span>Download Excluded Records</span>
    </Button>
  );
};

export default DownloadExcludedRecords;
