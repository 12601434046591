import * as styles from "../SearchDetailRecordSection.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { SCROLL_POSITION, SEARCH_ID_OF_RECORD } from "common/constants";

import Button from "components/common/Button/Button";
import TextField from "components/common/TextField/TextField";
import classNames from "classnames";
import { useState } from "react";

const RecordSearch = ({
  searchOptions,
  setSearchOptions,
  isLoading,
  isCountStale,
}) => {
  const [searchId, setSearchId] = useState(searchOptions?.search_id || "");

  const textFieldClasses = classNames(
    "w-90 border-color-light-blue py-1",
    styles.Record_Search_Field
  );

  const handleChange = () => {
    // Clear session storage before new search to get scroll on top
    sessionStorage.removeItem(SCROLL_POSITION);
    sessionStorage.removeItem(SEARCH_ID_OF_RECORD);
    setSearchOptions((prev) => ({
      ...prev,
      search_id: searchId,
    }));
    sessionStorage.setItem(SEARCH_ID_OF_RECORD, searchId);
  };

  return (
    <div className="d-flex justify-content-end gap-2 align-items-center user-select-none py-2">
      <span className="Font_H8 Font_Title_Color">ID Search:</span>
      <TextField
        {...{
          type: "text",
          value: searchId,
          handleAPICall: handleChange,
          onChange: (e) => setSearchId(e.target.value),
          placeholder: "Search by Transaction ID or Customer ID",
          className: textFieldClasses,
        }}
      />
      <Button
        {...{
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          onClick: handleChange,
          isLoading: isLoading,
          isDisabled: isCountStale || isLoading,
          className: "my-1 me-2",
        }}>
        Search
      </Button>
    </div>
  );
};

export default RecordSearch;
