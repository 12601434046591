import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as styles from "./ArchiveRawData.module.scss";

import classNames from "classnames";
import { formatDate } from "common/utils/dateAndTimeUtils";
import { sentenceCase } from "change-case";

const ArchiveRawDataKeyValue = ({
  title,
  archiveRawData,
  archiveRawDataFileColumns,
}) => {
  // * Check whether the record has file columns or not to maintain the order of data
  const hasRecordFileColumns = archiveRawDataFileColumns?.length > 0;

  const dataContainerClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    styles.Original_Data_Container
  );

  return (
    Object.keys(archiveRawData).length !== 0 && (
      <div className={dataContainerClasses}>
        <span className="Font_H7 Font_Title_Color d-flex pb-4">{title}</span>
        {Object.entries(
          !hasRecordFileColumns ? archiveRawData : archiveRawDataFileColumns
        ).map(([key, value]) => {
          return (
            <div
              key={key}
              className={countTableStyles.Count_Table_Item_Wrapper}>
              <span className="Font_H9 Font_Subtitle_Color">
                {!hasRecordFileColumns
                  ? sentenceCase(key)
                  : sentenceCase(value)}
              </span>
              <span className="Font_H9 Font_Title_Color w-50 text-end">
                {/* If key contains date value, then format it as a date */}
                {/* TODO: Come up with a better way to deal with this */}
                {!hasRecordFileColumns
                  ? key.includes("date")
                    ? formatDate(value)
                    : value
                  : (value.includes("date")
                      ? formatDate(archiveRawData[value])
                      : archiveRawData[value]) || "-"}
              </span>
            </div>
          );
        })}
      </div>
    )
  );
};

export default ArchiveRawDataKeyValue;
