import { useEffect } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { getErrorMessage } from "common/utils/errorUtils";
import { getProductModelList } from "common/API/dlightUgandaAPIs";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const ProductModelDropdown = ({
  productModel,
  setProductModel,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  const {
    data: response,
    isError,
    error,
  } = useQuery("dlight-uganda-data-products", getProductModelList);

  if (isError)
    toast.error(getErrorMessage(error), "Cannot fetch Products Model list");

  const productModelList = response?.data?.payload;

  useEffect(() => {
    const storedProjectModel = sessionStorage.getItem(
      `${pageIdentifier}ProductModel`
    );
    if (storedProjectModel) {
      setProductModel(JSON.parse(storedProjectModel));
    }
  }, [pageIdentifier]);

  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      product_model: productModel?.value,
    }));
    if (productModel !== undefined || productModel !== null) {
      sessionStorage.setItem(
        `${pageIdentifier}ProductModel`,
        JSON.stringify(productModel)
      );
    }
  }, [productModel, setSearchOptions, pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: productModel,
        setSelectedOption: setProductModel,
        options: productModelList,
        customWidth: "300px",
        className,
        placeholder: "Product_Model",
      }}
    />
  );
};

export default ProductModelDropdown;
