import CustomLoader from "components/common/CustomLoader/CustomLoader";
import PACapacityPieChart from "components/common/PAPieCharts/PACapacityPieChart";
import PAPieChart from "components/common/PAPieCharts/PAPieChart";
import Switch from "components/common/Switch/Switch";
import { useState } from "react";

const switchItems = [
  {
    name: "Sales",
    value: "Sales",
  },
  { name: "Capacities", value: "Project Activity Capacities" },
];

const PAProductTypeAndCapacityPieChart = ({
  isPieChartDataLoading,
  PAProductTypeStatistics,
  PACapacityStatistics,
}) => {
  const [selectedSwitchItem, setSelectedSwitchItem] = useState("Sales");

  if (isPieChartDataLoading) {
    return <CustomLoader containerClasses="col-6 my-auto" />;
  }

  return (
    <>
      <div className="d-flex justify-content-end pb-2 mt-4 me-2">
        <Switch
          {...{
            switchItems,
            groupName: "pa-pie-charts",
            selectedSwitchItem,
            setSelectedSwitchItem,
            isDisabled: isPieChartDataLoading,
          }}
        />
      </div>
      {selectedSwitchItem === "Project Activity Capacities" ? (
        <PACapacityPieChart {...{ PAStatistics: PACapacityStatistics }} />
      ) : (
        <PAPieChart {...{ PAStatistics: PAProductTypeStatistics }} />
      )}
    </>
  );
};

export default PAProductTypeAndCapacityPieChart;
