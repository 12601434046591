import ErrorMessage from "./ErrorMessage";
import ResetPasswordForm from "./ResetPasswordForm";
import SuccessMessage from "./SuccessMessage";
import { getErrorMessage } from "common/utils/errorUtils";

const ResetPasswordContent = ({
  passwordUpdated,
  setPasswordUpdated,
  isTokenInvalid,
  verificationError,
}) => {
  if (isTokenInvalid) {
    return (
      <ErrorMessage
        {...{
          message: getErrorMessage(
            verificationError,
            "Something went wrong. Please regenerate the link"
          ),
        }}
      />
    );
  }

  if (passwordUpdated) return <SuccessMessage />;

  return (
    <ResetPasswordForm
      {...{
        passwordUpdated,
        setPasswordUpdated,
        isTokenInvalid,
      }}
    />
  );
};

export default ResetPasswordContent;
