import { ReactComponent as UploadIcon } from "images/ic_upload.svg";

const Empty = () => {
  return (
    <>
      <UploadIcon />
      Choose a file
    </>
  );
};

export default Empty;
