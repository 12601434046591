import NewSearchForExportButton from "components/common/NewSearchForExportButton/NewSearchForExportButton";

const Actions = () => {
  return (
    <div className="d-flex gap-2">
      <NewSearchForExportButton />
    </div>
  );
};

export default Actions;
