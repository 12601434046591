import React, { useEffect, useRef, useState } from "react";

import Dropdown from "react-multilevel-dropdown";
import DropdownIndicatorImg from "images/ic_duotone_arrow_bottom.svg";
import greaterThanArrowIcon from "images/ic_arrow_right.svg";
import styles from "./NestedDropdown.module.scss";
import classNames from "classnames";

const NestedDropdown = ({
  options,
  selectedOption,
  optionName,
  setSelectedOption,
  wrapperClassName = "",
  disableParentOnClick = true,
  ...attributeProps
}) => {
  const [selectedOptionName, setSelectedOptionName] = useState(
    optionName ? optionName : ""
  );
  const ref = useRef(null);

  const handleClick = (e, option) =>
    handleItemClick(
      e,
      option,
      ref,
      setSelectedOptionName,
      setSelectedOption,
      disableParentOnClick
    );

  useEffect(() => {
    if (selectedOption === "") setSelectedOptionName("Select an option");
  }, [selectedOption]);

  return (
    <div className={styles.Nested_Dropdown}>
      <Dropdown
        {...{
          wrapperClassName: classNames(
            styles.Nested_Dropdown_Wrapper,
            wrapperClassName
          ),
          menuClassName: styles.Nested_Dropdown_Menu,
          buttonClassName: styles.Nested_Dropdown_Button,
          title: getDropdownTitle(selectedOptionName),
          ref,
          ...attributeProps,
        }}>
        {options.map((option, key) => {
          const isItemSelected = option?.sub_types.length
            ? option.sub_types.some((subType) => subType.id === selectedOption)
            : selectedOption === option?.id;

          return (
            <DropdownItem
              key={key}
              {...{
                handleClick,
                option,
                greaterThanArrowIcon,
                isItemSelected,
                selectedOption,
              }}
            />
          );
        })}
      </Dropdown>
    </div>
  );
};

export default NestedDropdown;

//********************************/
//****Local Utils and Handlers****/
//********************************/

const handleItemClick = (
  e,
  option,
  ref,
  setSelectedOptionName,
  setSelectedOption,
  disableParentOnClick
) => {
  if (disableParentOnClick && option.sub_types && option.sub_types.length > 0) {
    return;
  }
  if (ref.current) {
    ref.current.toggle(e);
  }

  setSelectedOptionName(option.name);
  setSelectedOption({ id: option.id, name: option.name });
};

const getDropdownTitle = (selectedOptionName) => {
  return (
    <>
      {selectedOptionName !== "" ? (
        <>
          <span className={styles.Nested_Dropdown_Title_Text}>
            {selectedOptionName}
          </span>
        </>
      ) : (
        <>
          <span className={styles.Nested_Dropdown_Placeholder_Text}>
            Select an option
          </span>
        </>
      )}
      <img
        className={styles.Dropdown_Indicator}
        src={DropdownIndicatorImg}
        alt="drop down"
      />
    </>
  );
};

//************************/
//****Local Components****/
//************************/

const DropdownItem = ({
  handleClick,
  option,
  greaterThanArrowIcon,
  isItemSelected,
  selectedOption,
}) => {
  const dropdownItemClasses = classNames(styles.Nested_Dropdown_Items, {
    [styles.Item_active]: isItemSelected,
  });

  return (
    <Dropdown.Item
      className={dropdownItemClasses}
      onClick={(e) => handleClick(e, option)}>
      <span className={styles.Nested_DropDown_Item_Name}>{option.name}</span>
      {option?.sub_types.length !== 0 && (
        <>
          <img className={styles.Arrow_Icon} src={greaterThanArrowIcon} />
          <DropdownSubMenu
            sub_types={option.sub_types}
            handleClick={handleClick}
            selectedOption={selectedOption}
          />
        </>
      )}
    </Dropdown.Item>
  );
};

const DropdownSubMenu = ({ sub_types, handleClick, selectedOption }) => {
  return (
    <Dropdown.Submenu
      position="right"
      className={styles.Nested_Dropdown_Submenu}>
      {sub_types.map((sub_type, key) => (
        <DropdownSubItem
          key={key}
          handleClick={handleClick}
          sub_type={sub_type}
          isSubItemSelected={selectedOption === sub_type?.id}
        />
      ))}
    </Dropdown.Submenu>
  );
};

const DropdownSubItem = ({ handleClick, sub_type, isSubItemSelected }) => {
  const dropdownItemClasses = classNames(styles.Nested_Dropdown_Items, {
    [styles.Item_active]: isSubItemSelected,
  });

  return (
    <Dropdown.Item
      className={dropdownItemClasses}
      onClick={(e) => handleClick(e, sub_type)}>
      <span className={styles.Nested_DropDown_Item_Name}>{sub_type.name}</span>
    </Dropdown.Item>
  );
};
