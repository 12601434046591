import { getUserProfileURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getUserProfileDetails = (userId) => {
  const url = getUserProfileURLs("getUserProfileDetails", { userId });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const updateUserProfileDetails = (userId, data) => {
  const url = getUserProfileURLs("updateUserProfileDetails", { userId });

  return axiosInstance.patch(url, data, {
    headers: getAuthorizationHeaders(),
  });
};

export const changePassword = (userId, data) => {
  const url = getUserProfileURLs("changePassword", { userId });

  return axiosInstance.put(url, data, {
    headers: getAuthorizationHeaders(),
  });
};
