import * as styles from "./ExportDetailLeftSection.module.scss";

import DetailSectionsTopActionBar from "components/common/DetailSectionsTopActionBar/DetailSectionsTopActionBar";
import EditPencilIcon from "images/ic_edit_pencil.svg";
import ExportDetailPillSection from "./ExportDetailPillSection/ExportDetailPillSection";
import SearchPillSection from "components/common/SearchPillSection/SearchPillSection";
import routeConstants from "routes/routeConstants";
import { useNavigate } from "react-router-dom";

export const ExportDetailLeftSection = ({ dataFilter, exportDetails }) => {
  const {
    id: filterId,
    filter_names: filterNames,
    group_by_names: groupByNames,
  } = dataFilter;

  return (
    <div className={styles.Export_Detail_Left_Section}>
      <ExportDetailPillSection {...{ exportDetails }} />
      <SearchPillSection {...{ filterId, filterNames, groupByNames }}>
        <EditSearchAction {...{ filterId }} />
      </SearchPillSection>
      <footer className={styles.Footer}></footer>
    </div>
  );
};

//***************************/
//*****Local Components******/
//***************************/

const EditSearchAction = ({ filterId }) => {
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(routeConstants.Search.path, { state: { filterId } });
  };

  return (
    <DetailSectionsTopActionBar>
      <div
        className="d-flex place-items-center cursor-pointer"
        onClick={handleEdit}>
        <img src={EditPencilIcon} alt="Edit" />
      </div>
    </DetailSectionsTopActionBar>
  );
};
