import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as importDetailStyles from "../ImportDetail.module.scss";

import { redirectToViewImport } from "common/utils/utils";

import classNames from "classnames";
import OverviewHeader from "./OverviewHeader";
import OverviewDetail from "./OverviewDetail";

const Overview = ({ details, title, type, className }) => {
  const {
    label,
    file_name: fileName,
    created_at: createdAt,
    id,
    file_path: filePath,
    user_name: userName,
    file_size: fileSize,
    partner,
    promoted_by_name: promotedByName,
    promoted_at: promotedAt,
    error_report_path: errorReportPath,
    import_status: importStatus,
    file_columns: fileColumns,
  } = details;

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    importDetailStyles.Import_Card_Container,
    className
  );

  return (
    <section
      className={sectionClasses}
      onClick={() => type !== "main" && redirectToViewImport(id)}>
      <OverviewHeader {...{ title, type }} />
      <OverviewDetail
        {...{
          label,
          fileName,
          createdAt,
          filePath,
          fileSize,
          userName,
          type,
          partner,
          promotedByName,
          promotedAt,
          errorReportPath,
          importStatus,
          fileColumns,
        }}
      />
    </section>
  );
};

export default Overview;
