import * as styles from "./CleanDataRecord.module.scss";

import KeyValue from "./KeyValue/KeyValue";
import { formatDate } from "common/utils/dateAndTimeUtils";

const CleanDataKeyValues = ({ cleanData }) => {
  return (
    <section className={styles.Clean_Data_Container}>
      <KeyValue
        {...{
          itemKey: "Cutomer Name",
          itemValue: cleanData?.customer_name,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Mobile Number",
          itemValue: cleanData?.mobile_number,
        }}
      />
      <KeyValue {...{ itemKey: "User Id", itemValue: cleanData?.user_id }} />
      <KeyValue {...{ itemKey: "Loan Id", itemValue: cleanData?.loan_id }} />
      <KeyValue
        {...{
          itemKey: "Address",
          itemValue: cleanData?.address,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Address Altered Dupe",
          itemValue: cleanData?.address_altered_dupe,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Partner Branch",
          itemValue: cleanData?.partner_branch_name,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Governing District",
          itemValue: cleanData?.governing_district_name,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Product Name",
          itemValue: cleanData?.product_name,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Product Type",
          itemValue: cleanData?.product_type,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Product Subtype",
          itemValue: cleanData?.product_subtype,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Installation Date",
          itemValue: formatDate(cleanData?.installation_date),
        }}
      />
      <KeyValue {...{ itemKey: "Import Label", itemValue: cleanData?.label }} />
      <KeyValue
        {...{
          itemKey: "Project Activity Code",
          itemValue: cleanData?.project_activity_code,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Record Status",
          itemValue: cleanData?.record_status,
        }}
      />
      <KeyValue
        {...{
          itemKey: "Usage Status",
          itemValue: cleanData?.usage_status,
        }}
      />
    </section>
  );
};

export default CleanDataKeyValues;
