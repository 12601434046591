import * as styles from "./SearchResultTopSection.module.scss";

import { TextTypes } from "constants/enumTypes.constants";

import React from "react";
import RichText from "components/common/RichText/RichText";
import { convertToCompactFormat } from "common/utils/dateAndTimeUtils";
import { SearchResultSummary } from "components/common/SearchResultSummary/SearchResultSummary";

export const SearchResultTopSection = ({
  totalCount,
  pivotTableSummary,
  recordsSummary,
  lastUpdatedCountAt,
  children,
  isRecordsTabSelected,
}) => {
  return (
    <section className={styles.Search_Result_Top_Section}>
      <SearchResultSummary
        {...{ totalCount, pivotTableSummary, recordsSummary }}
        textType={TextTypes.PAGE_TITLE}
      />
      <div className="d-flex align-items-center">
        {!isRecordsTabSelected && (
          <RichText textType={TextTypes.SUBHEAD3}>
            As of {convertToCompactFormat(lastUpdatedCountAt, true)}
          </RichText>
        )}
        {/* children will have button components */}
        {children}
      </div>
    </section>
  );
};
