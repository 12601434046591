import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as styles from "./FileInfoCard.module.scss";

import HyperLink from "components/common/HyperLink/HyperLink";
import { downloadFileFromS3 } from "common/utils/utils";

const FileNameLink = ({ filePath, fileName, keyName = "File Name" }) => {
  const handleDownloadFile = (event) => {
    event.stopPropagation();
    downloadFileFromS3(filePath);
  };

  return (
    <div className={countTableStyles.Count_Table_Item_Wrapper}>
      <span className="Font_H9 Font_Subtitle_Color">{keyName}</span>
      {fileName ? (
        <HyperLink
          onClick={handleDownloadFile}
          text={fileName}
          className={styles.Link_Wrapper}
        />
      ) : (
        "-"
      )}
    </div>
  );
};

export default FileNameLink;
