import * as styles from "./SearchDetailRecordSection.module.scss";

import classNames from "classnames";
import RecordSearch from "./RecordSearch/RecordSearch";
import RecordTable from "./RecordTable/RecordTable";
import { SearchResultTopSection } from "components/common/SearchPivotSection/SearchResultTopSection/SearchResultTopSection";
import moment from "moment";
import pluralize from "pluralize";
import CustomLoader from "components/common/CustomLoader/CustomLoader";

const SearchDetailRecordSection = ({
  filter,
  data,
  isSearchDetailRecordsLoading,
  fetchNextPage,
  hasNextPage,
  sortOrder,
  currentOrderField,
  orderingFields,
  handleSorting,
  searchOptions,
  setSearchOptions,
  recordStatus,
  handleUpdateCount,
}) => {
  if (!filter) {
    const loaderContainerClasses = classNames(
      styles.Search_Result_Container,
      "align-items-center",
      "justify-content-center",
      "m-auto"
    );
    return (
      <section className={styles.Search_Detail_Custom_loader}>
        <CustomLoader containerClasses={loaderContainerClasses} />
      </section>
    );
  }
  const {
    total_count: totalCount,
    pivot_table_summary: pivotTableSummary,
    last_updated_count_at: lastUpdatedCountAt,
  } = filter;

  const records = data?.pages?.flatMap((page) => page?.data?.results);
  const countOfRecords = data?.pages?.flatMap((page) => page?.data?.count);
  const lastPage = data?.pageParams.at(-1) || 1;

  const noDataFound = records?.length === 0 || !records;

  const currentDate = moment();

  // Check if count of recods is mismatched or count of record is updated one month ago or search id is empty for records list
  const isCountStale =
    ((countOfRecords && countOfRecords[0] !== totalCount) ||
      currentDate.diff(moment(lastUpdatedCountAt), "days") > 30) &&
    !sessionStorage.getItem("searchId");

  return (
    <>
      <SearchResultTopSection
        {...{
          isRecordsTabSelected: true,
          totalCount,
          pivotTableSummary: pivotTableSummary,
          recordsSummary: !isCountStale
            ? pluralize(
                "Record",
                Intl.NumberFormat("en-US").format(totalCount),
                true
              )
            : "Stale Records",
          lastUpdatedCountAt,
        }}>
        <RecordSearch
          {...{
            searchOptions,
            setSearchOptions,
            isLoading: isSearchDetailRecordsLoading,
            isCountStale,
          }}
        />
      </SearchResultTopSection>
      <RecordTable
        {...{
          records,
          filterId: filter?.id,
          recordStatus,
          fetchNextPage,
          hasNextPage,
          lastPage,
          isLoading: isSearchDetailRecordsLoading,
          noDataFound,
          sortOrder,
          currentOrderField,
          orderingFields,
          handleSorting,
          handleUpdateCount,
          isCountStale,
        }}
      />
    </>
  );
};

export default SearchDetailRecordSection;
