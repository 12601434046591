import * as styles from "./TextField.module.scss";

import { createRef, useEffect, useState } from "react";

import classNames from "classnames";

const TextField = ({
  id,
  type,
  setElementRef,
  placeholder,
  value,
  pattern,
  handleAPICall,
  onChange,
  className = "",
  ...attributeProps
}) => {
  const elementRef = createRef();
  const [isInputDirty, setIsInputDirty] = useState(false);

  useEffect(() => {
    if (setElementRef === undefined) return;
    setElementRef(elementRef);
  }, []);

  const classes = classNames(styles.Text_Field_Input, className, {
    [styles.Dirty_Text_Field_Input]: isInputDirty,
  });

  return (
    <>
      <input
        {...{
          id,
          type,
          ref: elementRef,
          className: classes,
          value,
          onChange,
          // * Check whether enter key is pressed after input and if pressed call API
          onKeyDown: (e) => {
            if (e.key === "Enter") handleAPICall && handleAPICall();
          },
          placeholder,
          required: true,
          pattern,
          onInvalid: (e) => {
            e.preventDefault();
            setIsInputDirty(true);
          },
        }}
        {...attributeProps}
      />
      <span className={styles.Input_Text}></span>
    </>
  );
};

export default TextField;
