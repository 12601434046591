import ImportList from "components/common/ImportList/ImportList";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { getPartnerImports } from "common/API/importAPIs";
import { useSelector } from "react-redux";

const ImportTab = ({ dashboardRef }) => {
  const { id: partnerId } = useSelector((state) => state.partner.partner);
  return (
    <>
      <ImportList
        {...{
          partnerId,
          fetchImports: getPartnerImports,
          currentDashboard: "partner",
        }}
      />
      <ScrollToTop element={dashboardRef} />
    </>
  );
};

export default ImportTab;
