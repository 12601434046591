import * as styles from "./EraseImportModalContent.module.scss";

import { ReactComponent as AlertIcon } from "images/ic_alert.svg";
import EraseButton from "./EraseButton";
import ModalCloseButton from "components/common/ModalCloseButton/ModalCloseButton";
import RichText from "components/common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import { eraseImport } from "common/API/importAPIs";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useState } from "react";

const EraseImportModalContent = ({
  setIsEraseImportModalOpen,
  importDetail,
  refetch,
}) => {
  const { rawDataImportId } = useParams();
  const [isBeingErased, setIsBeingErased] = useState(false);

  const handleErase = async () => {
    setIsBeingErased(true);
    try {
      const reponse = await eraseImport(rawDataImportId);
      setIsEraseImportModalOpen(false);
      toast.success(reponse?.data?.message || "Import erased successfully");
      refetch();
    } catch (error) {
      setIsBeingErased(false);
    }
  };

  return (
    <section className={styles.Erase_Verification}>
      <RichText textType={TextTypes.SUBHEAD1}>Erase Import Records</RichText>
      <p className="Font_H7 Font_Body_Color my-3 mx-2">
        <AlertIcon className="me-2" alt="alert" />
        {`Are you sure that you want to erase ${importDetail?.total_raw_records} records?`}
      </p>
      <div className="d-flex justify-content-end mt-5">
        <ModalCloseButton {...{ setIsModalOpen: setIsEraseImportModalOpen }} />
        <EraseButton {...{ isBeingErased, handleErase }} />
      </div>
    </section>
  );
};

export default EraseImportModalContent;
