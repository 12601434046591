import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "components/common/Breadcrumbs/Breadcrumbs";
import CustomToastContainer from "components/common/CustomToastContainer/CustomToastContainer";
import FirstTimePopUp from "components/FirstTimePopUp/FirstTimePopUp";
import NavBar from "components/common/NavBar/NavBar";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes/Routes";
import { getPartnersById } from "common/utils/partnerDashboardUtils";
import { isAuthenticated as isUserAuthenticated } from "common/utils/authenticationUtils";
import { setPartnersById } from "slices/partnersByIdSlice";
import { useEffect } from "react";

const App = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user, shallowEqual);
  const isAuthenticated = isUserAuthenticated(user);

  useEffect(() => {
    isAuthenticated &&
      getPartnersById().then((partnersDict) => {
        dispatch(setPartnersById(partnersDict));
      });
  }, [isAuthenticated]);

  return (
    <div className="App">
      {["staging-test", "uat-test"].includes(process.env.REACT_APP_ENV) && (
        <FirstTimePopUp />
      )}
      <Router>
        {isAuthenticated && (
          <>
            <NavBar />
            <Breadcrumbs />
          </>
        )}
        <Routes />
        <CustomToastContainer />
      </Router>
    </div>
  );
};

export default App;
