import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import { PRODUCT_TYPES } from "common/constants";
import { assignProjectActivity } from "common/API/api";
import { getErrorMessage } from "common/utils/errorUtils";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import { useState } from "react";

const AssignPAWizardButtons = ({
  refetchSearchRecords: refetch,
  wizardInstance,
  currentStep,
  selectedPA,
  dataFilterId,
  lastTotalCount,
  setIsModalOpen,
  errorPayload,
  setErrorPayload,
  isLoading,
  productTypeCounts,
}) => {
  const [assignPAClicked, setAssignPAClicked] = useState(false);

  const handleAssignPA = async () => {
    if (selectedPA === null) return;

    setAssignPAClicked(true);

    const reqPayload = {
      data_filter_id: dataFilterId,
      project_activity_id: selectedPA.value,
      last_total_count: lastTotalCount,
    };
    try {
      const { message, status } = await assignProjectActivity(reqPayload);
      const isVerificationSuccesful = status === 200;

      setIsModalOpen(!isVerificationSuccesful);
      isVerificationSuccesful ? toast.success(message) : toast.error(message);
      refetch();
    } catch (error) {
      console.error(error);
      toast.error(
        getErrorMessage(error, "Error while assigning project activity")
      );
      setErrorPayload(error?.response?.data?.payload);
    }

    setAssignPAClicked(false);
  };

  return (
    <>
      <PreviousButton
        {...{ currentStep, wizardInstance, errorPayload, setErrorPayload }}
      />
      <NextButton
        {...{
          currentStep,
          handleAssignPA,
          selectedPA,
          assignPAClicked,
          wizardInstance,
          lastTotalCount,
          errorPayload,
          isLoading,
          productTypeCounts,
        }}
      />
    </>
  );
};

export default AssignPAWizardButtons;

//***************************/
//*****Local Components******/
//***************************/

const PreviousButton = ({
  currentStep,
  wizardInstance,
  errorPayload,
  setErrorPayload,
}) => {
  return (
    <Button
      {...{
        buttonType: ButtonTypes.PRIMARY_SMALL,
        buttonContentType: ButtonContentTypes.TEXT,
        onClick: () => {
          // Set Error Payload to null on previous btn click
          errorPayload && setErrorPayload();
          wizardInstance.previousStep();
        },
        className: "me-3",
        isDisabled: currentStep === 1,
      }}>
      <span>Back</span>
    </Button>
  );
};

const NextButton = ({
  currentStep,
  handleAssignPA,
  selectedPA,
  assignPAClicked,
  wizardInstance,
  lastTotalCount,
  errorPayload,
  isLoading,
  productTypeCounts,
}) => {
  const onClick = currentStep <= 2 ? wizardInstance.nextStep : handleAssignPA;

  const isBothICSAndWPSPresentInResult =
    isBothICSAndWPSPresent(productTypeCounts);

  const isDisabled =
    isLoading ||
    (currentStep === 1 && lastTotalCount === 0) ||
    isBothICSAndWPSPresentInResult ||
    (currentStep === 2 && !selectedPA) ||
    assignPAClicked ||
    errorPayload;
  const buttonMessage =
    currentStep <= 2
      ? "Next"
      : assignPAClicked
      ? "Assigning Project Activity"
      : "Assign Project Activity";
  const buttonTitle =
    (currentStep === 1 &&
      lastTotalCount === 0 &&
      "Disabled because count is 0") ||
    (currentStep > 2 && errorPayload && "Error occurred") ||
    (isBothICSAndWPSPresentInResult &&
      "Both ICS and WPS product type is present in Result") ||
    undefined;

  return (
    <Button
      {...{
        buttonType: ButtonTypes.PRIMARY_SMALL,
        buttonContentType: ButtonContentTypes.TEXT,
        onClick,
        isDisabled,
        title: buttonTitle,
      }}>
      <span>{buttonMessage}</span>
    </Button>
  );
};

//***************************/
//*****Local Utilities*******/
//***************************/

const isBothICSAndWPSPresent = (productTypeCounts) => {
  if (!productTypeCounts || isEmpty(productTypeCounts)) {
    return false;
  }

  const ICS = productTypeCounts[PRODUCT_TYPES.pt2];
  const WPS = productTypeCounts[PRODUCT_TYPES.pt1];

  return ICS && WPS;
};
