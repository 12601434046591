import { ADMIN_USER_ROLE, DATA_TEAM_USER_ROLE } from "common/constants";
import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import { getUserProperty } from "common/utils/authenticationUtils";

const EraseImportButton = ({ handleErase }) => {
  const userRole = getUserProperty("user_role");

  const isAdminUser = [DATA_TEAM_USER_ROLE, ADMIN_USER_ROLE].includes(userRole);

  return (
    <Button
      buttonType={ButtonTypes.DANGER_SMALL}
      buttonContentType={ButtonContentTypes.ICON_TEXT}
      onClick={handleErase}
      className="me-3"
      title={!isAdminUser ? "Contact admin to erase the import" : ""}
      isDisabled={!isAdminUser}>
      <span>Erase Import</span>
    </Button>
  );
};

export default EraseImportButton;
