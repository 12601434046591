import { abbreviateNumber, showAvailableCapacity } from "common/utils/utils";

export const CapacityBodyTable = ({ productTypeCapacitites }) => {
  return (
    <tbody className="Tbody">
      {productTypeCapacitites &&
        productTypeCapacitites.map((prodCapacity, index) => {
          const prodType = Object.keys(prodCapacity)[0];
          const capacities = Object.values(prodCapacity)[0];
          return (
            <tr className="Tr" key={index}>
              <td className="Td">{prodType}</td>
              <td className="Td">
                {capacities?.total_capacity !== null
                  ? abbreviateNumber(capacities?.total_capacity)
                  : "-"}
              </td>
              <td className="Td">
                {capacities?.used_capacity !== null
                  ? abbreviateNumber(capacities?.used_capacity)
                  : "-"}
              </td>
              <td className="Td">{showAvailableCapacity(capacities)}</td>
            </tr>
          );
        })}
    </tbody>
  );
};
