import NoDataFound from "components/common/NoDataFound/NoDataFound";
import * as summaryStyles from "../ResultSummary.module.scss";

const NoResultSummary = ({ wrapperClasses = "" }) => {
  return (
    <section className={wrapperClasses}>
      <section className={summaryStyles.First_Level_Summary_Wrapper}>
        <div className={summaryStyles.First_Level_Title}>
          <span>
            <strong>Rule Check Results</strong>
          </span>
        </div>
      </section>
      <NoDataFound
        wrapperClasses={summaryStyles.No_Result_Summary_Found}
        {...{
          title:
            "No result summary found. The summary will be shown here once the pipeline is completed",
        }}
      />
    </section>
  );
};

export default NoResultSummary;
