import classNames from "classnames";
import * as styles from "./ToggleSwitch.module.scss";

const ToggleSwitch = ({
  value,
  onChange,
  leftLabel = "No",
  rightLabel = "Yes",
}) => {
  const toggleLabelClasses = classNames(
    "Font_H10 Font_Title_Color",
    styles.Toggle_Label
  );
  const leftLabelClasses = classNames("me-2", toggleLabelClasses);
  const rightLabelClasses = classNames("ms-2", toggleLabelClasses);

  return (
    <div className={styles.Toggle_Container}>
      <label htmlFor="switch" className={leftLabelClasses}>
        {leftLabel}
      </label>
      <input
        type="checkbox"
        id="switch"
        className={styles.Checkbox}
        onChange={onChange}
        checked={value}
      />
      <label htmlFor="switch" className={styles.Toggle}></label>
      <label htmlFor="switch" className={rightLabelClasses}>
        {rightLabel}
      </label>
    </div>
  );
};

export default ToggleSwitch;
