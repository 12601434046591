import React from "react";
import SuccessIconImg from "images/ic_success.svg";
import ErrorIconImg from "images/ic_error.svg";
import InfoIconImg from "images/ic_info.svg";
import AlertIconImg from "images/ic_alert.svg";

const toastIconUrls = {
  success: SuccessIconImg,
  error: ErrorIconImg,
  info: InfoIconImg,
  warning: AlertIconImg,
};

const ToastIcon = ({ type }) => {
  return (
    <div>
      <img src={toastIconUrls[type]} alt={type} />
    </div>
  );
};

export default ToastIcon;
