import * as commonStyles from "styles/Common.module.scss";

import {
  FILE_STATUS_ALL_VALID,
  FILE_STATUS_FAILED,
  FILE_STATUS_PROBLEMS_FOUND,
  FILE_STATUS_PROMOTION_FAILED,
  FILE_STATUS_SUBMISSION_PROBLEM,
} from "common/constants";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import EraseImportModal from "./EraseImportModal/EraseImportModal";
import ImportDetailLeftSection from "./ImportDetailLeftSection/ImportDetailLeftSection";
import { ImportFooter } from "./ImportFooter";
import ResultSummary from "./ResultSummary/ResultSummary";
import FileError from "./FileError/FileError";
import { getErrorMessage } from "common/utils/errorUtils";
import { getImportDetails } from "common/API/importAPIs";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

import { PAGE_IDENTIFIERS } from "common/constants";
import { clearImportFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

const ImportDetail = () => {
  const { rawDataImportId } = useParams();
  const [isEraseImportModalOpen, setIsEraseImportModalOpen] = useState(false);

  /* To automatically clear import filters for a given page identifier. */
  const pageIdentifier = PAGE_IDENTIFIERS.IMPORTS;
  useAutoClearFilter(pageIdentifier, clearImportFilters);

  const {
    isLoading,
    data: response,
    isError,
    error,
    refetch,
  } = useQuery(["import-details", rawDataImportId], () =>
    getImportDetails(rawDataImportId)
  );

  if (isLoading) {
    return (
      <CustomLoader
        containerClasses={commonStyles.Full_Height_Flex_Container}
      />
    );
  }

  if (isError) {
    toast.error(getErrorMessage(error, "Cannot fetch import details"));
  }

  const handleErase = () => setIsEraseImportModalOpen(true);
  const importDetail = response?.data?.payload;

  const isEraseActionAllowed = [
    FILE_STATUS_PROBLEMS_FOUND,
    FILE_STATUS_ALL_VALID,
    FILE_STATUS_PROMOTION_FAILED,
    FILE_STATUS_SUBMISSION_PROBLEM,
  ].includes(importDetail?.import_status);

  const hasFileErrors = [
    FILE_STATUS_SUBMISSION_PROBLEM,
    FILE_STATUS_FAILED,
  ].includes(importDetail?.import_status);

  return (
    <>
      <section className="pb-3 d-flex flex-wrap-reverse align-items-end">
        {importDetail && (
          <>
            <ImportDetailLeftSection {...{ importDetail }} />
            {!hasFileErrors ? (
              <ResultSummary {...{ importDetail }} />
            ) : (
              <FileError {...{ importDetail }} />
            )}
          </>
        )}
      </section>
      {isEraseActionAllowed && <ImportFooter {...{ handleErase }} />}
      {isEraseImportModalOpen && (
        <EraseImportModal
          {...{
            isEraseImportModalOpen,
            setIsEraseImportModalOpen,
            importDetail,
            refetch,
          }}
        />
      )}
    </>
  );
};

export default ImportDetail;
