import * as styles from "./SalesChartFilters.module.scss";

import { useEffect, useState } from "react";

import NestedDropdown from "components/common/NestedDropdown/NestedDropdown";
import { defaultProductType } from "./SalesChartFilters.constants";
import { getProductTypeList } from "common/API/fieldAPIs";
import { useQuery } from "react-query";

const ProductTypeFilter = ({ setQueryParams }) => {
  // * Local state to manage dropdown
  const [productType, setProductType] = useState(defaultProductType);

  // * Get product type options
  const { data: response, isLoading } = useQuery("product-type", () =>
    getProductTypeList()
  );

  // * Set product type name as query param, needed to show in title
  useEffect(() => {
    setQueryParams({ product_type_name: productType?.name || "" });
  }, []);

  // * Handle local state as well as query params
  const handleProductType = (productType) => {
    setQueryParams((value) => ({
      ...value,
      // ? If product type is 0 (All Product Types) pass ""
      product_type_id: productType?.id || "",
      product_type_name: productType?.name || "",
    }));
    setProductType(productType);
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <span className="Font_H10 Font_Subtitle_Color pe-3 pb-2">
        Product Type
      </span>
      {!isLoading && (
        <NestedDropdown
          {...{
            // ? Add default "All" product type in dropdown options
            options: [defaultProductType].concat(response?.data?.payload || []),
            selectedOption: productType?.id,
            optionName: productType?.name,
            setSelectedOption: handleProductType,
            disableParentOnClick: false,
            wrapperClassName: styles.Product_Type_Container,
            // TODO: Change the position dynamically based on Screen width
            // position: "top-right",
          }}
        />
      )}
    </div>
  );
};

export default ProductTypeFilter;
