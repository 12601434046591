import { ReactComponent as ErrorIcon } from "images/ic_error.svg";

const Error = ({ fileExtension }) => {
  return (
    <>
      <ErrorIcon />
      Invalid file with extension .{fileExtension} selected
    </>
  );
};

export default Error;
