import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as styles from "./ResultSummary.module.scss";

import classNames from "classnames";
import NoResultSummary from "./NoResultSummary/NoResultSummary";

const ResultSummary = ({ importDetail }) => {
  const { summary_stats: resultSummary } = importDetail;

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    styles.Result_Summary_Wrapper,
    "mb-2"
  );

  if (!resultSummary) {
    return <NoResultSummary {...{ wrapperClasses: sectionClasses }} />;
  }

  return (
    <section className={sectionClasses}>
      {resultSummary && <ResultSummaryContainer {...{ resultSummary }} />}
    </section>
  );
};

export default ResultSummary;

//***************************/
//*****Local Components******/
//***************************/

const ResultSummaryContainer = ({ resultSummary }) => {
  return (
    <>
      {Object.entries(resultSummary)?.map(
        ([firstLevelTitle, firstLevelSummary]) => {
          return (
            <section
              key={firstLevelTitle}
              className={styles.First_Level_Summary_Wrapper}>
              <FirstLevelTitle {...{ firstLevelTitle }} />
              {firstLevelSummary && (
                <FirstLevelSummary {...{ firstLevelSummary }} />
              )}
            </section>
          );
        }
      )}
    </>
  );
};

const FirstLevelTitle = ({ firstLevelTitle }) => {
  return (
    <div className={styles.First_Level_Title}>
      <span>
        <strong>{firstLevelTitle}</strong>
      </span>
    </div>
  );
};

const FirstLevelSummary = ({ firstLevelSummary }) => {
  return (
    <>
      {Object.entries(firstLevelSummary)?.map(
        ([secondLevelTitle, secondLevelSummary]) => {
          return (
            <section
              key={secondLevelTitle}
              className={styles.Second_Level_Summary_Wrapper}>
              <SecondLevelTitle {...{ secondLevelTitle }} />
              {secondLevelSummary && (
                <SecondLevelSummary {...{ secondLevelSummary }} />
              )}
            </section>
          );
        }
      )}
    </>
  );
};

const SecondLevelTitle = ({ secondLevelTitle }) => {
  return (
    <div className={styles.Second_Level_Title}>
      <span>
        <strong>{secondLevelTitle}</strong>
      </span>
    </div>
  );
};

const SecondLevelSummary = ({ secondLevelSummary }) => {
  return (
    <>
      {Object.entries(secondLevelSummary)?.map(
        ([thirdLevelTitle, thirdLevelSummary]) => {
          return (
            <section
              key={thirdLevelTitle}
              className={styles.Third_Level_Summary_Wrapper}>
              {thirdLevelTitle === "Detailed" && <ThirdLevelTitle />}
              {thirdLevelSummary && (
                <ThirdLevelSummary {...{ thirdLevelSummary }} />
              )}
            </section>
          );
        }
      )}
    </>
  );
};

const ThirdLevelTitle = () => {
  return (
    <>
      <div className={styles.Third_Level_Title}>
        <span>
          <strong>Records</strong>
        </span>
        <span>
          <strong>Unique Values</strong>
        </span>
      </div>
    </>
  );
};

const ThirdLevelSummary = ({ thirdLevelSummary }) => {
  return (
    <>
      {Object.entries(thirdLevelSummary)?.map(
        ([fourthLevelTitle, fourthLevelSummary]) => {
          return (
            <section
              key={fourthLevelTitle}
              className={styles.Fourth_Level_Summary_Wrapper}>
              <FourthLevelTitle {...{ fourthLevelTitle }} />
              {fourthLevelSummary && (
                <FourthLevelSummary {...{ fourthLevelSummary }} />
              )}
            </section>
          );
        }
      )}
    </>
  );
};

const FourthLevelTitle = ({ fourthLevelTitle }) => {
  return (
    <div className={styles.Fourth_Level_Title}>
      <span>{fourthLevelTitle}</span>
    </div>
  );
};

const FourthLevelSummary = ({ fourthLevelSummary }) => {
  return (
    <>
      {Object.values(fourthLevelSummary)?.map((value, index) => {
        return (
          <span className={styles.Detailed_Stats} key={index}>
            <i>{value}</i>
          </span>
        );
      })}
    </>
  );
};
