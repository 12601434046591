import SearchDetailRecordSection from "./SearchDetailRecordSection/SearchDetailRecordSection";
import SearchDetailSummarySection from "./SearchDetailSummarySection/SearchDetailSummarySection";

export const tabs = [
  {
    id: 1,
    name: "Summary",
    content: SearchDetailSummarySection,
    showScrollToTop: true,
  },
  {
    id: 2,
    name: "Records",
    content: SearchDetailRecordSection,
    showScrollToTop: true,
  },
];
