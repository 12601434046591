import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { renderCustomizedLabel, renderCustomizedLabelLine } from "./utils";

import CustomPieChartTooltip from "../SalesChart/CustomToolTips/CustomPieChartTooltip";
import React from "react";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FFB428"];

const PAPieChart = ({ PAStatistics }) => {
  const data = PAStatistics.map((PAStat) => {
    return {
      name: PAStat.name,
      value: PAStat.sales,
      percentage: PAStat.percentage,
    };
  });

  return (
    <section>
      <PieChart width={850} height={350}>
        <Pie
          {...{
            data,
            labelLine: renderCustomizedLabelLine,
            cx: "50%",
            cy: "50%",
            label: renderCustomizedLabel,
            outerRadius: 120,
            minAngle: 6,
            animationDuration: 1200,
          }}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomPieChartTooltip />} />
      </PieChart>
    </section>
  );
};

export default React.memo(PAPieChart);
