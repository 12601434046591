import ModalContent from "./ModalContent/ModalContent";
import ReactCustomModal from "components/common/ReactCustomModal/ReactCustomModal";
import { exportFormats } from "./ExportReportFormatConstants";
import { useState } from "react";

const GenerateDetailReportModal = ({
  refetchSearchRecords,
  isLoading,
  isGenerateReportModalOpen,
  setIsGenerateReportModalOpen,
  filterId,
  filterNames,
  groupByNames,
  totalCount,
  pivotTableSummary,
  onAfterOpen = () => {},
}) => {
  const exportFormatOptions =
    exportFormats[filterNames.common_filters.data_phase];

  const [exportFormat, setExportFormat] = useState(exportFormatOptions[0]);

  return (
    <div>
      <ReactCustomModal
        {...{
          contentStyles: { minHeight: "200px" },
          isOpen: isGenerateReportModalOpen,
          onAfterOpen,
        }}>
        <ModalContent
          {...{
            refetchSearchRecords,
            filterNames,
            isLoading,
            exportFormat,
            groupByNames,
            totalCount,
            pivotTableSummary,
            setExportFormat,
            exportFormatOptions,
            setIsGenerateReportModalOpen,
            filterId,
          }}
        />
      </ReactCustomModal>
    </div>
  );
};

export default GenerateDetailReportModal;
