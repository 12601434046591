import PromotionModalContent from "./PromotionModalContent";
import ReactCustomModal from "components/common/ReactCustomModal/ReactCustomModal";
import { stopPropagation } from "common/utils/utils";

const PromotionModal = ({
  isModalOpen,
  setIsModalOpen,
  numberOfCleanRecords,
  numberOfExcludedRecords,
  fileId,
  partnerId,
  refreshImports,
}) => {
  return (
    <ReactCustomModal
      {...{
        isOpen: isModalOpen,
        onRequestClose: () => setIsModalOpen(false),
        contentStyles: { minHeight: "150px", maxWidth: "500px" },
        onClick: stopPropagation,
      }}>
      <PromotionModalContent
        {...{
          numberOfCleanRecords,
          numberOfExcludedRecords,
          setIsModalOpen,
          fileId,
          partnerId,
          refreshImports,
        }}
      />
    </ReactCustomModal>
  );
};

export default PromotionModal;
