import {
  CURRENT_ORDER_FIELD,
  ORDERING_FIELD,
  SCROLL_POSITION,
  SEARCH_ID_OF_RECORD,
  SORTING_ORDER,
} from "common/constants";

import { useEffect } from "react";

/**
 * A custom hook that is used to fetch the following properties and store them in the session storage to preserve
 * @param scrollPosition - This is the scroll position of the records list of search detail
 * @param searchId - This is the search id of the records list
 * @param sortOrder - This is the sort order of the records list
 * @param currentOrderField - This is the current order field by which the records list is sorted
 * @param orderFields - This is the key path from response by which the records list is sorted
 */
export const usePreservedRecordTab = (
  scrollPosition,
  searchId,
  sortOrder,
  currentOrderField,
  orderFields
) => {
  // * Save the current scroll position, search id of records tab of search detail, sorting order and current ordering fields in sesseion storage to preserve them
  useEffect(() => {
    scrollPosition && sessionStorage.setItem(SCROLL_POSITION, scrollPosition);
    searchId && sessionStorage.setItem(SEARCH_ID_OF_RECORD, searchId);
    sortOrder && sessionStorage.setItem(SORTING_ORDER, sortOrder);
    currentOrderField &&
      sessionStorage.setItem(CURRENT_ORDER_FIELD, currentOrderField);
    orderFields && sessionStorage.setItem(ORDERING_FIELD, orderFields);
  }, [scrollPosition, searchId, sortOrder, currentOrderField, orderFields]);
};
