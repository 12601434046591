import * as countTableStyles from "components/common/CountTable/CountTable.module.scss";
import * as importDetailStyles from "../ImportDetail.module.scss";
import * as styles from "./Performance.module.scss";

import ImportTableItem from "../ImportTableItem/ImportTableItem";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import classNames from "classnames";
import { toHumanReadableDuration } from "common/utils/dateAndTimeUtils";

const Performance = ({ importDetail }) => {
  const {
    waiting_time: ruleCheckWaitingTime,
    execution_time: ruleCheckExecutionTime,
    average_execution_time: ruleCheckAvgExecutionTime,
    promotion_waiting_time: promotionWaitingTime,
    promoting_time: promotionExecutionTime,
    average_promoting_time: promotionAvgExecutionTime,
  } = importDetail;

  const sectionClasses = classNames(
    countTableStyles.Count_Table_Card_Container,
    importDetailStyles.Import_Card_Container,
    "mb-xxl-2"
  );

  return (
    <section className={sectionClasses}>
      <header className="d-flex justify-content-between pb-4">
        <span className="Font_H7 Font_Title_Color">Performance Stats</span>
      </header>
      <PerformanceStats
        {...{
          ruleCheckWaitingTime,
          ruleCheckExecutionTime,
          ruleCheckAvgExecutionTime,
          promotionWaitingTime,
          promotionExecutionTime,
          promotionAvgExecutionTime,
        }}
      />
    </section>
  );
};

export default Performance;

//***************************/
//*****Local Components******/
//***************************/

const PerformanceStats = ({
  ruleCheckWaitingTime,
  ruleCheckExecutionTime,
  ruleCheckAvgExecutionTime,
  promotionWaitingTime,
  promotionExecutionTime,
  promotionAvgExecutionTime,
}) => {
  if (
    [
      ruleCheckWaitingTime,
      ruleCheckExecutionTime,
      ruleCheckAvgExecutionTime,
      promotionWaitingTime,
      promotionExecutionTime,
      promotionAvgExecutionTime,
    ].every((item) => !item)
  ) {
    return (
      <NoDataFound
        wrapperClasses={styles.No_Performance_Data_Found}
        title="No Performance Stats Found"
      />
    );
  }

  return (
    <>
      <ImportTableItem
        itemKey="Waiting Time"
        itemValue={toHumanReadableDuration(ruleCheckWaitingTime)}
      />
      <ImportTableItem
        itemKey="Execution Time"
        itemValue={toHumanReadableDuration(ruleCheckExecutionTime)}
      />
      <ImportTableItem
        itemKey="Average Execution Time per 1K Rows"
        itemValue={toHumanReadableDuration(ruleCheckAvgExecutionTime)}
      />
      <ImportTableItem
        itemKey="Promotion Waiting Time"
        itemValue={toHumanReadableDuration(promotionWaitingTime)}
      />
      <ImportTableItem
        itemKey="Promoting Time"
        itemValue={toHumanReadableDuration(promotionExecutionTime)}
      />
      <ImportTableItem
        itemKey="Average Promoting Time per 1K Rows"
        itemValue={toHumanReadableDuration(promotionAvgExecutionTime)}
      />
    </>
  );
};
