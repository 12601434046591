import BoxKeyValue from "components/common/BoxKeyValue/BoxKeyValue";

const AssignPAConfirmationMessage = ({
  lastTotalCount,
  partner,
  selectedPA,
  productTypeCounts,
}) => {
  return (
    <div className="Font_H8 Font_Subtitle_Color pt-3">
      You are about to assign&nbsp;
      <span className="Font_H7 Font_Title_Color">
        {lastTotalCount} {partner}
      </span>
      &nbsp;records to project activity&nbsp;
      <span className="Font_H7 Font_Title_Color">{selectedPA?.code}</span>
      .
      <br />
      <ProjectActivityDifference {...{ selectedPA, productTypeCounts }} />
      <span className="Font_H7">Are you sure?</span>
    </div>
  );
};

export default AssignPAConfirmationMessage;

//***************************/
//*****Local Components******/
//***************************/

const ProjectActivityDifference = ({ selectedPA, productTypeCounts }) => {
  const capacities = selectedPA.capacities;
  return (
    <div className="m-4">
      <CurrentCapacity {...{ capacities }} />
      <AfterAssigningCapacity {...{ capacities, productTypeCounts }} />
    </div>
  );
};

const CurrentCapacity = ({ capacities }) => {
  return (
    <>
      <span className="mt-12 Font_H7 Font_Title_Color">
        Current available Project Activity Capacity
      </span>
      <div className="mt-3 d-flex">
        {Object.keys(capacities).map((productType, index) => (
          <Capacity
            key={index}
            {...{
              productType,
              capacity: capacities[productType].remaining_capacity,
            }}
          />
        ))}
      </div>
    </>
  );
};

const AfterAssigningCapacity = ({ capacities, productTypeCounts }) => {
  return (
    <div className="mt-3">
      <span className="mt-2 Font_H7 Font_Title_Color">After assigning</span>
      <div className="mt-3 d-flex">
        {Object.keys(capacities).map((productType, index) => (
          <Capacity
            key={index}
            {...{
              productType,
              capacity:
                capacities[productType].remaining_capacity -
                (productTypeCounts[productType] || 0),
            }}
          />
        ))}
      </div>
    </div>
  );
};

const Capacity = ({ capacity, productType }) => {
  return (
    <div>
      <BoxKeyValue
        {...{ itemKey: productType, itemValue: capacity, width: "250px" }}
      />
    </div>
  );
};
