import FileUpload from "components/common/FileUpload/FileUpload";

const FileUploadRow = ({
  file,
  setFile,
  isFileValid,
  setIsFileValid,
  inputRef,
  className,
  labelText = "Upload File: ",
}) => {
  return (
    <div className="Form_Row">
      <label>{labelText}</label>
      <FileUpload
        {...{
          file,
          setFile,
          isFileValid,
          setIsFileValid,
          inputRef,
          className,
        }}
      />
    </div>
  );
};

export default FileUploadRow;
