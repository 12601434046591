import CustomCollapsibleMenu from "components/common/CustomCollapsibleMenu/CustomCollapsibleMenu";
import FileNameLink from "components/common/FileInfoCard/FileNameLink";
import ImportTableItem from "../ImportTableItem/ImportTableItem";
import { formatBytes } from "common/utils/utils";
import { formatDateTime } from "common/utils/dateAndTimeUtils";

const OverviewDetail = ({
  label,
  fileName,
  createdAt,
  filePath,
  fileSize,
  userName,
  type,
  partner,
  promotedByName,
  promotedAt,
  errorReportPath,
  importStatus,
  fileColumns,
}) => {
  return (
    <>
      {type === "main" && (
        <ImportTableItem itemKey="Partner" itemValue={partner?.name} />
      )}
      <FileNameLink {...{ keyName: "File Name", filePath, fileName }} />
      <ImportTableItem itemKey="File Label" itemValue={label} />
      <ImportTableItem itemKey="File Size" itemValue={formatBytes(fileSize)} />
      <ImportTableItem
        itemKey="Uploaded at"
        itemValue={formatDateTime(createdAt)}
      />
      <ImportTableItem itemKey="Uploaded by" itemValue={userName} />
      <FileNameLink
        {...{
          keyName: "Error Report",
          filePath: errorReportPath,
          fileName: errorReportPath && errorReportPath.split("/").slice(-1),
        }}
      />

      <PromotionDetails {...{ promotedByName, promotedAt }} />
      <ImportTableItem itemKey="Import status" itemValue={importStatus} />
      <div className="mt-3">
        <CustomCollapsibleMenu
          {...{
            headerLabel: "Required Columns",
            disableHeaderLabel: "No required columns",
            isDisabled: (fileColumns?.required_columns || []).length == 0,
          }}>
          <FileColumnsBody fileColumns={fileColumns?.required_columns} />
        </CustomCollapsibleMenu>
      </div>
      <CustomCollapsibleMenu
        {...{
          headerLabel: "Ignored Columns",
          disableHeaderLabel: "No ignored columns",
          isDisabled: (fileColumns?.non_required_columns || []).length == 0,
        }}>
        <FileColumnsBody fileColumns={fileColumns?.non_required_columns} />
      </CustomCollapsibleMenu>
    </>
  );
};

export default OverviewDetail;

//***************************/
//*****Local Components******/
//***************************/

const PromotionDetails = ({ promotedByName, promotedAt }) => {
  return promotedByName ? (
    <>
      <ImportTableItem itemKey="Promoted By" itemValue={promotedByName} />
      <ImportTableItem
        itemKey="Promoted At"
        itemValue={formatDateTime(promotedAt)}
      />
    </>
  ) : null;
};

const FileColumnsBody = ({ fileColumns }) => {
  return (
    <div className="px-2 py-3 Font_Body_Color border border-color-light-blue rounded">
      {fileColumns.join(", ")}
    </div>
  );
};
