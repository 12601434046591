import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  CustomActiveDot,
  CustomDot,
} from "components/common/SalesChart/CustomChartComponents/CustomDots";
import {
  CustomXAxisLabel,
  CustomYAxis1Label,
  CustomYAxis2Label,
} from "components/common/SalesChart/CustomChartComponents/CustomAxisLabel/CustomAxisLabel";
import { createRef, useEffect } from "react";

import CustomSalesChartTooltip from "components/common/SalesChart/CustomToolTips/CustomSalesChartTooltip";
import TickWithoutLabel from "components/common/SalesChart/CustomChartComponents/CustomTicks/TickWithoutLabel";
import YearTick from "components/common/SalesChart/CustomChartComponents/CustomTicks/YearTick";
import { abbreviateNumber } from "common/utils/utils";
import { capitalCase } from "change-case";

const SalesComposedChart = ({
  chartData,
  uniqueYearsLength,
  firstSaleDate,
  lastSaleDate,
}) => {
  const chartHeight = 400;

  // * Increase svg viewbox height so that the axis labels are visible
  const svgRef = createRef();
  useEffect(() => {
    const svg = svgRef?.current?.container.getElementsByTagName("svg")[0];
    if (svg) svg.setAttribute("viewBox", `0 0 1030 ${chartHeight + 10}`);
  });
  return (
    <ComposedChart
      width={1000}
      height={chartHeight}
      data={chartData}
      ref={svgRef}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 20,
      }}>
      <XAxis
        dataKey="month"
        angle={-45}
        interval={0}
        label={<CustomXAxisLabel {...{ firstSaleDate }} />}
        {...(uniqueYearsLength > 3
          ? {
              tick: <TickWithoutLabel />,
            }
          : {})}
      />
      <XAxis
        dataKey="year"
        axisLine={false}
        tickLine={false}
        interval={0}
        height={1}
        xAxisId="year"
        tick={<YearTick data={chartData} lastSaleDate={lastSaleDate} />}
      />
      <YAxis
        yAxisId="left"
        label={<CustomYAxis1Label />}
        tickFormatter={abbreviateNumber}
      />
      <YAxis
        yAxisId="right"
        orientation="right"
        label={<CustomYAxis2Label />}
        tickFormatter={abbreviateNumber}
      />
      <Tooltip content={<CustomSalesChartTooltip />} />
      <Legend
        iconType="square"
        iconSize={12}
        height={36}
        verticalAlign="top"
        formatter={(value) => capitalCase(value)}
      />
      <Bar
        yAxisId="left"
        dataKey="total_sales"
        fill="#4C6EF5"
        radius={[1, 1, 0, 0]}
      />
      <Line
        yAxisId="right"
        dataKey="cumulative_sales"
        stroke="#F06595"
        strokeWidth={2}
        dot={<CustomDot />}
        activeDot={<CustomActiveDot />}
      />
    </ComposedChart>
  );
};

export default SalesComposedChart;
