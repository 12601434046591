import * as styles from "./ImportDetail.module.scss";

import EraseImportButton from "./EraseImportButton/EraseImportButton";

export const ImportFooter = ({ handleErase }) => {
  return (
    <footer className={styles.Footer}>
      <div className={styles.Button}>
        <EraseImportButton {...{ handleErase }} />
      </div>
    </footer>
  );
};
