export const options = [
  {
    label: "Recent Searches",
    name: "searches-group",
    value: "recent",
  },
  {
    label: "Bookmarked Searches",
    name: "searches-group",
    value: "saved",
  },
];
