import * as styles from "./PADetail.module.scss";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import DetailResponse from "./DetailResponse/DetailResponse";
import PAPieChart from "components/common/PAPieCharts/PAPieChart";

export const PAPartnerStats = ({
  PADetail,
  PAPartnerStatistics,
  isPieChartDataLoading,
}) => {
  const partnersList = PADetail?.partners_list;

  return (
    <>
      <div className="d-flex col-12 justify-content-between">
        <div className="col-5">
          <span className={styles.PA_Detail_Heading}>
            Partners : {PADetail?.total_partners}
          </span>
          <DetailResponse
            {...{
              itemName: "Name",
              itemCount: "Total Records",
              listItems: partnersList,
            }}
          />
        </div>
        <div className="col-6 my-auto">
          {isPieChartDataLoading ? (
            <CustomLoader containerClasses="col-6 my-auto" />
          ) : (
            <PAPieChart {...{ PAStatistics: PAPartnerStatistics }} />
          )}
        </div>
      </div>
      <hr className="mt-4" />
    </>
  );
};
