import * as styles from "./CountTable.module.scss";

import CustomLoader from "../CustomLoader/CustomLoader";
import InfoIcon from "images/ic_gray_info.svg";
import NoDataFound from "../NoDataFound/NoDataFound";
import Tooltip from "../Tooltip/Tooltip";
import { abbreviateNumber } from "common/utils/utils";

const CountTable = ({
  tableTitle,
  dataItems,
  isLoading,
  extraInfo,
  dataNameKey,
  dataCountKey = "count",
  dataPercentageKey = "percentage",
  title,
}) => {
  return (
    <section className={styles.Count_Table_Card_Container}>
      <div className="d-flex justify-content-between pb-4">
        <span className="Font_H7 Font_Title_Color">{tableTitle}</span>
        {extraInfo && (
          <>
            <img src={InfoIcon} alt="Info" data-tip={extraInfo} />
            <Tooltip />
          </>
        )}
      </div>
      <div>
        {isLoading ? (
          <CustomLoader
            height="50"
            width="50"
            containerClasses={styles.Count_Table_Illustration_Wrapper}
          />
        ) : (
          <CountTableItems
            {...{
              dataItems,
              dataNameKey,
              dataCountKey,
              dataPercentageKey,
              title,
            }}
          />
        )}
      </div>
    </section>
  );
};

export default CountTable;

//***************************/
//*****Local Components******/
//***************************/

const CountTableItems = ({
  dataItems,
  dataNameKey,
  dataCountKey,
  dataPercentageKey,
  title,
}) => {
  if (dataItems?.length === 0) {
    return (
      <NoDataFound
        {...{
          title,
          wrapperClasses: styles.Count_Table_Illustration_Wrapper,
        }}
      />
    );
  }
  return (
    <>
      {dataItems?.map((dataItem, index) => {
        return (
          <div key={index} className={styles.Count_Table_Item_Wrapper}>
            <span className="Font_H9 Font_Subtitle_Color">
              {dataItem[dataNameKey]}
            </span>
            <span className="Font_H8 Font_Title_Color">
              {abbreviateNumber(dataItem[dataCountKey])}
              {dataItem[dataPercentageKey] && (
                <span className="ps-1 Font_H7">
                  ({dataItem[dataPercentageKey]})
                </span>
              )}
            </span>
          </div>
        );
      })}
    </>
  );
};
