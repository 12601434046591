import { useEffect, useState } from "react";

import ChangePasswordFormRows from "./ChangePasswordFormRows";
import { changePassword } from "common/API/userProfileAPIs";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

const ChangePasswordForm = ({ userId }) => {
  const initialSchema = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const [formDetails, setFormDetails] = useState(initialSchema);
  const [arePasswordsSame, setArePasswordsSame] = useState(true);

  const mutation = useMutation(({ userId, data }) =>
    changePassword(userId, data)
  );

  useEffect(() => {
    setArePasswordsSame(
      formDetails?.newPassword === formDetails?.newPasswordConfirm
    );
  }, [formDetails.newPassword, formDetails.newPasswordConfirm]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!arePasswordsSame) return;

    mutation.mutate(
      {
        userId,
        data: {
          old_password: formDetails.oldPassword,
          password: formDetails.newPassword,
          confirm_password: formDetails.newPasswordConfirm,
        },
      },
      {
        onSuccess: (response) => {
          toast.success(
            response?.data?.message || "Password changed successfully"
          );
        },
        onError: () => {},
        onSettled: () => {
          setFormDetails(initialSchema);
        },
      }
    );
  };

  return (
    <form className="Form d-flex flex-column align-items-baseline">
      <span className="Font_H1 Font_Title_Color d-block mb-5 border-bottom-1 text-start w-30">
        Change Password
      </span>
      <ChangePasswordFormRows
        {...{
          setFormDetails,
          formDetails,
          arePasswordsSame,
          handleSubmit,
        }}
      />
    </form>
  );
};

export default ChangePasswordForm;
