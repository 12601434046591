import * as styles from "./SortableTableHeader.module.scss";

import SortingIcon from "components/common/SortingIcon/SortingIcon";
import classNames from "classnames";

const SortableTableHeader = ({
  sortOrder,
  currentOrderField,
  title,
  onClick,
  isLoading,
  isHeaderSticky,
  isHeaderNonClickable,
}) => {
  const headerClasses = classNames("Th cursor-pointer align-baseline ", {
    [styles.Table_Header]: currentOrderField === title,
    [styles.Table_Header_Non_Clickable]: isLoading || isHeaderNonClickable,
    [styles.Table_Header_Sticky]: isHeaderSticky,
  });

  return (
    <th onClick={onClick} className={headerClasses}>
      <div className="d-flex">
        <div className={styles.Table_Title}>{title} </div>
        <div className={styles.SortingIcon}>
          <SortingIcon
            {...(currentOrderField === title ? { sortOrder } : {})}
          />
        </div>
      </div>
    </th>
  );
};

export default SortableTableHeader;
