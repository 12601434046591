import { axiosInstance, getAuthorizationHeaders } from "./api";

import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getExportURLs } from "common/utils/apiUrls";

export const getPartnerExports = ({
  pageParam,
  searchOptions,
  urlParams,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getExportURLs("getPartnerExports", {
    partnerId: urlParams?.partnerId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { page: pageParam, page_size: pageSize, ...searchOptions },
  });
};

export const getUserExports = async ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getExportURLs("getUserExports");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { page: pageParam, page_size: pageSize, ...searchOptions },
  });
};

export const getAllExports = async ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getExportURLs("getAllExports");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { page: pageParam, page_size: pageSize, ...searchOptions },
  });
};
