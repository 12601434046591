import {
  ADMIN_USER_ROLE,
  CARBON_TECH_USER_ROLE,
  DATA_TEAM_USER_ROLE,
} from "common/constants";
import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import { getUserProperty } from "common/utils/authenticationUtils";

const AssignPAButton = ({ setIsModalOpen }) => {
  const allowedUserRoles = [
    DATA_TEAM_USER_ROLE,
    CARBON_TECH_USER_ROLE,
    ADMIN_USER_ROLE,
  ];
  const isUserRoleAuthencated = allowedUserRoles.includes(
    getUserProperty("user_role")
  );

  if (!isUserRoleAuthencated) return null;

  return (
    <Button
      buttonType={ButtonTypes.SECONDARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={() => setIsModalOpen(true)}
      className="me-3">
      <span>Assign Project Activity</span>
    </Button>
  );
};

export default AssignPAButton;
