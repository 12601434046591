import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getDlightKenyaURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getDlightKenyaDataList = ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getDlightKenyaURLs("getDlightKenyaDataList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};

export const getProductModelList = () => {
  const url = getDlightKenyaURLs("getProductModelList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getCPAList = () => {
  const url = getDlightKenyaURLs("getCPAList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getDlightKenyaDataDetail = (recordId) => {
  const url = getDlightKenyaURLs("getDlightKenyaDataDetail", { recordId });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const updateDlightKenyaDataDetail = (recordId, data) => {
  const url = getDlightKenyaURLs("getDlightKenyaDataDetail", { recordId });

  return axiosInstance.patch(url, data, {
    headers: getAuthorizationHeaders(),
  });
};
