import { ALL_YEARS, yearTimeline } from "./SalesChartFilters.constants";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { useState } from "react";

const TimelineFilter = ({ setQueryParams }) => {
  const [timeline, setTimeline] = useState(
    yearTimeline.find((obj) => obj.name === ALL_YEARS)
  );

  const handleTimeline = (timeline) => {
    const selectedYears = timeline?.formatter() || {};
    setQueryParams((value) => ({ ...value, ...selectedYears }));
    setTimeline(timeline);
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <span className="Font_H10 Font_Subtitle_Color pe-3 py-2">Timeline</span>
      <ReactSelectDropdown
        {...{
          selectedOption: timeline,
          setSelectedOption: handleTimeline,
          options: yearTimeline,
          isClearable: false,
          // TODO: Change the placement dynamically based on Screen width
          // menuPlacement: "top",
        }}
      />
    </div>
  );
};

export default TimelineFilter;
