import * as styles from "./SortingIcon.module.scss";

import classNames from "classnames";

const SortingIcon = ({ sortOrder }) => {
  const classes = classNames(styles.Sorting_Icon, {
    [styles[`Sorting_Icon--${sortOrder}`]]: sortOrder,
  });

  return <span className={classes}></span>;
};

export default SortingIcon;
