import classNames from "classnames";
import styles from "./HyperLink.module.scss";

const HyperLink = ({
  onClick,
  iconSrc,
  altText,
  text,
  disabled,
  className = "",
  wrapperClassName = "",
}) => {
  const classes = classNames(
    "Font_H9 Font_Link_Color d-flex gap-2 cursor-pointer",
    styles.HyperLink_Div,
    className
  );

  const wapperClasses = classNames(wrapperClassName, {
    "cursor-not-allowed": disabled,
  });

  return (
    <div className={wapperClasses}>
      <a onClick={onClick} className={classes} disabled={disabled}>
        {iconSrc && <img src={iconSrc} alt={altText} />}
        <span>{text}</span>
      </a>
    </div>
  );
};

export default HyperLink;
