import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import TextField from "components/common/TextField/TextField";
import { useState } from "react";

const DlightKenyaDataSearch = ({
  searchOptions,
  setSearchOptions,
  isLoading,
}) => {
  const [searchId, setSearchId] = useState(searchOptions?.search_id || "");
  const handleChange = () => {
    setSearchOptions((prev) => ({ ...prev, search_id: searchId }));
  };
  return (
    <div className="d-flex gap-3 align-items-center Bg_Card_Color w-75 user-select-none">
      <span className="Font_H8 Font_Title_Color">ID Search:</span>
      <TextField
        {...{
          type: "text",
          value: searchId,
          handleAPICall: handleChange,
          onChange: (e) => setSearchId(e.target.value),
          placeholder: "Search by loan_id and user_id",
          className: "w-50 border-color-light-blue",
        }}
      />
      <Button
        {...{
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          onClick: handleChange,
          isLoading: isLoading,
          isDisabled: isLoading,
          className: "my-1",
        }}>
        Search
      </Button>
    </div>
  );
};

export default DlightKenyaDataSearch;
