import { PRODUCT_TYPES } from "common/constants";
import React from "react";
import SortableTableHeader from "components/common/SortableTableHeader/SortableTableHeader";

const PartnerListTableHeader = ({
  records,
  sortOrder,
  handleSorting,
  currentOrderField,
  isLoading,
  isCountStale,
}) => {
  const isHeaderNonClickable =
    records?.length === 0 || isLoading || isCountStale;
  const isHeaderSticky = true;

  return (
    <thead className="Thead">
      <tr>
        <SortableTableHeader
          {...{
            title: "Partner",
            onClick: () => handleSorting("Partner", ["partner"]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Total Sales of last 12 months",
            onClick: () =>
              handleSorting("Total Sales of last 12 months", ["total_sales"]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Total Preliminary Records",
            onClick: () =>
              handleSorting("Total Preliminary Records", [
                "preliminary_records_count",
              ]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Total Proposed Records",
            onClick: () =>
              handleSorting("Total Proposed Records", [
                "proposed_records_count",
              ]),
            sortOrder,
            isHeaderSticky,
            currentOrderField,
            isLoading,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Total Confirmed Records",
            onClick: () =>
              handleSorting("Total Confirmed Records", [
                "confirmed_records_count",
              ]),
            sortOrder,
            isHeaderSticky,
            currentOrderField,
            isLoading,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: PRODUCT_TYPES.pt4,
            onClick: () =>
              handleSorting(PRODUCT_TYPES.pt4, [PRODUCT_TYPES.pt4]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: PRODUCT_TYPES.pt5,
            onClick: () =>
              handleSorting(PRODUCT_TYPES.pt5, [PRODUCT_TYPES.pt5]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: PRODUCT_TYPES.pt1,
            onClick: () =>
              handleSorting(PRODUCT_TYPES.pt1, [PRODUCT_TYPES.pt1]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: PRODUCT_TYPES.pt2,
            onClick: () =>
              handleSorting(PRODUCT_TYPES.pt2, [PRODUCT_TYPES.pt2]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
          }}
        />
      </tr>
    </thead>
  );
};
const MemoizedPartnerListTableHeader = React.memo(PartnerListTableHeader);

export default MemoizedPartnerListTableHeader;
