import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import SuccessIcon from "images/ic_success.svg";
import routeConstants from "routes/routeConstants";
import { useNavigate } from "react-router-dom";

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <>
      <img src={SuccessIcon} alt="SuccessIcon" className="col-2 mb-3" />
      <p className="Font_H4 mb-3">Password updated successfully!</p>
      <div className="d-flex justify-content-center">
        <Button
          buttonType={ButtonTypes.PRIMARY_SMALL}
          buttonContentType={ButtonContentTypes.ICON_TEXT}
          onClick={() => navigate(routeConstants.Login.path)}
          className="me-3">
          <span>Back to login</span>
        </Button>
      </div>
    </>
  );
};

export default SuccessMessage;
