import Empty from "./States/Empty";
import Error from "./States/Error";
import Success from "./States/Success";

const FileUploadLabel = ({ file, isFileValid }) => {
  return (
    <label htmlFor="file">
      {file ? (
        !isFileValid ? (
          <Error {...{ fileExtension: file?.name.split(".").pop() }} />
        ) : (
          <Success
            {...{
              name: file?.name,
            }}
          />
        )
      ) : (
        <Empty />
      )}
    </label>
  );
};

export default FileUploadLabel;
