export const getNextPageParam = (lastPage) => {
  const nextUrl = lastPage?.data?.next;

  if (!nextUrl) return undefined;

  const regex = /page=\d+/g;

  const [pageString] = nextUrl.match(regex) || [];

  return pageString && pageString.indexOf("=") !== -1
    ? Number(pageString.split("=")[1])
    : undefined;
};
