import DownloadExcludedRecords from "./DownloadExcludedRecords";
import NavigateButton from "components/common/NavigateButton/NavigateButton";
import { VIEWER_USER_ROLE } from "common/constants";
import { getUserProperty } from "common/utils/authenticationUtils";

const Actions = ({ selectedPartnerId }) => {
  const isViewerRoleUser = getUserProperty("user_role") === VIEWER_USER_ROLE;

  return (
    <div className="d-flex gap-2">
      <DownloadExcludedRecords {...{ selectedPartnerId }} />
      {!isViewerRoleUser && (
        <NavigateButton {...{ title: "Import", route: "Import" }} />
      )}
    </div>
  );
};

export default Actions;
