import classNames from "classnames";
import * as styles from "./SearchPillSection.module.scss";

import DataFilterPills from "components/common/DataFilterPills/DataFilterPills";
import RichText from "components/common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import CustomLoader from "../CustomLoader/CustomLoader";

const SearchPillSection = ({ filterNames, groupByNames, children }) => {
  const loaderContainerClasses = classNames(
    styles.Search_Result_Container,
    "align-items-center",
    "justify-content-center",
    "m-auto"
  );

  return (
    <section className={styles.Search_Pill_Section}>
      <section className="d-flex justify-content-between">
        <RichText textType={TextTypes.SUBHEAD2}>Filters</RichText>
        {children}
      </section>
      {!filterNames || !groupByNames ? (
        <CustomLoader
          containerClasses={loaderContainerClasses}
          height={"70px"}
          width={"70px"}
        />
      ) : (
        <>
          <DataFilterPills
            {...{
              obj: {
                ...filterNames?.common_filters,
                ...filterNames?.additional_filters,
              },
              renderWithLabels: true,
            }}
          />
          {groupByNames && Object.values(groupByNames).some((v) => v) && (
            <RichText textType={TextTypes.SUBHEAD2}>Group By</RichText>
          )}
          <DataFilterPills
            {...{ obj: groupByNames, renderWithLabels: false }}
          />
        </>
      )}
    </section>
  );
};

export default SearchPillSection;
