import * as styles from "../UserProfile.module.scss";

import { getUserNameInitials } from "common/utils/authenticationUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const UserProfileLeftSection = () => {
  const user = useSelector((state) => state.user.user);
  const userNameInitials = useMemo(() => getUserNameInitials(), [user]);

  return (
    <section className={styles.User_Profile_Left_Section}>
      <div className={styles.User_Name_Initials_Avatar}>{userNameInitials}</div>
    </section>
  );
};

export default UserProfileLeftSection;
