import React from "react";
import DataFilterPills from "../DataFilterPills/DataFilterPills";
import RichText from "../RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import styles from "./SearchSummary.module.scss";

const SearchSummary = ({ filterNames, groupByNames }) => {
  return (
    <>
      <FilterList {...{ filterNames }} />
      <GroupByList {...{ groupByNames }} />
    </>
  );
};

export default SearchSummary;

const FilterList = ({ filterNames }) => {
  return (
    <>
      <div className={styles.Filters}>
        <RichText textType={TextTypes.SUBHEAD2} className="text-nowrap">
          Filters:
        </RichText>
      </div>
      <div className={styles.Filters_Pills}>
        <DataFilterPills
          {...{
            obj: {
              ...filterNames?.common_filters,
              ...filterNames?.additional_filters,
            },
          }}
          renderWithLabels={true}
          className="ps-2 pt-0"
        />
      </div>
    </>
  );
};

const GroupByList = ({ groupByNames }) => {
  if (!groupByNames?.column1 && !groupByNames?.column2) return null;

  return (
    <>
      <div className={styles.Group_By}>
        <RichText textType={TextTypes.SUBHEAD2} className="text-nowrap">
          Group By:
        </RichText>
      </div>
      <div className={styles.Group_By_Pills}>
        <DataFilterPills
          {...{
            obj: groupByNames,
          }}
          renderWithLabels={false}
          className="ps-2 flex-row gap-0 pt-0"
        />
      </div>
    </>
  );
};
