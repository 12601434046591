import {
  getAbbreviatedUserName,
  getUserProperty,
} from "common/utils/authenticationUtils";

import DashboardTabs from "../common/DashboardTabs/DashboardTabs";
import RichText from "../common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import { ADMIN_USER_ROLE, DATA_TEAM_USER_ROLE } from "common/constants";
import { adminTabs, tabs } from "./UserDashboard.constant";

const UserDashboard = () => {
  const userRole = getUserProperty("user_role");

  const isAdminUser = [DATA_TEAM_USER_ROLE, ADMIN_USER_ROLE].includes(userRole);

  return (
    <section>
      <RichText textType={TextTypes.PAGE_TITLE}>
        User Dashboard for {getAbbreviatedUserName() || ""}
      </RichText>
      <DashboardTabs
        {...{
          tabs: isAdminUser ? adminTabs : tabs,
          dashboard: "user",
        }}
      />
    </section>
  );
};

export default UserDashboard;
