import * as styles from "./LoanIdModalContent.module.scss";

import { useNavigate, useParams } from "react-router-dom";

import LoanIdModalActions from "./LoanIdModalActions";
import RichText from "components/common/RichText/RichText";
import TextField from "components/common/TextField/TextField";
import { TextTypes } from "constants/enumTypes.constants";
import { getCleanDataId } from "common/API/cleanDataAPIs";
import { useState } from "react";

const LoanIdModalContent = ({ setIsModalOpen }) => {
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const [loanId, setLoanId] = useState("");
  const [isSearchRecord, setIsSearchRecord] = useState(false);

  const handleSearch = async () => {
    setIsSearchRecord(true);
    try {
      const response = await getCleanDataId({
        loanId,
        partnerId,
      });
      const cleanDataId = response?.data?.payload?.id;
      cleanDataId &&
        navigate(`/partners/${partnerId}/clean-data/${cleanDataId}`);
    } catch {
      setIsSearchRecord(false);
    }
  };
  return (
    <section className={styles.LoanId_Container}>
      <RichText textType={TextTypes.SUBHEAD2}>Search Clean Record</RichText>
      <div className={styles.LoanId_Field}>
        <TextField
          {...{
            placeholder: "Enter Loan Id",
            handleAPICall: handleSearch,
            onChange: (e) => setLoanId(e.target.value),
          }}
        />
      </div>
      <LoanIdModalActions
        {...{ setIsModalOpen, loanId, handleSearch, isSearchRecord }}
      />
    </section>
  );
};

export default LoanIdModalContent;
