import * as styles from "../CleanDataRecord.module.scss";

const KeyValue = ({ itemKey, itemValue }) => {
  return (
    <>
      <div className={styles.KeyValue_Container}>
        <span className="Font_H9 Font_Subtitle_Color text-start">
          {itemKey}
        </span>
        <span className="Font_H9 Font_Title_Color col-8 text-end">
          {itemValue || "-"}
        </span>
      </div>
    </>
  );
};

export default KeyValue;
