import CustomDateRangePicker from "components/common/CustomDateRangePicker/CustomDateRangePicker";

const DateRangeFilterItem = ({
  label,
  selectedDateRange,
  setSelectedDateRange,
  rangeKey,
}) => {
  return (
    <div className="Filter">
      <span className="Filter_Label">{label}</span>
      <CustomDateRangePicker
        {...{ selectedDateRange, setSelectedDateRange, rangeKey }}
        minDate={new Date(1000, 0, 1)} // Date validation YYYY:MM::DD
      />
    </div>
  );
};

export default DateRangeFilterItem;
