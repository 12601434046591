import classNames from "classnames";
import { formatStartEndDateString } from "common/utils/dateAndTimeUtils";

import CustomLoader from "../CustomLoader/CustomLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import SalesComposedChart from "./SalesComposedChart";

const SalesComposedChartWrapper = ({
  chartData,
  uniqueYearsLength,
  isLoading,
  isError,
  isEmptyData,
  firstSaleDate,
  lastSaleDate,
  queryParams,
  errorMessage,
}) => {
  const containerClasses =
    "d-flex justify-content-center align-items-center p-5 m-5";

  if (isLoading) {
    return <CustomLoader {...{ containerClasses }} />;
  }

  if (isError) {
    const errorClasses = classNames(
      containerClasses,
      "Font_H9 Error_Font_Color"
    );
    return <div className={errorClasses}>{errorMessage}</div>;
  }

  if (isEmptyData) {
    const dateFilterLabel = formatStartEndDateString(
      queryParams?.start_year || "All Years",
      queryParams?.end_year
    );
    const title = `No partner sales data was found for ${
      queryParams?.product_type_name
    } for ${dateFilterLabel}. ${
      (lastSaleDate && `Last sale was in ${lastSaleDate}`) || ""
    }`;

    return (
      <NoDataFound
        wrapperClasses={containerClasses}
        title={title}
        spanClasses="Font_H9 Font_Disabled_Color col-10"
      />
    );
  }

  return (
    <SalesComposedChart
      {...{ chartData, uniqueYearsLength, firstSaleDate, lastSaleDate }}
    />
  );
};

export default SalesComposedChartWrapper;
