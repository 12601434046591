import { useState } from "react";

import * as commonStyles from "styles/CommonDropdownStyles.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { PAGE_IDENTIFIERS } from "common/constants";
import { clearMongoliaFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

import DateRangeFilter from "components/common/DateRangeFilter/DateRangeFilter";
import ProductDropdown from "./ProductDropdown/ProductDropdown";
import ProjectActivityDropdown from "./ProjectActivityDropdown/ProjectActivityDropdown";
import Button from "components/common/Button/Button";

const CollapsibleFilterChildren = ({ setSearchOptions }) => {
  const [product, setProduct] = useState(null);
  const [projectActivity, setProjectActivity] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const pageIdentifier = PAGE_IDENTIFIERS.MONGOlIAN_PROGRAM;
  const buttonTitle = "Clear Filter";

  /**
   * The function `clearFilters` clears all filters and search options for a given page identifier.
   *
   * @param pageIdentifier - The `pageIdentifier` parameter is used to identify the page for which the
   * mongolian filters are being cleared.
   */
  const clearFilters = (pageIdentifier) => {
    clearMongoliaFilters(pageIdentifier);

    setSearchOptions({});
    setProduct(null);
    setProjectActivity(null);
    setStartDate("");
    setEndDate("");
  };

  /* To automatically clear monglian-program filters for a given page identifier. */
  useAutoClearFilter(pageIdentifier, clearMongoliaFilters);

  return (
    <div className="d-flex gap-4 flex-wrap">
      <ProductDropdown
        {...{
          product,
          setProduct,
          setSearchOptions,
          className: commonStyles.Dropdown,
          pageIdentifier,
        }}
      />
      <ProjectActivityDropdown
        {...{
          projectActivity,
          setProjectActivity,
          setSearchOptions,
          className: commonStyles.Dropdown,
          pageIdentifier,
        }}
      />
      <DateRangeFilter
        {...{
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          setSearchOptions,
          className: commonStyles.Date_Picker,
          placeholder: "Install Date",
          pageIdentifier,
        }}
      />
      <Button
        {...{
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          onClick: () => clearFilters(pageIdentifier),
          title: buttonTitle,
        }}>
        <span>{buttonTitle}</span>
      </Button>
    </div>
  );
};

export default CollapsibleFilterChildren;
