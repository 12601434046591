import {
  CURRENT_ORDER_FIELD,
  ORDERING_FIELD,
  SCROLL_POSITION,
  SEARCH_ID_OF_RECORD,
  SORTING_ORDER,
} from "common/constants";
import { useEffect, useState } from "react";

import Actions from "./Actions";
import CollapsibleFilterChildren from "./CollapsibleFilterChildren/CollapsibleFilterChildren";
import CollapsibleTopSection from "components/common/CollapsibleTopSection/CollapsibleTopSection";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import SearchTable from "components/common/SearchTable/SearchTable";
import { getAllSearches } from "common/API/searchAPIs";

const Searches = () => {
  const [searchOptions, setSearchOptions] = useState({});
  const [toggleFilters, setToggleFilters] = useState(false);

  const { partner, ...remainingSearchOptions } = searchOptions;

  //* Remove preserved props from records tab of the search detail page
  useEffect(() => {
    sessionStorage.removeItem(SCROLL_POSITION);
    sessionStorage.removeItem(SEARCH_ID_OF_RECORD);
    sessionStorage.removeItem(SORTING_ORDER);
    sessionStorage.removeItem(CURRENT_ORDER_FIELD);
    sessionStorage.removeItem(ORDERING_FIELD);
  }, []);

  return (
    <>
      <CollapsibleTopSection
        {...{
          searchOptions,
          actionChildren: <Actions />,
          collapsibleFilterChildren: (
            <CollapsibleFilterChildren
              {...{ searchOptions, setSearchOptions }}
            />
          ),
          toggleFilters,
          setToggleFilters,
        }}
      />
      <SearchTable
        {...{
          searchOptions: {
            ...remainingSearchOptions,
            partner_id: partner?.value,
          },
          setAreOptionsDisabled: () => {},
          fetchSearches: getAllSearches,
          showLastRunBy: true,
          extraClass: toggleFilters ? "Table_Height3" : "Table_Height1",
        }}
      />
      <ScrollToTop />
    </>
  );
};

export default Searches;
