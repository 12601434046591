import { useEffect } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { getErrorMessage } from "common/utils/errorUtils";
import { getProductList } from "common/API/mongolianProgramAPIs";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const ProductDropdown = ({
  product,
  setProduct,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  const {
    data: response,
    isError,
    error,
  } = useQuery("mongolia-data-products", getProductList);

  if (isError)
    toast.error(getErrorMessage(error), "Cannot fetch Products list");

  const productList = response?.data?.payload;

  useEffect(() => {
    const storedProject = sessionStorage.getItem(`${pageIdentifier}Product`);
    if (storedProject) {
      setProduct(JSON.parse(storedProject));
    }
  }, [pageIdentifier]);

  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      bid_device_code: product?.value,
    }));
    if (product !== undefined || product !== null) {
      sessionStorage.setItem(
        `${pageIdentifier}Product`,
        JSON.stringify(product)
      );
    }
  }, [product, setSearchOptions, pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: product,
        setSelectedOption: setProduct,
        options: productList,
        customWidth: "300px",
        className,
        placeholder: "BID Device Code",
      }}
    />
  );
};

export default ProductDropdown;
