import ReactTooltip from "react-tooltip";
import styles from "./Tooltip.module.scss";
import PropTypes from "prop-types";

const Tooltip = ({ id, children, ...attributeProps }) => {
  return (
    <ReactTooltip
      id={id}
      effect="solid"
      className={styles.Tooltip}
      arrowColor="#002848"
      backgroundColor="#002848"
      textColor="#ffffff"
      {...attributeProps}>
      {children}
    </ReactTooltip>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  place: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  effect: PropTypes.oneOf(["float", "solid"]),
};

export default Tooltip;
