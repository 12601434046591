import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import PlusIcon from "images/circle_plus.svg";
import { useNavigate } from "react-router-dom";
import routeConstants from "routes/routeConstants";
import PropTypes from "prop-types";

const NavigateButton = ({ title, route }) => {
  const navigate = useNavigate();

  const handleNewImport = () => navigate(routeConstants[route].path);

  return (
    <Button
      buttonType={ButtonTypes.PRIMARY_SMALL}
      buttonContentType={ButtonContentTypes.ICON_TEXT}
      onClick={handleNewImport}
      className="me-3">
      <img src={PlusIcon} alt="plus" />
      <span>{title}</span>
    </Button>
  );
};

NavigateButton.propTypes = {
  title: PropTypes.string.isRequired,
  route: routeConstants
    ? PropTypes.oneOf([Object.keys(routeConstants)]).isRequired
    : PropTypes.any.isRequired,
};
export default NavigateButton;
