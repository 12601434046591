import { axiosInstance, getAuthorizationHeaders } from "./api";

import { getArchiveExcludedRawDataURLs } from "common/utils/apiUrls";

export const getArchiveExcludedRawRecord = ({
  archiveRawDataId,
  dataFilterId,
}) => {
  const url = getArchiveExcludedRawDataURLs("getArchiveExcludedRawRecord", {
    archiveRawDataId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      data_filter_id: dataFilterId,
    },
  });
};
