export const siteAdminLinks = [
  {
    label: "Users",
    link: `${process.env.REACT_APP_ADMIN_URL}/users/user/`,
  },
  {
    label: "Products",
    link: `${process.env.REACT_APP_ADMIN_URL}/partners/product/`,
  },
  {
    label: "Branches",
    link: `${process.env.REACT_APP_ADMIN_URL}/partners/partnerbranch/`,
  },
  {
    label: "General",
    link: process.env.REACT_APP_ADMIN_URL,
  },
  {
    label: "Previous Clean Data Update Imports",
    disabled: true,
  },
];
