import { ReactComponent as Arrow } from "images/nav-dropdown-arrow.svg";
import classNames from "classnames";

const ExpandableItems = ({ items, isExpanded, hasMoreItems }) => {
  const arrowClasses = classNames(
    "ms-2",
    { "rotate-n90": !isExpanded },
    { "ms-3": isExpanded }
  );

  return (
    <div className="text-nowrap">
      <span className="Font_H12 Font_Title_Color">
        {items.join(", ")}
        {hasMoreItems && ","}
        {hasMoreItems && !isExpanded && " ..."}
      </span>
      {hasMoreItems && <Arrow className={arrowClasses} />}
    </div>
  );
};

export default ExpandableItems;
