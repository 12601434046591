import * as commonStyles from "styles/Common.module.scss";

import CollapsibleFilterChildren from "./CollapsibleFilterChildren/CollapsibleFilterChildren";
import CollapsibleTopSection from "components/common/CollapsibleTopSection/CollapsibleTopSection";
import CustomLoader from "components/common/CustomLoader/CustomLoader";
import MongoliaDataTable from "./MongoliaDataTable";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import { getErrorMessage } from "common/utils/errorUtils";
import { getMongoliaDataList } from "common/API/mongolianProgramAPIs";
import { toast } from "react-toastify";
import { useInfiniteQuery } from "react-query";
import { useState } from "react";
import MongoliaDataSearch from "./MongoliaDataSearch";

const MongoliaDataList = () => {
  const [searchOptions, setSearchOptions] = useState({});
  const [toggleFilters, setToggleFilters] = useState(false);

  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["mongolia-data", searchOptions],
      ({ pageParam = 1 }) =>
        getMongoliaDataList({
          pageParam,
          pageSize: 20,
          searchOptions,
        }),
      {
        refetchInterval: Infinity,
      }
    );

  if (isError)
    toast.error(getErrorMessage(error, "Cannot fetch mongolian data"));

  const mongoliaData = data?.pages.flatMap((page) => page.data.results);
  const lastPage = data?.pageParams.at(-1) || 1;
  const noDataFound = !mongoliaData || mongoliaData?.length === 0;

  return (
    <>
      <CollapsibleTopSection
        {...{
          searchOptions,
          extraChildren: (
            <MongoliaDataSearch
              {...{ searchOptions, setSearchOptions, isLoading }}
            />
          ),
          collapsibleFilterChildren: (
            <CollapsibleFilterChildren
              {...{ searchOptions, setSearchOptions }}
            />
          ),
          emptySearchOptionsToConsider: ["bid_device_code", "project_activity"],
          toggleFilters,
          setToggleFilters,
        }}
      />
      <MongoliaDataTable
        {...{
          fetchNextPage,
          lastPage,
          hasNextPage,
          mongoliaData,
          isLoading,
          noDataFound,
          extraClass: noDataFound
            ? ""
            : toggleFilters
            ? "Table_Height3"
            : "Table_Height1",
        }}
      />
      {isLoading && (
        <CustomLoader
          {...{ containerClasses: commonStyles.Table_Body_Loader_Container }}
        />
      )}
      {noDataFound && !isLoading && (
        <NoDataFound
          {...{
            title: "No data found for requested search",
            wrapperClasses: commonStyles.Table_Body_Loader_Container,
          }}
        />
      )}
    </>
  );
};

export default MongoliaDataList;
