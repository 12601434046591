import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import SearchList from "components/common/SearchList/SearchList";
import { getPartnerSearches } from "common/API/searchAPIs";
import { useSelector } from "react-redux";

const SearchTab = ({ dashboardRef }) => {
  const { id: partnerId } = useSelector((state) => state.partner.partner);
  return (
    <>
      <SearchList
        {...{
          currentDashboard: "partner",
          partnerId,
          fetchSearches: getPartnerSearches,
          showLastRunBy: true,
        }}
      />
      <ScrollToTop element={dashboardRef} />
    </>
  );
};

export default SearchTab;
