import * as styles from "./PromotionModal.module.scss";

import {
  ButtonContentTypes,
  ButtonTypes,
  TextTypes,
} from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import ModalCloseButton from "components/common/ModalCloseButton/ModalCloseButton";
import RichText from "components/common/RichText/RichText";
import { promoteFileV2 } from "common/API/api";
import { toast } from "react-toastify";
import { useState } from "react";
import { useMutation } from "react-query";
import { getErrorMessage } from "common/utils/errorUtils";
import { stopPropagation } from "common/utils/utils";

const PromotionModalContent = ({
  numberOfCleanRecords,
  numberOfExcludedRecords = 0,
  setIsModalOpen,
  fileId,
  partnerId,
  refreshImports,
}) => {
  return (
    <section
      className={styles.Promotion_Modal_Content_Container}
      onClick={stopPropagation}>
      <div className={styles.Promotion_Modal_Msg}>
        <RichText textType={TextTypes.SUBHEAD1} className="mb-3">
          Promoting Raw Records
        </RichText>
        <span className="Font_H8 Font_Subtitle_Color" onClick={stopPropagation}>
          You are going to promote {numberOfCleanRecords} clean records to
          Proposed state
          {numberOfExcludedRecords > 0
            ? ` and archive ${numberOfExcludedRecords} excluded records,`
            : ","}
        </span>
        <span className="Font_H8 Font_Subtitle_Color">Are you sure?</span>
      </div>
      <PromotionModalActions
        {...{ setIsModalOpen, fileId, partnerId, refreshImports }}
      />
    </section>
  );
};

export default PromotionModalContent;

const PromotionModalActions = ({
  setIsModalOpen,
  fileId,
  partnerId,
  refreshImports,
}) => {
  return (
    <div
      className={`d-flex justify-content-end ${styles.Promotion_Modal_Actions}`}>
      <ModalCloseButton {...{ setIsModalOpen }} />
      <PromoteActionButton
        {...{ fileId, partnerId, setIsModalOpen, refreshImports }}
      />
    </div>
  );
};

const PromoteActionButton = ({
  fileId,
  partnerId,
  setIsModalOpen,
  refreshImports,
}) => {
  const [isPromoting, setIsPromoting] = useState(false);
  const mutation = useMutation(({ partnerId, fileId }) =>
    promoteFileV2(partnerId, fileId)
  );

  const handlePromotion = async (event) => {
    event.stopPropagation();

    setIsPromoting(true);
    mutation.mutate(
      { partnerId, fileId },
      {
        onSuccess: (response) => {
          toast.success(
            response?.data?.message ||
              "Started moving records to proposed state"
          );
          refreshImports();
        },
        onError: (error) => {
          toast.error(
            getErrorMessage(error, "Error while promoting records to proposed")
          );
        },
        onSettled: () => {
          setIsPromoting(false);
          setIsModalOpen(false);
        },
      }
    );
  };

  return (
    <Button
      {...{
        buttonType: ButtonTypes.PRIMARY_SMALL,
        buttonContentType: ButtonContentTypes.TEXT,
        onClick: handlePromotion,
        isDisabled: isPromoting,
      }}>
      <span>{!isPromoting ? "Promote" : "Promoting"}</span>
    </Button>
  );
};
