import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "common/constants";
import {
  getISOFormatedDateString,
  isValidDate,
} from "common/utils/dateAndTimeUtils";

import CustomDateRangePicker from "components/common/CustomDateRangePicker/CustomDateRangePicker";
import { useEffect } from "react";

const DateRangeFilter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setSearchOptions,
  className,
  placeholder,
  pageIdentifier,
}) => {
  const rangeKey = "selection";
  const selectedDateRange = [
    {
      startDate: startDate ? new Date(startDate) : DEFAULT_START_DATE,
      endDate: endDate ? new Date(endDate) : DEFAULT_END_DATE,
      key: rangeKey,
    },
  ];

  // Fetches and applies a stored dateRange filter.
  useEffect(() => {
    const storedStartDate = sessionStorage.getItem(
      `${pageIdentifier}startDate`
    );
    const storedEndDate = sessionStorage.getItem(`${pageIdentifier}endDate`);
    if (storedStartDate) {
      setStartDate(storedStartDate);
    }
    if (storedEndDate) {
      setEndDate(storedEndDate);
    }
  }, [pageIdentifier]);

  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      start_date: startDate,
      end_date: endDate,
    }));
    if (startDate !== undefined || startDate !== null) {
      sessionStorage.setItem(`${pageIdentifier}startDate`, startDate);
    }
    if (endDate !== undefined || endDate !== null) {
      sessionStorage.setItem(`${pageIdentifier}endDate`, endDate);
    }
  }, [startDate, endDate, setSearchOptions, pageIdentifier]);

  const handleDateRange = (dateRangeArr) => {
    const { startDate, endDate } = dateRangeArr[0];

    const formattedStartDate = isValidDate(startDate)
      ? getISOFormatedDateString(startDate)
      : "";
    const formattedEndDate = isValidDate(endDate)
      ? getISOFormatedDateString(endDate)
      : "";
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  return (
    <CustomDateRangePicker
      {...{
        selectedDateRange,
        setSelectedDateRange: handleDateRange,
        rangeKey,
        wrapperClassName: className,
        minDate: new Date(1000, 0, 1),
        placeholder,
      }}
    />
  );
};

export default DateRangeFilter;
