import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getSearchURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getUserSearches = ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getSearchURLs("getUserSearches");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};

export const getPartnerSearches = ({
  pageParam,
  searchOptions,
  urlParams,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getSearchURLs("getPartnerSearches", {
    partnerId: urlParams?.partnerId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { page: pageParam, page_size: pageSize, ...searchOptions },
  });
};

export const getAllSearches = ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getSearchURLs("getAllSearches");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: { page: pageParam, page_size: pageSize, ...searchOptions },
  });
};

export const getSearchResultRecords = (queryParams) => {
  const { filterId, ...rest } = queryParams;

  const url = getSearchURLs("getSearchResultRecords", {
    dataFilterId: filterId,
  });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: rest,
  });
};
