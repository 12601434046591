import React from "react";
import RichText from "../RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import classNames from "classnames";
import { convertSnakeCaseToTitleCase } from "common/utils/utils";
import ExpandablePill from "../ExpandablePill/ExpandablePill";

const Pill = ({ pill, renderWithLabels, showAllPills }) => {
  if (Array.isArray(pill.value)) {
    return <ExpandablePill {...{ pill }} />;
  }

  const pillClasses = classNames(showAllPills ? "Pill" : "");
  return (
    <div className={pillClasses}>
      {renderWithLabels && (
        <RichText textType={TextTypes.BODY4}>
          {convertSnakeCaseToTitleCase(pill.label)}:&nbsp;
        </RichText>
      )}
      <RichText textType={renderWithLabels ? TextTypes.BODY3 : TextTypes.BODY4}>
        {pill.value}
      </RichText>
    </div>
  );
};

export default Pill;
