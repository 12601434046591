import * as styles from "styles/Common.module.scss";

import { useEffect, createRef } from "react";

import CustomLoader from "../CustomLoader/CustomLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../NoDataFound/NoDataFound";
import SearchTableBody from "./SearchTableBody/SearchTableBody";
import SearchTableHeader from "./SearchTableHeader/SearchTableHeader";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";
import { useInfiniteQuery } from "react-query";
import classNames from "classnames";
import {
  storeScrollPosition,
  fetchStoredScrollPosition,
} from "common/utils/utils";

const SearchTable = ({
  searchOptions,
  setAreOptionsDisabled,
  partnerId,
  isPartnerDashboard,
  fetchSearches,
  showLastRunBy,
  extraClass = "",
}) => {
  const tabelRef = createRef();

  const tableClasses = classNames(
    "Table_Wrapper",
    styles[extraClass],
    styles.List_Scroll_Table
  );
  const containerClasses = { overflow: "default" };

  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["searches", searchOptions, partnerId],
      ({ pageParam = 1 }) =>
        fetchSearches({
          pageParam,
          pageSize: 20,
          searchOptions,
          urlParams: { partnerId },
        }),
      {
        refetchInterval: Infinity,
      }
    );

  useEffect(() => {
    setAreOptionsDisabled(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setTimeout(() => {
      fetchStoredScrollPosition("search-scrollable", "SEARCHES");
    }, 100);
  }, []);

  // * #####################################
  // * Loading, Error and No data states
  // * #####################################
  if (isLoading) {
    return (
      <CustomLoader containerClasses={styles.Tab_Content_Flex_Container} />
    );
  }

  if (isError) toast.error(getErrorMessage(error, "Cannot fetch searches"));

  // * Manipulate paginated response data
  const searches = data?.pages.flatMap((page) => page.data.results);
  const lastPage = data?.pageParams.at(-1) || 1;

  if (!searches || searches?.length === 0) {
    return (
      <NoDataFound
        {...{
          title: "No search found for requested filter",
          wrapperClasses: styles.Tab_Content_Flex_Container,
        }}
      />
    );
  }
  return (
    <section id="search-scrollable" className={tableClasses} ref={tabelRef}>
      <InfiniteScroll
        style={containerClasses}
        loader={<h4 className="Font_H8">Loading...</h4>}
        next={fetchNextPage}
        dataLength={lastPage * searches?.length || 0}
        hasMore={hasNextPage}
        onScroll={(event) => storeScrollPosition(event, "SEARCHES")}
        scrollableTarget="search-scrollable">
        <table className="Table overflow-auto">
          <SearchTableHeader {...{ isPartnerDashboard, showLastRunBy }} />
          <SearchTableBody
            {...{
              searches,
              isPartnerDashboard,
              showLastRunBy,
            }}
          />
        </table>
      </InfiniteScroll>
    </section>
  );
};

export default SearchTable;
