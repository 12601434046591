import * as styles from "./Search.module.scss";

import {
  cleanUp,
  customizeComparison,
  fetchDefaultDropdownOptions,
  prefillFilterForm,
} from "./utils";
import {
  prepareFilterAndGroupBySchema,
  redirectToViewResult,
} from "common/utils/utils";
import { getErrorMessage } from "common/utils/errorUtils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import FilterWrapper from "./FilterWrapper/FilterWrapper";
import GroupByWrapper from "./GroupByWrapper/GroupByWrapper";
import SearchFooter from "./SearchFooter";
import { applyFilters } from "common/API/filtersAndGroupByApi";
import classNames from "classnames";
import { getFilterDetails } from "common/API/filterAndReportListAPI";
import isEqualWith from "lodash/isEqualWith";
import { setAreDataFiltersSame } from "slices/searchSlice";
import { toast } from "react-toastify";

// TODO: Handle prefilling partner for new search
// TODO: if partner is selected from dashboard
const Search = () => {
  // * #######################
  // * States
  // * #######################

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    partner,
    dataPhase,
    productType,
    productModel,
    state,
    branch,
    projectActivityAssigned,
    earliestDate,
    latestDate,
    monitoringPeriod,
    column1,
    column2,
    areDataFiltersSame,
    isGettingPrefilled,
  } = useSelector((state) => state.search);

  const [selectedDataFilter, setSelectedDataFilter] = useState(null);
  const [clearFilters, setClearFilters] = useState(false);
  const [isReappliedFilter, setIsReappliedFilter] = useState(false);
  const [recalculateFilterResult, setRecalculateFilterResult] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isReloaded, setIsReloaded] = useState(false);

  // * #######################
  // * Use effects
  // * #######################

  useEffect(() => {
    // ! Manually clean up the dropdowns
    cleanUp({ dispatch });
    try {
      fetchDefaultDropdownOptions({ dispatch });
    } catch (error) {
      toast.error(getErrorMessage(error, "Fail to fill main dropdown options"));
    }

    return () => {
      cleanUp({ dispatch });
    };
  }, []);

  // * Effect to prefill filters if reapplied
  useEffect(() => {
    (async () => {
      try {
        // On component mount, check if history has any filter applied
        // If so, load the states from that filter
        if (!location?.state?.filterId) {
          setIsLoading(false);
          return;
        }

        const dataFilter = await getFilterDetails(location?.state?.filterId);
        setSelectedDataFilter(dataFilter);
        prefillFilterForm({ dispatch, dataFilter });
        setIsLoading(false);
      } catch (error) {
        toast.error(getErrorMessage(error, "Fail to prefill filter dropdowns"));
      }
    })();
    // On browser reload button, replace state in location
    window.onbeforeunload = () => {
      setIsReloaded(true);
      navigate({ replace: true });
    };
  }, []);

  // * Effect if any of filterIds, groupByNames or SaveFilter is changed nullify
  // * the result
  useEffect(() => {
    if (isGettingPrefilled) return;

    const { filter_ids, group_by_ids } = prepareFilterAndGroupBySchema({
      partner,
      dataPhase,
      state,
      branch,
      productType,
      productModel,
      earliestDate,
      latestDate,
      monitoringPeriod,
      projectActivityAssigned,
      column1,
      column2,
    });

    const areFiltersSame = isEqualWith(
      filter_ids,
      selectedDataFilter?.filter_ids,
      customizeComparison
    );
    const areGroupBysSame = isEqualWith(
      group_by_ids,
      selectedDataFilter?.group_by_ids,
      customizeComparison
    );

    dispatch(setAreDataFiltersSame(areFiltersSame && areGroupBysSame));
  }, [
    partner,
    dataPhase,
    state,
    branch,
    productType,
    productModel,
    earliestDate,
    latestDate,
    monitoringPeriod,
    projectActivityAssigned,
    column1,
    column2,
  ]);

  // * Effect if recalculateFilterResult is true, recalculate the filter result
  useEffect(() => {
    if (!recalculateFilterResult) return;

    handleFilterSubmit();
    setRecalculateFilterResult(false);
  }, [recalculateFilterResult, setRecalculateFilterResult]);

  // * #######################
  // * Handlers
  // * #######################

  const handleFilterSubmit = async () => {
    const { filter_ids, group_by_ids } = prepareFilterAndGroupBySchema({
      partner,
      dataPhase,
      state,
      branch,
      productType,
      productModel,
      earliestDate,
      latestDate,
      monitoringPeriod,
      projectActivityAssigned,
      column1,
      column2,
    });

    const dataFilters = {
      filter_ids,
      group_by_ids,
    };

    try {
      const data = await applyFilters(dataFilters);
      redirectToViewResult(data?.id);
    } catch (error) {
      console.error(error);
    }
  };

  // * Return empty content when, browser reload is pressed
  // * This is done beacuse on using beforeunload event, image are
  // * not getting loaded for some mili seconds
  if (isReloaded) return <></>;

  if (isLoading) {
    const loaderContainerClasses = classNames(
      styles.Filter_And_Group_By_Container,
      "d-flex align-items-center justify-content-center"
    );
    return <CustomLoader containerClasses={loaderContainerClasses} />;
  }

  return (
    <div>
      <div className={styles.Filter_And_Group_By_Container}>
        <FilterWrapper
          {...{
            selectedDataFilter,
            setSelectedDataFilter,
            clearFilters,
            setClearFilters,
            isReappliedFilter,
            setIsReappliedFilter,
          }}
        />
        <GroupByWrapper
          {...{
            setSelectedDataFilter,
            clearFilters,
            setClearFilters,
          }}
        />
      </div>
      <SearchFooter
        {...{
          setClearForm: setClearFilters,
          handleFilterSubmit,
          areDataFiltersSame,
        }}
      />
    </div>
  );
};

export default Search;
