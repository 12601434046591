import { getCleanDataHeaders } from "common/API/adminAPIs";
import { getErrorMessage } from "common/utils/errorUtils";
import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

const CleanDataHeadersDropdown = ({
  updatedColumns,
  setUpdatedColumns,
  ...attributeProps
}) => {
  const {
    data: response,
    isError,
    error,
  } = useQuery("clean-data-headers", getCleanDataHeaders);

  if (isError)
    toast.error(getErrorMessage(error, "Cannot fetch clean data headers"));

  const cleanDataHeaders = response?.data?.payload;

  return (
    <ReactSelectDropdown
      {...{
        isMulti: true,
        value: updatedColumns,
        onChange: setUpdatedColumns,
        options: cleanDataHeaders,
        closeMenuOnSelect: false,
        ...attributeProps,
      }}
    />
  );
};

export default CleanDataHeadersDropdown;
