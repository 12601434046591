import React from "react";
import classNames from "classnames";
import styles from "./RadioButton.module.scss";

const RadioButton = ({
  label,
  name,
  value,
  onChange,
  checked,
  className,
  ...attributeProps
}) => {
  const classes = classNames(styles.Radio_Button_Container, className);
  return (
    <div className={classes}>
      <input
        type="radio"
        id={value}
        {...{ name, value, onChange, checked }}
        {...attributeProps}
      />
      <label htmlFor={value}>{label}</label>
    </div>
  );
};

export default RadioButton;
