import { convertToCompactFormat } from "common/utils/dateAndTimeUtils";
import { useNavigate } from "react-router-dom";

const DlightUgandaDataBody = ({ dlightUgandaData }) => {
  const tdClasses = "Td text-wrap";
  const navigate = useNavigate();

  const handleOnClick = (id) => {
    navigate(`/dlight-uganda/${id}`);
  };

  return (
    <tbody className="Tbody">
      {dlightUgandaData.map((dlightUgandaDataRecord, index) => {
        return (
          <tr
            className="Tr cursor-pointer"
            key={index}
            onClick={() => {
              handleOnClick(dlightUgandaDataRecord?.id);
            }}>
            <td className={tdClasses}>{dlightUgandaDataRecord?.customer_id}</td>
            <td className={tdClasses}>{dlightUgandaDataRecord?.loan_id}</td>
            <td className={tdClasses}>
              {dlightUgandaDataRecord?.customer_name}
            </td>
            <td className={tdClasses}>{dlightUgandaDataRecord?.address}</td>
            <td className={tdClasses}>{dlightUgandaDataRecord?.state}</td>
            <td className={tdClasses}>{dlightUgandaDataRecord?.branch}</td>
            <td className={tdClasses}>
              {dlightUgandaDataRecord?.technology_type}
            </td>
            <td className={tdClasses}>
              {dlightUgandaDataRecord?.product_model}
            </td>
            <td className={tdClasses}>
              {convertToCompactFormat(
                dlightUgandaDataRecord?.installation_date
              )}
            </td>
            <td className={tdClasses}>{dlightUgandaDataRecord?.cpa_number}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default DlightUgandaDataBody;
