import { createSlice } from "@reduxjs/toolkit";

export const partnersByIdSlice = createSlice({
  name: "partnersById",
  initialState: {},
  reducers: {
    setPartnersById: (state, action) => {
      state.partnersById = action.payload;
    },
  },
});

export const { setPartnersById } = partnersByIdSlice.actions;

export default partnersByIdSlice.reducer;
