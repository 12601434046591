import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getMongolianProgramURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getMongoliaDataList = ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getMongolianProgramURLs("getMongoliaDataList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};

export const getMongoliaDataDetail = (recordId) => {
  const url = getMongolianProgramURLs("getMongoliaDataDetail", { recordId });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const updateMongoliaDataDetail = (recordId, data) => {
  const url = getMongolianProgramURLs("getMongoliaDataDetail", { recordId });

  return axiosInstance.patch(url, data, {
    headers: getAuthorizationHeaders(),
  });
};

export const getProductList = () => {
  const url = getMongolianProgramURLs("getProductList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getProjectActivityList = () => {
  const url = getMongolianProgramURLs("getProjectActivityList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};
