import * as commonStyles from "styles/Common.module.scss";
import * as styles from "./ArchiveExcludedRawRecord.module.scss";

import { useLocation, useParams } from "react-router-dom";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import ExcludedDataKeyValues from "./ExcludedDataKeyValues/ExcludedDataKeyValues";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import RecordDetailTitle from "components/common/RecordDetailTitle/RecordDetailTitle";
import { getArchiveExcludedRawRecord } from "common/API/archiveExcludedRawDataAPIs";
import { getErrorMessage } from "common/utils/errorUtils";
import { setSearchDetailRecord } from "slices/searchDetailRecordSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { usePreservedRecordTab } from "common/hooks/usePreservedRecordTab";
import { useQuery } from "react-query";

const ArchiveExcludedRawRecord = () => {
  const location = useLocation();
  const { archiveRawDataId } = useParams();
  const dispatch = useDispatch();
  const {
    scrollPosition,
    searchId,
    sortOrder,
    currentOrderField,
    orderFields,
  } = location?.state || {};

  // Fetch data filter id from current URL
  const dataFilterId = location?.pathname?.split("/")[2];

  const {
    data: response,
    isLoading,
    error,
    isError,
  } = useQuery(
    ["archive-excluded-raw-data", archiveRawDataId, dataFilterId],
    () => getArchiveExcludedRawRecord({ archiveRawDataId, dataFilterId })
  );

  const archiveExcludedRawData = response?.data?.payload;

  // Constants for breadcrumbs of clean record detail page
  const dataPhase = archiveExcludedRawData?.data_phase;
  const partnerName = archiveExcludedRawData?.partner_name;

  // * #####################################
  // * Hooks
  // * #####################################

  /* Setting the loan id, search id, data phase, and partner name in the redux store. */
  useEffect(() => {
    dispatch(
      setSearchDetailRecord({
        loanId: archiveExcludedRawData?.loan_id || "",
        searchId: dataFilterId || "",
        dataPhase: dataPhase || "",
        partnerName: partnerName || "",
      })
    );
  }, [response]);

  // Save scroll position, record list's search id, sorting order, and sorting field in session storage to preserve
  usePreservedRecordTab(
    scrollPosition,
    searchId,
    sortOrder,
    currentOrderField,
    orderFields
  );

  if (isLoading) {
    return (
      <CustomLoader
        containerClasses={commonStyles.Tab_Content_Flex_Container}
      />
    );
  }

  if (!archiveExcludedRawData) {
    return (
      <NoDataFound
        {...{
          title: "No archive excluded raw data record found",
          wrapperClasses: commonStyles.Tab_Content_Flex_Container,
        }}
      />
    );
  }

  if (isError)
    return toast.error(
      getErrorMessage(error, "Failed to fetch archive excluded raw data")
    );

  const {
    file_columns: excludedFileColumns,
    error_indicators_data: errorsOfExcludedData,
    original_data: originalExcludedData,
    special_actions_data: specialActionsData,
    uploaded_file: excludedFileInfo,
  } = archiveExcludedRawData;

  return (
    <>
      <div className={styles.Archive_Excluded_Raw_Data}>
        <RecordDetailTitle
          {...{
            isExcludedRecord: true,
            recordsCustomerName: archiveExcludedRawData?.customer_name,
            recordsLoanId: archiveExcludedRawData?.loan_id,
          }}
        />
      </div>
      <ExcludedDataKeyValues
        {...{
          excludedFileColumns,
          archiveExcludedRawData,
          errorsOfExcludedData,
          originalExcludedData,
          specialActionsData,
          excludedFileInfo,
        }}
      />
    </>
  );
};

export default ArchiveExcludedRawRecord;
