import {
  CURRENT_ORDER_FIELD,
  DATA_PHASE,
  ORDERING_FIELD,
  SCROLL_POSITION,
  SEARCH_ID_OF_RECORD,
  SORTING_ORDER,
} from "common/constants";

import { formatDate } from "common/utils/dateAndTimeUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RecordListTableBody = ({ records, filterId, recordStatus }) => {
  const navigate = useNavigate();
  const tdClasses = "Td text-wrap";

  const handleOnClick = async (dataId) => {
    const scrollPosition = sessionStorage.getItem(SCROLL_POSITION);
    const searchId = sessionStorage.getItem(SEARCH_ID_OF_RECORD);
    const sortOrder = sessionStorage.getItem(SORTING_ORDER);
    const currentOrderField = sessionStorage.getItem(CURRENT_ORDER_FIELD);
    const orderFields = sessionStorage.getItem(ORDERING_FIELD);

    let searchDetailRecordPath = "";

    if ([DATA_PHASE.p1, DATA_PHASE.p2, DATA_PHASE.p3].includes(recordStatus))
      searchDetailRecordPath = `/searches/${filterId}/clean-data/${dataId}`;
    else if (recordStatus === DATA_PHASE.p4)
      searchDetailRecordPath = `/searches/${filterId}/raw-data/${dataId}`;
    else if (recordStatus === DATA_PHASE.p5)
      searchDetailRecordPath = `/searches/${filterId}/archive-excluded-raw-data/${dataId}`;
    else toast.error("No data phase exists for the record");

    navigate(searchDetailRecordPath, {
      state: {
        scrollPosition,
        searchId,
        sortOrder,
        currentOrderField,
        orderFields,
      },
    });
  };

  return (
    <tbody className="Tbody">
      {records &&
        records.map((record) => {
          return (
            <tr
              className="Tr cursor-pointer"
              key={record?.id}
              onClick={() => handleOnClick(record?.id)}>
              <td className="Td">{record?.transaction_id}</td>
              <td className="Td">{record?.customer_id}</td>
              <td className={tdClasses}>{record?.customer_name}</td>
              <td className={tdClasses}>{record?.product_type}</td>
              <td className="Td">{record?.product_model}</td>
              <td className="Td">{formatDate(record?.date)}</td>
              <td className={tdClasses}>{record?.branch}</td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default RecordListTableBody;
