import PropTypes from "prop-types";
import TextField from "components/common/TextField/TextField";

const TextFieldRow = ({
  labelText,
  value,
  setValue,
  isRequired = false,
  isDisabled = false,
  placeholder = "",
  inputId,
  className = "",
  type = "text",
  ...attributeProps
}) => {
  return (
    <div className="Form_Row">
      <label htmlFor={inputId}>
        {labelText} {isRequired && "*"} :
      </label>
      <TextField
        {...{
          value: value,
          onChange: (e) => setValue(e.target.value),
          placeholder,
          className,
          required: isRequired,
          id: inputId,
          type,
          disabled: isDisabled,
          ...attributeProps,
        }}
      />
    </div>
  );
};

TextFieldRow.propTypes = {
  className: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default TextFieldRow;
