import * as commonStyles from "styles/Common.module.scss";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import DlightUgandaDataForm from "./DlightUgandaDataForm";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import { getErrorMessage } from "common/utils/errorUtils";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getDlightUgandaDataDetail } from "common/API/dlightUgandaAPIs";

import { PAGE_IDENTIFIERS } from "common/constants";
import { clearUgandaFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

const DlightUgandaDataDetail = () => {
  const { recordId } = useParams();

  /* To automatically clear dlight-uganda filters for a given page identifier. */
  const pageIdentifier = PAGE_IDENTIFIERS.DLIGHT_UGANDA;
  useAutoClearFilter(pageIdentifier, clearUgandaFilters);

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(["dlight-uganda-data-detail", recordId], () =>
    getDlightUgandaDataDetail(recordId)
  );

  if (isLoading) {
    return (
      <CustomLoader
        containerClasses={commonStyles.Full_Height_Flex_Container}
      />
    );
  }

  if (error?.response?.status === 404) {
    return (
      <NoDataFound
        {...{
          title: `No dlight uganda record with ID ${recordId} found`,
          wrapperClasses: commonStyles.Full_Height_Flex_Container,
        }}
      />
    );
  }

  if (isError) {
    toast.error(
      getErrorMessage(
        error,
        `Cannot fetch requested record with ID ${recordId}`
      )
    );
  }

  return (
    <main className="px-1 py-5">
      <DlightUgandaDataForm
        {...{ record: response?.data?.payload, refetchRecord: refetch }}
      />
    </main>
  );
};

export default DlightUgandaDataDetail;
