import { TextTypes } from "constants/enumTypes.constants";

const RichText = ({ textType, children, className = "" }) => {
  switch (textType) {
    case TextTypes.PAGE_TITLE: {
      return (
        <div
          className={`d-flex justify-content-center p-3 Font_Title_Color Font_H1 ${className}`}>
          {children}
        </div>
      );
    }
    case TextTypes.SUBHEAD1: {
      return (
        <span className={`Font_H3 Font_Subtitle_Color ${className}`}>
          {children}
        </span>
      );
    }
    case TextTypes.HEADING2: {
      return (
        <span className={`Font_H2 Font_Body_Color ${className}`}>
          {children}
        </span>
      );
    }

    case TextTypes.SUBHEAD2: {
      return (
        <span className={`Font_H4 Font_Subtitle_Color ${className}`}>
          {children}
        </span>
      );
    }
    case TextTypes.SUBHEAD3: {
      return (
        <span className={`Font_H5 Font_Subtitle_Color ${className}`}>
          {children}
        </span>
      );
    }
    case TextTypes.BODY1: {
      return (
        <span className={`Font_H9 Font_Disabled_Color ${className}`}>
          {children}
        </span>
      );
    }
    case TextTypes.BODY2: {
      return (
        <span className={`Font_H10 Font_Title_Color ${className}`}>
          {children}
        </span>
      );
    }
    case TextTypes.BODY3: {
      return (
        <span className={`Font_H12 Font_Title_Color ${className}`}>
          {children}
        </span>
      );
    }
    case TextTypes.BODY4: {
      return (
        <span className={`Font_H13 Font_Title_Color ${className}`}>
          {children}
        </span>
      );
    }
    default: {
      return children;
    }
  }
};

export default RichText;
