import * as styles from "./CustomToolTip.module.scss";

const CustomSalesChartTooltip = ({ active, payload }) => {
  if (!(active && payload && payload.length)) return null;

  const { payload: firstPayload } = payload[0];
  const {
    month,
    year,
    total_sales: totalSales,
    cumulative_sales: cumulativeSales,
  } = firstPayload;

  return (
    <div className={styles.Tooltip}>
      <div className={styles.Tooltip_Title}>
        {month} {year}
      </div>
      <p className={styles.Tooltip_Desc}>
        <span className="Font_H13 Font_Title_Color pe-3">Total Sales:</span>
        <span className="Font_H12 Font_Title_Color">
          {Intl.NumberFormat("en-US").format(totalSales)}
        </span>
      </p>
      <p className={styles.Tooltip_Desc}>
        <span className="Font_H13 Font_Title_Color pe-3">
          Cumulative Sales:
        </span>
        <span className="Font_H12 Font_Title_Color">
          {Intl.NumberFormat("en-US").format(cumulativeSales)}
        </span>
      </p>
    </div>
  );
};

export default CustomSalesChartTooltip;
