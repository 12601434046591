import { convertToCompactFormat } from "common/utils/dateAndTimeUtils";
import { useNavigate } from "react-router-dom";

const DlightKenyaDataBody = ({ dlightKenyaData }) => {
  const tdClasses = "Td text-wrap";

  const navigate = useNavigate();
  const handleOnClick = (id) => {
    navigate(`/dlight-kenya/${id}`);
  };

  return (
    <tbody className="Tbody">
      {dlightKenyaData.map((dlightKenyaDataRecord, index) => {
        return (
          <tr
            className="Tr cursor-pointer"
            key={index}
            onClick={() => {
              handleOnClick(dlightKenyaDataRecord?.id);
            }}>
            <td className={tdClasses}>{dlightKenyaDataRecord?.name}</td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.address}</td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.branch}</td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.state_x}</td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.prod_type_x}</td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.prod_model_x}</td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.loan_id}</td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.user_id}</td>
            <td className={tdClasses}>
              {convertToCompactFormat(dlightKenyaDataRecord?.install_date)}
            </td>
            <td className={tdClasses}>{dlightKenyaDataRecord?.cpa_x}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default DlightKenyaDataBody;
