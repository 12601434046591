import {
  downloadFileFromS3,
  formatBytes,
  redirectToViewExport,
} from "common/utils/utils";
import { getErrorMessage } from "common/utils/errorUtils";
import { useEffect, useState } from "react";

import DownloadIcon from "images/ic_download.svg";
import { EXPORT_REPORT_STATUS } from "common/constants";
import RichText from "components/common/RichText/RichText";
import { TextTypes } from "constants/enumTypes.constants";
import { convertToCompactFormat } from "common/utils/dateAndTimeUtils";
import { getSearchExportReports } from "common/API/filterAndReportListAPI";
import styles from "./SearchRecentExports.module.scss";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import { toast } from "react-toastify";
import classNames from "classnames";
import CustomLoader from "components/common/CustomLoader/CustomLoader";

const SearchRecentExports = ({ filterId }) => {
  const [isLoader, setIsLoader] = useState(true);

  const [exportReports, setExportReports] = useState([]);

  useEffect(() => {
    getExportReports(setExportReports, filterId, setIsLoader);

    const getExportReportsInterval = setInterval(() => {
      getExportReports(
        setExportReports,
        filterId,
        setIsLoader,
        getExportReportsInterval
      );
    }, 2000);

    return () => {
      clearInterval(getExportReportsInterval);
    };
  }, []);

  return (
    <div className={styles.Search_Exports_Section}>
      <section className="d-flex justify-content-start p-1">
        <RichText textType={TextTypes.SUBHEAD2}>Recent Exports</RichText>
      </section>
      <SearchExportList {...{ exportReports, isLoader }} />
    </div>
  );
};

export default SearchRecentExports;

//***************************/
//*****Local Utils******/
//***************************/

const getExportReports = async (
  setExportReports,
  filterId,
  setIsLoader,
  getExportReportsInterval = undefined
) => {
  try {
    const response = await getSearchExportReports(filterId, { limit: 5 });
    const data = response.data.payload;
    const reports = data?.reports;
    if (reports) {
      setIsLoader(false);
    }
    setExportReports(reports);

    const uncompletedReports = reports.filter(
      (report) => report.report_status !== "Completed"
    );

    if (uncompletedReports.length === 0) {
      // Clear the interval if there are no uncompleted reports
      getExportReportsInterval && clearInterval(getExportReportsInterval);
    }
  } catch (err) {
    console.error(err);

    toast.error(getErrorMessage(err, "Error getting export reports"));
  } finally {
    setIsLoader(false);
  }
};

//***************************/
//*****Local Components******/
//***************************/

const SearchExportList = ({ exportReports, isLoader }) => {
  const loaderContainerClasses = classNames(
    styles.Search_Result_Container,
    "align-items-center",
    "justify-content-center",
    "my-auto"
  );

  return isLoader ? (
    <CustomLoader
      containerClasses={loaderContainerClasses}
      height={"70px"}
      width={"70px"}
    />
  ) : exportReports.length > 0 ? (
    <section className={styles.Search_Exports_List}>
      {exportReports.map((report, index) => (
        <ExportReport key={index} {...{ report }} />
      ))}
    </section>
  ) : (
    <NoDataFound
      {...{
        title: "No recent exports found",
        wrapperClasses: styles.No_Recent_Exports_Wrapper,
      }}
    />
  );
};

const ExportReport = ({ report }) => {
  const isExportCompleted =
    report?.report_status === EXPORT_REPORT_STATUS.COMPLETED;
  const exportClasses = classNames(styles.Search_Exports_List_Item, {
    [styles.Search_Exports_List_Item_Completed]: isExportCompleted,
  });
  return (
    <div className={exportClasses}>
      <ExportReportSummary {...{ report, isExportCompleted }} />
      <DownloadReportIcon {...{ report, isExportCompleted }} />
    </div>
  );
};

const ExportReportSummary = ({ report, isExportCompleted }) => {
  const exportId = report?.id;
  const recentExplortClasses = classNames(styles.Report_Summary, {
    [styles.Report_Summary_Completed]: isExportCompleted,
  });
  return (
    <div
      className={recentExplortClasses}
      onClick={() =>
        isExportCompleted && exportId && redirectToViewExport(exportId)
      }>
      <span className={styles.Report_Title}>
        {`${report?.report_type}, `}
        {convertToCompactFormat(report?.updated_at) || ""}
      </span>
      <ExportReportStatistics {...{ report, isExportCompleted }} />
    </div>
  );
};

const ExportReportStatistics = ({ report, isExportCompleted }) => {
  return (
    <div className={styles.Report_File_Statistics}>
      {isExportCompleted ? (
        <>
          <div className={styles.Report_Total_Counts}>
            {report.total_count} Rows
          </div>
          <span className={styles.Report_File_Size}>
            {formatBytes(report.file_size)}
          </span>
        </>
      ) : (
        <span className={styles.Report_Status_Text}>
          {report?.report_status}
        </span>
      )}
    </div>
  );
};

const DownloadReportIcon = ({ report, isExportCompleted }) => {
  if (!isExportCompleted) return null;

  return (
    <img
      role="button"
      className="ms-2"
      src={DownloadIcon}
      height="31px"
      width="31px"
      alt="Download File"
      onClick={() => downloadFileFromS3(report?.report_path)}
    />
  );
};
