export const CURRENT_YEAR = "Current Year";
export const LAST_YEAR = "Last Year";
export const LAST_3_YEARS = "Last 3 Years";
export const LAST_5_YEARS = "Last 5 Years";
export const ALL_YEARS = "All Years";

export const yearTimeline = [
  {
    name: CURRENT_YEAR,
    value: CURRENT_YEAR,
    formatter: () => {
      const currentYear = new Date().getFullYear();
      return { start_year: currentYear, end_year: currentYear };
    },
  },
  {
    name: LAST_YEAR,
    value: LAST_YEAR,
    formatter: () => {
      const lastYear = new Date().getFullYear() - 1;
      return { start_year: lastYear, end_year: lastYear };
    },
  },
  {
    name: LAST_3_YEARS,
    value: LAST_3_YEARS,
    formatter: () => {
      const currentYear = new Date().getFullYear();
      return { start_year: currentYear - 2, end_year: currentYear };
    },
  },
  {
    name: LAST_5_YEARS,
    value: LAST_5_YEARS,
    formatter: () => {
      const currentYear = new Date().getFullYear();
      return { start_year: currentYear - 4, end_year: currentYear };
    },
  },
  {
    name: ALL_YEARS,
    value: ALL_YEARS,
    formatter: () => {
      return { start_year: "", end_year: "" };
    },
  },
];

export const defaultProductType = {
  id: 0,
  name: "All Product Types",
  sub_types: [],
};
