import CountTable from "components/common/CountTable/CountTable";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import { sentenceCase } from "change-case";

const TopPerformersCardTables = ({ data, selectedSwitchItem }) => {
  const { top_product_models, top_branches, top_product_types } = data;

  const { [selectedSwitchItem]: topProductModels } = top_product_models;
  const { [selectedSwitchItem]: topBranches } = top_branches;
  const { [selectedSwitchItem]: topProductTypes } = top_product_types;

  const doesDataExist =
    topProductModels?.length && topBranches?.length && topProductTypes?.length;

  return (
    <div className="d-flex align-items-stretch justify-content-center flex-wrap">
      {doesDataExist ? (
        <>
          <CountTable
            {...{
              tableTitle: "Top Branches",
              dataItems: topBranches,
              dataNameKey: "branch",
            }}
          />
          <CountTable
            {...{
              tableTitle: "Top Products",
              dataItems: topProductModels,
              dataNameKey: "product_model",
            }}
          />
          <CountTable
            {...{
              tableTitle: "Top Product Types",
              dataItems: topProductTypes,
              dataNameKey: "product_type",
            }}
          />
        </>
      ) : (
        <NoDataFound
          {...{
            title: `No Sales data found for ${sentenceCase(
              selectedSwitchItem
            ).toLowerCase()}`,
            wrapperClasses: "my-5 py-5",
            spanClasses: "Font_H9",
          }}
        />
      )}
    </div>
  );
};

export default TopPerformersCardTables;
