import styles from "./ReactSelectDropdown.module.scss";

export const reactSelectCustomStyles = {
  container: (provided) => ({
    ...provided,
    backgroundColor: styles.bgDropdownColor,
    border: "0px",
    borderRadius: "6px",
    width: "332px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "8px",
    gap: "5px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: styles.bgDropdownColor,
    border: "0px",
    borderRadius: "6px",
    itemsAlign: "center",
    marginLeft: "14px",
    position: "relative",
    left: "-17.67px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: styles.fontTitleColor,
    textAlign: "left",
    height: "22px",
    position: "relative",
    right: "-8px",
  }),
  multiValue: (provided) => ({
    ...provided,
    color: styles.fontTitleColor,
    textAlign: "left",
    height: "22px",
    position: "relative",
    right: "-8px",
    borderRadius: "1000px",
    backgroundColor: styles.bgNavbarColor,
    "> div": {
      color: styles.fontTitleColor,
      padding: "0 3px 0 6px",
    },
    "> div[role=button]": {
      color: "#4B6478",
      paddingRight: "6px",
      ":hover": {
        backgroundColor: "transparent",
        color: "#4B6478",
      },
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "#AFB4B8",
    textAlign: "left",
    height: "22px",
    position: "relative",
    right: "-8px",
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: "left",
    height: "22px",
    position: "relative",
    right: "-8px",
    fontFamily: styles.fontFamilyKarla,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",

    letterSpacing: "0.2px",

    color: "#AFB4B8",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: styles.bgDropdownColor,
    border: `1px solid ${styles.bgDropdownBorderColor}`,
    borderRadius: "6px",
    fontFamily: styles.fontFamilyKarla,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.2px",
    boxShadow: "none",

    "&:hover": {
      border: `1px solid ${styles.bgDropdownBorderColor}`,
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: styles.bgDropdownColor,
    top: "unset",
    margin: "4px 0px",
    boxShadow:
      "0px 0px 1px rgba(88, 181, 255, 0.25), 0px 4px 8px rgba(88, 181, 255, 0.15)",
  }),
  option: (provided, state) => ({
    ...provided,
    background:
      (state.isSelected && styles.bgDropdownSelectedOptionColor) ||
      (state.isFocused
        ? styles.bgDropdownOptionHoverColor
        : styles.bgDropdownColor),
    zIndex: 1,
    ":active": {
      backgroundColor: "none",
    },
    cursor: state.isDisabled && "not-allowed",
    color: state.isDisabled && "#727374",
  }),
};
