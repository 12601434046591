export const DetailListHeader = ({ itemName, itemCount }) => {
  return (
    <thead className="Thead">
      <tr className="Tr">
        <th className="Th">{itemName}</th>
        <th className="Th">{itemCount}</th>
      </tr>
    </thead>
  );
};
