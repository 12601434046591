import ArchiveRawDataBody from "./ArchiveRawDataBody";
import ArchiveRawDataHeader from "./ArchiveRawDataHeader";
import Collapsible from "react-collapsible";
import { useState } from "react";

const ArchiveRawData = ({
  originalRawDataFileColumns,
  originalRawData,
  originalRawFileInfo,
  originalSpecialActionsData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDisabled = !originalRawData;

  return (
    <Collapsible
      {...{
        trigger: <ArchiveRawDataHeader {...{ isOpen, isDisabled }} />,
        onOpening: () => setIsOpen(true),
        onClosing: () => setIsOpen(false),
        triggerDisabled: isDisabled,
      }}>
      {!isDisabled && (
        <ArchiveRawDataBody
          {...{
            originalRawDataFileColumns,
            originalRawData,
            originalRawFileInfo,
            originalSpecialActionsData,
          }}
        />
      )}
    </Collapsible>
  );
};

export default ArchiveRawData;
