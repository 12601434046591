import * as styles from "./Breadcrumbs.module.scss";

import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { VIEWER_USER_ROLE } from "common/constants";
import classNames from "classnames";
import { getUserProperty } from "common/utils/authenticationUtils";
import { matchPath } from "react-router-dom";
import routeConstants from "routes/routeConstants";
import { routes } from "./Breadcrumbs.constant";
import { setPartner } from "slices/partnerSlice";
import { setSearchDetailRecord } from "slices/searchDetailRecordSlice";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useEffect } from "react";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(getRoutes());

  return (
    <>
      <div className={styles.Breadcrumbs_Wrapper}>
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          const breadcrumbClasses = classNames(styles.Breadcrumbs_Title, {
            [styles.Breadcrumbs_Active]: index === breadcrumbs.length - 1,
          });
          return (
            <span key={match.pathname}>
              <Link
                className={breadcrumbClasses}
                to={getPathsForBreadCrumb(match, breadcrumb)}>
                {breadcrumb}
              </Link>
              {index < breadcrumbs.length - 1 && ">"}
            </span>
          );
        })}
      </div>
    </>
  );
};
export default Breadcrumbs;

const getRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Get values of loan id and search id for breadcrumbs of record detail page
  const loanId = useSelector(
    (state) => state.searchDetailRecord?.searchDetailRecord?.loanId
  );
  const searchId = useSelector(
    (state) => state.searchDetailRecord?.searchDetailRecord?.searchId
  );
  const dataPhase = useSelector(
    (state) => state.searchDetailRecord?.searchDetailRecord?.dataPhase
  );
  const partnerName = useSelector(
    (state) => state.searchDetailRecord?.searchDetailRecord?.partnerName
  );

  useEffect(() => {
    dispatch(
      setSearchDetailRecord({
        loanId,
        searchId,
        dataPhase,
      })
    );
    dispatch(setPartner({ name: partnerName }));
  }, []);

  const currentPath = location.pathname;

  if (currentPath !== routeConstants.Search.path) return routes;

  // *  If current page is Search and user is redirected to that page using edit,
  // *  then show "Edit" in breadcrumb intead of "New"
  const edit = location?.state?.filterId;
  if (!edit) return routes;

  const newRoutes = routes.map((match) =>
    match.path === routeConstants.Search.path
      ? { ...match, breadcrumb: "Edit" }
      : match
  );
  return newRoutes;
};

const getPathsForBreadCrumb = (match, breadcrumb) => {
  const isViewerRoleUser = getUserProperty("user_role") === VIEWER_USER_ROLE;
  const partnerOrSearchId = location?.pathname?.split("/")[2];
  const quickLink = location?.pathname?.split("/")[1];
  let matchPatternOfURL = {};

  // Return the object if current url's pattern is matched with any element's pattern of given array
  // Else return null
  [
    routeConstants.CleanDataRecord.path,
    routeConstants.RawDataRecord.path,
    routeConstants.ArchiveExcludedRawRecord.path,
  ].find((path) => {
    matchPatternOfURL = matchPath(
      {
        path,
        exact: true,
      },
      location.pathname
    );

    return matchPatternOfURL;
  });

  if (quickLink === "partners" && breadcrumb?.props?.children === "Clean data")
    return `/partners/${partnerOrSearchId}`;
  else if (
    !!matchPatternOfURL &&
    ["clean-data", "raw-data", "archive-excluded-raw-data"].includes(
      breadcrumb?.key.split("/")[3]
    )
  ) {
    return `/searches/${partnerOrSearchId}?tab=2`;
  } else if (isViewerRoleUser && breadcrumb?.props?.children === "Home")
    return routeConstants.PartnersList.path;

  return match.pathname;
};
