import FormFooter from "components/common/FormFooter/FormFooter";
import NewImportButton from "./NewImportButton";

const NewImportFooter = ({ handleSubmit, setClearForm, isDisabled }) => {
  return (
    <FormFooter {...{ setClearForm }}>
      <NewImportButton {...{ handleSubmit, isDisabled }} />
    </FormFooter>
  );
};

export default NewImportFooter;
