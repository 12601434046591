import ImportTab from "./ImportTab/ImportTab";
import OverviewTab from "./OverviewTab/OverviewTab";
import SearchTab from "./SearchTab/SearchTab";
import ExportTab from "./ExportTab/ExportTab";

// * Add your components in the content attribute
export const tabs = [
  { id: 1, name: "Overview", content: OverviewTab, showScrollToTop: false },
  { id: 2, name: "Imports", content: ImportTab, showScrollToTop: true },
  { id: 3, name: "Searches", content: SearchTab, showScrollToTop: true },
  { id: 4, name: "Exports", content: ExportTab, showScrollToTop: true },
];
