import pluralize from "pluralize";
import RichText from "../RichText/RichText";

export const SearchResultSummary = ({
  totalCount,
  pivotTableSummary,
  textType,
  recordsSummary,
  className = "",
}) => {
  pluralize.addUncountableRule("Project Activity Assigned");

  return (
    <RichText {...{ textType, className }}>
      <span>{totalCount !== "undefined" ? recordsSummary : ""}</span>
      {Object.entries(pivotTableSummary || {}).map(([column, count]) => (
        <span key={column}>&nbsp;•&nbsp;{pluralize(column, count, true)}</span>
      ))}
    </RichText>
  );
};
