import React from "react";
import * as styles from "components/common/SortableTableHeader/SortableTableHeader.module.scss";

const ExportTableHeader = ({ showCreatedBy }) => {
  return (
    <thead className={`Thead ${styles.Table_Header_Sticky}`}>
      <tr>
        {showCreatedBy ? <th className="Th">Created By</th> : null}
        <th className="Th">Partner</th>
        <th className="Th">Data Phase</th>
        <th className="Th">Format</th>
        <th className="Th">Exported On</th>
        <th className="Th">Additional Filters</th>
        <th className="Th">Counts</th>
        <th className="Th">Status</th>
        <th className="Th">Action</th>
      </tr>
    </thead>
  );
};
export default React.memo(ExportTableHeader);
