import FormFooter from "components/common/FormFooter/FormFooter";
import CleanDataUpdateImportButton from "./CleanDataUpdateImportButton";

const CleanDataUpdateImportFooter = ({
  setClearForm,
  handleSubmit,
  isDisabled,
}) => {
  return (
    <FormFooter {...{ setClearForm }}>
      <CleanDataUpdateImportButton {...{ handleSubmit, isDisabled }} />
    </FormFooter>
  );
};

export default CleanDataUpdateImportFooter;
