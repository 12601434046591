import {
  setColumn1,
  setColumn1List,
  setColumn2,
  setColumn2List,
  setCommonColumnsList,
} from "slices/searchSlice";
import { useDispatch, useSelector } from "react-redux";

import FilterItem from "../../FilterItem/FilterItem";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import styles from "./Column1AndColumn2Filters.module.scss";
import { useEffect } from "react";
import { getGroupByColumnList } from "common/API/filtersAndGroupByApi";

const Column1AndColumn2Filters = () => {
  // * #####################################
  // * Redux States
  // * #####################################
  const {
    column1,
    column2,
    column1List,
    column2List,
    commonColumnsList,
    dataPhase,
  } = useSelector((state) => state.search);

  const dispatch = useDispatch();

  // * ##############################################
  // * USE EFFECTS
  // * ##############################################

  // * Effect to update group by columns list based on selected data phase
  useEffect(() => {
    (async () => {
      const groupByColumns = await getGroupByColumnList(dataPhase?.value);
      dispatch(setColumn1List(groupByColumns));
      dispatch(setColumn2List(groupByColumns));
      dispatch(setCommonColumnsList(groupByColumns));

      // * Reset selected columns if column is not present in options
      const listOfIds = map(groupByColumns, "value");
      if (!listOfIds.includes(column1?.value)) dispatch(setColumn1(""));
      if (!listOfIds.includes(column2?.value)) dispatch(setColumn2(""));
    })();
  }, [dataPhase]);

  // * Use effect to update column 2 based on current column 1
  useEffect(() => {
    const updatedList = commonColumnsList.filter(
      (item) => !isEqual(item, column1)
    );
    dispatch(setColumn2List([...updatedList]));
  }, [column1, commonColumnsList]);

  // * Use effect to update column 1 based on current column 2
  useEffect(() => {
    const updatedList = commonColumnsList.filter(
      (item) => !isEqual(item, column2)
    );
    dispatch(setColumn1List([...updatedList]));
  }, [column2, commonColumnsList]);

  // * #####################################
  // * Handlers
  // * #####################################

  const handleColumn1 = (column1) => {
    dispatch(setColumn1(column1));
  };

  const handleColumn2 = (column2) => {
    dispatch(setColumn2(column2));
  };

  return (
    <>
      <div className={styles.Column_1_Dd}>
        <FilterItem
          label="Column 1"
          type="dropdown"
          selectedOption={column1}
          setSelectedOption={handleColumn1}
          options={column1List}
        />
      </div>
      <div className={styles.Column_2_Dd}>
        <FilterItem
          label="Column 2"
          type="dropdown"
          selectedOption={column2}
          setSelectedOption={handleColumn2}
          options={column2List}
        />
      </div>
    </>
  );
};

export default Column1AndColumn2Filters;
