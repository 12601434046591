import * as styles from "./SiteAdminLinks.module.scss";

import HyperLink from "components/common/HyperLink/HyperLink";
import { ReactComponent as RightArrow } from "images/ic_tail_arrow_right.svg";
import classNames from "classnames";

const SiteAdminLink = ({ disabled, index, link, label }) => {
  const classes = classNames(styles.Links, { "cursor-not-allowed": disabled });
  return (
    <div
      className={classes}
      key={index}
      onClick={() => !disabled && window.open(link, "_blank")}>
      <HyperLink
        className={styles.Links_Title}
        altText="useful-links"
        text={label}
        disabled={disabled}
      />
      {!disabled && <RightArrow id="arrow" alt="right-arrow" />}
    </div>
  );
};

export default SiteAdminLink;
