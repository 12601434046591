import * as styles from "../SearchDetailRecordSection.module.scss";

import NoDataFound from "components/common/NoDataFound/NoDataFound";
import UpdateCountButton from "components/SearchDetail/UpdateCountButton/UpdateCountButton";
const UpdateCountMessage = ({ handleUpdateCount }) => {
  return (
    <div className={styles.Count_Mismatch_Container}>
      <NoDataFound
        {...{
          title:
            "Number of records are stale. Please update the count of records.",
        }}
      />
      <div className="mt-3">
        <UpdateCountButton
          {...{
            handleUpdateCount,
          }}
        />
      </div>
    </div>
  );
};

export default UpdateCountMessage;
