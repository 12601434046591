import PartnerDropdown from "components/common/PartnerDropdown/PartnerDropdown";
const PartnerRow = ({ partner, setPartner, ...attributeProps }) => {
  return (
    <div className="Form_Row">
      <label>Partner: </label>
      <PartnerDropdown
        {...{
          selectedPartner: partner?.partner,
          setSearchOptions: setPartner,
          customWidth: "300px",
          placeholder: "Select a partner",
          ...attributeProps,
        }}
      />
    </div>
  );
};

export default PartnerRow;
