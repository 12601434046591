import * as styles from "./FileUpload.module.scss";

import { ALLOWED_EXTENSIONS, ALLOWED_TYPES } from "common/constants";

import FileUploadInput from "./FileUploadInput";
import FileUploadLabel from "./FileUploadLabel";
import InfoIcon from "../InfoIcon/InfoIcon";
import classNames from "classnames";
import { useEffect } from "react";

const FileUpload = ({
  file,
  setFile,
  isFileValid,
  setIsFileValid,
  inputRef,
  className = "",
}) => {
  // * Hook to check if file has valid extension
  useEffect(() => {
    if (!file) return;

    const isExtensionValid = ALLOWED_EXTENSIONS.exec(file.name);
    const isMimeTypeValid = ALLOWED_TYPES.exec(file.type);

    setIsFileValid(!!(isExtensionValid && isMimeTypeValid));
  }, [file]);

  const wrapperClasses = classNames(styles.File_Upload_Container, className);

  return (
    <div className="d-flex gap-4 align-items-center">
      <div className={wrapperClasses}>
        <FileUploadInput {...{ setFile, inputRef }} />
        <FileUploadLabel {...{ file, isFileValid }} />
      </div>
      <InfoIcon msg="Only .xlsx, .xls and .csv files are allowed" />
    </div>
  );
};

export default FileUpload;
