import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";

const CreateNewExportButton = ({ handleGenerateReport }) => {
  return (
    <Button
      buttonType={ButtonTypes.PRIMARY_SMALL}
      buttonContentType={ButtonContentTypes.TEXT}
      onClick={handleGenerateReport}
      className="me-3">
      <span>Create New Export</span>
    </Button>
  );
};

export default CreateNewExportButton;
