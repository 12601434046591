export const ButtonTypes = {
  PRIMARY_LARGE: "primary_large",
  SECONDARY_LARGE: "secondary_large",
  DANGER_LARGE: "danger_large",
  GHOST_LARGE: "ghost_large",
  GHOST_DANGER_LARGE: "ghost_danger_large",
  PRIMARY_SMALL: "primary_small",
  SECONDARY_SMALL: "secondary_small",
  DANGER_SMALL: "danger_small",
  GHOST_SMALL: "ghost_small",
  GHOST_DANGER_SMALL: "ghost_danger_small",
};

export const ButtonContentTypes = {
  TEXT: "text",
  ICON_TEXT: "icon_text",
  ICON: "icon",
};

export const HyperLinkTypes = {
  LARGE: "large",
  SMALL: "small",
};

export const TextTypes = {
  PAGE_TITLE: "page_title",
  HEADING2: "heading2",
  SUBHEAD1: "subhead1",
  SUBHEAD2: "subhead2",
  SUBHEAD3: "subhead3",
  BODY1: "body1",
  BODY2: "body2",
  BODY3: "body3",
  BODY4: "body4",
};
