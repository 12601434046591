import * as styles from "./OverviewTab.module.scss";

import HyperLink from "components/common/HyperLink/HyperLink";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import routeConstants from "routes/routeConstants";
import { useNavigate } from "react-router-dom";

const ActivePartner = ({ activePartners }) => {
  const navigate = useNavigate();
  const handlePartnerClick = (partner) => {
    navigate(`${routeConstants.PartnersList.path}/${partner.partner_id}`);
  };
  return (
    <div className={styles.Active_Partner_Container}>
      <div className="p-3">
        <h4 className="Font_H4">Active Partners</h4>
      </div>

      {activePartners?.length === 0 ? (
        <NoDataFound
          {...{
            title: "No active partners found",
            wrapperClasses: styles.Empty_Content_Container,
          }}
        />
      ) : (
        <div className="Bg_Card_Color p-3">
          <PartnerList {...{ activePartners, handlePartnerClick }} />
        </div>
      )}
    </div>
  );
};
export default ActivePartner;

const PartnerList = ({ activePartners, handlePartnerClick }) => {
  return (
    <>
      {activePartners?.map((partner) => {
        return (
          <div key={partner.partner_id} className={styles.Active_Partner_Item}>
            <HyperLink
              onClick={() => handlePartnerClick(partner)}
              text={partner.partner_name}
            />
          </div>
        );
      })}
    </>
  );
};
