import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getDlightUgandaURLs } from "common/utils/apiUrls";
import { axiosInstance, getAuthorizationHeaders } from "./api";

export const getDlightUgandaDataList = ({
  pageParam,
  searchOptions,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const url = getDlightUgandaURLs("getDlightUgandaDataList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
    params: {
      page: pageParam,
      page_size: pageSize,
      ...searchOptions,
    },
  });
};

export const getProductModelList = () => {
  const url = getDlightUgandaURLs("getProductModelList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getCPAList = () => {
  const url = getDlightUgandaURLs("getCPAList");

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const getDlightUgandaDataDetail = (recordId) => {
  const url = getDlightUgandaURLs("getDlightUgandaDataDetail", { recordId });

  return axiosInstance.get(url, {
    headers: getAuthorizationHeaders(),
  });
};

export const updateDlightUgandaDataDetail = (recordId, data) => {
  const url = getDlightUgandaURLs("getDlightUgandaDataDetail", { recordId });

  return axiosInstance.patch(url, data, {
    headers: getAuthorizationHeaders(),
  });
};
