import ModalCloseButton from "components/common/ModalCloseButton/ModalCloseButton";
import SearchCleanRecordButton from "./SearchCleanRecordButton";

const LoanIdModalActions = ({
  setIsModalOpen,
  loanId,
  handleSearch,
  isSearchRecord,
}) => {
  return (
    <div className="pt-4 d-flex justify-content-end">
      <ModalCloseButton {...{ setIsModalOpen }} />
      <SearchCleanRecordButton {...{ handleSearch, loanId, isSearchRecord }} />
    </div>
  );
};

export default LoanIdModalActions;
