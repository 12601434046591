import * as commonStyles from "styles/Common.module.scss";
import * as styles from "./CleanDataRecord.module.scss";

import { useLocation, useParams } from "react-router-dom";

import ArchiveRawData from "./ArchiveRawData/ArchiveRawData";
import CleanDataKeyValues from "./CleanDataKeyValues";
import CustomLoader from "components/common/CustomLoader/CustomLoader";
import NoDataFound from "components/common/NoDataFound/NoDataFound";
import RecordDetailTitle from "components/common/RecordDetailTitle/RecordDetailTitle";
import { getCleanDataRecord } from "common/API/cleanDataAPIs";
import { getErrorMessage } from "common/utils/errorUtils";
import { setSearchDetailRecord } from "slices/searchDetailRecordSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { usePreservedRecordTab } from "common/hooks/usePreservedRecordTab";
import { useQuery } from "react-query";

const CleanDataRecord = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { cleanDataId } = useParams();

  const {
    scrollPosition,
    searchId,
    sortOrder,
    currentOrderField,
    orderFields,
  } = location?.state || {};

  // Fetch data filter id from current URL
  const dataFilterId = location?.pathname?.split("/")[2];

  const {
    data: response,
    isLoading,
    error,
    isError,
  } = useQuery(["clean-data-record", cleanDataId, dataFilterId], () =>
    getCleanDataRecord({ cleanDataId, dataFilterId })
  );

  const cleanData = response?.data?.payload;

  // Constants for breadcrumbs of clean record detail page
  const dataPhase = cleanData?.data_phase;
  const partnerName = cleanData?.partner_name;

  // * #####################################
  // * Hooks
  // * #####################################

  /* Setting the loan id, search id, data phase, and partner name in the redux store. */
  useEffect(() => {
    dispatch(
      setSearchDetailRecord({
        loanId: cleanData?.loan_id || "",
        searchId: dataFilterId || "",
        dataPhase: dataPhase || "",
        partnerName: partnerName || "",
      })
    );
  }, [response]);

  // Save scroll position, record list's search id, sorting order, and sorting field in session storage to preserve
  usePreservedRecordTab(
    scrollPosition,
    searchId,
    sortOrder,
    currentOrderField,
    orderFields
  );

  if (isLoading) {
    return (
      <CustomLoader
        containerClasses={commonStyles.Tab_Content_Flex_Container}
      />
    );
  }

  if (!cleanData) {
    return (
      <NoDataFound
        {...{
          title: "No clean data record found",
          wrapperClasses: commonStyles.Tab_Content_Flex_Container,
        }}
      />
    );
  }

  if (isError)
    return toast.error(getErrorMessage(error), "Failed to fetch clean data");

  const {
    file_columns: originalRawDataFileColumns,
    original_raw_special_actions_data: originalSpecialActionsData,
    original_raw_data: originalRawData,
    original_raw_file: originalRawFileInfo,
  } = cleanData;

  return (
    <>
      <div className={styles.Clean_Data}>
        <RecordDetailTitle
          {...{
            recordsLoanId: cleanData?.loan_id,
            recordsFileInfo: originalRawFileInfo,
          }}
        />
      </div>
      <div className="pb-5">
        <CleanDataKeyValues {...{ cleanData }} />
        <ArchiveRawData
          {...{
            originalRawDataFileColumns,
            originalRawData,
            originalRawFileInfo,
            originalSpecialActionsData,
          }}
        />
      </div>
    </>
  );
};

export default CleanDataRecord;
