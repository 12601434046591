import { useEffect } from "react";

const useOutsideClick = (ref, callback, refsToExclude = []) => {
  useEffect(() => {
    const handleClickOutside = (evt) => {
      const doNotCallCallback = refsToExclude.some(
        (refToExclude) =>
          refToExclude.current && refToExclude.current.contains(evt.target)
      );

      if (
        ref.current &&
        !ref.current.contains(evt.target) &&
        !doNotCallCallback
      ) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export default useOutsideClick;
