import * as commonStyles from "styles/Common.module.scss";
import * as styles from "./ProjectActivityList.module.scss";

import CustomLoader from "components/common/CustomLoader/CustomLoader";
import MemoizedProjectActivityListTableHeader from "components/ProjectActivityList/ProjectActivityListTableHeader";
import ProjectActivityListTableBody from "./ProjectActivityListTableBody/ProjectActivityListTableBody";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import classNames from "classnames";
import { getProjectActivityListWithDetails } from "common/API/projectActivityApi";
import { useSortableTable } from "common/hooks/useSortableTable";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, createRef } from "react";
import {
  storeScrollPosition,
  fetchStoredScrollPosition,
} from "common/utils/utils";

const ProjectActivityList = () => {
  const tableRef = createRef();

  const tableclasses = classNames(
    "Table_Wrapper",
    "position-relative",
    styles.List_Scroll_Table
  );
  const containerClasses = { overflow: "default" };

  const {
    isLoading,
    data: response,
    isError,
    error,
    sortOrder,
    currentOrderField,
    handleSorting,
  } = useSortableTable(
    "project-activities-with-list",
    getProjectActivityListWithDetails,
    "asc",
    "VPA Code",
    ["code"]
  );

  useEffect(() => {
    fetchStoredScrollPosition(
      "scrollable-project-activities",
      "PROJECT_ACTIVITIES"
    );
  }, []);

  const headerClasses = classNames(
    styles.Project_Activity_List,
    "position-absolute"
  );

  const projectActivityList = response?.data;
  const lastPage = response?.pageParams?.at(-1) || 1;

  return (
    <section
      id="scrollable-project-activities"
      className={tableclasses}
      ref={tableRef}>
      <hr className={headerClasses} />
      <InfiniteScroll
        style={containerClasses}
        loader={<h4 className="Font_H8">Loading...</h4>}
        dataLength={lastPage * projectActivityList?.length || 0}
        onScroll={(event) => storeScrollPosition(event, "PROJECT_ACTIVITIES")}
        scrollableTarget="scrollable-project-activities">
        <table className="Table overflow-auto">
          <MemoizedProjectActivityListTableHeader
            {...{ sortOrder, handleSorting, currentOrderField, isLoading }}
          />
          {!isLoading && (
            <ProjectActivityListTableBody
              {...{ projectActivityList, error, isError }}
            />
          )}
        </table>
      </InfiniteScroll>
      <ScrollToTop />
      {isLoading && (
        <CustomLoader
          containerClasses={commonStyles.Table_Body_Loader_Container}
        />
      )}
    </section>
  );
};

export default ProjectActivityList;
