import React from "react";

const SearchPivotTableBody = ({ getTableBodyProps, rows, prepareRow }) => {
  return (
    <section {...getTableBodyProps()} className="body">
      {rows.map((row, index) => {
        prepareRow(row);
        return <SearchPivotTableBodyRow key={index} {...{ row }} />;
      })}
    </section>
  );
};

export default SearchPivotTableBody;

// * #####################################
// * Local Components
// * #####################################
const SearchPivotTableBodyRow = ({ row }) => {
  return (
    <div {...row.getRowProps()} className="tr">
      {row.cells.map((cell, index) => {
        return (
          <div {...cell.getCellProps()} className="td" key={index}>
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );
};
