import Logo from "images/mec_logo.png";
import ResetPasswordButton from "./ResetPasswordButton";
import ResetPasswordTextFields from "./ResetPasswordTextFields";
import { useState } from "react";

const ResetPasswordForm = ({
  passwordUpdated,
  setPasswordUpdated,
  isTokenInvalid,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <img src={Logo} alt="logo" />
      <h3 className="h3 text-center my-3">Create a new password</h3>
      <ResetPasswordTextFields {...{ setPassword, setConfirmPassword }} />
      {!isTokenInvalid && errorMessage && (
        <p className="text-danger Font_H7  my-3 mx-auto col-6">
          {errorMessage}
        </p>
      )}
      <ResetPasswordButton
        {...{
          password,
          confirmPassword,
          passwordUpdated,
          setErrorMessage,
          setPasswordUpdated,
        }}
      />
    </>
  );
};

export default ResetPasswordForm;
