import DashboardTabFilters from "components/common/DashboardTabFilters/DashboardTabFilters";
import ListTopSection from "components/common/ListTopSection/ListTopSection";
import SearchButton from "components/common/SearchButton/SearchButton";
import SearchTable from "components/common/SearchTable/SearchTable";
import { options } from "./SearchTab.constant";
import { useState } from "react";

const SearchList = ({
  currentDashboard,
  partnerId,
  fetchSearches,
  showLastRunBy,
}) => {
  const [selectedOption, setSelectedOption] = useState("recent");
  const [searchOptions, setSearchOptions] = useState({});
  const [areOptionsDisabled, setAreOptionsDisabled] = useState(false);

  const isPartnerDashboard = currentDashboard === "partner";

  const handleOptionChange = (e) => {
    const targetValue = e.target.value;
    setSelectedOption(targetValue);
    setSearchOptions(targetValue === "recent" ? {} : { is_bookmarked: "True" });
  };

  return (
    <>
      <ListTopSection
        actionChildren={<SearchButton />}
        filterChildren={
          currentDashboard !== "partner" && (
            <DashboardTabFilters
              {...{
                options,
                selectedOption,
                handleOptionChange,
                areOptionsDisabled,
              }}
            />
          )
        }
      />
      <SearchTable
        {...{
          searchOptions,
          setAreOptionsDisabled,
          partnerId,
          isPartnerDashboard,
          fetchSearches,
          showLastRunBy,
          extraClass: "Table_Height2",
        }}
      />
    </>
  );
};
export default SearchList;
