import InfiniteScroll from "react-infinite-scroll-component";
import DlightUgandaDataBody from "./DlightUgandaDataBody";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import DlightUgandaDataHeader from "./DlightUgandaDataHeader";
import classNames from "classnames";
import { useEffect, createRef } from "react";
import * as styles from "./DlightUganda.module.scss";
import {
  storeScrollPosition,
  fetchStoredScrollPosition,
} from "common/utils/utils";

const DlightUgandaDataTable = ({
  fetchNextPage,
  lastPage,
  hasNextPage,
  dlightUgandaData,
  isLoading,
  noDataFound,
  extraClass,
}) => {
  const tableRef = createRef();

  const tableclasses = classNames(
    "Table_Wrapper",
    styles[extraClass],
    styles.List_Scroll_Table
  );
  const containerClasses = { overflow: "default" };

  useEffect(() => {
    setTimeout(() => {
      fetchStoredScrollPosition("scrollable-table", "DLIGHT_UGANDA");
    }, 100);
  }, []);

  return (
    <section id="scrollable-table" className={tableclasses} ref={tableRef}>
      <InfiniteScroll
        style={containerClasses}
        loader={!isLoading && <h4 className="Font_H8">Loading...</h4>}
        next={fetchNextPage}
        dataLength={isLoading ? 0 : lastPage * dlightUgandaData.length}
        hasMore={hasNextPage}
        onScroll={(event) => storeScrollPosition(event, "DLIGHT_UGANDA")}
        scrollableTarget="scrollable-table">
        <table className="Table overflow-auto">
          <DlightUgandaDataHeader />
          {!isLoading && !noDataFound && (
            <DlightUgandaDataBody {...{ dlightUgandaData }} />
          )}
        </table>
      </InfiniteScroll>
      <ScrollToTop />
    </section>
  );
};

export default DlightUgandaDataTable;
