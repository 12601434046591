import * as styles from "../ArchiveExcludedRawRecord.module.scss";

import FileInfoCard from "components/common/FileInfoCard/FileInfoCard";
import RecordDetailKeyValue from "components/common/RecordDetailKeyValue/RecordDetailKeyValue";
import { objectWithTruthyValues } from "common/utils/utils";

const ExcludedDataKeyValues = ({
  excludedFileColumns,
  archiveExcludedRawData,
  errorsOfExcludedData,
  originalExcludedData,
  specialActionsData,
  excludedFileInfo,
}) => {
  return (
    <div className="pb-5">
      <div className={styles.Excluded_Data_Reason}>
        <p className={styles.Excluded_Data_Reason_Heading}>Exclusion Reason</p>
        <p className="Font_H9 Font_Subtitle_Color m-3">
          {archiveExcludedRawData?.exclusion_reason}
        </p>
      </div>
      <RecordDetailKeyValue
        {...{
          dataCardHeading: "Errors",
          recordData: objectWithTruthyValues(errorsOfExcludedData),
        }}
      />
      <RecordDetailKeyValue
        {...{
          dataCardHeading: "Archive excluded raw data",
          recordData: originalExcludedData,
          recordDataFileColumns: excludedFileColumns,
        }}
      />
      <RecordDetailKeyValue
        {...{
          dataCardHeading: "Special actions data",
          recordData: objectWithTruthyValues(specialActionsData),
        }}
      />
      <div className={styles.File_Info_Container}>
        <FileInfoCard
          {...{
            fileIdKey: "uploaded_file_id",
            filePathKey: "uploaded_file_path",
            fileNameKey: "uploaded_file_name",
            fileInfo: excludedFileInfo,
          }}
        />
      </div>
    </div>
  );
};

export default ExcludedDataKeyValues;
