import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import { resetPassword } from "common/API/authenticationAPIs";
import { useParams } from "react-router-dom";

const ResetPasswordButton = ({
  password,
  confirmPassword,
  passwordUpdated,
  setErrorMessage,
  setPasswordUpdated,
}) => {
  const { user_id, token } = useParams();

  const handleOnClick = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Please make sure your passwords match.");
      return;
    }

    try {
      await resetPassword({
        user_id,
        token,
        password,
        confirm_password: confirmPassword,
      });

      setPasswordUpdated(true);
    } catch (error) {
      setPasswordUpdated(false);
    }
  };

  return (
    <div className="mt-4 d-flex justify-content-center">
      <Button
        buttonType={ButtonTypes.PRIMARY_SMALL}
        buttonContentType={ButtonContentTypes.ICON_TEXT}
        onClick={handleOnClick}
        className="me-3"
        isDisabled={!password || passwordUpdated}>
        <span>Reset Password</span>
      </Button>
    </div>
  );
};

export default ResetPasswordButton;
