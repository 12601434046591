import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";
import TextFieldRow from "components/common/FormRows/TextFieldRow/TextFieldRow";

const ChangePasswordFormRows = ({
  setFormDetails,
  formDetails,
  arePasswordsSame,
  handleSubmit,
}) => {
  return (
    <div className="d-flex flex-column align-items-end">
      <TextFieldRow
        {...{
          value: formDetails.oldPassword,
          setValue: (value) => {
            setFormDetails((prev) => ({ ...prev, oldPassword: value }));
          },
          labelText: "Old Password",
          isRequired: true,
          inputId: "old_password",
          type: "password",
        }}
      />
      <TextFieldRow
        {...{
          value: formDetails.newPassword,
          setValue: (value) => {
            setFormDetails((prev) => ({ ...prev, newPassword: value }));
          },
          labelText: "New Password",
          isRequired: true,
          inputId: "new_password",
          type: "password",
        }}
      />
      <TextFieldRow
        {...{
          value: formDetails.newPasswordConfirm,
          setValue: (value) => {
            setFormDetails((prev) => ({
              ...prev,
              newPasswordConfirm: value.trim(),
            }));
          },
          labelText: "Confirm New Password",
          isRequired: true,
          inputId: "confirm_new_password",
          type: "password",
        }}
      />
      <Button
        {...{
          onClick: handleSubmit,
          type: "submit",
          isDisabled:
            !formDetails?.oldPassword?.trim() ||
            !formDetails?.newPassword?.trim() ||
            !formDetails?.newPasswordConfirm?.trim() ||
            !arePasswordsSame,
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
        }}>
        Change Password
      </Button>
    </div>
  );
};

export default ChangePasswordFormRows;
