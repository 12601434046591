import * as styles from "./ArchiveRawData.module.scss";

import ArchiveRawDataKeyValue from "./ArchiveRawDataKeyValue";
import FileInfoCard from "components/common/FileInfoCard/FileInfoCard";
import classNames from "classnames";
import { objectWithTruthyValues } from "common/utils/utils";

const ArchiveRawDataBody = ({
  originalRawDataFileColumns,
  originalRawData,
  originalRawFileInfo,
  originalSpecialActionsData,
}) => {
  const wrapperClasses = classNames(
    "mx-auto my-0",
    styles.Archive_Raw_Data_Body_Wrapper
  );

  return (
    <section className={wrapperClasses}>
      <ArchiveRawDataKeyValue
        {...{
          title: "Raw Data",
          archiveRawData: originalRawData,
          archiveRawDataFileColumns: originalRawDataFileColumns,
        }}
      />
      <ArchiveRawDataKeyValue
        {...{
          title: "Approvals",
          archiveRawData: objectWithTruthyValues(originalSpecialActionsData),
        }}
      />
      {originalRawFileInfo?.uploaded_file_id && (
        <FileInfoCard
          {...{
            fileIdKey: "uploaded_file_id",
            filePathKey: "uploaded_file_path",
            fileNameKey: "uploaded_file_name",
            fileInfo: originalRawFileInfo,
          }}
        />
      )}
    </section>
  );
};

export default ArchiveRawDataBody;
