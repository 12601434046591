import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";

import Button from "components/common/Button/Button";

const UpdateCountButton = ({ handleUpdateCount }) => {
  return (
    <div className="d-flex justify-content-center align-items-center ms-4">
      <Button
        buttonContentType={ButtonContentTypes.TEXT}
        onClick={handleUpdateCount}
        buttonType={ButtonTypes.SECONDARY_SMALL}>
        Update Count
      </Button>
    </div>
  );
};

export default UpdateCountButton;
