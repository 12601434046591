import Moment from "react-moment";
import { formatDuration } from "date-fns";
import moment from "moment";

export const getDateObjectFromString = (date) => {
  if (date === "") {
    return "";
  }
  return new Date(date);
};

export const getISOFormatedDateString = (date) => {
  if (!isValidDate(date)) return null;

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  let iso_format_date = year + "-" + month + "-" + dt;
  return iso_format_date;
};

export const convertTimeStampToHumanReadable = (timestamp) => {
  // convert given timestamp to human readable format date and time

  // If timestamp is undefined or null, return empty string
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);

  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const strTime = hours + ":" + minutes + " " + ampm;

  const display_date = `${date.getDate()} ${date.toLocaleString("default", {
    month: "short",
  })} ${date.getFullYear()}`;

  return `${display_date} ${strTime}`;
};

/**
 * Convert a date to a string in the format of "day month year"
 * @returns The date in the format of `DD MMM YYYY`
 */
export const convertToCompactFormat = (
  datetime,
  considerRelativeTime = false
) => {
  // If timestamp is undefined or null, return empty string
  if (!datetime) return "";

  const dateObj = new Date(datetime);
  const currentYear = new Date().getFullYear();
  const isNotCurrentYear = dateObj.getFullYear() !== currentYear;

  const date = dateObj.getDate();
  const month = dateObj.toLocaleString("default", {
    month: "short",
  });

  const year = isNotCurrentYear ? dateObj.getFullYear() : "";
  return considerRelativeTime && !isNotCurrentYear
    ? moment(dateObj).fromNow()
    : `${date} ${month} ${year}`;
};

export const convertTimetoSeconds = (time) => {
  if (!time) {
    return;
  }

  // convert HH:MM:SS.SSS to seconds
  let time_array = time.split(":");
  let seconds = 0;

  if (time_array.length === 3) {
    seconds += parseInt(time_array[0]) * 3600;
    seconds += parseInt(time_array[1]) * 60;
    seconds += parseFloat(time_array[2]);
  }

  // round decimal to 2 digits
  seconds = Math.round(seconds * 100) / 100;

  return seconds;
};

export const convertTimeToMinutes = (time) => {
  if (!time) {
    return;
  }

  // convert HH:MM:SS.SSS to seconds
  let time_array = time.split(":");
  let seconds = 0;

  if (time_array.length === 3) {
    seconds += parseInt(time_array[0]) * 3600;
    seconds += parseInt(time_array[1]) * 60;
    seconds += parseFloat(time_array[2]);
  }

  // round decimal to 2 digits
  let minutes = seconds / 60;
  minutes = Math.round(minutes * 100) / 100;

  return minutes;
};

export const formatDate = (dateTime) => {
  if (!dateTime) return "";

  const now = moment();
  const dateTimeObj = moment(dateTime);
  const format = now.year() !== dateTimeObj.year() ? "D MMM YY" : "D MMM";
  return <Moment format={format}>{dateTimeObj}</Moment>;
};

export const formatDateTime = (dateTime) => {
  if (dateTime === "") {
    return "";
  }
  const now = moment();
  const dateTimeObj = moment(dateTime);
  const format =
    now.year() != dateTimeObj.year() ? "D MMM YY, hh:mm a" : "D MMM, hh:mm a";
  return <Moment format={format}>{dateTimeObj}</Moment>;
};

/**
 * It takes a start and end date and returns a string in the format "DD - DD mmm yy"
 * or "DD - DD mmm" depending on whether the start and end dates are in the
 * current year
 * @returns A function that takes an object with a startDate and endDate property
 * and returns a formatted string.
 */
export const convertDateRangeToCompactFormat = ({ startDate, endDate }) => {
  if (!isValidDate(startDate) || !isValidDate(endDate)) return "";

  let doShowYear = true;

  // * Check whether to show the year or not
  if (startDate instanceof Date && endDate instanceof Date) {
    const currentYear = moment().year();
    doShowYear =
      startDate.getFullYear() !== currentYear &&
      endDate.getFullYear() !== currentYear;
  }

  // * Use ECMA Internationalization API to format range
  const fmt2 = new Intl.DateTimeFormat("en-GB", {
    month: "short",
    day: "numeric",
    ...(doShowYear ? { year: "2-digit" } : {}),
  });
  return fmt2.formatRange(startDate, endDate);
};

/**
 * If the date is a valid date, return true, otherwise return false.
 * @param date - The date to check.
 * @returns A boolean value.
 */
export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date.valueOf());
};

/**
 * Accept timedelta duration and return human readable duration string
 * @param time - Timedelta duration string
 * @returns Human readable string.
 */
export const toHumanReadableDuration = (time) => {
  if (!time) return "";

  const momentDuration = moment.duration(time);

  // * If duration is less than 1 seconds, return in milliseconds
  if (momentDuration.asSeconds() < 1) {
    return `${momentDuration.milliseconds()} ms`;
  }

  return formatDuration(momentDuration._data);
};

export const formatStartEndDateString = (startDate, endDate) => {
  return `${startDate}${
    (startDate && endDate && startDate !== endDate && `-${endDate}`) || ""
  }`;
};

/**
 * It takes a date object and returns a string in the format of
 * `YYYY-MM-DDTHH:MM:SSZ` or null value
 * @param date - The date object to be converted to a string.
 * @returns A string in the format of YYYY-MM-DDTHH:MM:SSZ or null value
 */
export const getISOFormatedDatetimeString = (date) => {
  if (!isValidDate(date)) return null;

  return date.toISOString().split(".")[0] + "Z";
};
