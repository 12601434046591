import BookmarkIcon from "components/common/BookmarkIcon/BookmarkIcon";
import BookmarkModalContent from "./BookmarkModalContent/BookmarkModalContent";
import DetailSectionsTopActionBar from "components/common/DetailSectionsTopActionBar/DetailSectionsTopActionBar";
import EditPencilIcon from "images/ic_edit_pencil.svg";
import ReactCustomModal from "components/common/ReactCustomModal/ReactCustomModal";
import routeConstants from "routes/routeConstants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const SearchActions = ({
  isBookmarked,
  setIsBookmarked,
  filterId,
  filterNames,
  groupByNames,
  setIsSaved,
}) => {
  return (
    <DetailSectionsTopActionBar>
      <EditSearchAction {...{ filterId }} />
      <BookmarkSearchAction
        {...{
          filterId,
          isBookmarked,
          setIsBookmarked,
          setIsSaved,
          filterNames,
          groupByNames,
        }}
      />
    </DetailSectionsTopActionBar>
  );
};

export default SearchActions;

//***************************/
//*****Local Components******/
//***************************/

const EditSearchAction = ({ filterId }) => {
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(routeConstants.Search.path, { state: { filterId } });
  };

  return (
    <div
      className="d-flex place-items-center cursor-pointer"
      onClick={handleEdit}>
      <img src={EditPencilIcon} alt="Edit" />
    </div>
  );
};

const BookmarkSearchAction = ({
  filterId,
  isBookmarked,
  setIsSaved,
  filterNames,
  groupByNames,
  setIsBookmarked,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBookmark = () => setIsModalOpen(true);
  return (
    <>
      <BookmarkIcon
        {...{
          isBookmarked,
          showEmptyIcon: true,
          className: "cursor-pointer",
          onClick: handleBookmark,
        }}
      />
      <ReactCustomModal
        {...{
          isOpen: isModalOpen,
          onRequestClose: () => setIsModalOpen(false),
          contentStyles: {
            minHeight: "200px",
          },
        }}>
        <BookmarkModalContent
          {...{
            isBookmarked,
            setIsBookmarked,
            setIsModalOpen,
            filterId,
            setIsSaved,
            filterNames,
            groupByNames,
          }}
        />
      </ReactCustomModal>
    </>
  );
};
