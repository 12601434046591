import routeConstants from "routes/routeConstants";
import { useNavigate } from "react-router-dom";

export const DetailBodyTable = ({ listItems, isState }) => {
  const navigate = useNavigate();

  return (
    <tbody className="Tbody">
      {listItems &&
        listItems.map((listItem, index) => (
          <tr
            className="Tr"
            key={index}
            onClick={() =>
              !isState &&
              navigate(`${routeConstants.PartnersList.path}/${listItem?.id}`)
            }>
            <td className="Td">
              {!isState ? listItem?.partner : listItem?.state}
            </td>
            <td className="Td">{listItem?.count}</td>
          </tr>
        ))}
    </tbody>
  );
};
