import PasswordTextField from "components/common/PasswordTextField/PasswordTextField";

const ResetPasswordTextFields = ({ setPassword, setConfirmPassword }) => {
  return (
    <>
      <PasswordTextField
        {...{
          onChange: (e) => setPassword(e.target.value),
          placeholder: "Password",
          className: "mb-3",
        }}
      />
      <PasswordTextField
        {...{
          onChange: (e) => setConfirmPassword(e.target.value),
          placeholder: "Confirm Password",
        }}
      />
    </>
  );
};

export default ResetPasswordTextFields;
