import * as styles from "./CustomCollapsibleMenu.module.scss";

import { ReactComponent as ChevronIconDownImg } from "images/chevron-down.svg";
import classNames from "classnames";

const CustomCollapsibleMenuHeader = ({
  isOpen,
  isDisabled,
  headerLabel,
  disableHeaderLabel = "Disabled",
}) => {
  const svgClasses = classNames({ "rotate-n90": !isOpen });
  const containerClasses = classNames(styles.Collapsible_Menu_Header, {
    [styles.Disabled_Header]: isDisabled,
  });

  return (
    <>
      <div className={containerClasses}>
        {isDisabled ? disableHeaderLabel : headerLabel}
        <ChevronIconDownImg className={svgClasses} />
      </div>
    </>
  );
};

export default CustomCollapsibleMenuHeader;
