import * as styles from "./ArchiveRawData.module.scss";

import { ReactComponent as ChevronIconDownImg } from "images/chevron-down.svg";
import classNames from "classnames";

const ArchiveRawDataHeader = ({ isOpen, isDisabled }) => {
  const svgClasses = classNames({ "rotate-n90": !isOpen });
  const containerClasses = classNames(
    styles.Archive_Raw_Data_Expandable_Box_Header,
    { [styles.Disabled_Header]: isDisabled }
  );

  return (
    <>
      <div className={containerClasses}>
        {isDisabled ? "Original raw data archived" : "Original Raw Data"}
        <ChevronIconDownImg className={svgClasses} />
      </div>
    </>
  );
};

export default ArchiveRawDataHeader;
