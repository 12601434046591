import {
  getAbbreviatedUserName,
  getUserProperty,
  logout,
} from "common/utils/authenticationUtils";
import { menuRoutes, quickLinkMenuItems } from "./NavBar.constant";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MECLogo from "images/mec_logo.png";
import NavigationDropdown from "../NavigationDropdown/NavigationDropdown";
import { VIEWER_USER_ROLE } from "common/constants";
import classNames from "classnames";
import routeConstants from "routes/routeConstants";
import { camelCase } from "change-case";
import styles from "./NavBar.module.scss";
import { useDispatch } from "react-redux";
import startCase from "lodash/startCase";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //* States
  const [selectedMenuItem, setSelectedMenuItem] = useState("Quick Links");
  const [selectedProfileMenuItem, setSelectedProfileMenuItem] = useState("");
  const userRole = getUserProperty("user_role");
  const isNonViewerUser = userRole !== VIEWER_USER_ROLE;

  //* Effects
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === routeConstants.UserDashboardV2.path
    ) {
      setSelectedMenuItem("Quick Links");
    } else {
      Object.entries(menuRoutes).forEach(([key, value]) => {
        if (location.pathname.startsWith(value)) {
          setSelectedMenuItem(key);
        }
      });
    }
  }, [location]);

  const profileMenuItems = {
    [`${camelCase(userRole)} Role`]: () => {},
    Profile: () => {
      setSelectedMenuItem("Quick Links");
      setSelectedProfileMenuItem("Profile");
      navigate(routeConstants.UserProfile.path);
    },
    Logout: () => logout(dispatch),
  };

  //* Handlers

  const handleMenuItemChange = (menuItem) => {
    setSelectedMenuItem(menuItem);
    navigate(menuRoutes[menuItem]);
  };

  const handleProfileItemChange = (profileItem) => {
    profileMenuItems[profileItem]();
  };

  return (
    <nav className={navWrapperClasses}>
      <Logo />
      <section className={navRightSectionClasses}>
        <NavBarItems
          {...{
            selectedMenuItem,
            isNonViewerUser,
            profileMenuItems: Object.keys(profileMenuItems),
            handleMenuItemChange,
            handleProfileItemChange,
            selectedProfileMenuItem,
          }}
        />
      </section>
    </nav>
  );
};

export default NavBar;

//************************/
//****Local Components****/
//************************/

const Logo = () => {
  const navigate = useNavigate();

  return (
    <section
      className={"cursor-pointer"}
      onClick={() => navigate(routeConstants.UserDashboardV2.path)}>
      <img src={MECLogo} alt="logo" />
      {["staging-test", "uat-test"].includes(process.env.REACT_APP_ENV) && (
        <span className="p-2 Font_H4">
          {process.env.REACT_APP_HEADER_SUBTEXT}
        </span>
      )}
    </section>
  );
};

const NavBarItems = ({
  selectedMenuItem,
  selectedProfileMenuItem,
  profileMenuItems,
  handleMenuItemChange,
  handleProfileItemChange,
}) => {
  return (
    <ul className={navItemsListClasses}>
      <li>
        <NavigationDropdown
          {...{
            title: startCase(selectedMenuItem),
            selectedMenuItem,
            handleMenuItemChange,
            menuItems: quickLinkMenuItems,
          }}
        />
      </li>
      <li className="cursor-pointer">
        <NavigationDropdown
          {...{
            title: getAbbreviatedUserName(),
            selectedMenuItem: selectedProfileMenuItem,
            menuItems: profileMenuItems,
            handleMenuItemChange: handleProfileItemChange,
          }}
        />
      </li>
    </ul>
  );
};

//**************************/
//********Styles************/
//**************************/

const navWrapperClasses = classNames(
  styles.Nav_Wrapper,
  {
    ["Bg_Test_Server_Color"]: [
      "staging-test",
      "staging-demo",
      "uat-test",
      "uat-demo",
    ].includes(process.env.REACT_APP_ENV),
  },
  "Bg_Card_Color Hover_Effect_2",
  "d-flex justify-content-between align-items-center"
);

const navRightSectionClasses = classNames(
  "d-flex align-items-center justify-content-center"
);

const navItemsListClasses = classNames(
  styles.Nav_Items_List,
  "d-flex justify-content-between align-items-center"
);
