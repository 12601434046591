import ToggleSwitch from "components/common/ToggleSwitch/ToggleSwitch";

const CorrectionImportRow = ({ isCorrectionImport, setIsCorrectionImport }) => {
  return (
    <div className="Form_Row">
      <label>Correction Import? </label>
      <ToggleSwitch
        {...{
          value: isCorrectionImport,
          onChange: () => setIsCorrectionImport((value) => !value),
        }}
      />
    </div>
  );
};

export default CorrectionImportRow;
