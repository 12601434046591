import * as styles from "./AssignPAModalContent.module.scss";

import AssignPAModalActions from "./AssignPAModalActions/AssignPAModalActions";
import AssignPAModalWizard from "./AssignPAModalWizard/AssignPAModalWizard";
import { useState } from "react";

const AssignPAModalContent = ({
  refetchSearchRecords,
  setIsModalOpen,
  lastTotalCount,
  partner,
  dataFilterId,
  filterNames,
  groupByNames,
  pivotTableSummary,
  productTypeCounts,
  isLoading,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPA, setSelectedPA] = useState(null);
  const [wizardInstance, setWizardInstance] = useState({});
  const [errorPayload, setErrorPayload] = useState();

  const onStepChange = (stats) => setCurrentStep(stats?.activeStep || 1);

  return (
    <section className={styles.Assign_PA_Modal_Content_Container}>
      <AssignPAModalWizard
        {...{
          onStepChange,
          selectedPA,
          setSelectedPA,
          setWizardInstance,
          lastTotalCount,
          partner,
          filterNames,
          groupByNames,
          pivotTableSummary,
          productTypeCounts,
          isLoading,
          errorPayload,
        }}
      />
      <AssignPAModalActions
        {...{
          refetchSearchRecords,
          setIsModalOpen,
          wizardInstance,
          currentStep,
          selectedPA,
          dataFilterId,
          lastTotalCount,
          errorPayload,
          setErrorPayload,
          productTypeCounts,
          isLoading,
        }}
      />
    </section>
  );
};

export default AssignPAModalContent;
