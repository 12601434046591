import * as styles from "./OverviewTab.module.scss";

import {
  CURRENT_ORDER_FIELD,
  ORDERING_FIELD,
  SCROLL_POSITION,
  SEARCH_ID_OF_RECORD,
  SORTING_ORDER,
} from "common/constants";

import ActivePartner from "./ActivePartner";
import CustomLoader from "components/common/CustomLoader/CustomLoader";
import UploadedFileStatistics from "./UploadedFileStatistics";
import classNames from "classnames";
import { getUserDashboardStatistics } from "common/API/userDashboardAPI";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useState } from "react";

const OverviewTab = () => {
  const [uploadedDataFileStatistics, setUploadedDataFileStatistics] = useState(
    []
  );
  const [activePartners, setActivePartners] = useState([]);

  const {
    data: response,
    isLoading,
    error,
    isError,
  } = useQuery(["user-dashboard-statistics"], () =>
    getUserDashboardStatistics()
  );

  useEffect(() => {
    setUploadedDataFileStatistics(response?.uploaded_data_file_statistics);
    setActivePartners(response?.active_partners);
  }, [response]);

  //* Remove preserved props from records tab of the search detail page
  useEffect(() => {
    sessionStorage.removeItem(SCROLL_POSITION);
    sessionStorage.removeItem(SEARCH_ID_OF_RECORD);
    sessionStorage.removeItem(SORTING_ORDER);
    sessionStorage.removeItem(CURRENT_ORDER_FIELD);
    sessionStorage.removeItem(ORDERING_FIELD);
  }, []);

  // * #####################################
  // * Loading state
  // * #####################################

  if (isLoading) {
    const loaderContainerClasses = classNames(
      styles.Overview_Container,
      "d-flex align-items-center justify-content-center"
    );
    return <CustomLoader containerClasses={loaderContainerClasses} />;
  }

  if (isError) return toast.error(error?.message || "Something went wrong.");

  return (
    <div className={styles.Overview_Container}>
      <div className="row">
        <UploadedFileStatistics {...{ uploadedDataFileStatistics }} />
        <ActivePartner {...{ activePartners }} />
      </div>
    </div>
  );
};

export default OverviewTab;
