import * as styles from "./ScrollToTop.module.scss";

import { useEffect, useState } from "react";
import { ReactComponent as TopArrow } from "images/ic_arrow_top.svg";
import classNames from "classnames";

const ScrollToTop = ({ element, className }) => {
  const [visible, setVisible] = useState(false);

  const scrollClasses = classNames(styles.Scroll_To_Top_Btn, className);

  const onScroll = () => {
    setVisible((element?.current || document?.documentElement).scrollTop > 20);
  };

  useEffect(() => {
    (element?.current || document)?.addEventListener("scroll", onScroll);
    // * Remove listener on unmount
    return () =>
      (element?.current || document)?.removeEventListener("scroll", onScroll);
  }, [element]);

  return (
    <>
      {visible && (
        <button
          onClick={() => {
            (element?.current || window)?.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          className={scrollClasses}>
          <TopArrow />
        </button>
      )}
    </>
  );
};

export default ScrollToTop;
