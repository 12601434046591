import { useState } from "react";
import * as commonStyles from "styles/CommonDropdownStyles.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import { PAGE_IDENTIFIERS } from "common/constants";
import { clearKenyaFilters } from "common/utils/utils";
import { useAutoClearFilter } from "common/hooks/useAutoClearFitlers";

import DateRangeFilter from "components/common/DateRangeFilter/DateRangeFilter";
import ProductModelDropdown from "./ProductModel/ProductModelDropdown";
import CPADropdown from "./CPADropdown/CPADropdown";
import Button from "components/common/Button/Button";

const CollapsibleFilterChildren = ({ setSearchOptions }) => {
  const [productModel, setProductModel] = useState(null);
  const [cpa, setCPA] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const pageIdentifier = PAGE_IDENTIFIERS.DLIGHT_KENYA;
  const buttonTitle = "Clear Filter";

  /**
   * The function `clearFilters` clears all filters and search options for a given page identifier.
   *
   * @param pageIdentifier - The `pageIdentifier` parameter is used to identify the page for which the
   * kenya filters are being cleared.
   */
  const clearFilters = (pageIdentifier) => {
    clearKenyaFilters(pageIdentifier);

    setSearchOptions({});
    setProductModel(null);
    setCPA(null);
    setStartDate("");
    setEndDate("");
  };

  /* To automatically clear delight-kenya filters for a given page identifier. */
  useAutoClearFilter(pageIdentifier, clearKenyaFilters);

  return (
    <div className="d-flex gap-4 flex-wrap">
      <ProductModelDropdown
        {...{
          productModel,
          setProductModel,
          setSearchOptions,
          className: commonStyles.Dropdown,
          pageIdentifier,
        }}
      />
      <CPADropdown
        {...{
          cpa,
          setCPA,
          setSearchOptions,
          className: commonStyles.Dropdown,
          pageIdentifier,
        }}
      />
      <DateRangeFilter
        {...{
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          setSearchOptions,
          className: commonStyles.Date_Picker,
          placeholder: "Install Date",
          pageIdentifier,
        }}
      />
      <Button
        {...{
          buttonType: ButtonTypes.PRIMARY_SMALL,
          buttonContentType: ButtonContentTypes.TEXT,
          onClick: () => clearFilters(pageIdentifier),
          title: buttonTitle,
        }}>
        <span>{buttonTitle}</span>
      </Button>
    </div>
  );
};

export default CollapsibleFilterChildren;
