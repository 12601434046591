import * as styles from "./FileError.module.scss";

import { capitalCase } from "change-case";

const ErrorContainer = ({ errorName, errorValue }) => {
  return (
    <section className={styles.Error_Container}>
      <ErrorLabel {...{ errorName }} />
      <ErrorValue {...{ errorValue }} />
    </section>
  );
};

export default ErrorContainer;

//***************************/
//*****Local Components******/
//***************************/

const ErrorLabel = ({ errorName }) => {
  return (
    <span className="Font_H7 Font_Subtitle_Color" role="label">
      {capitalCase(errorName)}
    </span>
  );
};

const ErrorValue = ({ errorValue }) => {
  let render = JSON.stringify(errorValue);

  if (Array.isArray(errorValue)) {
    render = <RenderErrorArray {...{ errorValue }} />;
  } else if (typeof errorValue === "object") {
    render = <RenderErrorObject {...{ errorValue }} />;
  }

  return (
    <section className={styles.Error_Msg_Flex_Container}>{render}</section>
  );
};

const RenderErrorArray = ({ errorValue }) => {
  return (
    <>
      {errorValue?.map((error, index) => (
        <span className="Font_H7 Font_Title_Color" role="error-obj" key={index}>
          {typeof error === "object" ? (
            <>
              <span>{error[Object.keys(error)[0]]}</span>
              <span>({error[Object.keys(error)[1]]})</span>
            </>
          ) : (
            error
          )}
        </span>
      ))}
    </>
  );
};

const RenderErrorObject = ({ errorValue }) => {
  return (
    <>
      {Object.entries(errorValue)?.map(([key, value], index) => (
        <span className="Font_H7 Font_Title_Color" role="error-obj" key={index}>
          <span>{key}</span>&nbsp;
          <span>
            ({Array.isArray(value) ? value.join(", ") : value} Row indices)
          </span>
        </span>
      ))}
    </>
  );
};
