import { formatStartEndDateString } from "common/utils/dateAndTimeUtils";

const SalesChartTitle = ({
  PADetail,
  productTypeName,
  partner,
  state,
  startYear = "",
  endYear = "",
}) => {
  startYear = startYear || "All Years";
  const paCode = PADetail?.code;

  return (
    <span className="Font_H7 Font_Title_Color">
      {paCode} | {productTypeName} {partner && "|"} {partner} {state && "|"}{" "}
      {state} {startYear && "|"} {formatStartEndDateString(startYear, endYear)}
    </span>
  );
};

export default SalesChartTitle;
