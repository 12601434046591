import { useEffect } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";
import { getErrorMessage } from "common/utils/errorUtils";
import { getProjectActivityList } from "common/API/mongolianProgramAPIs";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

const ProjectActivityDropdown = ({
  projectActivity,
  setProjectActivity,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  const {
    data: response,
    isError,
    error,
  } = useQuery("mongolia-data-project-activities", getProjectActivityList);

  if (isError) toast.error(getErrorMessage(error), "Cannot fetch PA list");

  const projectActivityList = response?.data?.payload;

  useEffect(() => {
    const storedProjectActivity = sessionStorage.getItem(
      `${pageIdentifier}ProjectActivities`
    );
    if (storedProjectActivity) {
      setProjectActivity(JSON.parse(storedProjectActivity));
    }
  }, [pageIdentifier]);

  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      project_activity: projectActivity?.value,
    }));
    if (projectActivity !== undefined || projectActivity !== null) {
      sessionStorage.setItem(
        `${pageIdentifier}ProjectActivities`,
        JSON.stringify(projectActivity)
      );
    }
  }, [projectActivity, setSearchOptions, pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: projectActivity,
        setSelectedOption: setProjectActivity,
        options: projectActivityList,
        customWidth: "300px",
        className,
        placeholder: "Project Activity",
      }}
    />
  );
};

export default ProjectActivityDropdown;
