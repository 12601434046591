import * as styles from "./Login.module.scss";

import { ButtonContentTypes, ButtonTypes } from "constants/enumTypes.constants";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Button from "components/common/Button/Button";
import EmailTextField from "components/common/EmailTextField/EmailTextField";
import HyperLink from "components/common/HyperLink/HyperLink";
import Logo from "images/mec_logo.png";
import PasswordTextField from "components/common/PasswordTextField/PasswordTextField";
import { getErrorMessage } from "common/utils/errorUtils";
import { isAuthenticated as isUserAuthenticated } from "common/utils/authenticationUtils";
import { login } from "common/API/authenticationAPIs";
import routeConstants from "routes/routeConstants";
import { setUser } from "slices/userSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const searchParams = new URLSearchParams(location.search);
  const next = searchParams.get("next") || "/";

  const user = useSelector((state) => state.user.user, shallowEqual);
  const isAuthenticated = isUserAuthenticated(user);

  const dispatch = useDispatch();

  const [email, setEmail] = useState(0);
  const [password, setPassword] = useState(0);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // UseEffect to redirect to default page if user is authenticated
  useEffect(() => {
    isAuthenticated && navigate(next);
  }, [isAuthenticated]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setMessage("");
    try {
      const response = await login({ email, password });
      dispatch(setUser(response?.data?.payload));
      navigate(next);
    } catch (error) {
      if (error?.response?.status) {
        // For any other error coming from server like 401, 404 or 400
        toast.error(getErrorMessage(error), "Failed");
      } else {
        // If server is not reachable
        setMessage("Something went wrong! Please try again later.");
      }
    }
  };

  return (
    <div className="container" data-testid="Login">
      <div className="d-flex justify-content-center">
        <form className={styles.Login} onSubmit={handleLogin}>
          <img src={Logo} alt="logo" className="col-3" />
          <p className="Font_H4 text-center mt-3 mb-4">Micro Energy Credits</p>
          <EmailTextField
            {...{
              className: styles.Login_Textfields,
              email,
              setEmail,
            }}
          />
          <PasswordTextField
            {...{
              className: styles.Login_Textfields,
              placeholder: "Password",
              onChange: (e) => setPassword(e.target.value),
            }}
          />
          {message && <p className="text-danger mt-1">{message}</p>}
          <div className="d-flex justify-content-between px-1 pt-1 align-items-center">
            <Button
              buttonType={ButtonTypes.PRIMARY_SMALL}
              buttonContentType={ButtonContentTypes.ICON_TEXT}
              isDisabled={!email || !password}
              type="submit">
              <span>Login</span>
            </Button>
            <HyperLink
              text="Forgot Password?"
              onClick={() => navigate(routeConstants.ForgotPassword.path)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
