import * as styles from "./CustomDateRangePicker.module.scss";

import { DateRangePicker } from "react-date-range";

const DateRangePickerWrapper = ({
  dateRangePickerRef,
  selectedDateRange,
  setSelectedDateRange,
  rangeKey,
  minDate,
  maxDate,
}) => {
  const handleOnChange = (item) => {
    const { startDate, endDate } = item[rangeKey];

    // * Remove disabled dates from the range selection
    item[rangeKey] = {
      ...item[rangeKey],
      startDate: minDate && startDate < minDate ? minDate : startDate,
      endDate: maxDate && endDate > maxDate ? maxDate : endDate,
    };

    setSelectedDateRange([item[rangeKey]]);
  };

  return (
    <div ref={dateRangePickerRef} className={styles.Date_Range_Picker_Wrapper}>
      <DateRangePicker
        onChange={handleOnChange}
        months={1} // * Renders a single month
        showDateDisplay={false} // * Hides top date range display
        inputRanges={[]} // * Removes the passive date fields for DefinedRange
        ranges={selectedDateRange}
        classNames={{
          prevButton: "rdrPrevButtonClassName",
          nextButton: "rdrNextButtonClassName",
        }}
        {...(minDate ? { minDate } : {})}
        {...(maxDate ? { maxDate } : {})}
      />
    </div>
  );
};

export default DateRangePickerWrapper;
