import { useEffect } from "react";

import ReactSelectDropdown from "components/common/ReactSelectDropdown/ReactSelectDropdown";

const exportTypeList = [
  { value: "Clean", name: "Clean" },
  { value: "Direct", name: "Direct" },
  { value: "Raw", name: "Raw" },
];

const ExportTypeDropdown = ({
  exportType,
  setExportType,
  setSearchOptions,
  className,
  pageIdentifier,
}) => {
  useEffect(() => {
    const storedExportType = sessionStorage.getItem(
      `${pageIdentifier}ExportType`
    );
    if (storedExportType) {
      setExportType(JSON.parse(storedExportType));
    }
  }, [pageIdentifier]);

  useEffect(() => {
    setSearchOptions((value) => ({
      ...value,
      report_type: exportType?.value,
    }));
    if (exportType !== undefined) {
      sessionStorage.setItem(
        `${pageIdentifier}ExportType`,
        JSON.stringify(exportType)
      );
    }
  }, [exportType, setSearchOptions, pageIdentifier]);

  return (
    <ReactSelectDropdown
      {...{
        isAsync: false,
        selectedOption: exportType,
        setSelectedOption: setExportType,
        options: exportTypeList,
        customWidth: "300px",
        className,
        placeholder: "Export Type",
      }}
    />
  );
};

export default ExportTypeDropdown;
