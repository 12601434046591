import Async from "react-select/async";
import DropdownIndicatorImg from "images/ic_duotone_arrow_bottom.svg";
import Select from "react-select";
import cloneDeep from "lodash/cloneDeep";
import { reactSelectCustomStyles } from "./ReactSelectCustomStyles";

const ReactSelectDropdown = ({
  isAsync,
  selectedOption = "",
  setSelectedOption,
  options,
  dynamicOptions,
  customWidth,
  ...otherProps
}) => {
  const reactSelectCustomStylesCopy = cloneDeep(reactSelectCustomStyles);

  if (customWidth) {
    const containerStyles = reactSelectCustomStylesCopy.container({});
    reactSelectCustomStylesCopy.container = (provided) => ({
      ...provided,
      ...containerStyles,
      width: customWidth,
    });
  }

  const reactSelectCommonProps = {
    styles: reactSelectCustomStylesCopy,
    value: selectedOption,
    onChange: setSelectedOption,
    getOptionLabel: (e) => e.name,
    getOptionValue: (e) => e.value,
    isSearchable: true,
    isClearable: true,
    placeholder: "Select an option",
    menuPlacement: "auto",
    components: {
      IndicatorSeparator: () => null,
      DropdownIndicator: () => (
        <img src={DropdownIndicatorImg} alt="drop down" />
      ),
    },
    ...otherProps,
  };

  if (isAsync) {
    return (
      <Async
        cacheOptions
        loadOptions={dynamicOptions}
        defaultOptions={options}
        {...reactSelectCommonProps}
      />
    );
  }

  return <Select options={options} {...reactSelectCommonProps} />;
};

export default ReactSelectDropdown;
