import classNames from "classnames";

const NoDataFound = ({ title, wrapperClasses, spanClasses }) => {
  const noDataWrapperClasses = classNames(wrapperClasses);
  const newSpanClasses = classNames(
    "Font_H5 Font_Disabled_Color col-7",
    spanClasses
  );

  return (
    <section className={noDataWrapperClasses}>
      <span className={newSpanClasses}>{title}</span>
    </section>
  );
};

export default NoDataFound;
