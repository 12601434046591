import React from "react";
import SortableTableHeader from "components/common/SortableTableHeader/SortableTableHeader";

const RecordListHeader = ({
  records,
  sortOrder,
  currentOrderField,
  handleSorting,
  isLoading,
  isCountStale,
}) => {
  const isHeaderNonClickable =
    records?.length === 0 || isLoading || isCountStale;
  const isHeaderSticky = true;

  return (
    <thead className="Thead">
      <tr>
        <SortableTableHeader
          {...{
            title: "Transaction Id",
            onClick: () => handleSorting("Transaction Id", ["transaction_id"]),
            sortOrder,
            currentOrderField,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Customer Id",
            onClick: () => handleSorting("Customer Id", ["customer_id"]),
            sortOrder,
            currentOrderField,
            isLoading,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Customer Name",
            onClick: () => handleSorting("Customer Name", ["customer_name"]),
            sortOrder,
            currentOrderField,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Product Type",
            onClick: () => handleSorting("Product Type", ["product_type"]),
            sortOrder,
            currentOrderField,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Product Model",
            onClick: () => handleSorting("Product Model", ["product_model"]),
            sortOrder,
            currentOrderField,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Installation Date",
            onClick: () => handleSorting("Installation Date", ["date"]),
            sortOrder,
            currentOrderField,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
        <SortableTableHeader
          {...{
            title: "Branch",
            onClick: () => handleSorting("Branch", ["branch"]),
            sortOrder,
            currentOrderField,
            isHeaderSticky,
            isHeaderNonClickable,
          }}
        />
      </tr>
    </thead>
  );
};

export default React.memo(RecordListHeader);
