import * as styles from "./Switch.module.scss";

import { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Switch = ({
  switchItems,
  groupName,
  selectedSwitchItem,
  setSelectedSwitchItem,
  isDisabled = false,
}) => {
  const classes = classNames(styles.Switch_Container, {
    [styles.Disabled_Switch_Container]: isDisabled,
  });

  return (
    <div className={classes}>
      {switchItems?.map(({ name, value }) => {
        const id = `${groupName}-${value}`;
        const defaultChecked = selectedSwitchItem === value;

        return (
          <Fragment key={id}>
            <input
              {...{
                type: "radio",
                name: groupName,
                value,
                id,
                defaultChecked,
                onClick: (e) => setSelectedSwitchItem(e.target.value),
              }}
            />
            <label htmlFor={id}>{name}</label>
          </Fragment>
        );
      })}
    </div>
  );
};

Switch.propTypes = {
  switchItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  groupName: PropTypes.string.isRequired,
  selectedSwitchItem: PropTypes.string.isRequired,
  setSelectedSwitchItem: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default Switch;
