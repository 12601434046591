import axios from "axios";
import { getAuthenticationURLs } from "common/utils/apiUrls";
import { apiURL, axiosInstance } from "./api";

// API to Authorize
export const login = (data) => {
  return axios
    .create({
      baseURL: apiURL,
    })
    .post(getAuthenticationURLs("login"), data);
};

// API to get email for reseting password
export const sendRecoveryEmail = (data) => {
  return axiosInstance.patch(getAuthenticationURLs("passwordResetEmail"), data);
};

// API to resset passowrd
export const resetPassword = (data) => {
  return axiosInstance.patch(getAuthenticationURLs("resetPassword"), data);
};

// API to passowrd reset token verification
export const passwordResetTokenVerification = (userId, passwordResetToken) => {
  return axiosInstance.get(
    getAuthenticationURLs("passwordResetTokenVerification"),
    {
      params: {
        user_id: userId,
        token: passwordResetToken,
      },
    }
  );
};
